import React from "react";
const defaultSorted = [
    {
        dataField: "UseGroupClassification",
        order: "asc",
    },
];
const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
        {size} の結果の {from} から {to} を表示しています
    </span>
);
const pageButtonRenderer = ({ page, onPageChange, currSizePerPage }) => {
    const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
    };
    return (
        <button
            key={page}
            className={`btn ${currSizePerPage === `${page}` ? "btn-warning" : "btn-light"
                }`}
            type="button"
            onClick={handleClick}
        >
            <li className="page-item">
                <a style={{ color: "black" }} href="#">
                    {page}
                </a>
            </li>
        </button>
    );
};
const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
}) => (
    <div className="btn-group" role="group">
        {options.map((option) => (
            <button
                key={option.text}
                type="button"
                onClick={() => onSizePerPageChange(option.page)}
                className={`btn ${currSizePerPage === `${option.page}` ? "btn-warning" : "btn-light"
                    }`}
            >
                {option.text}
            </button>
        ))}
    </div>
);
const options = {
    paginationSize: 20,
    pageStartIndex: 1,
    showTotal: true,
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: "最初",
    prePageText: "戻る",
    nextPageText: "次へ",
    lastPageText: "最後",
    nextPageTitle: "最初のページ",
    prePageTitle: "プレページ",
    firstPageTitle: "次のページ",
    lastPageTitle: "最後のページ",
    paginationTotalRenderer: customTotal,
    sizePerPageRenderer: sizePerPageRenderer,
    pageButtonRenderer: pageButtonRenderer,
    disablePageTitle: true,
    sizePerPageList: [
        {
            text: "20",
            value: 20,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        },
    ],
};
const openValueInit = {
    UseGroupClassification: true,
    itemCode: true,
    MaterialCode: true,
    ItemName: true,
    SalesDocType: true,
    suppCode: true,
    SuppName: true,
    SuppGroup: true,
    OrderReceiptCode: true,
    OrderName: true,
    shipmentCode: true,
    DestName: true,
    EUARBmgmtCode: true,
    endUserName: true,
    TorayEndUser: true,
    TorayEndUserName: true,
    Capacity: true,
    CapacityUnit: true,
    PriceUnit: true,
    RegQty: true,
    OrdQty: true,
    DTDelOrderRec: true,
    PriceTypeSales: true,
    PriceTypePurchase: true,
    SalesUnitPriceScale1: true,
    SalesUnitPriceScale2: true,
    SalesUnitPriceScale3: true,
    SalesUnitPriceScale4: true,
    SalesUnitPriceScale5: true,
    SalesUnitPriceUnitScale1: true,
    SalesUnitPriceUnitScale2: true,
    SalesUnitPriceUnitScale3: true,
    SalesUnitPriceUnitScale4: true,
    SalesUnitPriceUnitScale5: true,
    PurchUnitPriceScale1: true,
    PurchUnitPriceScale2: true,
    PurchUnitPriceScale3: true,
    PurchUnitPriceScale4: true,
    PurchUnitPriceScale5: true,
    PurchUnitPriceUnitScale1: true,
    PurchUnitPriceUnitScale2: true,
    PurchUnitPriceUnitScale3: true,
    PurchUnitPriceUnitScale4: true,
    PurchUnitPriceUnitScale5: true,
    BulkSales: true,
    BulkShippingFee: true,
    CoolDeliveryCharge: true,
    SalespersonCode: true,
    LastOrderDate: true,
    WebOrderNumber: true,
    ExpirationStart: true,
    Expired: true,
    RecordSalesDate: true,
    RecordSalesApplicable: true,
    PurchaseAccountDate: true,
    AcctForPurchasesApplicableble: true,
    SalesRevRate: true,
    SalesRevPrice: true,
    PurchaseRevRate: true,
    PurchaseRevPrice: true,
    ActualFlag: true,
    HistoryNum: true,
    Remarks: true,
    UpDailyPayment: true,
    UpTime: true,
    SeqNo: true,
};
const dialogText1 = {
    dialogText: {
        UseGroupClassification: "",
        ItemCode: "",
        MaterialCode: "",
        ItemName: "",
        SalesDocType: "",
        SuppCode: "",
        SuppName: "",
        SuppGroup: "",
        OrderReceiptCode: "",
        OrderName: "",
        ShipmentCode: "",
        DestName: "",
        EUARBmgmtCode: "",
        EndUserName: "",
        TorayEndUser: "",
        TorayEndUserName: "",
        Capacity: "",
        CapacityUnit: "",
        PriceUnit: "",
        RegQty: "",
        OrdQty: "",
        DTDelOrderRec: "",
        PriceTypeSales: "",
        PriceTypePurchase: "",
        SalesUnitPriceScale1: "",
        SalesUnitPriceScale2: "",
        SalesUnitPriceScale3: "",
        SalesUnitPriceScale4: "",
        SalesUnitPriceScale5: "",
        SalesUnitPriceUnitScale1: "",
        SalesUnitPriceUnitScale2: "",
        SalesUnitPriceUnitScale3: "",
        SalesUnitPriceUnitScale4: "",
        SalesUnitPriceUnitScale5: "",
        PurchUnitPriceScale1: "",
        PurchUnitPriceScale2: "",
        PurchUnitPriceScale3: "",
        PurchUnitPriceScale4: "",
        PurchUnitPriceScale5: "",
        PurchUnitPriceUnitScale1: "",
        PurchUnitPriceUnitScale2: "",
        PurchUnitPriceUnitScale3: "",
        PurchUnitPriceUnitScale4: "",
        PurchUnitPriceUnitScale5: "",
        BulkSales: "",
        BulkShippingFee: "",
        CoolDeliveryCharge: "",
        SalespersonCode: "",
        LastOrderDate: "",
        WebOrderNumber: "",
        ExpirationStart: "",
        Expired: "",
        RecordSalesDate: "",
        RecordSalesApplicable: "",
        PurchaseAccountDate: "",
        AcctForPurchasesApplicableble: "",
        SalesRevRate: "",
        SalesRevPrice: "",
        PurchaseRevRate: "",
        PurchaseRevPrice: "",
        ActualFlag: "",
        OrderQuantity: "",
        HistoryNum: "",
        Remarks: "",
        UpDailyPayment: "",
        UpTime: "",
        SeqNo: "",
        id:"",
    }

}
const dialogText2 = {
    UseGroupClassification: "",
    ItemCode: "",
    MaterialCode: "",
    ItemName: "",
    SalesDocType: "",
    SuppCode: "",
    SuppName: "",
    SuppGroup: "",
    OrderReceiptCode: "",
    OrderName: "",
    ShipmentCode: "",
    DestName: "",
    EUARBmgmtCode: "",
    EndUserName: "",
    TorayEndUser: "",
    TorayEndUserName: "",
    Capacity: "",
    CapacityUnit: "",
    PriceUnit: "",
    RegQty: "",
    OrdQty: "",
    DTDelOrderRec: "",
    PriceTypeSales: "",
    PriceTypePurchase: "",
    SalesUnitPriceScale1: "",
    SalesUnitPriceScale2: "",
    SalesUnitPriceScale3: "",
    SalesUnitPriceScale4: "",
    SalesUnitPriceScale5: "",
    SalesUnitPriceUnitScale1: "",
    SalesUnitPriceUnitScale2: "",
    SalesUnitPriceUnitScale3: "",
    SalesUnitPriceUnitScale4: "",
    SalesUnitPriceUnitScale5: "",
    PurchUnitPriceScale1: "",
    PurchUnitPriceScale2: "",
    PurchUnitPriceScale3: "",
    PurchUnitPriceScale4: "",
    PurchUnitPriceScale5: "",
    PurchUnitPriceUnitScale1: "",
    PurchUnitPriceUnitScale2: "",
    PurchUnitPriceUnitScale3: "",
    PurchUnitPriceUnitScale4: "",
    PurchUnitPriceUnitScale5: "",
    BulkSales: "",
    BulkShippingFee: "",
    CoolDeliveryCharge: "",
    SalespersonCode: "",
    LastOrderDate: "",
    WebOrderNumber: "",
    ExpirationStart: "",
    Expired: "",
    RecordSalesDate: "",
    RecordSalesApplicable: "",
    PurchaseAccountDate: "",
    AcctForPurchasesApplicableble: "",
    SalesRevRate: "",
    SalesRevPrice: "",
    PurchaseRevRate: "",
    PurchaseRevPrice: "",
    ActualFlag: "",
    OrderQuantity: "",
    HistoryNum: "",
    Remarks: "",
    UpDailyPayment: "",
    UpTime: "",
    SeqNo: "",
    id:"",
}
const ArPriceInfoConst = {
    PROC_ADD: "新規登録",
    PROC_CADD: "参照登録",
    PROC_REF: "変更",
    PROC_DEL: "削除",
    DilogDetill: "",
    DilogDetillDisabled: {
        dialogTextUseGroupClassification: false,
        dialogTextItemCode: false,
        dialogTextMaterialCode: false,
        dialogTextItemName: false,
        dialogTextSalesDocType: false,
        dialogTextSuppCode: false,
        dialogTextSuppName: false,
        dialogTextSuppGroup: false,
        dialogTextOrderReceiptCode: false,
        dialogTextOrderName: true,
        dialogTextShipmentCode: false,
        dialogTextDestName: true,
        dialogTextEUARBmgmtCode: false,
        dialogTextEndUserName: false,
        dialogTextTorayEndUser: false,
        dialogTextTorayEndUserName: false,
        dialogTextCapacity: false,
        dialogTextCapacityUnit: false,
        dialogTextPriceUnit: false,
        dialogTextRegQty: false,
        dialogTextOrdQty: false,
        dialogTextDTDelOrderRec: false,
        dialogTextPriceTypeSales: false,
        dialogTextPriceTypePurchase: false,
        dialogTextSalesUnitPriceScale1: false,
        dialogTextSalesUnitPriceScale2: false,
        dialogTextSalesUnitPriceScale3: false,
        dialogTextSalesUnitPriceScale4: false,
        dialogTextSalesUnitPriceScale5: false,
        dialogTextSalesUnitPriceUnitScale1: false,
        dialogTextSalesUnitPriceUnitScale2: false,
        dialogTextSalesUnitPriceUnitScale3: false,
        dialogTextSalesUnitPriceUnitScale4: false,
        dialogTextSalesUnitPriceUnitScale5: false,
        dialogTextPurchUnitPriceScale1: false,
        dialogTextPurchUnitPriceScale2: false,
        dialogTextPurchUnitPriceScale3: false,
        dialogTextPurchUnitPriceScale4: false,
        dialogTextPurchUnitPriceScale5: false,
        dialogTextPurchUnitPriceUnitScale1: false,
        dialogTextPurchUnitPriceUnitScale2: false,
        dialogTextPurchUnitPriceUnitScale3: false,
        dialogTextPurchUnitPriceUnitScale4: false,
        dialogTextPurchUnitPriceUnitScale5: false,
        dialogTextBulkSales: false,
        dialogTextBulkShippingFee: false,
        dialogTextCoolDeliveryCharge: false,
        dialogTextSalespersonCode: false,
        dialogTextLastOrderDate: true,
        dialogTextWebOrderNumber: true,
        dialogTextExpirationStart: false,
        dialogTextExpired: false,
        dialogTextRecordSalesDate: false,
        dialogTextRecordSalesApplicable: false,
        dialogTextPurchaseAccountDate: false,
        dialogTextAcctForPurchasesApplicableble: false,
        dialogTextSalesRevRate: true,
        dialogTextSalesRevPrice: true,
        dialogTextPurchaseRevRate: true,
        dialogTextPurchaseRevPrice: true,
        dialogTextActualFlag: false,
        dialogTextOrderQuantity: false,
        dialogTextHistoryNum: false,
        dialogTextRemarks: false,
        dialogTextUpDailyPayment: true,
        dialogTextUpTime: true,
        dialogTextSeqNo: true,
    }
}
const DilogDetillDisabled = {
    add:{
        dialogTextUseGroupClassification: false,
        dialogTextItemCode: false,
        dialogTextMaterialCode: false,
        dialogTextItemName: false,
        dialogTextSalesDocType: false,
        dialogTextSuppCode: false,
        dialogTextSuppName: true,
        dialogTextSuppGroup: false,
        dialogTextOrderReceiptCode: false,
        dialogTextOrderName: true,
        dialogTextShipmentCode: false,
        dialogTextDestName: true,
        dialogTextEUARBmgmtCode: false,
        dialogTextEndUserName: true,
        dialogTextTorayEndUser: false,
        dialogTextTorayEndUserName: false,
        dialogTextCapacity: false,
        dialogTextCapacityUnit: false,
        dialogTextPriceUnit: false,
        dialogTextRegQty: false,
        dialogTextOrdQty: false,
        dialogTextDTDelOrderRec: false,
        dialogTextPriceTypeSales: false,
        dialogTextPriceTypePurchase: false,
        dialogTextSalesUnitPriceScale1: false,
        dialogTextSalesUnitPriceScale2: false,
        dialogTextSalesUnitPriceScale3: false,
        dialogTextSalesUnitPriceScale4: false,
        dialogTextSalesUnitPriceScale5: false,
        dialogTextSalesUnitPriceUnitScale1: false,
        dialogTextSalesUnitPriceUnitScale2: false,
        dialogTextSalesUnitPriceUnitScale3: false,
        dialogTextSalesUnitPriceUnitScale4: false,
        dialogTextSalesUnitPriceUnitScale5: false,
        dialogTextPurchUnitPriceScale1: false,
        dialogTextPurchUnitPriceScale2: false,
        dialogTextPurchUnitPriceScale3: false,
        dialogTextPurchUnitPriceScale4: false,
        dialogTextPurchUnitPriceScale5: false,
        dialogTextPurchUnitPriceUnitScale1: false,
        dialogTextPurchUnitPriceUnitScale2: false,
        dialogTextPurchUnitPriceUnitScale3: false,
        dialogTextPurchUnitPriceUnitScale4: false,
        dialogTextPurchUnitPriceUnitScale5: false,
        dialogTextBulkSales: false,
        dialogTextBulkShippingFee: false,
        dialogTextCoolDeliveryCharge: false,
        dialogTextSalespersonCode: false,
        dialogTextLastOrderDate: true,
        dialogTextWebOrderNumber: true,
        dialogTextExpirationStart: false,
        dialogTextExpired: false,
        dialogTextRecordSalesDate: false,
        dialogTextRecordSalesApplicable: false,
        dialogTextPurchaseAccountDate: false,
        dialogTextAcctForPurchasesApplicableble: false,
        dialogTextSalesRevRate: true,
        dialogTextSalesRevPrice: true,
        dialogTextPurchaseRevRate: true,
        dialogTextPurchaseRevPrice: true,
        dialogTextActualFlag: false,
        dialogTextOrderQuantity: false,
        dialogTextHistoryNum: false,
        dialogTextRemarks: false,
        dialogTextUpDailyPayment: true,
        dialogTextUpTime: true,
        dialogTextSeqNo: true,
    },
    modify:{
        dialogTextUseGroupClassification: false,
        dialogTextItemCode: false,
        dialogTextMaterialCode: false,
        dialogTextItemName: false,
        dialogTextSalesDocType: false,
        dialogTextSuppCode: false,
        dialogTextSuppName: false,
        dialogTextSuppGroup: false,
        dialogTextOrderReceiptCode: false,
        dialogTextOrderName: false,
        dialogTextShipmentCode: false,
        dialogTextDestName: false,
        dialogTextEUARBmgmtCode: false,
        dialogTextEndUserName: false,
        dialogTextTorayEndUser: false,
        dialogTextTorayEndUserName: false,
        dialogTextCapacity: false,
        dialogTextCapacityUnit: false,
        dialogTextPriceUnit: false,
        dialogTextRegQty: false,
        dialogTextOrdQty: false,
        dialogTextDTDelOrderRec: false,
        dialogTextPriceTypeSales: false,
        dialogTextPriceTypePurchase: false,
        dialogTextSalesUnitPriceScale1: false,
        dialogTextSalesUnitPriceScale2: false,
        dialogTextSalesUnitPriceScale3: false,
        dialogTextSalesUnitPriceScale4: false,
        dialogTextSalesUnitPriceScale5: false,
        dialogTextSalesUnitPriceUnitScale1: false,
        dialogTextSalesUnitPriceUnitScale2: false,
        dialogTextSalesUnitPriceUnitScale3: false,
        dialogTextSalesUnitPriceUnitScale4: false,
        dialogTextSalesUnitPriceUnitScale5: false,
        dialogTextPurchUnitPriceScale1: false,
        dialogTextPurchUnitPriceScale2: false,
        dialogTextPurchUnitPriceScale3: false,
        dialogTextPurchUnitPriceScale4: false,
        dialogTextPurchUnitPriceScale5: false,
        dialogTextPurchUnitPriceUnitScale1: false,
        dialogTextPurchUnitPriceUnitScale2: false,
        dialogTextPurchUnitPriceUnitScale3: false,
        dialogTextPurchUnitPriceUnitScale4: false,
        dialogTextPurchUnitPriceUnitScale5: false,
        dialogTextBulkSales: false,
        dialogTextBulkShippingFee: false,
        dialogTextCoolDeliveryCharge: false,
        dialogTextSalespersonCode: false,
        dialogTextLastOrderDate: true,
        dialogTextWebOrderNumber: true,
        dialogTextExpirationStart: false,
        dialogTextExpired: false,
        dialogTextRecordSalesDate: false,
        dialogTextRecordSalesApplicable: false,
        dialogTextPurchaseAccountDate: false,
        dialogTextAcctForPurchasesApplicableble: false,
        dialogTextSalesRevRate: true,
        dialogTextSalesRevPrice: true,
        dialogTextPurchaseRevRate: true,
        dialogTextPurchaseRevPrice: true,
        dialogTextActualFlag: false,
        dialogTextOrderQuantity: false,
        dialogTextHistoryNum: false,
        dialogTextRemarks: false,
        dialogTextUpDailyPayment: true,
        dialogTextUpTime: true,
        dialogTextSeqNo: true,
    },
    delete:{
        dialogTextUseGroupClassification: true,
        dialogTextItemCode: true,
        dialogTextMaterialCode: true,
        dialogTextItemName: true,
        dialogTextSalesDocType: true,
        dialogTextSuppCode: true,
        dialogTextSuppName: true,
        dialogTextSuppGroup: true,
        dialogTextOrderReceiptCode: true,
        dialogTextOrderName: true,
        dialogTextShipmentCode: true,
        dialogTextDestName: true,
        dialogTextEUARBmgmtCode: true,
        dialogTextEndUserName: true,
        dialogTextTorayEndUser: true,
        dialogTextTorayEndUserName: true,
        dialogTextCapacity: true,
        dialogTextCapacityUnit: true,
        dialogTextPriceUnit: true,
        dialogTextRegQty: true,
        dialogTextOrdQty: true,
        dialogTextDTDelOrderRec: true,
        dialogTextPriceTypeSales: true,
        dialogTextPriceTypePurchase: true,
        dialogTextSalesUnitPriceScale1: true,
        dialogTextSalesUnitPriceScale2: true,
        dialogTextSalesUnitPriceScale3: true,
        dialogTextSalesUnitPriceScale4: true,
        dialogTextSalesUnitPriceScale5: true,
        dialogTextSalesUnitPriceUnitScale1: true,
        dialogTextSalesUnitPriceUnitScale2: true,
        dialogTextSalesUnitPriceUnitScale3: true,
        dialogTextSalesUnitPriceUnitScale4: true,
        dialogTextSalesUnitPriceUnitScale5: true,
        dialogTextPurchUnitPriceScale1: true,
        dialogTextPurchUnitPriceScale2: true,
        dialogTextPurchUnitPriceScale3: true,
        dialogTextPurchUnitPriceScale4: true,
        dialogTextPurchUnitPriceScale5: true,
        dialogTextPurchUnitPriceUnitScale1: true,
        dialogTextPurchUnitPriceUnitScale2: true,
        dialogTextPurchUnitPriceUnitScale3: true,
        dialogTextPurchUnitPriceUnitScale4: true,
        dialogTextPurchUnitPriceUnitScale5: true,
        dialogTextBulkSales: true,
        dialogTextBulkShippingFee: true,
        dialogTextCoolDeliveryCharge: true,
        dialogTextSalespersonCode: true,
        dialogTextLastOrderDate: true,
        dialogTextWebOrderNumber: true,
        dialogTextExpirationStart: true,
        dialogTextExpired: true,
        dialogTextRecordSalesDate: true,
        dialogTextRecordSalesApplicable: true,
        dialogTextPurchaseAccountDate: true,
        dialogTextAcctForPurchasesApplicableble: true,
        dialogTextSalesRevRate: true,
        dialogTextSalesRevPrice: true,
        dialogTextPurchaseRevRate: true,
        dialogTextPurchaseRevPrice: true,
        dialogTextActualFlag: true,
        dialogTextOrderQuantity: true,
        dialogTextHistoryNum: true,
        dialogTextRemarks: true,
        dialogTextUpDailyPayment: true,
        dialogTextUpTime: true,
        dialogTextSeqNo: true,
    }
}
export {
    openValueInit,
    options,
    defaultSorted,
    ArPriceInfoConst,
    dialogText1,
    dialogText2,
    DilogDetillDisabled,
}
