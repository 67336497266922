import React from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import useStyles from "./ArItemMasterStyle";
import Button from "@material-ui/core/Button";
export default function MasterDialogUpload(param){
    const classes = useStyles()
    const Executionlog = () => {
        param.closedialogArItemMasterDialogUpload()
    }
    return (
        <div style={{ width: "1000px",paddingLeft:"4%",height:"100%" }}>
            <div style={{height: "16%"}}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label>
                            <span>品目データアップロード</span>
                        </label>
                    </InputGroup>
                </div>
            </div>
            <div style={{height: "64%",display:"flex" }}>
                        <label>品目情報データ格納場所指定</label>
                            <FormControl
                                style={{ width: "68%", height: "25px",marginLeft:"2%",border:"1px solid black",borderRadius:"0px"}}
                            />
            </div>
            <div style={{textAlign:"right",marginRight:"2%"}}>
                <Button className="btn" onClick={() => param.closedialogArItemMasterDialogUpload()} style={{ height: 40, width: 100, fontSize: '15px', color: 'white', background: "#000", marginRight: '10px', borderRadius: '8px' }}>戻&nbsp;&nbsp;&nbsp;る</Button>
                <Button className="btn" onClick={() => { Executionlog() }} style={{ height: 40, width: 100, fontSize: '15px', color: 'white', background: "#000", marginRight: '10px', borderRadius: '8px' }}>実&nbsp;&nbsp;&nbsp;行
                </Button>
                <Button className="btn" onClick={() => param.closedialogArItemMasterDialogUpload()}style={{ height: 40, width: 100, fontSize: '15px', color: 'white', background: "#000", marginRight: '10px', borderRadius: '8px' }}>実行ログ
                </Button>
            </div>
        </div>
    )
}