import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textlabel: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '32px',
    width: '15ch',
    background: "rgb(91,56,9)",
    border: "1px solid #707070",
    color: "white",
    fontWeight: "bold",
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: "transparent",
    border: "1px solid #707070",
    borderRadius: "6px",
    color: "black",
  },
  textlabelDialog: {
    width: "120px",
    display:"inline-block",
    textAlign:"right"
  },
  textlabelSpanDialog:{
    fontSize:"14px"
  },
  textRequired:{
    color:"red"
  },
  textDivDialog:{
    height:"30px",
  },
  inputSize:{
    height:"30px"
  },
  inputSizeBa: {
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    color: 'black',
    width: '4ch',
    height: '40px',
    verticalAlign: 'middle',
  },
  textlabelDialogBa:{
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '25px',
    width: '20ch',
    background: "rgb(91,56,9)",
    border: "1px solid #707070",
    color: "white",
    fontWeight: "bold",
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textFieldDialog: {
    margin: theme.spacing(0),
    alignItems: "center",
    textAlign: "center",
    'font-size': '16px',
    color: "white",
  },
  textField2Dialog: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    'font-size': '16px',
    color: "white",
  }
}));
export default useStyles;