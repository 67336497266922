import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  textlabel: {
    marginLeft: theme.spacing(1),
   marginRight: theme.spacing(1),
   height:'32px',
   width:'16ch',
   background: "rgb(91,56,9)",
   border: "1px solid #707070",
   color: "white",
   fontWeight: "bold",
   fontSize:'14px',
   alignItems: 'center',
   justifyContent:'center'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: "transparent",
    border: "1px solid #707070",
    borderRadius: "6px",
    color: "black",
  },
  textlabelDialog: {
    width: "150px",
    display: "inline-block",
    textAlign: "right"
  },
  textlabelSpanDialog: {
    fontSize: "14px"
  },
  textRequired: {
    color: "red"
  },
  textDivDialog: {
    height: "30px",
  },
  inputSize: {
    height: "30px"
  },
  inputSizeBa: {
    marginRight: theme.spacing(1),
    color: 'black',
    width: '3ch',
    height: '3ch',
    verticalAlign: 'middle',
  },
  textlabelDialogBa: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '25px',
    width: '20ch',
    background: "rgb(91,56,9)",
    border: "1px solid #707070",
    color: "white",
    fontWeight: "bold",
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  raidoCheack: {
    backgroundColor: "rgb(240,242,245)",
    height: "25px",
    border: "1px solid #707070",
    borderRadius: "4px!important",
    overflow:"hidden",
    display: "flex",
    alignItems: "center",
    flexWrap:"wrap"
  },
  raidoCheackDiv: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    fontSize:"12px"
  },
  textRight:{
    textAlign:"right"
  },
  fristBorder:{
    width:"1200px",
    height:"90px",
    marginLeft:"30px",
    display:"flex",
    border:"3px solid #DDD"
  },
  fristBorderDiv:{
    width:"50%",
    height:"100%",
  },
  inputSize1: {
    marginRight: theme.spacing(1),
    color: 'black',
    width: '3ch',
    height: '3ch',
    verticalAlign: 'middle',
  },
}));
export default useStyles;