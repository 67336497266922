/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  privacy.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary プライバシーポリシー画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
// pages
import Meta from "../components/Meta";
import React, { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
/*  AWS標準設定 with Login Auth. */
// import Amplify from "aws-amplify";
// import awsconfig from "../aws-exports";

// Amplify.configure(awsconfig);

const pageTitle = "AR BROWN Order System";

const privacy = () => {
  return (
    <Container
      style={{
        backgroundColor: "",
        minWidth: "100%",
        minHeight: "74vh",
        padding: "10px 25% 10px 25%",
      }}
    >
      <Meta title={pageTitle} />
      <Row>
        <Col>
          <div id="page">
            <h1 className="heading1">プライバシーポリシー</h1>

            <h2 className="heading2">個人情報に関する方針</h2>

            <h3 className="heading3">個人情報の取扱いについて</h3>
            <p>
              エア・ブラウン株式会社（以下「当社」といいます）は、お客様からお預かりする個人情報を、お客様の大切な資産であると認識し、その保有及び利用にあたり以下に定めるプライバシーポリシーに基づき、個人情報の保護に努めます。
            </p>

            <h3 className="heading3">プライバシポリシー</h3>
            <p>
              &quot;次のような場合にお客様から個人情報を取得し、また次の利用目的のためだけに利用いたします。個人情報の管理については、お客様の個人情報の重要性を認識したうえで、個人情報保護に関する関連法令を遵守し、個人情報を管理し利用致します。
              <br />
              当社は、個人情報を最新かつ正確な状態に保ち、また個人情報に対する不正アクセス･紛失･破壊･改ざん･漏洩のないように適切に管理致します。また、個人情報の提供は、次の「利用目的範囲内での利用」に記載されている場合に限定されます。&quot;
            </p>

            <h2 className="heading2">個人情報の取扱について</h2>

            <h3 className="heading3">法令の遵守</h3>
            <p>
              当社は、個人情報（特定の個人を識別できるもの、以下も同様）を取り扱う際に、個人情報の保護に関する法律をはじめ個人情報保護に関する諸法令、および主務大臣のガイドラインに定められた義務、並びに本ポリシーを遵守します。
            </p>

            <h3 className="heading3">個人情報をご提供いただく場合</h3>
            <ul>
              <li>
                当社もしくは当社が提供するサービスのサイトにてご登録または登録内容の修正をいただく場合
              </li>
              <li>当社にご注文をいただく場合</li>
              <li>当社サイトにてアンケートにご回答いただく場合</li>
              <li>
                個人情報の開示又は変更を請求された場合にご本人確認資料等をご提示いただく場合
              </li>
            </ul>

            <h3 className="heading3">個人情報の利用目的</h3>
            <ul>
              <li>ご依頼された業務遂行およびアフターフォロー</li>
              <li>
                ご注文いただいた当社取り扱い製品の配送、入金の取引成立確認等ならびに管理
              </li>
              <li>
                当社の取り扱い製品・サービスに関するお問い合わせ・ご依頼への対応
              </li>
              <li>
                当社の取り扱い製品・サービスに関する各種キャンペーン情報のご案内
              </li>
              <li>
                当社の取り扱い製品・サービスに関する製品・サービスのご紹介
              </li>
              <li>
                プライバシーポリシーや本方針等への変更・改定に関するご通知
              </li>
              <li>その他お問い合わせ・ご依頼等への対応</li>
            </ul>

            <h3 className="heading3">利用目的範囲内での利用</h3>
            <p>
              当社は、利用目的をできる限り特定したうえ、あらかじめご本人の同意を得た場合、および法令により例外として認められた場合を除き、明示または公表した利用目的の範囲内でのみ個人情報を取り扱います。
            </p>

            <h3 className="heading3">
              属性情報・行動履歴の取得及び利用について
            </h3>
            <p>
              当社では、第三者から配信される広告が掲載される場合があり、これに関連して、当該第三者が、当社サイトを訪問したユーザーのクッキー情報等を取得し、利用している場合があります。
            </p>
            <p>
              当該第三者によって取得されたクッキー情報等は、当該第三者のプライバシーポリシーに従って取り扱われます。
            </p>
            <p>
              ユーザーは、当該第三者のウェブサイト内に設けられたオプトアウトページにアクセスして、当該第三者によるクッキー情報等の広告配信への利用を停止することができます。
              <br />
              「ヤフー株式会社
              <a
                href="http://btoptout.yahoo.co.jp/optout/index.html"
                target="_blank"
                rel="noreferrer"
              >
                http://btoptout.yahoo.co.jp/optout/index.html
              </a>{" "}
              」 <br />
              「グーグル株式会社
              <a
                href="http://www.google.co.jp/intl/ja/policies/privacy/ads/"
                target="_blank"
                rel="noreferrer"
              >
                http://www.google.co.jp/intl/ja/policies/privacy/ads/
              </a>
              」
            </p>
            <p>
              クッキーとは、ウェブページを利用したときに、ブラウザとサーバーとの間で送受信した利用履歴や入力内容などを、お客様のコンピュータにファイルとして保存しておく仕組みです。
              次回、同じページにアクセスすると、クッキーの情報を使って、ページの運営者はお客様ごとに表示を変えたりすることができます。お客様がブラウザの設定でクッキーの送受信を許可している場合、ウェブサイトは、ユーザーのブラウザからクッキーを取得できます。
              なお、お客様のブラウザは、プライバシー保護のため、そのウェブサイトのサーバーが送受信したクッキーのみを送信します。
            </p>
            <p>
              お客様は、クッキーの送受信に関する設定を「すべてのクッキーを許可する」、「すべてのクッキーを拒否する」、「クッキーを受信したらユーザーに通知する」などから選択できます。
              設定方法は、ブラウザにより異なります。クッキーに関する設定方法は、お使いのブラウザの「ヘルプ」メニューでご確認ください。
            </p>
            <p>
              すべてのクッキーを拒否する設定を選択されますと、認証が必要なサービスを受けられなくなる等、インターネット上の各種サービスの利用上、制約を受ける場合がありますのでご注意ください。
            </p>

            <h3 className="heading3">セキュリティー等について</h3>
            <p>
              当社サイトを通じてお客様に個人情報をご提供していただく場合、必要に応じて、SSL（Secure
              Socket
              Layer）などのセキュリティ機能を提供いたします。セキュリティ機能に対応したブラウザを使用することで、お客様が入力される名前や住所あるいは電話番号などの個人情報が自動的に暗号化されて送受信されます。
            </p>

            <h3 className="heading3">個人情報のお問い合わせについて</h3>
            <p>
              個人情報に関するご質問・お問い合わせは、「お問合せ」よりご連絡ください。個人情報の開示についてご対応させていただく際、ご本人であることを確認させていただきますのでご了承ください。
            </p>

            <h3 className="heading3">個人情報の保護方針改正について</h3>
            <p>
              当社は、法令およびその他関連規範に変更等への対応や当社が必要と判断した場合に個人情報保護方針を予告なく改訂することがあります。
            </p>

            <h3 className="heading3">お問い合わせ</h3>
            <p>
              <Link to="/Contact">お問い合わせフォーム</Link>
              からお願いいたします。
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default privacy;
