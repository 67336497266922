/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  Agent.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary Agent画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
// pages
import userAuth from "../components/userAuth";
import React, { useEffect, useState } from "react";
/*  GraphQL API Acess */
import API, { graphqlOperation } from "@aws-amplify/api";
import {
  customerByeMail,
  getArAZCustomerCode,
  arCustomerAddressByOrderRecipientCode,
  listArCustomerAddresses,
  arCustomerByAgencyID
} from "../graphql/queries";
/*  AWS標準設定 with Login Auth. */
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);
import Grid from "@material-ui/core/Grid";
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import { v4 as uuidv4 } from "uuid";
import { createArCustomer, updateArCustomerAddress } from "../graphql/mutations";
import BootstrapTable from "react-bootstrap-table-next";
// import DialogContentText from '@material-ui/core/DialogContentText';
import paginationFactory from "react-bootstrap-table2-paginator";
import { useHistory } from "react-router-dom";
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
let agencyId = "";
let addressId = '';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
  },
  rootl: {
    maxWidth: "100%",
  },
  card1: {
    maxWidth: "50%",
    minWidth: "50%",
    width: "50%",
  },
  card2: {
    maxWidth: "100%",
  },
  table: {
    minWidth: 700,
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "20ch",
  },
  textFieldNm: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "60ch",
  },
  textKField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "60ch",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  // avatar: {
  //   backgroundColor: red[500],
  // },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  textFieldSearch:{
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: "transparent",
    border: "1px solid #707070",
    borderRadius: "6px",
    color: "black",
    width:"80%"
  },
  textlabelSearch:{
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: "rgb(91,56,9)",
    border: "1px solid #707070",
    color: "white",
    fontWeight: "bold",
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center',
    width:"20%"
  }
}));
const customTotal = (from, to, size) => (
  <span
    style={{ marginLeft: "1em" }}
    className="react-bootstrap-table-pagination-total"
  >
    {size} の結果の {from} から {to} を表示しています
  </span>
);
const pageButtonRenderer = ({ page, onPageChange }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };
  return (
    <button className="btn-secondary btn" type="button" onClick={handleClick}>
      <li className="page-item">
        <a style={{ color: "white" }} href="#">
          {page}
        </a>
      </li>
    </button>
  );
};

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${
            currSizePerPage === `${option.page}` ? "btn-warning" : "btn-light"
            }`}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
const options = {
  paginationSize: 10,
  pageStartIndex: 1,
  showTotal: true,
  withFirstAndLast: true,
  firstPageText: "最初",
  prePageText: "戻る",
  nextPageText: "次へ",
  lastPageText: "最後",
  nextPageTitle: "最初のページ",
  prePageTitle: "プレページ",
  firstPageTitle: "次のページ",
  lastPageTitle: "最後のページ",
  paginationTotalRenderer: customTotal,
  sizePerPageRenderer: sizePerPageRenderer,
  pageButtonRenderer: pageButtonRenderer,
  disablePageTitle: true,
  sizePerPageList: [
    {
      text: "10",
      value: 10,
    },
    {
      text: "20",
      value: 20,
    },
    {
      text: "30",
      value: 30,
    },
  ],
};

export const Agent = () => {
  const classes = useStyles();
  const [addressList, setAddress] = useState([]);
  const history = useHistory();
  const [searchProgress, setSearchProgress] = useState(false)
  const [displayColumns,setDisplayColumns] = useState({
    TorayShipCode:false,
    TorayShipMasterName2:false
  })
  const [searchValue, setSearchValue] = useState({
    CustomerReceiptCode: "",
    Name2: ""
})
const [CustomerReceiptCodeList, setCustomerReceiptCodeList] = useState([])
const searchCustomerReceiptCodeChange = (e, value) => {
  setSearchValue({ ...searchValue, CustomerReceiptCode: value ? value.split(":")[0] : "" })
}
const searchName2Change = (e, value) => {
  setSearchValue({ ...searchValue, Name2: value ? value.split(":")[1] : "" })
}
 // 検索ボタンが押されました
 const sortByCustomerReceiptCode = (a, b)=> {
  if (!a.CustomerReceiptCode) {
    return 1;
  } else if (!b.CustomerReceiptCode) {
    return 1;
  } else {
    return a.CustomerReceiptCode.localeCompare(b.CustomerReceiptCode)
  }
}
const multiSort = (arr, ...sortFuncs)=> {
  return arr.sort((a, b) => {
    for (const func of sortFuncs) {
      const result = func(a, b);
      if (result !== 0) {
        return result;
      }
    }
    return 0;
  });
}
const searchBtn = async () => {
setSearchProgress(true)
try {
    let arr = []
  let dataSource = await getAddressList()
  dataSource.forEach(item => {
    let flag = true
    if (Object.keys(searchValue).length !== 0) {
        for (let name in searchValue) {
            if (item[name]) {
                if (!(item[name]).includes(searchValue[name])) {
                    flag = false;
                    break;
                }
            } else {
                flag = false
                break;
            }
        }
    }
    if (flag === true) {
        arr.push(item)
    }
})
  arr = multiSort(arr,sortByCustomerReceiptCode);
  setAddress(arr);
  } catch (error) {
      if (error.message === 'No current user') {
          //ユーザープールに障害が発生した場合
          console.log('User pool is invalid.Please reauthenticate.');
          //ログイン画面に戻る
          signOut();
      } else {
          //他のエラーを処理する
          console.log('An error occurred:', error);
      }
  }
  setSearchProgress(false)
}
const signOut = async () => {
  await Auth.signOut();
  try {
      await Auth.signOut();
  } catch (error) {
      console.log("error signing out: ", error);
  }
  document.location.reload();
};
  useEffect(() => {
    // 1) Auth.currentSession() から、Cogniteのユーザグループ情報を取得
    async function getLoginGroup() {
    try{
      setSearchProgress(true)
      // get the Login User Group from the top of the cognito groups list
      const user = await Auth.currentUserInfo();
      const agencyPriceGroup = await API.graphql(
        graphqlOperation(customerByeMail, {
          Agency_Email: user.attributes.email,
        })
      );
      const agentinfo = agencyPriceGroup.data.customerByeMail["items"];
      const userAuths = await userAuth();
      if (userAuths === "1") {
        if (agentinfo.length > 0) {
          // getAgent({ type: eMailQUERY, Agent: agentinfo });
        }
        setDisplayColumns({
          TorayShipCode:true,
          TorayShipMasterName2:true
        })
      } else {
        let items = {
          Agency_id: "‐",
          SubId: "‐",
          Company_Name: "‐",
          Agency_Name: "管理者",
        };
        let itemALL = [];
        itemALL.push(items);
        // getAgent({ type: eMailQUERY, Agent: itemALL });
        setDisplayColumns({
          TorayShipCode:false,
          TorayShipMasterName2:false
        })
      }
      agencyId = agentinfo[0].Agency_id;
      let dataAll = await getAddressList();
      let customerReceiptCodeList = []
      for (let i = 0; i < dataAll.length; i++) {
        if (dataAll[i].CustomerReceiptCode) {
          customerReceiptCodeList.push(dataAll[i].CustomerReceiptCode + ":" + dataAll[i].Name2)
        }
    }
    dataAll = multiSort(dataAll,sortByCustomerReceiptCode)
    setCustomerReceiptCodeList(Array.from(new Set(customerReceiptCodeList)))
    setAddress(dataAll);
    getContractorList();
    setSearchProgress(false)
    } catch (error) {
      if (error.message === 'No current user') {
          //ユーザープールに障害が発生した場合
          console.log('User pool is invalid.Please reauthenticate.');
          //ログイン画面に戻る
          signOut();
      } else {
          //他のエラーを処理する
          console.log('An error occurred:', error);
      }
    }
    setSearchProgress(false)
  }
    getLoginGroup();
  }, []);
  function addClick() {
    // handleClickOpen1(Object);
    history.push({ pathname: '/AddAddress', state: { CustomerCodeKey: agencyId } });
  }
  const [title, setTitle] = React.useState(Object);
  const handleSetTitle = (Object) => {
    setTitle(Object);
  };
  const handleClose2 = () => {
    setOpen1(false);
  };
  const [buttonText, setButtonText] = React.useState(Object);
  const handleSetButtonText = (Object) => {
    setButtonText(Object);
  };

  const [error, setError] = React.useState(Object);
  const handleSetError = (Object) => {
    setError(Object);
  };
  const [errorFlg, setErrorFlg] = React.useState(Object);
  const handleSetErrorFlg = (Object) => {
    setErrorFlg(Object);
  };
  // dialog
  const [open1, setOpen1] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const handleClose1 = () => {
    createArAddressFunction();
  };
  const handleClose4 = () => {
    updateArAddressFunction();
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  // dialog 項目 END
  const updateArAddressFunction = async () => {
    if (Name1Dialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※名称1を入力してください");
      return;
    }
    if (Name2Dialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※名称2を入力してください");
      return;
    }
    if (Name3Dialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※名称3を入力してください");
      return;
    }
    await API.graphql(
      graphqlOperation(updateArCustomerAddress, {
        input: {
          id: addressId,
          Name1: Name1Dialog,
          Name2: Name2Dialog,
          Name3: Name3Dialog,
          OutFlag: '1',
          CustomerCodeKey: agencyId,
        },
      })
    );
    await API.graphql(
      graphqlOperation(arCustomerByAgencyID, {
        CustomerCodeKey: agencyId,
        filter: { AccounKey: { eq: "SH" } },
      })
    );
    handleSetError("");
    handleSetErrorFlg(true);
    setOpen3(false);
    let dataAll = await getAddressList();
    dataAll = multiSort(dataAll,sortByCustomerReceiptCode)
    setAddress(dataAll);
    getContractorList();
  };
  // dialog 項目 Start
  const [CustomerAccountGroup, setCustomerAccountGroup] =
    React.useState(Object);
  const txtChangedCustomerAccountGroup = (Object) => {
    setCustomerAccountGroup(Object);
  };
  const [HonorificTitleKeyDialog, setHonorificTitleKeyDialog] =
    React.useState(Object);
  const txtChangedHonorificTitleKeyDialog = (Object) => {
    setHonorificTitleKeyDialog(Object);
  };
  const [Name1Dialog, setName1Dialog] = React.useState(Object);
  const txtChangedName1Dialog = (Object) => {
    setName1Dialog(Object);
  };
  const [Name2Dialog, setName2Dialog] = React.useState(Object);
  const txtChangedName2Dialog = (Object) => {
    setName2Dialog(Object);
  };
  const [Name3Dialog, setName3Dialog] = React.useState(Object);
  const txtChangedName3Dialog = (Object) => {
    setName3Dialog(Object);
  };
  const [SearchTerm1Dialog, setSearchTerm1Dialog] = React.useState(Object);
  const txtChangedSearchTerm1Dialog = (Object) => {
    setSearchTerm1Dialog(Object);
  };
  const [PlaceNameDialog, setPlaceNameDialog] = React.useState(Object);
  const txtChangedPlaceNameDialog = (Object) => {
    setPlaceNameDialog(Object);
  };
  const [PostCodeDialog, setPostCodeDialog] = React.useState(Object);
  const txtChangedPostCodeDialog = (Object) => {
    setPostCodeDialog(Object);
  };

  const [CountryCodeDialog, setCountryCodeDialog] = React.useState(Object);
  const txtChangedCountryCodeDialog = (Object) => {
    setCountryCodeDialog(Object);
  };

  const [AreaDialog, setAreaDialog] = React.useState(Object);
  const txtChangedAreaDialog = (Object) => {
    setAreaDialog(Object);
  };

  const [LanguageCodeDialog, setLanguageCodeDialog] = React.useState(Object);
  const txtChangedLanguageCodeDialog = (Object) => {
    setLanguageCodeDialog(Object);
  };

  const [FirstPhoneNumberDialog, setFirstPhoneNumberDialog] =
    React.useState(Object);
  const txtChangedFirstPhoneNumberDialog = (Object) => {
    setFirstPhoneNumberDialog(Object);
  };
  // dialog 項目 END
  const defaultSorted = [
    {
      dataField: "CustomerReceiptCode",
      order: "asc",
    },
  ];
  // dialog 項目 END
  const createArAddressFunction = async () => {
    if (CustomerAccountGroup === "") {
      handleSetErrorFlg(false);
      handleSetError("　※得意先勘定グループ を入力してください");
      return;
    }
    if (HonorificTitleKeyDialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※敬称キーを入力してください");
      return;
    }
    if (Name1Dialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※名称1を入力してください");
      return;
    }
    if (Name2Dialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※名称2を入力してください");
      return;
    }
    if (Name3Dialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※名称3を入力してください");
      return;
    }
    if (SearchTerm1Dialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※検索語句1を入力してください");
      return;
    }
    if (PlaceNameDialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※地名を入力してください");
      return;
    }
    if (PostCodeDialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※郵便番号を入力してください");
      return;
    }
    if (CountryCodeDialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※国コードを入力してください");
      return;
    }
    if (AreaDialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※地域を入力してください");
      return;
    }
    if (LanguageCodeDialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※言語コードを入力してください");
      return;
    }
    if (FirstPhoneNumberDialog === "") {
      handleSetErrorFlg(false);
      handleSetError("　※電話番号を入力してください");
      return;
    }
    let ArAzInfo = await API.graphql(
      graphqlOperation(getArAZCustomerCode, { id: "1" })
    );
    let codeNumber = ArAzInfo.data.getArAZCustomerCode["CustomerCodeNumber"];
    let initialCode = "AZ00000001";
    initialCode =
      initialCode.slice(0, initialCode.length - codeNumber.toString().length) +
      codeNumber;
    await API.graphql(
      graphqlOperation(createArCustomer, {
        input: {
          id: uuidv4(),
          CustomerCodeKey: agencyId,
          AccounKey: "SH",
          CustomerCode: initialCode,
          CustomerAccountGroup: CustomerAccountGroup,
          HonorificTitleKey: HonorificTitleKeyDialog,
          Name1: Name1Dialog,
          Name2: Name2Dialog,
          Name3: Name3Dialog,
          SearchTerm1: SearchTerm1Dialog,
          PlaceName: PlaceNameDialog,
          PostCode: PostCodeDialog,
          CountryCode: CountryCodeDialog,
          Area: AreaDialog,
          LanguageCode: LanguageCodeDialog,
          FirstPhoneNumber: FirstPhoneNumberDialog,
        },
      })
    );

    handleSetError("");
    handleSetErrorFlg(true);
    setOpen1(false);
    let dataAll = await getAddressList();
    dataAll = multiSort(dataAll,sortByCustomerReceiptCode)
    setAddress(dataAll);
    getContractorList();
  };

  const getAddressList = async () => {
    const userAuths = await userAuth();
    let address = "";
    let listAddressInfo = "";
    if (userAuths === "1") {
      listAddressInfo = await API.graphql(
        graphqlOperation(arCustomerAddressByOrderRecipientCode, {
          OrderRecipientCode: agencyId
        }));
      address = listAddressInfo.data.queryArCustomerAddressesByOrderRecipientCodeIndex["items"];
      for (let j = 0; j < address.length; j++) {
        address[j].PlaceName = address[j].PlaceName + address[j].city + address[j].PlaceName3
      }
    } else {
      // 全員
      listAddressInfo = await API.graphql(
        graphqlOperation(listArCustomerAddresses)
      );
      address = listAddressInfo.data.listArCustomerAddresses["items"];
      let nextToken = listAddressInfo.data.listArCustomerAddresses.nextToken;
      while (nextToken !== null) {
        let InvDataSec = await API.graphql(
          graphqlOperation(listArCustomerAddresses, {
            nextToken: nextToken,
          })
        );
        nextToken = InvDataSec.data.listArCustomerAddresses.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.listArCustomerAddresses.items.length;
          i++
        ) {
          address.push(InvDataSec.data.listArCustomerAddresses.items[i]);
        }
      }
      for (let j = 0; j < address.length; j++) {
        address[j].PlaceName = address[j].PlaceName + address[j].city + address[j].PlaceName3
      }
    }
    const uniqueData = address.filter((item, index, self) =>
       index === self.findIndex(obj => obj.CustomerReceiptCode === item.CustomerReceiptCode)
     );
    return uniqueData
  };
  const getContractorList = async () => {
    // const ContractorList = await API.graphql(
    //   graphqlOperation(arCustomerByAgencyID, {
    //     CustomerCodeKey: agencyId,
    //     filter: { AccounKey: { eq: "SP" } },
    //   })
    // );
    // const address = ContractorList.data.arCustomerByAgencyID["items"];
    // setContractor(address);
  };
  const editClick = (direct) => {
    setOpen3(true);
    handleSetError("");
    handleSetErrorFlg(true);
    handleSetTitle("出荷先編集");
    handleSetButtonText("更新");
    addressId = direct;
    for (let j = 0; j < addressList.length; j++) {
      if (addressList[j].id === direct) {
        txtChangedName1Dialog(addressList[j].Name1);
        txtChangedName2Dialog(addressList[j].Name2);
        txtChangedName3Dialog(addressList[j].Name3);
        break;
      }
    }
  };


  const headerStyle = {
    backgroundColor: "white",
    borderBottom: "3px solid #B45210",
  };
  let columns1 = [
    { dataField: "id", text: "ID", hidden: true },
    { dataField: "CustomerReceiptCode", text: "出荷先コード", headerStyle, sort: true, style: { margin: "0px", width: "130px" } },
    { dataField: "Name1", text: "出荷先名称", headerStyle, sort: true, style: { margin: "0px", width: "130px" }, hidden: true },
    { dataField: "Name2", text: "出荷先名", headerStyle, sort: true, style: { margin: "0px", width: "280px" } },
    { dataField: "Name3", text: "拠点名", headerStyle, sort: true, style: { margin: "0px", width: "140px" } },
    { dataField: "Name4", text: "部署名", headerStyle, sort: true, style: { margin: "0px", width: "130px" } },
    { dataField: "SearchTerm1", text: "検索語句 1", headerStyle, sort: true, style: { margin: "0px", width: "110px" }, hidden: true },
    { dataField: "PostCode", text: "郵便番号", headerStyle, sort: true, style: { margin: "0px", width: "90px" } },
    { dataField: "AreaCode", text: "地域コード", headerStyle, sort: true, style: { margin: "0px", width: "110px" }, hidden: true },
    { dataField: "Area", text: "都道府県", headerStyle, sort: true, style: { margin: "0px", width: "100px" } },
    {
      dataField: "PlaceName", text: "市区町村番地", headerStyle, sort: true, style: { margin: "0px", width: "260px" },
      formatter: (cellContent, row) => {
        return <span>{row.PlaceName}</span>;
      },
    },
    { dataField: "Tel", text: "電話番号", headerStyle, sort: true, style: { margin: "0px", width: "150px" }, },
    { dataField: "TorayShipCode", text: "東レ_出荷先コード",hidden:displayColumns.TorayShipCode === true ? true : false, headerStyle, style: { margin: "0px", width: "170px" }, },
    { dataField: "TorayShipMasterName2", text: "東レ_出荷先マスタ名称", hidden:displayColumns.TorayShipMasterName2 === true ? true : false,headerStyle,style: { margin: "0px", width: "230px" }, },
    {
      dataField: "edit",
      text: "操作",
      headerStyle,
      hidden: true,
      formatter: (cellContent, row) => {
        return (
          <span>
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  editClick(row.id);
                }}
              >
                編集
              </Button>
            </div>
          </span>
        );
      },
    },
  ];


  return (
    <Container
      style={{ backgroundColor: "", minWidth: "82vw", minHeight: "74vh" }}
    >
      <div style={{position: 'fixed',top: '50%', left: '50%', display: searchProgress === true ? "block" : "none",zIndex:10}}>
            <CircularProgress style={{margin:"auto"}}/>
        </div>
        <div style={{ minWidth: "1000px", }}>
      <Grid>
        <Grid>
          <Row
            style={{
              borderRadius: "0.25em",
              color: "purple",
              padding: "0.5em",
            }}
          >
            <div style={{ display: 'flex', marginBottom: "10px",width:"100%"}}>
            <Col>
              <h3 style={{
              textAlign: "left",
              color: "black",
              height: 30,
              width: '20%',
              paddingLeft: "4px",
              whiteSpace:"nowrap"}}>出荷先一覧</h3>
            </Col>
            <div style={{ width: '80%', height: 140}}>
                    <div style={{ width: '90%',minWidth:"90%",height: 140, border: '2px solid rgb(191,191,191)', display: 'flex', flexWrap: "wrap",justifyContent:"center", padding: "30px 0px 0px 0px" }}>
                        <div style={{ width: "50%", height: '25px' }}>
                            <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap" }}>
                                <InputGroup.Text
                                    className={classes.textlabelSearch}
                                    id="basic-name"
                                    style={{ borderRadius: "0px",minWidth:"100px"}}
                                >出荷先コード
                                </InputGroup.Text>
                                <Autocomplete
                                    className={classes.textFieldSearch}
                                    sx={{
                                      display: 'inline-block',
                                      '& input': {
                                          height: 0,
                                      },
                                  }}
                                    options={CustomerReceiptCodeList}
                                    value={searchValue.CustomerReceiptCode}
                                    maxLength ="20"
                                    onChange={(e, value) => { searchCustomerReceiptCodeChange(e, value) }}
                                    name="CustomerReceiptCode"
                                    id="CustomerReceiptCode"
                                    isOptionEqualToValue={(option, value) => option.split(":")[0] === value || option === "" || value === ""}
                                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                />
                            </InputGroup>
                        </div>
                        <div style={{ width: "50%", height: '25px'}}>
                            <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap", }}>
                                <InputGroup.Text
                                    className={classes.textlabelSearch}
                                    id="basic-name"
                                    style={{ borderRadius: "0px",minWidth:"100px"}}
                                >出荷先名
                                </InputGroup.Text>
                                <Autocomplete
                                    className={classes.textFieldSearch}
                                    sx={{
                                      display: 'inline-block',
                                      '& input': {
                                          height: 0,
                                      },
                                  }}
                                    options={CustomerReceiptCodeList}
                                    id="Name2"
                                    value={searchValue.Name2}
                                    name="Name2"
                                    maxLength ="20"
                                    isOptionEqualToValue={(option, value) => option.split(":")[1] === value || option === "" || value === ""}
                                    onChange={(e, value) => { searchName2Change(e, value) }}
                                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                />
                            </InputGroup>
                        </div>
                        <Button className="btn" onClick={()=>searchBtn()} style={{ height: '40px', width: '120px', fontSize: '18px', color: 'black', border: '3px solid black', background: "white", margin: 'auto' }}>検索</Button>
                    </div>
              </div>
              </div>
            <Col align="right" hidden="true">
              <Button
                className="btn btn-danger"
                onClick={() => {
                  addClick("add");
                }}
              >
                <AddIcon />
                出荷先新規申請
              </Button>
            </Col>
          </Row>
        </Grid>
        <Grid>
          <BootstrapTable
            striped={true}
            hover={true}
            wrapperClasses="boo bg-white"
            bootstrap4
            keyField="id"
            data={addressList}
            columns={columns1}
            noDataIndication={"no results found"}
            defaultSorted={defaultSorted}
            pagination={paginationFactory(options)}
          />
        </Grid>
      </Grid>
        </div>
      <Dialog
        open={open1}
        onClose={handleClose1}
        fullWidth="md"
        disableEscapeKeyDown="true"
        disableBackdropClick="true"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <div hidden={errorFlg}>
            <span style={{ color: "red" }}>{error}</span>
          </div>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              得意先勘定グループ
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=" "
              aria-describedby="basic-name"
              id="CustomerAccountGroup"
              className={classes.textField}
              value={CustomerAccountGroup}
              onChange={(e) => {
                txtChangedCustomerAccountGroup(e.target.value);
              }}
              maxLength="4"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              敬称キー
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="HonorificTitleKeyDialog"
              className={classes.textField}
              value={HonorificTitleKeyDialog}
              onChange={(e) => {
                txtChangedHonorificTitleKeyDialog(e.target.value);
              }}
              maxLength="4"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              名称1
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="Name1Dialog"
              className={classes.textField}
              value={Name1Dialog}
              onChange={(e) => {
                txtChangedName1Dialog(e.target.value);
              }}
              maxLength="40"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              名称2
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="Name2Dialog"
              className={classes.textField}
              value={Name2Dialog}
              onChange={(e) => {
                txtChangedName2Dialog(e.target.value);
              }}
              maxLength="40"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              名称3
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="Name3Dialog"
              className={classes.textField}
              value={Name3Dialog}
              onChange={(e) => {
                txtChangedName3Dialog(e.target.value);
              }}
              maxLength="40"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              検索語句1
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="SearchTerm1Dialog"
              className={classes.textField}
              value={SearchTerm1Dialog}
              onChange={(e) => {
                txtChangedSearchTerm1Dialog(e.target.value);
              }}
              maxLength="20"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              地名
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="PlaceNameDialog"
              className={classes.textField}
              value={PlaceNameDialog}
              onChange={(e) => {
                txtChangedPlaceNameDialog(e.target.value);
              }}
              maxLength="60"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              市区町村の郵便番号
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="PostCodeDialog"
              className={classes.textField}
              value={PostCodeDialog}
              onChange={(e) => {
                txtChangedPostCodeDialog(e.target.value);
              }}
              maxLength="10"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              国コード
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="CountryCodeDialog"
              className={classes.textField}
              value={CountryCodeDialog}
              onChange={(e) => {
                txtChangedCountryCodeDialog(e.target.value);
              }}
              maxLength="3"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              地域 (都道府県)
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="AreaDialog"
              className={classes.textField}
              value={13}
              readOnly={true}
              onChange={(e) => {
                txtChangedAreaDialog(e.target.value);
              }}
              maxLength="20"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              言語コード
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="LanguageCodeDialog"
              className={classes.textField}
              value={LanguageCodeDialog}
              onChange={(e) => {
                txtChangedLanguageCodeDialog(e.target.value);
              }}
              maxLength="1"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">
              電話番号
            </InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="FirstPhoneNumberDialog"
              className={classes.textField}
              value={FirstPhoneNumberDialog}
              onChange={(e) => {
                txtChangedFirstPhoneNumberDialog(e.target.value);
              }}
              maxLength="30"
            />
          </InputGroup>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose1}
            variant="contained"
            color="primary"
            autoFocus
          >
            {buttonText}
          </Button>
          <Button onClick={handleClose2} variant="contained" color="primary">
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open3}
        onClose={handleClose1}
        fullWidth="md"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <div hidden={errorFlg} >
            <span style={{ color: 'red' }}>{error}</span>
          </div>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">名称1</InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="Name1Dialog"
              className={classes.textFieldNm}
              value={Name1Dialog}
              onChange={(e) => { txtChangedName1Dialog(e.target.value) }}
              maxLength="40"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">名称2</InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="Name2Dialog"
              className={classes.textFieldNm}
              value={Name2Dialog}
              onChange={(e) => { txtChangedName2Dialog(e.target.value) }}
              maxLength="40"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">名称3</InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              aria-describedby="basic-name"
              id="Name3Dialog"
              className={classes.textFieldNm}
              value={Name3Dialog}
              onChange={(e) => { txtChangedName3Dialog(e.target.value) }}
              maxLength="40"
            />
          </InputGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose4} variant="contained" color="primary" autoFocus>{buttonText}</Button>
          <Button onClick={handleClose3} variant="contained" color="primary">キャンセル</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Agent;