/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  Footer.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary Footer
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
import React from "react";
import { Layout } from "antd";
import logo from "../components/foot_logo.svg";

const HeaderLogin = () => {
  const { Footer } = Layout;
  return (
    <Footer
      style={{
        textAlign: "center",
        backgroundColor: "black",
        width: "100%",
        display: "inline-flex",
        alignItems: "left",
        height: "70px",
      }}
    >
      <img src={logo} />
    </Footer>
  );
};

export default HeaderLogin;
