import React from "react";
import { InputGroup } from "react-bootstrap";
import useStyles from "./ArPriceInfoStyle";
import {checkedMasterlist} from "./ArPriceInfoMethod";
export default function ArPriceInfoDisplayItem(params) {
    const classes = useStyles()
    const checkboxHandleChange = ()=>{
        let SelectAllFlagInit = true
        let settingButtonDisable = true
        const checkedlist = checkedMasterlist();
        for (let item in checkedlist) {
          if(checkedlist[item] === false){
            SelectAllFlagInit = false
          }else{
            settingButtonDisable = false
          }
      }
      params.checkBoxChange(SelectAllFlagInit,settingButtonDisable)
    }
    return (
        <div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px",background:"white",color:"black"}}
                    id="basic-name"
                >全項目チェック
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="selectAll" name="selectAll" onClick={()=>params.DisplayProjectsSettingsSelectAll()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >使用グループ区分
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="UseGroupClassification" name="UseGroupClassification" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >品目コード(ARB)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="itemCode" name="itemCode" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >Material Code
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="MaterialCode" name="MaterialCode" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >品目名
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="ItemName" name="ItemName" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売伝票タイプ
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesDocType" name="SalesDocType" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入先コード
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="suppCode" name="suppCode" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入先名称
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SuppName" name="SuppName" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >受注先Gr
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SuppGroup" name="SuppGroup" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >受注先コード
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="OrderReceiptCode" name="OrderReceiptCode" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >受注先名称
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="OrderName" name="OrderName" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >出荷先コード
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="shipmentCode" name="shipmentCode" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >出荷先名称
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="DestName" name="DestName" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >EU(ARB管理)コード
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="EUARBmgmtCode" name="EUARBmgmtCode" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >エンドユーザー名称
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="endUserName" name="endUserName" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >東レエンドユーザー
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="TorayEndUser" name="TorayEndUser" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >東レエンドユーザー名
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="TorayEndUserName" name="TorayEndUserName" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >容量
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="Capacity" name="Capacity" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >容量単位
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="CapacityUnit" name="CapacityUnit" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >価格単位
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PriceUnit" name="PriceUnit" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >規定数量
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="RegQty" name="RegQty" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >1注文可能数
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="OrdQty" name="OrdQty" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >DT直送/受注後手配品(輸入品含む)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="DTDelOrderRec" name="DTDelOrderRec" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >価格種別（販売）
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PriceTypeSales" name="PriceTypeSales" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >価格種別（仕入）
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PriceTypePurchase" name="PriceTypePurchase" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売単価(Price Scale1)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesUnitPriceScale1" name="SalesUnitPriceScale1" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売単価(Price Scale2)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesUnitPriceScale2" name="SalesUnitPriceScale2" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売単価(Price Scale3)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesUnitPriceScale3" name="SalesUnitPriceScale3" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売単価(Price Scale4)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesUnitPriceScale4" name="SalesUnitPriceScale4" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売単価(Price Scale5)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesUnitPriceScale5" name="SalesUnitPriceScale5" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売単価(Unit Scale1)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesUnitPriceUnitScale1" name="SalesUnitPriceUnitScale1" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売単価(Unit Scale2)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesUnitPriceUnitScale2" name="SalesUnitPriceUnitScale2" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売単価(Unit Scale3)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesUnitPriceUnitScale3" name="SalesUnitPriceUnitScale3" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売単価(Unit Scale4)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesUnitPriceUnitScale4" name="SalesUnitPriceUnitScale4" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売単価(Unit Scale5)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesUnitPriceUnitScale5" name="SalesUnitPriceUnitScale5" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入単価(Price Scale1)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchUnitPriceScale1" name="PurchUnitPriceScale1" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入単価(Price Scale2)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchUnitPriceScale2" name="PurchUnitPriceScale2" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入単価(Price Scale3)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchUnitPriceScale3" name="PurchUnitPriceScale3" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入単価(Price Scale4)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchUnitPriceScale4" name="PurchUnitPriceScale4" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入単価(Price Scale5)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchUnitPriceScale5" name="PurchUnitPriceScale5" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入単価(Unit Scale1)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchUnitPriceUnitScale1" name="PurchUnitPriceUnitScale1" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入単価(Unit Scale2)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchUnitPriceUnitScale2" name="PurchUnitPriceUnitScale2" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入単価(Unit Scale3)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchUnitPriceUnitScale3" name="PurchUnitPriceUnitScale3" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入単価(Unit Scale4)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchUnitPriceUnitScale4" name="PurchUnitPriceUnitScale4" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入単価(Unit Scale5)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchUnitPriceUnitScale5" name="PurchUnitPriceUnitScale5" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >出荷単位時のﾊﾞﾗ販売可否
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="BulkSales" name="BulkSales" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >ﾊﾞﾗ出荷手数料
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="BulkShippingFee" name="BulkShippingFee" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >クール便送料
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="CoolDeliveryCharge" name="CoolDeliveryCharge" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >営業担当者コード
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalespersonCode" name="SalespersonCode" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >前回受注年月日
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="LastOrderDate" name="LastOrderDate" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >WEB受注番号
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="WebOrderNumber" name="WebOrderNumber" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >有効期限開始
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="ExpirationStart" name="ExpirationStart" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >有効期限終了
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="Expired" name="Expired" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >売上計上(日付)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="RecordSalesDate" name="RecordSalesDate" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >売上計上(適用)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="RecordSalesApplicable" name="RecordSalesApplicable" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入計上(日付)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchaseAccountDate" name="PurchaseAccountDate" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入計上(適用)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="AcctForPurchasesApplicableble" name="AcctForPurchasesApplicableble" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売改訂率(%)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesRevRate" name="SalesRevRate" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >販売改訂価格
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SalesRevPrice" name="SalesRevPrice" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入改訂率(%)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchaseRevRate" name="PurchaseRevRate" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入改訂価格
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PurchaseRevPrice" name="PurchaseRevPrice" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >実績フラグ
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="ActualFlag" name="ActualFlag" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >履歴番号
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="HistoryNum" name="HistoryNum" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >社内備考
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="Remarks" name="Remarks" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >更新日付
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="UpDailyPayment" name="UpDailyPayment" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >更新時刻
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="UpTime" name="UpTime" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >SeqNo
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SeqNo" name="SeqNo" onClick={()=>checkboxHandleChange()}/>
            </div>
        </div>
    )
}