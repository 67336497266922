/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  Orderlist.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2022/03/30 Rayoo)li : 新規作成
 *
 * Summary Orderlist画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
import React, { useEffect, useRef, useState } from "react";
import userAuth from "../components/userAuth";
import BootstrapTable from "react-bootstrap-table-next";
import { Container, InputGroup, FormControl } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import { useHistory, useLocation } from "react-router-dom";
import { listArAgencyOrderInfoByAgencyId, listAllArAgencyOrderInfo } from "../graphql/queries";
import API, { graphqlOperation } from "@aws-amplify/api";
import moment from "moment";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import { makeStyles } from "@material-ui/core/styles";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Auth } from "aws-amplify";
import { customerByeMail } from "../graphql/queries";
import { Dialog, DialogActions, DialogContent, DialogContentText,DialogTitle,Link } from "@material-ui/core";
import qs from 'qs';
import { deleteArAgencyOrderInfo,updateArAgencyOrderInfo } from "../graphql/mutations";
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DoneIcon from '@material-ui/icons/Done';
import clsx from 'clsx';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
  },
  rootl: {
    maxWidth: "100%",
  },
  card1: {
    maxWidth: "50%",
    minWidth: "50%",
    width: "50%",
  },
  datePicker: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    borderRadius: '4px',
    border: "1px solid #707070",
  },
  card2: {
    maxWidth: "100%",
  },
  table: {
    minWidth: "auto",
    display: "flex",
    justifyContent: "end",
    border: "none",
  },
  textlabel: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '25px',
    width: '15ch',
    background: "rgb(91,56,9)",
    border: "1px solid #707070",
    color: "white",
    fontWeight: "bold",
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textlabelDialog: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height: '25px',
    width: '20ch',
    background: "rgb(91,56,9)",
    border: "1px solid #707070",
    color: "white",
    fontWeight: "bold",
    fontSize: '14px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textlabelTT: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: "transparent",
    border: "1px solid #707070",
    borderRadius: "1px",
    color: "black",
    fontWeight: "bold",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: "transparent",
    border: "1px solid #707070",
    borderRadius: "6px",
    color: "black",
  },
  textFieldP: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "100%",
    "text-align": "right",
    align: "right",
  },
  inputCheckbox: {
    whiteSpace: "nowrap",
    width:110
  },
  inputSize: {
    marginRight: theme.spacing(1),
    color: 'black',
    width: '3ch',
    height: '3ch',
    verticalAlign: 'middle',
  },
  textFieldOrderQuantity: {
    align: "right",
  },
  textKField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: "transparent",
    borderBottom: "1px dashed #707070",
    width: "auto",
    border: "none",
    borderRadius: "1px",
    color: "black",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  fixedTop:{
    position: "sticky",
    top: 0,
    boxShadow:"0 -1px 0 0 white,0 3px 0 0 #804F29,0 3px 0 0 transparent",
    backgroundColor: "white",
    zIndex:9,
  },
  textArea: {
    resize: 'none',
    height: '200px',
    maxHeight: '200px',
  },

}));
const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    {size} の結果の {from} から {to} を表示しています
  </span>
);
const pageButtonRenderer = ({ page, onPageChange, currSizePerPage }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };
  return (
    <button
      key={page}
      className={`btn ${currSizePerPage === `${page}` ? "btn-warning" : "btn-light"
        }`}
      type="button"
      onClick={handleClick}
    >
      <li className="page-item">
        <a style={{ color: "black" }} href="#">
          {page}
        </a>
      </li>
    </button>
  );
};
const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
  <div className="btn-group" role="group">
    {options.map((option) => (
      <button
        key={option.text}
        type="button"
        onClick={() => onSizePerPageChange(option.page)}
        className={`btn ${currSizePerPage === `${option.page}` ? "btn-warning" : "btn-light"
          }`}
      >
        {option.text}
      </button>
    ))}
  </div>
);
const options = {
  paginationSize: 20,
  pageStartIndex: 1,
  showTotal: true,
  withFirstAndLast: true, // Hide the going to First and Last page button
  firstPageText: "最初",
  prePageText: "戻る",
  nextPageText: "次へ",
  lastPageText: "最後",
  nextPageTitle: "最初のページ",
  prePageTitle: "プレページ",
  firstPageTitle: "次のページ",
  lastPageTitle: "最後のページ",
  paginationTotalRenderer: customTotal,
  sizePerPageRenderer: sizePerPageRenderer,
  pageButtonRenderer: pageButtonRenderer,
  disablePageTitle: true,
  sizePerPageList: [
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};
const { SearchBar, ClearSearchButton } = Search;
let AgencyID = "";
let Permission = "";
let dataAll = null;
const OrderList = () => {
  const [memoInfo, setMemoTextInfo] = useState({
    memo:"",
    show:false,
    id:"",
    DiaQuoteNumber:""
  });
  const handleDialogmemoOk = async()=>{
    setSearchProgress(true)
    try{
      await API.graphql(graphqlOperation(updateArAgencyOrderInfo, {
        input: {
          id: memoInfo.id,
          CompanMemo: memoInfo.memo
      }}))
      orderinfo.forEach((item)=>{
        if(item.id === memoInfo.id){
          item.CompanMemo = memoInfo.memo
        }})
      setOrderinfo(orderinfo)
      setMemoTextInfo({memo:"",show:false,id:"",DiaQuoteNumber:""})
    }catch(error){
      if(error.message === 'No current user'){
        // ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        // ログイン画面に戻る
        signOut();
        }else {
        // 他のエラーを処理する
        console.log('An error occurred:',error);
        }
     }
      setSearchProgress(false)
  }
  const handletrue = (QuoteNumber,CompanMemo,ID)=>{
    setMemoTextInfo({
    memo:CompanMemo,
    show:true,
    id:ID,
    DiaQuoteNumber:QuoteNumber
    })
  }
  const handleDialogmemo = (e)=>{
    setMemoTextInfo({...memoInfo,memo:e.target.value})
  }
  const handleCancel = ()=>{
    setMemoTextInfo({...memoInfo,show:false})
  }
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [DelInfo,setDelInfo] = useState("")
  const [searchPage, setSearchPage] = useState(false);
  //電子材料部表示項目設定init
  const openValueInit = {
    registrationDate: true,
    orderDetailsNumber: true,
    orderStatus: true,
    quoteNumber: true,
    orderNumber: true,
    contractorNm: true,
    shippingDestinationNm: true,
    endUserNm: true,
    product_Code: true,
    product_Name_j: true,
    orderQuantity: true,
    shippingInvoice: true,
    shipmentQuantity: true,
    quantity: true,
    desiredDeliveryDate: true,
    deliveryDate: true,
    CompanMemo: true,
    ShipmentStatus: true,
    salesperson: true,
    assigneeCode:true,
    suppName:true,
  };
  const handleClickOpen = async () => {
    let SelectAllFlagInit = true
    let checkedList = sessionStorage.getItem("displayItemSettingObj") ? JSON.parse(sessionStorage.getItem("displayItemSettingObj")) : openValueInit
    await setOpen2(true);
    for (let item in checkedList) {
      let dom = document.querySelector(`#${item}`)
      dom.checked = checkedList[item]
      if(checkedList[item] === false){
        SelectAllFlagInit = false
      }
    }
    document.getElementsByName("selectAll")[0].checked = SelectAllFlagInit
    setSelectAllFlag(SelectAllFlagInit)
    setSettingButton(false)
  };
  const [SelectAllFlag,setSelectAllFlag] = useState(true)
  const [settingButton,setSettingButton] = useState(false)
  const DisplayProjectsSettingsSelectAll = ()=>{
    let flag = !SelectAllFlag
    for (let item in openValueInit) {
      let dom = document.querySelector(`#${item}`)
      dom.checked = flag
    }
    if(!flag){
      setSettingButton(true)
    }else{
      setSettingButton(false)
    }
    setSelectAllFlag(flag)
  }
  const checkboxHandleChange = (e)=>{
    let SelectAllFlagInit = true
    let settingButtonDisable = true
    const checkedlist = {
      registrationDate: (document.getElementsByName("registrationDate")[0]).checked,
      orderDetailsNumber: (document.getElementsByName("orderDetailsNumber")[0]).checked,
      orderStatus: (document.getElementsByName("orderStatus")[0]).checked,
      quoteNumber: (document.getElementsByName("quoteNumber")[0]).checked,
      orderNumber: (document.getElementsByName("orderNumber")[0]).checked,
      contractorNm: (document.getElementsByName("contractorNm")[0]).checked,
      shippingDestinationNm: (document.getElementsByName("shippingDestinationNm")[0]).checked,
      endUserNm: (document.getElementsByName("endUserNm")[0]).checked,
      product_Code: (document.getElementsByName("product_Code")[0]).checked,
      product_Name_j: (document.getElementsByName("product_Name_j")[0]).checked,
      orderQuantity: (document.getElementsByName("orderQuantity")[0]).checked,
      shippingInvoice: (document.getElementsByName("shippingInvoice")[0]).checked,
      shipmentQuantity: (document.getElementsByName("shipmentQuantity")[0]).checked,
      quantity: (document.getElementsByName("quantity")[0]).checked,
      desiredDeliveryDate: (document.getElementsByName("desiredDeliveryDate")[0]).checked,
      deliveryDate: (document.getElementsByName("deliveryDate")[0]).checked,
      CompanMemo: (document.getElementsByName("CompanMemo")[0]).checked,
      ShipmentStatus: (document.getElementsByName("ShipmentStatus")[0]).checked,
      salesperson: (document.getElementsByName("salesperson")[0]).checked,
      assigneeCode: (document.getElementsByName("assigneeCode")[0]).checked,
      suppName: (document.getElementsByName("suppName")[0]).checked,
    }
    for (let item in checkedlist) {
      if(checkedlist[item] === false){
        SelectAllFlagInit = false
      }else{
        settingButtonDisable = false
      }
  }
  document.getElementsByName("selectAll")[0].checked = SelectAllFlagInit
  setSelectAllFlag(SelectAllFlagInit)
  setSettingButton(settingButtonDisable)
}
  const handleOK = () => {
    const checkedlist = {
      registrationDate: (document.getElementsByName("registrationDate")[0]).checked,
      orderDetailsNumber: (document.getElementsByName("orderDetailsNumber")[0]).checked,
      orderStatus: (document.getElementsByName("orderStatus")[0]).checked,
      quoteNumber: (document.getElementsByName("quoteNumber")[0]).checked,
      orderNumber: (document.getElementsByName("orderNumber")[0]).checked,
      contractorNm: (document.getElementsByName("contractorNm")[0]).checked,
      shippingDestinationNm: (document.getElementsByName("shippingDestinationNm")[0]).checked,
      endUserNm: (document.getElementsByName("endUserNm")[0]).checked,
      product_Code: (document.getElementsByName("product_Code")[0]).checked,
      product_Name_j: (document.getElementsByName("product_Name_j")[0]).checked,
      orderQuantity: (document.getElementsByName("orderQuantity")[0]).checked,
      shippingInvoice: (document.getElementsByName("shippingInvoice")[0]).checked,
      shipmentQuantity: (document.getElementsByName("shipmentQuantity")[0]).checked,
      quantity: (document.getElementsByName("quantity")[0]).checked,
      desiredDeliveryDate: (document.getElementsByName("desiredDeliveryDate")[0]).checked,
      deliveryDate: (document.getElementsByName("deliveryDate")[0]).checked,
      CompanMemo: (document.getElementsByName("CompanMemo")[0]).checked,
      ShipmentStatus: (document.getElementsByName("ShipmentStatus")[0]).checked,
      salesperson: (document.getElementsByName("salesperson")[0]).checked,
      assigneeCode: (document.getElementsByName("assigneeCode")[0]).checked,
      suppName: (document.getElementsByName("suppName")[0]).checked,
    }
    sessionStorage.setItem("displayItemSettingObj", JSON.stringify(checkedlist))
    setDisplayItemSetting(checkedlist)
    setOpen2(false);
  };
  const handleCloseNG = () => {
    setOpen2(false);
  };
  const handleCloseOK = () => {
    setOpen2(false);
  };
  // const DateFunPick = moment(formatY).utcOffset(9).format('YYYY-MM-DD');
  const [DesiredDeliveryDateStart, setDesiredDeliveryDateStart] = useState(null)
  const [DesiredDeliveryDateEnd, setDesiredDeliveryDateEnd] = useState(null)
  const [RegistrationDateStart, setRegistrationDateStart] = useState(null)
  const [RegistrationDateEnd, setRegistrationDateEnd] = useState(null)
  const [userAuths, setUserAuths] = useState()
  //電子材料部ページの初期取得
  const [searchValue, setSearchValue] = useState({
    OrderDetailsNumber: "",
    ContractorNm: "",
    ShippingDestinationNm: "",
    EndUserNm: "",
    Product_Code: "",
    Salesperson: "",
    SalesGroup:"",
    OrderStatus2: false,
    OrderStatus3: false,
    OrderStatus5: false,
    OrderStatus7: false,
    OrderStatus8: false,
    OrderStatus9: false,
    AssigneeCode:"",
    OrderStatus4:false,
    ShipmentStatusA:false,
    ShipmentStatusB:false,
    ShipmentStatusC:false,
    ShipmentStatusN:false
  })
  const searchValueChange = (e) => {
    if (e.target.name === "OrderStatus2") {
      setSearchValue({ ...searchValue, OrderStatus2: (document.getElementsByName("OrderStatus2")[0]).checked })
      return;
    }
    if (e.target.name === "OrderStatus3") {
      setSearchValue({ ...searchValue, OrderStatus3: (document.getElementsByName("OrderStatus3")[0]).checked })
      return;
    }
    if (e.target.name === "OrderStatus5") {
      setSearchValue({ ...searchValue, OrderStatus5: (document.getElementsByName("OrderStatus5")[0]).checked })
      return;
    }
    if (e.target.name === "OrderStatus7") {
      setSearchValue({ ...searchValue, OrderStatus7: (document.getElementsByName("OrderStatus7")[0]).checked })
      return;
    }
    if (e.target.name === "OrderStatus8") {
      setSearchValue({ ...searchValue, OrderStatus8: (document.getElementsByName("OrderStatus8")[0]).checked })
      return;
    }
    if (e.target.name === "OrderStatus9") {
      setSearchValue({ ...searchValue, OrderStatus9: (document.getElementsByName("OrderStatus9")[0]).checked })
      return;
    }
    if (e.target.name === "OrderStatus4") {
      setSearchValue({ ...searchValue, OrderStatus4: (document.getElementsByName("OrderStatus4")[0]).checked })
      return;
    }
    if (e.target.name === "ShipmentStatusA") {
      setSearchValue({ ...searchValue, ShipmentStatusA: (document.getElementsByName("ShipmentStatusA")[0]).checked })
      return;
    }
    if (e.target.name === "ShipmentStatusB") {
      setSearchValue({ ...searchValue, ShipmentStatusB: (document.getElementsByName("ShipmentStatusB")[0]).checked })
      return;
    }
    if (e.target.name === "ShipmentStatusC") {
      setSearchValue({ ...searchValue, ShipmentStatusC: (document.getElementsByName("ShipmentStatusC")[0]).checked })
      return;
    }
    if (e.target.name === "ShipmentStatusN") {
      setSearchValue({ ...searchValue, ShipmentStatusN: (document.getElementsByName("ShipmentStatusN")[0]).checked })
      return;
    }
    setSearchValue({ ...searchValue, [e.target.name]: e.target.value })
  }
  const classes = useStyles()
  const history = useHistory();
  const location = useLocation();
  const { QuoteNumber } = qs.parse(location.search.replace(/^\?/, ''));
  const [userAuthValue, setUserAuthValue] = useState(true)
  const [orderinfo, setOrderinfo] = useState([]);
  const [SalesGroupInfo, setSalesGroupInfo] = useState("162");
  const [ShippingInvoiceDisable, setShippingInvoiceDisable] = useState(true)
  const [SearChQuoteNumber, setSearChQuoteNumber] = useState({
    defaultSearch: QuoteNumber
  });
  const signOut = async () => {
    await Auth.signOut();
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
    document.location.reload();
  };
  const sortOrderDetailsNumber = (arr)=>{
    for (let i = 0; i < arr.length - 1; i++) {
      for (let j = 0; j < arr.length - 1 - i; j++) {
          if (arr[j].OrderDetailsNumber < arr[j + 1].OrderDetailsNumber) {
            let temp = arr[j];
            arr[j] = arr[j + 1];
            arr[j + 1] = temp;
          }
      }
    }
    return arr
  }
  const [scrollTableWidth,setscrollTableWidth] = useState("200%")
  useEffect(() => {
    async function listOrderData() {
      setSearchProgress(true)
      let userAuths
      try{
     userAuths = await userAuth();
    }catch(error){
      if(error.message === 'No current user'){
        // ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        // ログイン画面に戻る
        signOut();
        }else {
        // 他のエラーを処理する
        console.log('An error occurred:',error);
        }
     }
     setUserAuths(userAuths)
      if (userAuths === '1') {
      setUserAuthValue(true);
        setDisplayItemSetting({ ...LifeSciencesColumnsDisplay, contractorNm: false,shippingInvoice:false })
        const user = await Auth.currentUserInfo();
        const agencyPriceGroup = await API.graphql(graphqlOperation(customerByeMail, { Agency_Email: user.attributes.email }));
        const agentinfo = agencyPriceGroup.data.customerByeMail['items'];
        if (agentinfo.length > 0) {
          AgencyID = agentinfo[0].Agency_id;
        }
        const listArOrder = await API.graphql(graphqlOperation(listArAgencyOrderInfoByAgencyId, { Agency_id: AgencyID, sortDirection: "DESC",filter: {UserAuth:{eq:userAuths}} }));
        if (listArOrder.data.listArAgencyOrderInfoByAgencyId !== null) {
          const order = listArOrder.data.listArAgencyOrderInfoByAgencyId['items'];
          for (let j = 0; j < order.length; j++) {
            if (order[j].RegistrationDate !== null && order[j].RegistrationDate !== '') {
              order[j].RegistrationDate = moment(order[j].RegistrationDate).utcOffset(9).format('YYYY/MM/DD HH:mm');
            }
            if (order[j].OrderDate !== null && order[j].OrderDate !== '') {
              order[j].OrderDate = moment(order[j].OrderDate).utcOffset(0).format('YYYY/MM/DD');
            }
            if (order[j].DesiredDeliveryDate !== null && order[j].DesiredDeliveryDate !== '') {
              order[j].DesiredDeliveryDate = moment(order[j].DesiredDeliveryDate).format('YYYY/MM/DD');
            }
            if (!order[j].ShipmentQuantity) {
              order[j].ShipmentQuantity = "";
            }
          }
          let arr = order
          arr = sortOrderDetailsNumber(arr)
          setOrderinfo(arr);
        }
      } else if(userAuths === '2') {
        setSearchPage(true);
        const user = await Auth.currentUserInfo();
        const { accessToken } = await Auth.currentSession();
           const cognitogroups = accessToken.payload['cognito:groups'];
           let SalesGroupInfo = "162"
           if (cognitogroups && cognitogroups[0] === "silicone162"){
            SalesGroupInfo = '162'
           }else if(cognitogroups && cognitogroups[0] === "silicone141"){
            SalesGroupInfo = '141'
           }else if(cognitogroups && cognitogroups[0] === "silicone181"){
            SalesGroupInfo = '181'
           }
        const agencyPriceGroup = await API.graphql(graphqlOperation(customerByeMail, { Agency_Email: user.attributes.email }));
        const agentinfo = agencyPriceGroup.data.customerByeMail['items'];
        if (agentinfo.length > 0) {
          Permission = agentinfo[0].Permission;
        }
        const listArOrder = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, { limit: 10000, filter: {SalesGroup: {eq: SalesGroupInfo}}}));
        if (listArOrder.data.listAllArAgencyOrderInfo !== null) {
          let nextToken = listArOrder.data.listAllArAgencyOrderInfo.nextToken;
          dataAll = listArOrder.data.listAllArAgencyOrderInfo.items;
          while (nextToken !== null) {
            let InvDataSec = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, { limit: 10000, filter: {SalesGroup: {eq: SalesGroupInfo}},nextToken: nextToken}));
            nextToken = InvDataSec.data.listAllArAgencyOrderInfo.nextToken;
            for (let i = 0; i < InvDataSec.data.listAllArAgencyOrderInfo.items.length; i++) {
              dataAll.push(InvDataSec.data.listAllArAgencyOrderInfo.items[i]);
            }
          }
        }
        for (let j = 0; j < dataAll.length; j++) {
          if (dataAll[j].RegistrationDate !== null && dataAll[j].RegistrationDate !== '') {
            dataAll[j].RegistrationDate = moment(dataAll[j].RegistrationDate).utcOffset(9).format('YYYY/MM/DD HH:mm');
          }
          if (dataAll[j].OrderDate !== null && dataAll[j].OrderDate !== '') {
            dataAll[j].OrderDate = moment(dataAll[j].OrderDate).utcOffset(0).format('YYYY/MM/DD');
          }
          if (dataAll[j].DesiredDeliveryDate !== null && dataAll[j].DesiredDeliveryDate !== '') {
            dataAll[j].DesiredDeliveryDate = moment(dataAll[j].DesiredDeliveryDate).format('YYYY/MM/DD');
          }
          if (!dataAll[j].ShipmentQuantity) {
            dataAll[j].ShipmentQuantity = "";
          }
        }
        dataAll = sortOrderDetailsNumber(dataAll)
        setSalesGroupInfo(SalesGroupInfo)
        setOrderinfo(dataAll);
      } else {
        setUserAuthValue(false)
        setShippingInvoiceDisable(false)
        setDisplayItemSetting(sessionStorage.getItem("displayItemSettingObj") ? JSON.parse(sessionStorage.getItem("displayItemSettingObj")) : { ...LifeSciencesColumnsDisplay, CompanMemo: true, salesperson: true,suppName:true,assigneeCode:true })
        const listArOrder = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, { limit: 10000, filter: {and:[{OrderStatus: {gt: "2" }},{UserAuth: {eq: "1" }}]}}));
        if (listArOrder.data.listAllArAgencyOrderInfo !== null) {
          let nextToken = listArOrder.data.listAllArAgencyOrderInfo.nextToken;
          dataAll = listArOrder.data.listAllArAgencyOrderInfo.items;
          while (nextToken !== null) {
            let InvDataSec = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, { limit: 10000, filter: {and:[{OrderStatus: {gt: "2" }},{UserAuth: {eq: "1" }}]},nextToken: nextToken}));
            nextToken = InvDataSec.data.listAllArAgencyOrderInfo.nextToken;
            for (let i = 0; i < InvDataSec.data.listAllArAgencyOrderInfo.items.length; i++) {
              dataAll.push(InvDataSec.data.listAllArAgencyOrderInfo.items[i]);
            }
          }
        }
        for (let j = 0; j < dataAll.length; j++) {
          if (dataAll[j].RegistrationDate !== null && dataAll[j].RegistrationDate !== '') {
            dataAll[j].RegistrationDate = moment(dataAll[j].RegistrationDate).utcOffset(9).format('YYYY/MM/DD HH:mm');
          }
          if (dataAll[j].OrderDate !== null && dataAll[j].OrderDate !== '') {
            dataAll[j].OrderDate = moment(dataAll[j].OrderDate).utcOffset(0).format('YYYY/MM/DD');
          }
          if (dataAll[j].DesiredDeliveryDate !== null && dataAll[j].DesiredDeliveryDate !== '') {
            dataAll[j].DesiredDeliveryDate = moment(dataAll[j].DesiredDeliveryDate).format('YYYY/MM/DD');
          }
          if (!dataAll[j].ShipmentQuantity) {
            dataAll[j].ShipmentQuantity = "";
          }
        }
        dataAll = sortOrderDetailsNumber(dataAll)
        setOrderinfo(dataAll);
      }
      if (QuoteNumber) {
        document.getElementById("search-bar-0").value = QuoteNumber;
      }
      let dom = document.querySelector('#table');
      if (dom) {
        let width = dom.offsetWidth;
        setscrollTableWidth(`${width}px`);
    }
      setSearchProgress(false)
    }
    listOrderData();
    // document.getElementById("search-bar-0").value = 'E23011813091539493'
  }, []);
  window.addEventListener('resize', function() {
    let dom = document.querySelector('#table');
    let width = dom.offsetWidth;
    setscrollTableWidth(`${width}px`)
  });
  const LifeSciencesColumnsDisplay = {
    registrationDate: true,
    orderDetailsNumber: true,
    orderStatus: true,
    quoteNumber: true,
    orderNumber: true,
    contractorNm: true,
    shippingDestinationNm: true,
    endUserNm: true,
    product_Code: true,
    product_Name_j: true,
    orderQuantity: true,
    shippingInvoice: true,
    shipmentQuantity: true,
    quantity: true,
    desiredDeliveryDate: true,
    deliveryDate: true,
    CompanMemo: userAuthValue === true ? false : true,
    salesperson: userAuthValue === true ? false : true,
    assigneeCode: userAuthValue === true ? false : true,
    suppName: userAuthValue === true ? false : true,
    ShipmentStatus: true,
  }
  //電子材料部表示項目設定
  const [displayItemSetting, setDisplayItemSetting] = useState(sessionStorage.getItem("displayItemSettingObj") ? JSON.parse(sessionStorage.getItem("displayItemSettingObj")) : {
    registrationDate: true,
    orderDetailsNumber: true,
    orderStatus: true,
    quoteNumber: true,
    orderNumber: true,
    contractorNm: true,
    shippingDestinationNm: true,
    endUserNm: true,
    product_Code: true,
    product_Name_j: true,
    orderQuantity: true,
    shippingInvoice: true,
    shipmentQuantity: true,
    quantity: true,
    desiredDeliveryDate: true,
    deliveryDate: true,
    CompanMemo: true,
    salesperson: true,
    assigneeCode:true,
    suppName:true,
    ShipmentStatus: true,
  })
  const handleDelClick = async (DelInfo) => {
    setOpen(false)
    let allOrder = [];
    for (let i = 0; i < orderinfo.length; i++) {
      if (orderinfo[i].id !== DelInfo.id) {
        allOrder.push(orderinfo[i]);
      }
    }
    await API.graphql(
      graphqlOperation(deleteArAgencyOrderInfo, {
        input: {
          id: DelInfo.id,
          QuoteNumber: DelInfo.QuoteNumber
        },
      })
    );
    allOrder = sortOrderDetailsNumber(allOrder)
    setOrderinfo(allOrder);
  };
  const defaultSorted = [
    {
      dataField: "RegistrationDate",
      order: "desc",
    },
  ];
  function handleEditClick(OrderStatus, QuoteNumber, DesiredDeliveryDate, AgencyID, OrderDetailsNumber) {
    if (userAuths === "2") {
      if (OrderStatus >= "3" && OrderStatus !== "8" && OrderStatus !== "9") {
        history.push({
          pathname: "/OrderConfirmation",
          state: {
            QuoteNumber: QuoteNumber,
            OrderDetailsNumber: OrderDetailsNumber
          },
        });
      }
    } else {
      if (userAuthValue) {
        if (OrderStatus === "1") {
          history.push({
            pathname: "/Order",
            state: { QuoteNumber: QuoteNumber, AgencyID: AgencyID },
          });
        } else if (OrderStatus === "2") {
          history.push({
            pathname: "/OrderConfirmation",
            state: { QuoteNumber: QuoteNumber },
          });
        } else if (OrderStatus === "3") {
          history.push({
            pathname: "/",
            state: {
              QuoteNumber: QuoteNumber,
              DesiredDeliveryDate: DesiredDeliveryDate,
            },
          });
        } else {
          history.push({
            pathname: "/OrderConfirmation",
            state: {
              QuoteNumber: QuoteNumber,
            },
          });
        }
      } else {
        if (OrderStatus === "2") {
          history.push({
            pathname: "/OrderConfirmation",
            state: { QuoteNumber: QuoteNumber },
          });
        } else if (OrderStatus === "3") {
          history.push({
            pathname: "/OrderConfirmation",
            state: { QuoteNumber: QuoteNumber, OrderDetailsNumber: OrderDetailsNumber },
          });
        } else {
          history.push({
            pathname: "/OrderConfirmation",
            state: {
              QuoteNumber: QuoteNumber,
              OrderDetailsNumber: OrderDetailsNumber
            },
          });
        }
      }
    }
  }
  const headerStyle = {
    backgroundColor: "white",
    borderBottom: "3px solid #804F29",
    fontSize: userAuthValue ? "70%" : "60%",
    minWidth: '100px'
  };

  let columns = [
    { dataField: "id", text: "ID", hidden: true, sort: true },
{
      dataField: "edit",
      text: "操作",
      headerStyle,
      style: { margin: "0px",minWidth:"100px",fontSize: "90%" },
      formatter: (cellContent, row) => {
        if (row.count !== null) {
            if(searchPage === true){
              if(Number(row.OrderStatus) >= 3 && Number(row.OrderStatus) !== 8 && Number(row.OrderStatus) !== 9){
                if (row.OutputFlag === '0' || row.OutputFlag === '2') {
                  return (
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={true}
                        size="small"
                      >
                        確認済
                      </Button>
                    </span>
                  );
                } else {
                  return (
                    <span>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => {
                          handleEditClick(
                            row.OrderStatus,
                            row.QuoteNumber,
                            row.DesiredDeliveryDate,
                            row.AgencyID,
                            row.OrderDetailsNumber
                          );
                        }}
                      >
                        確定確認
                      </Button>
                    </span >
                  );
                }
              }else{
                if(Permission === "2" && (row.OrderStatus === "1" || row.OrderStatus === "2")){
                  return (
                    <span>
                        <Button
                          variant="contained"
                          style={{backgroundColor:"LightCoral",color:"white"}}
                          onClick={() => {
                            setDelInfo(row)
                            setOpen(true);
                          }}
                        >
                          削除
                        </Button>
                    </span>
                  );
                }
              }
            }
          if (searchPage === false && userAuthValue === false) {
            if (Number(row.OrderStatus) >= 3 && Number(row.OrderStatus) !== 8 && Number(row.OrderStatus) !== 9) {
            if (row.OutputFlag === '0' || row.OutputFlag === '2') {
              return (
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={true}
                    size="small"
                  >
                    確認済
                  </Button>
                </span>
              );
            } else {
              return (
                <span>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    //style={{ fontSize:"90%" }}
                    onClick={() => {
                      handleEditClick(
                        row.OrderStatus,
                        row.QuoteNumber,
                        row.DesiredDeliveryDate,
                        row.AgencyID,
                        row.OrderDetailsNumber
                      );
                    }}
                  >
                    確定確認
                  </Button>
                </span >
              );
            }}
          } else {
            if (row.count !== null) {
              return (
                <span>
                  <div style={{ display: searchPage === true ? "none" : "flex" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ display: 'none' }}
                      onClick={() => {
                        handleEditClick(
                          row.OrderStatus,
                          row.QuoteNumber,
                          row.DesiredDeliveryDate,
                          row.AgencyID,
                          row.OrderDetailsNumber
                        );
                      }}
                    >
                      編集
                    </Button>
                    <Button
                      variant="contained"
                      style={{ display: row.OrderStatus === "1" || row.OrderStatus === "2" ? "flex" : "none",backgroundColor:"LightCoral",color:"white"}}
                      onClick={() => {
                        // handleDelClick(row.QuoteNumber);
                        setDelInfo(row)
                        setOpen(true);
                      }}
                    >
                      削除
                    </Button>
                  </div>
                </span>
              );
            }
          }
        }
      },
    },
    { dataField: "RegistrationDate", text: "注文日時", hidden: !displayItemSetting.registrationDate, sort: true, headerStyle, style: { margin: "0px", minWidth: "150px", fontSize: "90%" } },
    {
      dataField: "OrderDetailsNumber",
      text: "受注番号",
      sort: true,
      hidden: !displayItemSetting.orderDetailsNumber ,
      headerStyle,
      style: { margin: "0px", minWidth: "160px", fontSize: "90%"},
      formatter: (cellContent, row) => {
        if (row.OrderStatus === "1" || row.OrderStatus === "2" || row.OrderStatus === "8" || row.OrderStatus === "9") {
          return (
            <Link
              onClick={() => {
                if(row.UserAuth === "2"){
                  if (row.OrderStatus === "1" || row.OrderStatus === "8" || row.OrderStatus === "9") {
                    history.push({
                      pathname: "/OrderEmd",
                      state: {QuoteNumber: row.QuoteNumber,AgencyID: row.Agency_id},
                    });
                  } else {
                    history.push({
                      pathname: "/OrderConfirmation",
                      state: {QuoteNumber: row.QuoteNumber,AgencyID: row.Agency_id },
                    });
                  }
                }else{
                     if (row.OrderStatus === "1") {
                  history.push({
                    pathname: "/Order",
                    state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id },
                  });
                } else {
                  history.push({
                    pathname: "/OrderConfirmation",
                    state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id },
                  });
                }
                }
              }}
            >
              {row.OrderDetailsNumber}
            </Link>
          );
        }
        else {
          return (
            <Link
              onClick={() => {
                if(row.UserAuth === "2"){
                  if(row.OrderStatus === "3" || row.OrderStatus === "4" || row.OrderStatus === "5"){
                    history.push({
                      pathname: "/OrderEmd",
                      state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id, OrderDetailsNumber: row.OrderDetailsNumber,RepurchaseDisable:true,readOnly: true},
                    });
                  }else{
                    history.push({
                      pathname: "/OrderEmd",
                      state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id, readOnly: true, OrderDetailsNumber: row.OrderDetailsNumber },
                    });
                  }
                }else{
                  history.push({
                    pathname: "/Order",
                    state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id, readOnly: true, OrderDetailsNumber: row.OrderDetailsNumber },
                  });
                }
              }}
            >
              {row.OrderDetailsNumber}
            </Link>
          );
        }
      },
    },
    {
      dataField: "OrderStatus",
      text: "ステータス",
      sort: true,
      hidden: !displayItemSetting.orderStatus,
      headerStyle: { ...headerStyle, minWidth: '120px' },
      style: { margin: "0px", minWidth: "130px", fontSize: "90%", },
      formatter: (cellContent, row) => {
        if (row.ShipmentStatus === "N") {
          return (
            <div>
              キャンセル
            </div>
          );
        }
        else {
          if (row.OrderStatus === "1") {
            return (
              <div>
                {/* <Link to='/OrderConfirmation' component="button" variant="body2" onClick={() => { handleClick(row.QuoteNumber);}}>登録中</Link> */}
                一時保存
              </div>
            );
          } else if (row.OrderStatus === "2") {
            return (
              <div>
                {/* <Link to='/OrderConfirmation' component="button" variant="body2" onClick={() => { handleClick(row.QuoteNumber);}}></Link> */}
                一時保存
              </div>
            );
          } else if (row.OrderStatus === "3") {
            return (
              <div>
                {/* <Link to='/OrderConfirmation' component="button" variant="body2" onClick={() => { handleClick(row.QuoteNumber);}}></Link> */}
                受注受付
              </div>
            );
          } else if (row.OrderStatus === "4") {
            return (
              <div>
                {/* <Link to='/OrderConfirmation' component="button" variant="body2" onClick={() => { handleClick(row.QuoteNumber);}}></Link> */}
                受付処理中
              </div>
            );
          } else if (row.OrderStatus === "5") {
            let content = "納期確定"
            return (
              <div>
                {content}
              </div>
            );
          } else if (row.OrderStatus === "6") {
            return (
              <div>
                {/* <Link to='/OrderConfirmation' component="button" variant="body2" onClick={() => { handleClick(row.QuoteNumber);}}></Link> */}
                受注キャンセル
              </div>
            );
          } else if (row.OrderStatus === "7") {
            return (
              <div>
                {/* <Link to='/OrderConfirmation' component="button" variant="body2" onClick={() => { handleClick(row.QuoteNumber);}}></Link> */}
                出荷済み
              </div>
            );
          } else if (row.OrderStatus === "9") {
            return (
              <div>
                {/* <Link to='/OrderConfirmation' component="button" variant="body2" onClick={() => { handleClick(row.QuoteNumber);}}></Link> */}
                見積受付
              </div>
            );
          } else if (row.OrderStatus === "8") {
            return (
              <div>
                {/* <Link to='/OrderConfirmation' component="button" variant="body2" onClick={() => { handleClick(row.QuoteNumber);}}></Link> */}
                営業確認
              </div>
            );
          }
        }

        },

    },
    {
      dataField: "ShipmentStatus",
      text: "出荷ステータス",
      hidden: !displayItemSetting.ShipmentStatus,
      headerStyle: { ...headerStyle, minWidth: '120px' },
      style: { margin: "0px", minWidth: "120px", fontSize: "90%" },
      formatter: (cellContent, row) => {
        let resultContent = "";
        if (cellContent) {
          if (cellContent === 'A') {
            resultContent = "未出荷"
          } else if (cellContent === 'B') {
            resultContent = "一部出荷"
          }
          else if (cellContent === 'C') {
            resultContent = "全出荷"
          }
        }
        return (<span>{resultContent}</span>)
      }
    },
    {
      dataField: "QuoteNumber", text: "登録番号", hidden: !displayItemSetting.quoteNumber, sort: true, headerStyle, style: { margin: "0px", minWidth: "130px", fontSize: "90%", },
      formatter: (cellContent, row) => {
        if (row.OrderStatus === "1" || row.OrderStatus === "2" || row.OrderStatus === "8" || row.OrderStatus === "9") {
          return (
            <Link
              onClick={() => {
                if (row.UserAuth === "2") {
                  if (row.OrderStatus === "1" || row.OrderStatus === "8" || row.OrderStatus === "9") {
                    history.push({
                      pathname: "/OrderEmd",
                      state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id },
                    });
                  } else {
                    history.push({
                      pathname: "/OrderConfirmation",
                      state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id },
                    });
                  }
                } else {
                  if (row.OrderStatus === "1") {
                    history.push({
                      pathname: "/Order",
                      state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id },
                    });
                  } else {
                    history.push({
                      pathname: "/OrderConfirmation",
                      state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id },
                    });
                  }
                }
              }}
            >
              {row.QuoteNumber}
            </Link>
          );
        }
        else {
          return (
            <Link
              onClick={() => {
                if (row.UserAuth === "2") {
                  if (row.OrderStatus === "3" || row.OrderStatus === "4" || row.OrderStatus === "5") {
                    history.push({
                      pathname: "/OrderEmd",
                      state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id, RepurchaseDisable: true, readOnly: true },
                    });
                  } else {
                    history.push({
                      pathname: "/OrderEmd",
                      state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id, readOnly: true },
                    })
                  }
                } else {
                  history.push({
                    pathname: "/Order",
                    state: { QuoteNumber: row.QuoteNumber, AgencyID: row.Agency_id, readOnly: true },
                  });
                }
              }}
            >
              {row.QuoteNumber}
            </Link>
          );
        }
      },
    },
    { dataField: "OrderNumber", text: "注文番号", hidden: !displayItemSetting.orderNumber, sort: true, headerStyle, style: { margin: "0px", minWidth: "130px", fontSize: "90%" }, },
    { dataField: "ContractorNm", text: "発注先", hidden: !displayItemSetting.contractorNm, sort: true, headerStyle, style: { margin: "0px", minWidth: "200px", fontSize: "90%" },
    formatter: (cellContent, row) => {
      let val = ""
      if(row.GroupName !== null && row.GroupName !== undefined && row.GroupName){
        val = " " + row.GroupName
       }
      return (
        <span>{ row.UserAuth === "1" ? row.ContractorNm + val : row.ContractorNm}</span>
      )
    }
  },
    { dataField: "ShippingDestinationNm", text: "出荷先", hidden: !displayItemSetting.shippingDestinationNm, sort: true, headerStyle, style: { margin: "0px", minWidth: "210px", fontSize: "90%" }, },
    { dataField: "EndUserNm", text: "エンドユーザー", hidden: !displayItemSetting.endUserNm, sort: true, headerStyle: { ...headerStyle, minWidth: '160px' }, style: { margin: "0px", minWidth: "220px", fontSize: "90%" } },
    {
      dataField: "Product_Code", text: "品目", hidden: !displayItemSetting.product_Code , sort: true, headerStyle, style: { margin: "0px", minWidth: "100px", fontSize: "90%" },
      // formatter: (cellContent, row) => {
      //   let Orderitemlist = row.Orderitem.split(";");
      //   let text = "<span>";
      //   for (let i = 0; i < Orderitemlist.length; i++) {
      //     if (Orderitemlist[i] !== "") {
      //       text += Orderitemlist[i] + "<br></br>";
      //     }
      //   }
      //   text += "</span>"
      //   return (
      //     <div dangerouslySetInnerHTML={
      //       { __html: text }}>
      //     </div>
      //   );
      // },
    },
    { dataField: "Product_Name_j", text: "品名", hidden: !displayItemSetting.product_Name_j, sort: true, headerStyle, style: { margin: "0px", minWidth: "260px", fontSize: "90%" } },
    {
      dataField: "OrderQuantity", text: "注文数量", hidden: !displayItemSetting.orderQuantity, headerStyle, style: { margin: "0px", minWidth: "100px", fontSize: "90%" },
      // formatter: (cellContent, row) => {
      //   let Orderitemlist = row.OrderOrderQuantity.split(";");
      //   let text = "<span>";
      //   for (let i = 0; i < Orderitemlist.length; i++) {
      //     if (Orderitemlist[i] !== "") {
      //       text += Orderitemlist[i] + "<br></br>";
      //     }
      //   }
      //   text += "</span>"
      //   return (
      //     <div dangerouslySetInnerHTML={
      //       { __html: text }}>
      //     </div>
      //   );
      // },
    },
    {
      dataField: "ShippingInvoice",
      text: "出荷伝票",
      headerStyle,
      hidden:!displayItemSetting.shippingInvoice,
      style: { margin: "0px", minWidth: "100px",fontSize: "90%" },
      formatter: (cellContent) => {
        const info = String(cellContent);
        if (info.indexOf(";") > -1) {
          const infoList = info.split(";");
          return (
            <div>
              {infoList.map((item, index) => {
                const id = item + index;
                return (item ? <p key={id}>{item}</p> : "");
              })}
            </div>
          )
        }
        return (
          <span>{cellContent}</span>
        )
      }
    },
    {
      dataField: "ShipmentQuantity",
      text: "ロット", headerStyle, hidden: !displayItemSetting.shipmentQuantity,
      style: { margin: "0px", minWidth: "100px", fontSize: "90%" },
      formatter: (cellContent) => {
        const info = String(cellContent);
        if (info.indexOf(";") > -1) {
          const infoList = info.split(";");
          return (
            <div>
              {infoList.map((item, index) => {
                const id = item + index;
                return (<p key={id}>{item.split(" ")[0]}</p>);
              })}
            </div>
          )
        }
        return (
          <span>{cellContent.split(" ")[0]}</span>
        )
      }


    },
    {
      dataField: "quantity",
      text: "確定数量", headerStyle, hidden: !displayItemSetting.quantity,
      style: { margin: "0px", minWidth: "100px", fontSize: "90%" },
      formatter: (cellContent, row) => {
        const info = String(row.ShipmentQuantity);
        if (info.indexOf(";") > -1) {
          const infoList = info.split(";");
          return (
            <div>
              {infoList.map((item, index) => {
                const id = item + index;
                return (<p key={id}>{item.split(" ")[1]}</p>);
              })}
            </div>
          )
        }
        return (
          <span>{row.ShipmentQuantity.split(" ")[1]}</span>
        )
      }

    },
    {
      dataField: "DesiredDeliveryDate",
      text: "希望納期", hidden: !displayItemSetting.desiredDeliveryDate,
      headerStyle,
      style: { margin: "0px", minWidth: "100px", fontSize: "90%" },
    },
    {
      dataField: "DeliveryDate",
      text: "確定納期", hidden: !displayItemSetting.deliveryDate,
      headerStyle,
      style: { margin: "0px", minWidth: "100px", fontSize: "90%" },
      formatter: (cellContent) => {
        const info = String(cellContent);
        if (info.indexOf(";") > -1) {
          const infoList = info.split(";");
          return (
            <div>
              {infoList.map((item, index) => {
                const id = item + index;
                return (item ? <p key={id}>{moment(item).utcOffset(9).format('YYYY/MM/DD')}</p> : "");
              })}
            </div>
          )
        }
        return (
          <span>{cellContent ? moment(cellContent).utcOffset(9).format('YYYY/MM/DD') : ""}</span>
        )
      }
    },
    {
      dataField: "Salesperson",
      text: "営業担当者",
      hidden: !displayItemSetting.salesperson ,
      headerStyle,
      style: { margin: "0px", minWidth: "100px", fontSize: "90%" },
      formatter: (cellContent, row) => {
        return (
          <span>{row.Salesperson}<br />{row.SalespersonName}</span>
        )
      }
    },
    {
      dataField: "AssigneeCode",
      text: "入力担当者",
      hidden: !displayItemSetting.assigneeCode ,
      headerStyle,
      style: { margin: "0px", minWidth: "100px", fontSize: "90%" },
      formatter: (cellContent, row) => {
        return (
          <span>{row.AssigneeCode}<br />{row.User_Name}</span>
        )
      }
    },
    {
      dataField: "SuppName",
      text: "仕入先名称",
      hidden: !displayItemSetting.suppName ,
      headerStyle,
      style: { margin: "0px", minWidth: "100px", fontSize: "90%" },
      formatter: (cellContent, row) => {
        return (
          <span>{row.SuppName}</span>
        )
      }
    },
    {
      dataField: "CompanMemo",
      text: "社内メモ",
      hidden: !displayItemSetting.CompanMemo,
      headerStyle: { ...headerStyle,minWidth:"460px",maxWidth:"460px",width:"460px" },
      style: { margin: "0px",fontSize: "90%" },
      formatter: (cellContent,row) => {
        return (
      //     <div style={{display:"flex"}}>
      //     <TextField
      //     id="outlined-start-adornment"
      //     className={clsx(classes.margin, classes.textField)}
      //     variant="standard"
      //     value={cellContent}
      //     // onChange={(e)=>handleChange(e,row)}
      //   />
      //  {/* <div style={{display:"flex",flexWrap:"noWarp",justifyContent:"end",width:"100%"}}><HighlightOffIcon position="end"style={{fontSize:"14px"}}></HighlightOffIcon><DoneIcon position="end"style={{fontSize:"14px"}}></DoneIcon></div> */}
      //   {/* <div>
      //     <HighlightOffIcon position="end"style={{fontSize:"14px",display:"block"}}></HighlightOffIcon>
      //     <DoneIcon position="end"style={{fontSize:"14px",display:"block"}}></DoneIcon>
      //     </div> */}
      //   </div>

      <a href="#" style={{cursor:"pointer",color:"#3f51b5",height:10}} onClick={() => handletrue(row.OrderDetailsNumber, row.CompanMemo,row.id)}>{row.CompanMemo ? row.CompanMemo : "編集"}</a>
        )
      }
    },
    // {
    //   dataField: "ShipmentQuantity",
    //   text: "出荷数量",
    //   headerStyle,
    //   style: { margin: "0px", width: "4%" }
    // },
    // { dataField: "OrderNumber", text: "発注番号", sort: true, headerStyle },
    // {
    //   dataField: "InvoicePayTotal",
    //   text: "発注金額",
    //   align: "right",
    //   sort: true,
    //   headerStyle,
    // },
    // { dataField: "CompanyName", text: "納入先", sort: true, headerStyle },
    // { dataField: "ChouDate", text: "注文日", sort: true, headerStyle },

    // {
    //   dataField: "EstimatedShippingDate",
    //   text: "出荷予定日",
    //   sort: true,
    //   headerStyle,
    // },
    // {
    //   dataField: "DeliveryYtDate",
    //   text: "納品予定日",
    //   sort: true,
    //   headerStyle,
    // },
  ];
  //電子材料部検索
  const [searchProgress,setSearchProgress] = useState(false)
  const searchBtn = async function(){
   await setSearchProgress(true)
   try{
    let arr = [];
    let obj = {};
    let DataSource = []
    let dataAll = []
    if(!searchValue.SalesGroup){
    if(searchPage === true){
      const listArOrder = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, {limit: 10000, filter: {SalesGroup: {eq: SalesGroupInfo}}}));
      if (listArOrder.data.listAllArAgencyOrderInfo !== null) {
        let nextToken = listArOrder.data.listAllArAgencyOrderInfo.nextToken;
        dataAll = listArOrder.data.listAllArAgencyOrderInfo.items;
        while (nextToken !== null) {
          let InvDataSec = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, { limit: 10000, filter: {SalesGroup: {eq: SalesGroupInfo}},nextToken: nextToken}));
          nextToken = InvDataSec.data.listAllArAgencyOrderInfo.nextToken;
          for (let i = 0; i < InvDataSec.data.listAllArAgencyOrderInfo.items.length; i++) {
            dataAll.push(InvDataSec.data.listAllArAgencyOrderInfo.items[i]);
          }
        }
      }
    }else{
      const listArOrder = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, {limit: 10000, filter: {and:[{OrderStatus: {gt: "2" }},{UserAuth: {eq: "1" }}]}}));
      if (listArOrder.data.listAllArAgencyOrderInfo !== null) {
        let nextToken = listArOrder.data.listAllArAgencyOrderInfo.nextToken;
        dataAll = listArOrder.data.listAllArAgencyOrderInfo.items;
        while (nextToken !== null) {
          let InvDataSec = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, { limit: 10000, filter: {and:[{OrderStatus: {gt: "2" }},{UserAuth: {eq: "1" }}]},nextToken: nextToken }));
          nextToken = InvDataSec.data.listAllArAgencyOrderInfo.nextToken;
          for (let i = 0; i < InvDataSec.data.listAllArAgencyOrderInfo.items.length; i++) {
            dataAll.push(InvDataSec.data.listAllArAgencyOrderInfo.items[i]);
          }
        }
      }
    }}else{
      const listArOrder = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, {limit: 10000, filter: {or:[{UserAuth:{ne:"1"}},{and:[{OrderStatus: {gt: "2" }},{UserAuth: {eq: "1" }}]}]}}));
      if (listArOrder.data.listAllArAgencyOrderInfo !== null) {
        let nextToken = listArOrder.data.listAllArAgencyOrderInfo.nextToken;
        dataAll = listArOrder.data.listAllArAgencyOrderInfo.items;
        while (nextToken !== null) {
          let InvDataSec = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, { limit: 10000, filter: {or:[{UserAuth:{ne:"1"}},{and:[{OrderStatus: {gt: "2" }},{UserAuth: {eq: "1" }}]}]}, nextToken: nextToken }));
          nextToken = InvDataSec.data.listAllArAgencyOrderInfo.nextToken;
          for (let i = 0; i < InvDataSec.data.listAllArAgencyOrderInfo.items.length; i++) {
            dataAll.push(InvDataSec.data.listAllArAgencyOrderInfo.items[i]);
          }
        }
      }

    }
    for (let j = 0; j < dataAll.length; j++) {
      if (dataAll[j].RegistrationDate !== null && dataAll[j].RegistrationDate !== '') {
        dataAll[j].RegistrationDate = moment(dataAll[j].RegistrationDate).utcOffset(9).format('YYYY/MM/DD HH:mm');
      }
      if (dataAll[j].OrderDate !== null && dataAll[j].OrderDate !== '') {
        dataAll[j].OrderDate = moment(dataAll[j].OrderDate).utcOffset(0).format('YYYY/MM/DD');
      }
      if (dataAll[j].DesiredDeliveryDate !== null && dataAll[j].DesiredDeliveryDate !== '') {
        dataAll[j].DesiredDeliveryDate = moment(dataAll[j].DesiredDeliveryDate).format('YYYY/MM/DD');
      }
      if (!dataAll[j].ShipmentQuantity) {
        dataAll[j].ShipmentQuantity = "";
      }
    }
      DataSource = dataAll;
    for(let item in searchValue){
      if(item === "OrderStatus2" || item === "OrderStatus3" || item === "OrderStatus5" || item === "OrderStatus4" || item === "OrderStatus7" || item === "OrderStatus8" || item === "OrderStatus9" || item === "ShipmentStatusA" || item === "ShipmentStatusB" || item === "ShipmentStatusC" || item === "ShipmentStatusN"){
        if(searchValue[item] === true){
          if(item === "OrderStatus2"){
            obj[item] = ["2","1"]
          }
          if(item === "OrderStatus3"){
            obj[item] = "3"
          }
          if(item === "OrderStatus5"){
            obj[item] = "5"
          }
          if(item === "OrderStatus7"){
            obj[item] = "7"
          }
          if(item === "OrderStatus8"){
            obj[item] = "8"
          }
          if(item === "OrderStatus9"){
            obj[item] = "9"
          }
          if(item === "OrderStatus4"){
            obj[item] = "4"
          }
          if(item === "ShipmentStatusA"){
            obj[item] = "A"
          }
          if(item === "ShipmentStatusB"){
            obj[item] = "B"
          }
          if(item === "ShipmentStatusC"){
            obj[item] = "C"
          }
          if(item === "ShipmentStatusN"){
            obj[item] = "N"
          }
        }
      }else{
        if(searchValue[item] !== ""){
          obj[item] = searchValue[item]
        }
      }
    }
      DataSource.forEach(item => {
          let flag = true
          if(Object.keys(obj).length !== 0){
          for(let name in obj){
            if(name !== "OrderStatus2" && name !== "OrderStatus3" && name !== "OrderStatus5" && name !== "OrderStatus7" && name !== "OrderStatus8" && name !== "OrderStatus9" && name !== "OrderStatus4" && name !== "ShipmentStatusA" && name !== "ShipmentStatusB" && name !== "ShipmentStatusC" && name !== "ShipmentStatusN"){
              if(item[name]){
                if(!(item[name]).includes(obj[name])){
                   flag = false;
                   break;
               }
              }else{
                flag = false
                break;
              }
            }else{
              if(name !== "ShipmentStatusA" && name !== "ShipmentStatusB" && name !== "ShipmentStatusC"){
                if(item["OrderStatus"] !== (obj["OrderStatus2"] && obj["OrderStatus2"][0]) && item["OrderStatus"] !== (obj["OrderStatus2"] && obj["OrderStatus2"][1]) && item["OrderStatus"] !== obj["OrderStatus3"] && item["OrderStatus"] !== obj["OrderStatus5"] && item["OrderStatus"] !== obj["OrderStatus7"] && item["OrderStatus"] !== obj["OrderStatus8"] && item["OrderStatus"] !== obj["OrderStatus9"] && item["OrderStatus"] !== obj["OrderStatus4"] && item["ShipmentStatus"] !== obj["ShipmentStatusN"]){
                  flag = false
                  break;
                }
                if(item["OrderStatus"] === obj["OrderStatus3"] || item["OrderStatus"] === obj["OrderStatus4"] || item["OrderStatus"] === obj["OrderStatus5"]){
                if(item["ShipmentStatus"] === "N" && item["ShipmentStatus"] !== obj["ShipmentStatusN"]){
                  flag = false
                  break;
                }
                }
              }else{
                if(item["ShipmentStatus"] !== obj["ShipmentStatusA"] && item["ShipmentStatus"] !== obj["ShipmentStatusB"] && item["ShipmentStatus"] !== obj["ShipmentStatusC"]){
                  flag = false
                  break;
                }
              }
          }
        }}
        if(RegistrationDateStart && !RegistrationDateEnd){
          if(!(moment(item.RegistrationDate).utcOffset(0).format('YYYY/MM/DD') >= moment(RegistrationDateStart).utcOffset(0).format('YYYY/MM/DD'))){
            flag = false;
            }
      }else if(!RegistrationDateStart && RegistrationDateEnd){
          if(!(moment(item.RegistrationDate).utcOffset(0).format('YYYY/MM/DD') <= moment(RegistrationDateEnd).utcOffset(0).format('YYYY/MM/DD'))){
          flag = false;
          }
      }else if(RegistrationDateStart && RegistrationDateEnd){
        if(!((moment(item.RegistrationDate).utcOffset(0).format('YYYY/MM/DD') >= moment(RegistrationDateStart).utcOffset(0).format('YYYY/MM/DD')) && (moment(item.RegistrationDate).utcOffset(0).format('YYYY/MM/DD') <= moment(RegistrationDateEnd).utcOffset(0).format('YYYY/MM/DD')))){
          flag = false
        }
      }
      if(DesiredDeliveryDateStart && !DesiredDeliveryDateEnd){
        if(!(item.DesiredDeliveryDate >= moment(DesiredDeliveryDateStart).format('YYYY/MM/DD'))){
          flag = false
          }
      }else if(!DesiredDeliveryDateStart && DesiredDeliveryDateEnd){
        if(!(item.DesiredDeliveryDate <= moment(DesiredDeliveryDateEnd).format('YYYY/MM/DD'))){
        flag = false
          }
      }else if(DesiredDeliveryDateStart && DesiredDeliveryDateEnd){
        if(!((item.DesiredDeliveryDate >= moment(DesiredDeliveryDateStart).format('YYYY/MM/DD')) && (item.DesiredDeliveryDate <= moment(DesiredDeliveryDateEnd).format('YYYY/MM/DD')))){
        flag = false
          }
      }
          if(flag === true){
            arr.push(item)
          }
    })
 if(Object.keys(obj).length === 0 && !RegistrationDateStart && !RegistrationDateEnd && !DesiredDeliveryDateStart && !DesiredDeliveryDateEnd){
    arr = DataSource
  }
  arr = sortOrderDetailsNumber(arr)
  setOrderinfo(arr)
}catch(error){
    if(error.message === 'No current user'){
      // ユーザープールに障害が発生した場合
      console.log('User pool is invalid.Please reauthenticate.');
      // ログイン画面に戻る
      signOut();
      }else {
      // 他のエラーを処理する
      console.log('An error occurred:',error);
      }
   }
  setSearchProgress(false)
  }
  const searchClear = async ()=>{
    await setSearchProgress(true)
    try{
    let dataAll = []
    if(searchPage === true){
      const listArOrder = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, {limit: 10000, filter: {SalesGroup: {eq: SalesGroupInfo}}}));
      if (listArOrder.data.listAllArAgencyOrderInfo !== null) {
        let nextToken = listArOrder.data.listAllArAgencyOrderInfo.nextToken;
        dataAll = listArOrder.data.listAllArAgencyOrderInfo.items;
        while (nextToken !== null) {
          let InvDataSec = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, { limit: 10000, filter: {SalesGroup: {eq: SalesGroupInfo}},nextToken: nextToken}));
          nextToken = InvDataSec.data.listAllArAgencyOrderInfo.nextToken;
          for (let i = 0; i < InvDataSec.data.listAllArAgencyOrderInfo.items.length; i++) {
            dataAll.push(InvDataSec.data.listAllArAgencyOrderInfo.items[i]);
          }
        }
      }
    }else{
      const listArOrder = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, {limit: 10000, filter: {and:[{OrderStatus: {gt: "2" }},{UserAuth: {eq: "1" }}]}}));
      if (listArOrder.data.listAllArAgencyOrderInfo !== null) {
        let nextToken = listArOrder.data.listAllArAgencyOrderInfo.nextToken;
        dataAll = listArOrder.data.listAllArAgencyOrderInfo.items;
        while (nextToken !== null) {
          let InvDataSec = await API.graphql(graphqlOperation(listAllArAgencyOrderInfo, { limit: 10000, filter: {and:[{OrderStatus: {gt: "2" }},{UserAuth: {eq: "1" }}]},nextToken: nextToken}));
          nextToken = InvDataSec.data.listAllArAgencyOrderInfo.nextToken;
          for (let i = 0; i < InvDataSec.data.listAllArAgencyOrderInfo.items.length; i++) {
            dataAll.push(InvDataSec.data.listAllArAgencyOrderInfo.items[i]);
          }
        }
      }
    }
    for (let j = 0; j < dataAll.length; j++) {
      if (dataAll[j].RegistrationDate !== null && dataAll[j].RegistrationDate !== '') {
        dataAll[j].RegistrationDate = moment(dataAll[j].RegistrationDate).utcOffset(9).format('YYYY/MM/DD HH:mm');
      }
      if (dataAll[j].OrderDate !== null && dataAll[j].OrderDate !== '') {
        dataAll[j].OrderDate = moment(dataAll[j].OrderDate).utcOffset(0).format('YYYY/MM/DD');
      }
      if (dataAll[j].DesiredDeliveryDate !== null && dataAll[j].DesiredDeliveryDate !== '') {
        dataAll[j].DesiredDeliveryDate = moment(dataAll[j].DesiredDeliveryDate).format('YYYY/MM/DD');
      }
      if (!dataAll[j].ShipmentQuantity) {
        dataAll[j].ShipmentQuantity = "";
      }
    }
  dataAll = sortOrderDetailsNumber(dataAll)
  setOrderinfo(dataAll)
  setRegistrationDateEnd(null)
  setRegistrationDateStart(null)
  setDesiredDeliveryDateEnd(null)
  setDesiredDeliveryDateStart(null)
  setSearchValue({
    OrderDetailsNumber: "",
    ContractorNm: "",
    ShippingDestinationNm: "",
    EndUserNm: "",
    Product_Code: "",
    Salesperson: "",
    AssigneeCode:"",
    SalesGroup: "",
    OrderStatus2: false,
    OrderStatus3: false,
    OrderStatus5: false,
    OrderStatus7: false,
    OrderStatus8: false,
    OrderStatus9: false,
    OrderStatus4:false,
    ShipmentStatusA:false,
    ShipmentStatusB:false,
    ShipmentStatusC:false,
    ShipmentStatusN:false
  })
}catch(error){
  if(error.message === 'No current user'){
    // ユーザープールに障害が発生した場合
    console.log('User pool is invalid.Please reauthenticate.');
    // ログイン画面に戻る
    signOut();
    }else {
    // 他のエラーを処理する
    console.log('An error occurred:',error);
    }
 }
 setSearchProgress(false)
  }
  const handleKeyCode = (e)=> {
    if (e.keyCode === 13) {
      e.preventDefault()
    }
  }
  return (
    <Container
      style={{ backgroundColor: "", minWidth: '100%', minHeight: "74vh",height: '100%', position: 'relative'}}
    >
       <div style={{ minWidth: '1000px'}}>
      <div style={{ width: '100%'}}>
        <div style={{width:'100%',display: 'flex', marginBottom: "10px" }}>
          <h4
            style={{
              textAlign: "left",
              color: "black",
              height: 30,
              width: '16%',
              borderLeft: "4px solid #804F29",
              paddingLeft: "4px",
              whiteSpace:"nowrap"
            }}
          >
             注文一覧
          </h4>
          <div style={{ display: userAuths === "1" ? 'none' : "block", width: '84%' }}>
            <div style={{ width: '100%', height: 'auto', display: 'flex' }}>
              <div style={{ width: '80%', minWidth: "820px", height: 'auto', border: '2px solid rgb(191,191,191)', display: 'flex', flexWrap: 'wrap', padding: "30px 20px 0px 20px" }}>
                <div style={{ width: "50%", height: '25px', position: "relative",marginBottom:"2px" }}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textlabel}
                      id="basic-name"
                      style={{ borderRadius: "0px" }}
                    >受注番号
                    </InputGroup.Text>
                    <FormControl
                    autocomplete="off"
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-name"
                      id="mitumoriNo"
                      maxLength="18"
                      className={classes.textField}
                      style={{
                        width: "50px",
                        height: "25px",
                        borderRadius: "4px",
                      }}
                      name="OrderDetailsNumber"
                      value={searchValue.OrderDetailsNumber}
                      onChange={(e) => { searchValueChange(e) }}
                    />
                    <p onClick={() => { setSearchValue({ ...searchValue, OrderDetailsNumber: "" }) }}
                      style={{ display: searchValue.OrderDetailsNumber ? 'block' : "none", position: "absolute", right: "3%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 20, backgroundColor: 'gray', color: 'white' }}>X
                    </p>
                  </InputGroup>
                </div>
                <div style={{ width: "49%", height: '25px', position: 'relative',marginBottom:"2px" }}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textlabel}
                      style={{ borderRadius: "0px" }}
                      id="basic-name"
                    >品目コード
                    </InputGroup.Text>
                    <FormControl
                    autocomplete="off"
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-name"
                      id="mitumoriNo"
                      maxLength="20"
                      className={classes.textField}
                      style={{
                        width: "50%",
                        height: "25px", borderRadius: "4px",
                      }}
                      name="Product_Code"
                      value={searchValue.Product_Code}
                      onChange={(e) => { searchValueChange(e) }}
                    />
                    <p onClick={() => { setSearchValue({ ...searchValue, Product_Code: "" }) }}
                      style={{ display: searchValue.Product_Code ? 'block' : "none", position: "absolute", right: "3%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 20, backgroundColor: 'gray', color: 'white' }}>X
                    </p>
                  </InputGroup>
                </div>
                <div style={{ width: "50%", height: '25px', position: "relative",marginBottom:"2px" }}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textlabel}
                      id="basic-name"
                      style={{ borderRadius: "0px" }}
                    >発注先
                    </InputGroup.Text>
                    <FormControl
                    autocomplete="off"
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-name"
                      id="mitumoriNo"
                      maxLength="10"
                      className={classes.textField}
                      style={{
                        width: "50px",
                        height: "25px", borderRadius: "4px",
                      }}
                      name="ContractorNm"
                      value={searchValue.ContractorNm}
                      onChange={(e) => { searchValueChange(e) }}
                    />
                    <p onClick={() => { setSearchValue({ ...searchValue, ContractorNm: "" }) }}
                      style={{ display: searchValue.ContractorNm ? 'block' : "none", position: "absolute", right: "3%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 20, backgroundColor: 'gray', color: 'white' }}>X
                    </p>
                  </InputGroup>
                </div>
                <div style={{ width: "50%", height: '25px', display: 'flex', position: "relative",marginBottom:"2px" }}>
                  <InputGroup.Text
                    className={classes.textlabel}
                    style={{ borderRadius: "0px" }}
                    id="basic-name"
                  >希望納期
                  </InputGroup.Text>
                  <div style={{ width: "72%", display: "flex" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale} >
                      <DatePicker
                        className={classes.datePicker}
                        disableToolbar
                        variant="normal"
                        cancelLabel="キャンセル"
                        value={DesiredDeliveryDateStart}
                        onChange={setDesiredDeliveryDateStart}
                        format="yyyy/MM/dd"
                        label=""
                        InputProps={{ disableUnderline: true,style : {padding:0,margin:0,fontSize:"15px"}}}
                      />
                      <p onClick={() => { setDesiredDeliveryDateStart(null) }}
                        style={{ visibility: DesiredDeliveryDateStart ? 'visible' : "hidden", position: "relative", right: "7%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 20, backgroundColor: 'gray', color: 'white' }}>X
                      </p>
                    </MuiPickersUtilsProvider>
                    <div style={{marginRight:"10px"}}>~</div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                      <DatePicker
                        className={classes.datePicker}
                        disableToolbar
                        variant="normal"
                        cancelLabel="キャンセル"
                        value={DesiredDeliveryDateEnd}
                        onChange={setDesiredDeliveryDateEnd}
                        format="yyyy/MM/dd"
                        label=""
                        InputProps={{ disableUnderline: true,style : {padding:0,margin:0,fontSize:"15px"} }}
                      />
                      <p onClick={() => { setDesiredDeliveryDateEnd(null) }}
                        style={{ visibility: DesiredDeliveryDateEnd ? 'visible' : "hidden", position: "relative", right: "7%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 20, backgroundColor: 'gray', color: 'white' }}>X
                      </p>
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div style={{ width: "50%", height: '25px', position: "relative",marginBottom:"2px" }}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textlabel}
                      id="basic-name"
                      style={{ borderRadius: "0px" }}
                    >出荷先
                    </InputGroup.Text>
                    <FormControl
                    autocomplete="off"
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-name"
                      id="mitumoriNo"
                      maxLength="20"
                      className={classes.textField}
                      style={{
                        width: "50px",
                        height: "25px", borderRadius: "4px",
                      }}
                      name="ShippingDestinationNm"
                      value={searchValue.ShippingDestinationNm}
                      onChange={(e) => { searchValueChange(e) }}
                    />
                    <p onClick={() => { setSearchValue({ ...searchValue, ShippingDestinationNm: "" }) }}
                      style={{ display: searchValue.ShippingDestinationNm ? 'block' : "none", position: "absolute", right: "3%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 20, backgroundColor: 'gray', color: 'white' }}>X
                    </p>
                  </InputGroup>
                </div>
                <div style={{ width: "50%", height: '25px', display: "flex", position: "relative",marginBottom:"2px" }}>
                  <InputGroup.Text
                    className={classes.textlabel}
                    id="basic-name"
                    style={{ borderRadius: "0px" }}
                  >注文日
                  </InputGroup.Text>
                  <div style={{ width: "72%", display: "flex" }}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                      <DatePicker
                      className={classes.datePicker}
                      disableToolbar
                      variant="normal"
                      cancelLabel="キャンセル"
                      value={RegistrationDateStart}
                      onChange={setRegistrationDateStart}
                      format="yyyy/MM/dd"
                      label=""
                      InputProps={{ disableUnderline: true,style : {padding:0,margin:0,fontSize:"15px"} }}
                      />
                      <p onClick={() => { setRegistrationDateStart(null) }}
                        style={{ visibility: RegistrationDateStart ? 'visible' : "hidden", position: "relative", right: "7%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 50, backgroundColor: 'gray', color: 'white'}}>X
                      </p>
                    </MuiPickersUtilsProvider>
                    <div style={{marginRight:"10px"}}>~</div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                      <DatePicker
                        className={classes.datePicker}
                        disableToolbar
                        variant="normal"
                        cancelLabel="キャンセル"
                        value={RegistrationDateEnd}
                        onChange={setRegistrationDateEnd}
                        format="yyyy/MM/dd"
                        label=""
                        InputProps={{ disableUnderline: true,style : {padding:0,margin:0,fontSize:"15px"} }}
                      />
                      <p onClick={() => { setRegistrationDateEnd(null) }}
                        style={{ visibility: RegistrationDateEnd ? 'visible' : "hidden", position: "relative", right: "7%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 50, backgroundColor: 'gray', color: 'white' }}>X
                      </p>
                    </MuiPickersUtilsProvider>
                  </div>
                </div>
                <div style={{ width: "50%", height: '25px', position: "relative" ,marginBottom:"2px"}}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textlabel}
                      id="basic-name"
                      style={{ borderRadius: "0px" }}
                    >エンドユーザー
                    </InputGroup.Text>
                    <FormControl
                    autocomplete="off"
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-name"
                      id="mitumoriNo"
                      className={classes.textField}
                      style={{
                        width: "50px",
                        height: "25px", borderRadius: "4px",
                      }}
                      name="EndUserNm"
                      value={searchValue.EndUserNm}
                      onChange={(e) => { searchValueChange(e) }}
                    />
                    <p onClick={() => { setSearchValue({ ...searchValue, EndUserNm: "" }) }}
                      style={{ display: searchValue.EndUserNm ? 'block' : "none", position: "absolute", right: "3%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 20, backgroundColor: 'gray', color: 'white' }}>X
                    </p>
                  </InputGroup>
                </div>
                <div style={{ width: "49%", height: '25px', position: "relative",marginBottom:"2px" }}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textlabel}
                      id="basic-name"
                      style={{ borderRadius: "0px" }}
                    >営業担当者
                    </InputGroup.Text>
                    <FormControl
                      autocomplete="off"
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-name"
                      id="basic-name"
                      className={classes.textField}
                      style={{
                        width: "50px",
                        height: "25px", borderRadius: "4px",
                      }}
                      name="Salesperson"
                      value={searchValue.Salesperson}
                      onChange={(e) => { searchValueChange(e) }}
                    />
                    <p onClick={() => { setSearchValue({ ...searchValue, Salesperson: "" }) }}
                      style={{ display: searchValue.Salesperson ? 'block' : "none", position: "absolute", right: "3%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 20, backgroundColor: 'gray', color: 'white' }}>X
                    </p>
                  </InputGroup>
                </div>
                <div style={{ width: "50%", height: '25px', position: "relative" ,marginBottom:"2px"}}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textlabel}
                      id="basic-name"
                      style={{ borderRadius: "0px" }}
                    >営業Grp
                    </InputGroup.Text>
                    <FormControl
                    autocomplete="off"
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-name"
                      id="basic-name"
                      className={classes.textField}
                      style={{
                        width: "50px",
                        height: "25px", borderRadius: "4px",
                      }}
                      name="SalesGroup"
                      value={searchValue.SalesGroup}
                      onChange={(e) => { searchValueChange(e) }}
                    />
                    <p onClick={() => { setSearchValue({ ...searchValue, SalesGroup: "" }) }}
                      style={{ display: searchValue.SalesGroup ? 'block' : "none", position: "absolute", right: "3%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 20, backgroundColor: 'gray', color: 'white' }}>X
                    </p>
                  </InputGroup>
                </div>
                <div style={{ width: "49%", height: '25px', position: "relative" ,marginBottom:"2px"}}>
                  <InputGroup className="mb-3">
                    <InputGroup.Text
                      className={classes.textlabel}
                      id="basic-name"
                      style={{ borderRadius: "0px" }}
                    >入力担当者
                    </InputGroup.Text>
                    <FormControl
                      autocomplete="off"
                      placeholder=""
                      aria-label=""
                      aria-describedby="basic-name"
                      id="basic-name"
                      className={classes.textField}
                      style={{
                        width: "50px",
                        height: "25px", borderRadius: "4px",
                      }}
                      name="AssigneeCode"
                      value={searchValue.AssigneeCode}
                      onChange={(e) => { searchValueChange(e) }}
                    />
                    <p onClick={() => { setSearchValue({ ...searchValue, AssigneeCode: "" }) }}
                      style={{ display: searchValue.AssigneeCode ? 'block' : "none", position: "absolute", right: "3%", top: "20%", width: 15, height: 15, textAlign: "center", lineHeight: "15px", fontSize: "12px", cursor: "pointer", borderRadius: 20, backgroundColor: 'gray', color: 'white' }}>X
                    </p>
                  </InputGroup>
                </div>
                <div style={{ width: "100%", height: 'auto', display: 'flex',flexWrap:"nowrap",marginBottom:"2px" }}>
                  <InputGroup.Text
                    className={classes.textlabel}
                    id="basic-name" style={{ borderRadius: "0px", marginRight: '16px' }}
                  >ステータス
                  </InputGroup.Text>
                  <div style={{ width: "85%", height: 'auto', display: 'flex',flexWrap:"wrap" }}>
                  <span className={classes.inputCheckbox}><input type="checkbox" checked = {searchValue.OrderStatus8} name="OrderStatus8" onChange={(e) => searchValueChange(e)} className={classes.inputSize} />営業確認</span>
                  <span className={classes.inputCheckbox}><input type="checkbox" checked = {searchValue.OrderStatus9} name="OrderStatus9" onChange={(e) => searchValueChange(e)} className={classes.inputSize} />見積受付</span>
                  <span className={classes.inputCheckbox}><input type="checkbox" checked = {searchValue.OrderStatus2} name="OrderStatus2" onChange={(e) => searchValueChange(e)} className={classes.inputSize} />一時保存</span>
                  <span className={classes.inputCheckbox}><input type="checkbox" checked = {searchValue.OrderStatus3} name="OrderStatus3" onChange={(e) => searchValueChange(e)} className={classes.inputSize} />受注受付</span>
                  <span className={classes.inputCheckbox}><input type="checkbox" checked = {searchValue.OrderStatus4} name="OrderStatus4" onChange={(e) => searchValueChange(e)} className={classes.inputSize} />受付処理中</span>
                  <span className={classes.inputCheckbox}><input type="checkbox" checked = {searchValue.OrderStatus5} name="OrderStatus5" onChange={(e) => searchValueChange(e)} className={classes.inputSize} />納期確定</span>
                  <span className={classes.inputCheckbox}><input type="checkbox" checked = {searchValue.ShipmentStatusN} name="ShipmentStatusN" onChange={(e) => searchValueChange(e)} className={classes.inputSize} />キャンセル</span>
                </div>
                </div>
                <div style={{ width: "100%", height: 'auto', display: 'flex',flexWrap:"nowrap" ,marginBottom:"2px"}}>
                  <InputGroup.Text
                    className={classes.textlabel}
                    id="basic-name" style={{ borderRadius: "0px", marginRight: '16px' }}
                  >出荷ステータス
                  </InputGroup.Text>
                  <div style={{ width: "85%", height: 'auto', display: 'flex',flexWrap:"wrap" }}>
                  <span className={classes.inputCheckbox}><input type="checkbox" checked = {searchValue.ShipmentStatusA} name="ShipmentStatusA" onChange={(e) => searchValueChange(e)} className={classes.inputSize} />未出荷</span>
                  <span className={classes.inputCheckbox}><input type="checkbox" checked = {searchValue.ShipmentStatusB} name="ShipmentStatusB" onChange={(e) => searchValueChange(e)} className={classes.inputSize} />一部出荷</span>
                  <span className={classes.inputCheckbox}><input type="checkbox" checked = {searchValue.ShipmentStatusC} name="ShipmentStatusC" onChange={(e) => searchValueChange(e)} className={classes.inputSize} />全出荷</span>
                 </div>
                 </div>
                <div style={{margin:"auto"}}>
                <Button className="btn" onClick={() => searchBtn()} style={{ height: 40, width: 120, fontSize: '18px', color: 'black', border: '3px solid black', background: "white",marginRight:"10px"}}>検索</Button>
                <Button className="btn" onClick={() => searchClear()} style={{ height: 40, width: 120, fontSize: '18px', color: 'black', border: '3px solid black', background: "white"}}>Clear</Button>
                </div></div>
              <div style={{ width: '20%', height: 240, overflow: 'auto', minWidth: '200px', marginLeft: '10px' }}>
                <p style={{ height: 180 }}></p>
                <Button className="btn" onClick={() => handleClickOpen()} style={{ height: 40, width: 180, fontSize: '18px', border: '3px solid black', color: 'black', background: "white", float: 'right', marginRight: '10px' }}>表示項目設定
                </Button>
              </div>
            </div>
          </div>
        </div>
          <ToolkitProvider
            keyField="id"
            data={orderinfo}
            columns={columns}
            // search
            columnToggle
            headerWrapperClasses="foo"
            wrapperClasses="boo"
            search={SearChQuoteNumber}
          >
            {(props) => (
              <div>
                <div style={{ width:'100%', height: '100%', display: userAuths !== "1" ? 'none' : "block"}}>
                  <SearchBar
                    className="form-control"
                    placeholder=" "
                    style={{
                      fontSize: "14px",
                      height: "auto",
                      padding: "8px 16px",
                      lineHeight: "1.3333333",
                      borderRadius: "2px",
                    }}
                    {...props.searchProps}
                  />
                  <ClearSearchButton
                    className="btn btn-dark btn-md "
                    {...props.searchProps}
                  /></div>
                   <div style={{ width:'100%',height: "100%", overflowX: "scroll",transform:"scaleY(-1)"}}>
                  <div style={{width: scrollTableWidth,height: "60vh",overflowY: "scroll",overflowX: "hidden",transform:"scaleY(-1)"}}>
                  <div style={{direction:"ltr"}}>
                  <BootstrapTable
                  bootstrap4
                  striped={true}
                  id="table"
                  hover={true}
                  headerStyle
                  keyField="id"
                  condensed
                  data={orderinfo}
                  columns={columns}
                  {...props.baseProps}
                  pagination={paginationFactory(options)}
                  noDataIndication={"no results found"}
                  defaultSorted={defaultSorted}
                  headerClasses={classes.fixedTop}
                />
                </div>
                </div>
              </div>
              </div>
            )}
          </ToolkitProvider>
      </div>
      <Dialog open={open}
        onClose={() => { setOpen(false) }}
      >
        <DialogContent>
          選択した文書を削除します。よろしいですか？
        </DialogContent>
        <DialogActions>
        <Button variant="contained" style={{backgroundColor:"LightCoral",color:"white"}} onClick={() => {
            handleDelClick(DelInfo);
          }}>削除</Button>
          <Button color="primary" onClick={() => {
            setOpen(false)
          }} >キャンセル</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open2}
        onClose={handleCloseNG}
        disableEscapeKeyDown={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <span style={{ color: 'black', letterSpacing: '3px', fontSize: "24px", display: "inline-block", marginLeft: '12%' }}>表示項目設定</span></DialogTitle>
        <DialogContent>
        <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly"}}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px",background:"white",color:"black"}}
              id="basic-name"
            >全項目チェック
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="selectAll" name="selectAll" onClick={()=>DisplayProjectsSettingsSelectAll()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >注文日時
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="registrationDate" name="registrationDate" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >受注番号
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="orderDetailsNumber" name="orderDetailsNumber" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >ステータス
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="orderStatus" name="orderStatus" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >出荷ステータス
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="ShipmentStatus" name="ShipmentStatus" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >登録番号
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="quoteNumber" name="quoteNumber" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >注文番号
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="orderNumber" name="orderNumber" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >発注先
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="contractorNm" name="contractorNm" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >出荷先
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="shippingDestinationNm" name="shippingDestinationNm" onClick={()=>checkboxHandleChange()}/>
          </div>  <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >エンドユーザー
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="endUserNm" name="endUserNm" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >品目
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="product_Code" name="product_Code" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >品名
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="product_Name_j" name="product_Name_j" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >注文数量
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="orderQuantity" name="orderQuantity" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >出荷伝票
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="shippingInvoice" name="shippingInvoice" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >ロット
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="shipmentQuantity" name="shipmentQuantity" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >確定数量
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="quantity" name="quantity" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >希望納期
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="desiredDeliveryDate" name="desiredDeliveryDate" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >確定納期
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="deliveryDate" name="deliveryDate" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display:"flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >営業担当者
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="salesperson" name="salesperson" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display:"flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >入力担当者
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="assigneeCode" name="assigneeCode" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display:"flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >仕入先名称
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="suppName" name="suppName" onClick={()=>checkboxHandleChange()}/>
          </div>
          <div style={{ width: "300px", height: '40px', display:"flex", justifyContent: 'space-evenly' }}>
            <InputGroup.Text
              className={classes.textlabelDialog}
              style={{ height: '30px', borderRadius: "0px" }}
              id="basic-name"
            >社内メモ
            </InputGroup.Text>
            <input type="checkbox" className={classes.inputSize} id="CompanMemo" name="CompanMemo" onClick={()=>checkboxHandleChange()}/>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ width: "70%", display: 'flex', justifyContent: "start" }}>
            <Button className="btn" onClick={handleOK} disabled={settingButton} style={{ height: 40, fontSize: '18px', width: '80px', color: 'black', border: '3px solid black', background: "white", margin: 'auto' }}>設定</Button>
            <Button className="btn" onClick={handleCloseOK} style={{ marginLeft: '1px', height: 40, width: "120px", fontSize: '18px', color: 'black', border: '3px solid black', background: "white", margin: 'auto' }}>キャンセル</Button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
    open={memoInfo.show}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    style={{zIndex:500}}
  >
<DialogContent>
  <p style={{ fontSize: "18px", marginBottom: "16px" }}>社内メモ編集</p>
  <InputGroup className="mb-3">
    {/* <InputGroup.Text id="basic-name">社内メモ</InputGroup.Text> */}
    <TextareaAutosize
      className={classes.textArea}
      aria-label="minimum height"
      minRows={7}
      maxRows={7}
      placeholder="200文字以内で入力してください"
      maxLength={200}
      style={{ width: "100%", margin: 'auto' }}
      value={memoInfo.memo}
      color="primary"
      onChange={(e)=>handleDialogmemo(e)}
      onKeyDown={(e)=>handleKeyCode(e)}
    />
  </InputGroup>

  <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "16px" }}>
    <div style={{ marginLeft: "40px",marginRight: "40px", fontSize: "20px" }}>
      {memoInfo.DiaQuoteNumber}
    </div>
    <div style={{ display: "flex", gap: "8px" }}>
      <Button
        variant="contained"
        color="primary"
        style={{
          minWidth: "104px",
          fontSize: "14px",
          padding: "5px",
          height: "36.5px",
          color: "white",
        }}
        onClick={handleDialogmemoOk}
      >
        OK
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{
          minWidth: "104px",
          fontSize: "14px",
          padding: "5px",
          height: "36.5px",
          color: "white",
        }}
        onClick={handleCancel}
      >
        キャンセル
      </Button>
    </div>
  </div>
</DialogContent>

  </Dialog>
      <div style={{position: 'fixed',top: '50%', left: '50%',display: searchProgress === true ? "block" : "none" }}>
      <CircularProgress />
      </div>
      </div>
    </Container>
  );
};

export default OrderList;
