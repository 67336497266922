/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  Orderlist.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2022/03/30 Rayoo)li : 新規作成
 *
 * Summary Orderlist画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
 import React, { useEffect, useState } from "react";
 import BootstrapTable from "react-bootstrap-table-next";
 import { Container, InputGroup, FormControl } from "react-bootstrap";
 import Button from "@material-ui/core/Button";
 import useStyles from "./ArItemMasterStyle";
 import { defaultSorted, options, openValueInit, selectRow } from "./ArItemMasterConst"
 import { Columns, checkedMasterlist, searchData, listOrderData, openDilogDetill } from "./ArItemMasterMethod";
 import { getlistArItemMasters } from "../ArPriceInfo/ArPriceInfoMethod"
 import MasterDilogDetill from "./ArItemMasterDialogDetill";
 import MasterDisplayItem from "./ArItemMasterDisplayItem";
 import paginationFactory from "react-bootstrap-table2-paginator";
 import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
 import API, { graphqlOperation } from "@aws-amplify/api";
 import { customerByeMail, } from "../../../graphql/queries";
 import Dialog from "@material-ui/core/Dialog";
 import DialogActions from "@material-ui/core/DialogActions";
 import DialogContent from "@material-ui/core/DialogContent";
 import DialogContentText from "@material-ui/core/DialogContentText";
 import DialogTitle from "@material-ui/core/DialogTitle";
 import CircularProgress from '@material-ui/core/CircularProgress';
 import cellEditFactory from "react-bootstrap-table2-editor";
 import TextField from '@mui/material/TextField';
 import Autocomplete from '@mui/material/Autocomplete';
 import Amplify, { Auth } from "aws-amplify";
 import {ArItemMasterConst,} from "./ArItemMasterConst";
 import Typography from "@material-ui/core/Typography";
 import MasterDialogUpload from "./ArItemMasterDialogUpload"
 const { SearchBar, ClearSearchButton } = Search;
 let orderselect_row = [];
 const ArItemMaster = () => {
     const classes = useStyles()
     // テーブル内のデータ
     const [arItemMasterInfo, setarItemMasterInfo] = useState([]);
     const [userAuths, setUserAuths] = useState()
     // テーブル内のリストが非表示かどうか
     const [displayItemSetting, setDisplayItemSetting] = useState(sessionStorage.getItem("displayItemSettingMasterObj") ? JSON.parse(sessionStorage.getItem("displayItemSettingMasterObj")) : openValueInit);
     // テーブル内のリストの形式
     const columns = Columns(displayItemSetting);
     // リスト内の既定の並べ替え
     const DefaultSorted = defaultSorted;
     // 条件の既定値を取得します。
     const [searchValue, setSearchValue] = useState({
         SAPCODE: "",
         SuppName: ""
     })
     const searchValueChange1 = (e, value) => {
         setSearchValue({ ...searchValue, SAPCODE: value ? value : "" })
     }
     const searchValueChange2 = (e, value) => {
         setSearchValue({ ...searchValue, SuppName: value ? value : "" })
     }
     const [searchProgress, setSearchProgress] = useState(false)
     // Dilogはデフォルトでオンになっています
     const [SuppNameList, setSuppNameList] = useState([])
     const [SAPCODEList, setSAPCODEList] = useState([])
     const [dilogDetill, setdilogDetill] = useState(false)
     const [dialogDisplayItem, setdialogdialogDisplayItem] = useState(false)
     // 品目詳細画面シャットダウン
     const closeDilogDetill = function () {
         setdilogDetill(false)
     }
     const [msgText, setMsgText] = React.useState(Object);
     const handleSetMsgText = (Object) => {
       setMsgText(Object);
     };
     const [msgbtnOK, setMsgBtnOK] = React.useState(Object);
     const handleSetMsgBtnOK = (Object) => {
       setMsgBtnOK(Object);
     };
     const [open2, setOpen2] = React.useState(false);
     const handleClickOpen2 = () => {
       setOpen2(true);
     };
     const handleCloseNG2 = () => {
      setOpen2(false);
    };
    const handleCloseOK2 = () => {
      setOpen2(false);
    };
    const [msg1, setMsgId] = React.useState(Object);
     const handleSetMsgId = (Object) => {
       setMsgId(Object);
     };
     const [SelectAllFlag,setSelectAllFlag] = useState(true)
     const [settingButton,setSettingButton] = useState(false)
     const DisplayProjectsSettingsSelectAll = ()=>{
         let flag = !SelectAllFlag
         for (let item in openValueInit) {
           let dom = document.querySelector(`#${item}`)
           dom.checked = flag
         }
         if(!flag){
            setSettingButton(true)
          }else{
            setSettingButton(false)
          }
         setSelectAllFlag(flag)
       }
     const checkBoxChange = (val,val2)=>{
       document.getElementsByName("selectAll")[0].checked = val
       setSelectAllFlag(val)
       setSettingButton(val2)
     }
     // 表示項目設定開けて下さい
     const openDialogDisplayItem = async function () {
        let SelectAllFlagInit = true
         let checkedList = displayItemSetting
         await setdialogdialogDisplayItem(true)
         for (let item in checkedList) {
             let dom = document.querySelector(`#${item}`)
             dom.checked = checkedList[item]
             if(checkedList[item] === false){
                SelectAllFlagInit = false
              }
         }
         document.getElementsByName("selectAll")[0].checked = SelectAllFlagInit
         setSelectAllFlag(SelectAllFlagInit)
         setSettingButton(false)
     }
     // 表示項目設定シャットダウン
     const closeDialogDisplayItem = function () {
         setdialogdialogDisplayItem(false)
     }
     // 設定ボタンが押されました
     const handleOK = () => {
         const checkedlist = checkedMasterlist();
         setDisplayItemSetting(checkedlist)
         closeDialogDisplayItem()
     };

 const selectRow = {
     mode: 'radio',
     selectionHeaderRenderer: () => "選択",
     bgColor: "white",
     selected: orderselect_row,
     clickToSelect: true,
     headerColumnStyle: {
         backgroundColor: "white",
         borderBottom: "3px solid #804F29",
         fontSize: "70%"
     },
     onSelect: (row, isSelect) => {
         orderselect_row = [];
         if (isSelect) {
             orderselect_row.push(row.id);
         }
     },
 };
     // 検索ボタンが押されました
     const searchBtn = async () => {
         orderselect_row = []
         setSearchProgress(true)
         let arr = [];
         try {
             arr = await searchData(searchValue)
         } catch (error) {
             if (error.message === 'No current user') {
                 // ユーザープールに障害が発生した場合
                 console.log('User pool is invalid.Please reauthenticate.');
                 // ログイン画面に戻る
                 signOut();
             } else {
                 // 他のエラーを処理する
                 console.log('An error occurred:', error);
             }
         }
         setarItemMasterInfo(arr)
         setSearchProgress(false)
     }
     const signOut = async () => {
         await Auth.signOut();
         try {
             await Auth.signOut();
         } catch (error) {
             console.log("error signing out: ", error);
         }
         document.location.reload();
     };
     const [btnDisable, setBtnDisabled] = useState({
         btn1: false,
         btn2: false,
         btn3: false,
         btn4: false,
         btn5: false,
     })
     const [dependence,setdependence] = useState(false)
     const setDependencefun = ()=>{
         setdependence(true)
     }
     useEffect(async () => {
        setSearchProgress(true)
         let user = ""
         try {
             user = await Auth.currentUserInfo();
         } catch (error) {
             if (error.message === 'No current user') {
                 // ユーザープールに障害が発生した場合
                 console.log('User pool is invalid.Please reauthenticate.');
                 // ログイン画面に戻る
                 signOut();
             } else {
                 // 他のエラーを処理する
                 console.log('An error occurred:', error);
             }
         }
         const agencyPriceGroup = await API.graphql(
             graphqlOperation(customerByeMail, {
                 Agency_Email: user.attributes.email,
             })
         );
         const agentinfo = agencyPriceGroup.data.customerByeMail !== null ? agencyPriceGroup.data.customerByeMail["items"] : []
         if (agentinfo.length > 0) {
             if (agentinfo[0].Permission !== "1" && agentinfo[0].Permission !== "2") {
                 setBtnDisabled({
                     btn1: true,
                     btn2: true,
                     btn3: true,
                     btn4: true,
                     btn5: true,
                 })
             } else {
                 setBtnDisabled({
                     btn1: false,
                     btn2: false,
                     btn3: false,
                     btn4: false,
                     btn5: false,
                 })
             }
         }
         listOrderData(setUserAuths);
         let ItemMaster = await getlistArItemMasters()
         for (let i = 0; i < ItemMaster.length; i++) {
             if (ItemMaster[i].SuppName) {
                 SuppNameList.push(ItemMaster[i].SuppName)
             }
             if (ItemMaster[i].SAPCODE) {
                 SAPCODEList.push(ItemMaster[i].SAPCODE)
             }
         }
         setSuppNameList(Array.from(new Set(SuppNameList)))
         setSAPCODEList(Array.from(new Set(SAPCODEList)))
         setSearchProgress(false)
     }, [dependence]);
     const [openConfirm, setOpenConfirm] = React.useState(false);
     const handleClickConfirmOpen = () => {
         setOpenConfirm(true);
     };
     const handleConfirmClose = () => {
         setOpenConfirm(false);
     };
     const handleConfirmOK = async () => {
         setOpenConfirm(false);
     }
     const [errorcode, setErrorCode] = React.useState(Object);
     const handleSetErrorcode = (Object) => {
         setErrorCode(Object);
     };
     const [msg1Text, setMsg1Text] = React.useState(Object);
     const handleSetMsg1Text = (Object) => {
         setMsg1Text(Object);
     };
     const [msg2Text, setMsg2Text] = React.useState(Object);
     const handleSetMsg2Text = (Object) => {
         setMsg2Text(Object);
     };
     const openDilog = async(type, setdilogDetill, data,orderselect_row)=>{
        setSearchProgress(true)
         if (type !== ArItemMasterConst.PROC_ADD && (orderselect_row).length === 0) {
             // handleSetMsgId("");
             // handleSetMsgText("品目一覧に対象の品目を一覧表示して選択してください");
             // handleSetMsgBtnOK("はい");
             // handleClickOpen2();
             handleSetErrorcode("エラーコード：1006");
             handleSetMsg1Text("選択エラー");
             handleSetMsg2Text("品目一覧に対象の品目を一覧表示して選択してください");
             handleClickConfirmOpen();
             setSearchProgress(false)
             return
         }
         await openDilogDetill(type, setdilogDetill, data,orderselect_row)
         setSearchProgress(false)
     }
     const [dialogArItemMasterDialogUpload, setdialogArItemMasterDialogUpload] = useState(false)
     const opendialogArItemMasterDialogUpload = function () {
         setdialogArItemMasterDialogUpload(true)
     }
     const closedialogArItemMasterDialogUpload = function () {
         setdialogArItemMasterDialogUpload(false)
     }
     const MyExportCSV2 = (props) => {
        const handleClick = () => {
          props.onExport();
        };
        return (
            <Button className="btn " onClick={handleClick}　style={{cursor:btnDisable.btn1 === true ? 'no-drop' : "pointer", height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '10px', borderRadius: '8px' }}>ダウンロード</Button>
        );
      };
     return (
         <Container
             style={{ backgroundColor: "", minWidth: "100%", minHeight: "74vh", height: '100%', position: 'relative' }}
         >
        <div style={{position: 'fixed',top: '50%', left: '50%', display: searchProgress === true ? "block" : "none"}}>
            <CircularProgress style={{margin:"auto"}}/>
        </div>
             <div style={{ minWidth: "1000px", }}>
                 <div style={{ width: '100%' }}>
                     <div style={{ display: 'flex', marginBottom: "10px" }}>
                         <h4
                             style={{
                                 textAlign: "left",
                                 color: "black",
                                 height: 30,
                                 width: '16%',
                                 borderLeft: "4px solid #804F29",
                                 paddingLeft: "4px",
                             }}
                         >
                             品目一覧(Si)
                         </h4>
                         <div style={{ width: '80%' }}>
                             <div style={{ width: '100%', height: 140, display: 'flex' }}>
                                 <div style={{ width: '90%', minWidth: "90%", height: 140, border: '2px solid rgb(191,191,191)', display: 'flex', flexWrap: "wrap", padding: "30px 20px 0px 20px" }}>
                                     <div style={{ width: "50%", height: '2px' }}>
                                         <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap" }}>
                                             <InputGroup.Text
                                                 className={classes.textlabel}
                                                 id="basic-name"
                                                 style={{ borderRadius: "0px",width:"20%" }}
                                             >品目
                                             </InputGroup.Text>
                                             <Autocomplete
                                                 className={classes.autocomplete}
                                                 sx={{
                                                     display: 'inline-block',
                                                     '& input': {
                                                         width: 200,
                                                         height: 0,
                                                     },
                                                 }}
                                                 options={SAPCODEList}
                                                 style={{
                                                     width: "80%",
                                                     height: "30px",
                                                     borderRadius: "4px",
                                                 }}
                                                 value={searchValue.SAPCODE}
                                                 onChange={(e, value) => { searchValueChange1(e, value) }}
                                                 name="SAPCODE"
                                                 id="SAPCODE"
                                                 maxLength="20"
                                                 renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                             />
                                         </InputGroup>
                                     </div>
                                     <div style={{ width: "50%", height: '25px' }}>
                                         <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap", }}>
                                             <InputGroup.Text
                                                 className={classes.textlabel}
                                                 id="basic-name"
                                                 style={{ borderRadius: "0px",width:"20%"}}
                                             >仕入先名
                                             </InputGroup.Text>
                                             <Autocomplete
                                                 className={classes.autocomplete}
                                                 sx={{
                                                     display: 'inline-block',
                                                     '& input': {
                                                         width: 300,
                                                         height: 0,
                                                     },
                                                 }}
                                                 options={SuppNameList}
                                                 id="SuppName"
                                                 maxLength="18"
                                                 style={{
                                                     width: "80%",
                                                     height: "25px", borderRadius: "4px",
                                                 }}
                                                 value={searchValue.SuppName}
                                                 name="SuppName"
                                                 onChange={(e, value) => { searchValueChange2(e, value) }}
                                                 renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                             />
                                         </InputGroup>
                                     </div>
                                     <Button className="btn" onClick={searchBtn} style={{ height: '40px', width: '120px', fontSize: '18px', color: 'black', border: '3px solid black', background: "white", margin: 'auto' }}>検索</Button>
                                 </div>
                                 <div style={{ width: '20%', height: '140px', overflow: 'auto', minWidth: '200px', marginLeft: '10px' }}>
                                     <Button onClick={openDialogDisplayItem} className="btn" style={{ height: '40px', width: '140px', fontSize: '18px', border: '3px solid black', color: 'black', background: "white", float: 'left', marginRight: '10px' }}>表示項目設定
                                     </Button>
                                 </div>
                             </div>
                         </div>
                     </div>
                     <ToolkitProvider
                         keyField="id"
                         data={arItemMasterInfo}
                         columns={columns}
                         headerWrapperClasses="foo"
                         wrapperClasses="boo"
                         columnToggle
                         exportCSV={{
                            fileName: '品目一覧.csv',
                            noAutoBOM: false,
                            blobType: 'text/plain;charset=Shift-JIS',
                            exportAll: true,
                            onlyExportSelection: false}}
                     >
                         {
                             (props) => (
                                 <div>
                                     <div style={{ width: '100%', height: '100%', display: 'none' }}>
                                         <SearchBar
                                             className="form-control"
                                             placeholder=" "
                                             style={{
                                                 fontSize: "14px",
                                                 height: "auto",
                                                 padding: "8px 16px",
                                                 lineHeight: "1.3333333",
                                                 borderRadius: "2px",
                                             }}
                                             {...props.searchProps}
                                         />
                                         <ClearSearchButton
                                             className="btn btn-dark btn-md "
                                             {...props.searchProps}
                                         />
                                     </div>
                                     <div style={{ width: '100%', height: '100%', overflow: "auto" }}>
                                         <BootstrapTable
                                             bootstrap4
                                             striped={true}
                                             selectRow={selectRow}
                                             id="table"
                                             hover={true}
                                             headerStyle
                                             keyField="id"
                                             condensed
                                             data={arItemMasterInfo}
                                             columns={columns}
                                             {...props.baseProps}
                                             pagination={paginationFactory(options)}
                                             noDataIndication={"no results found"}
                                             defaultSorted={DefaultSorted}
                                             cellEdit={cellEditFactory({
                                                 mode: "click",
                                                 blurToSave: true,
                                                 afterSaveCell: (beforeValue, afterValue, row) => {
                                                     console.log(beforeValue, afterValue, row)
                                                     // handleSetCount(row.id, afterValue)
                                                 }
                                             })}
                                         />
                                     </div>
                                     <div style={{ width: '1px', height: '80px', bottom: '20px' }}></div>
                 <div style={{ width: '99%', minWidth: "900px", height: '40px', bottom: '20px', position: 'absolute', textAlign: 'right', display: "flex", flexWrap: "nowrap", justifyContent: "flex-end" }}>
                      <MyExportCSV2 　{...props.csvProps} ></MyExportCSV2 >
                     <Button className="btn" onClick={() => openDilog("照会", setdilogDetill, arItemMasterInfo, orderselect_row)} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '10px', borderRadius: '8px' }}>照&nbsp;&nbsp;&nbsp;会
                     </Button>
                     <Button className="btn" disabled={btnDisable.btn1} onClick={() =>openDilog("新規登録", setdilogDetill, arItemMasterInfo,orderselect_row)} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '10px', borderRadius: '8px' }}>新規登録
                     </Button>
                     <Button className="btn" disabled={btnDisable.btn2} onClick={() => { openDilog("参照登録", setdilogDetill, arItemMasterInfo,orderselect_row)}} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '10px', borderRadius: '8px' }}>参照登録
                     </Button>
                     <Button className="btn" disabled={btnDisable.btn3} onClick={() => openDilog("変更", setdilogDetill, arItemMasterInfo,orderselect_row)} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '10px', borderRadius: '8px' }}>変&nbsp;&nbsp;&nbsp;更
                     </Button>
                     <Button className="btn" disabled={btnDisable.btn4} onClick={() => openDilog("削除", setdilogDetill, arItemMasterInfo,orderselect_row)} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '10px', borderRadius: '8px' }}>削&nbsp;&nbsp;&nbsp;除
                     </Button>
                     <Button className="btn" disabled={btnDisable.btn5} onClick={() => opendialogArItemMasterDialogUpload()} style={{display:"none", height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '30px', borderRadius: '8px' }}>アップロード
                     </Button>
                 </div>
                                 </div>
                             )
                         }
                     </ToolkitProvider>
                 </div>
                 <Dialog
                     open={dilogDetill}
                     maxWidth='xl'
                 >
                     <DialogTitle>
                         <span style={{ fontWeight: '900' }}>品目詳細画面{"(" + ArItemMasterConst.DilogDetill + ")"}</span>
                     </DialogTitle>
                     <DialogContent>
                         <MasterDilogDetill closeDilogDetill={closeDilogDetill} setDependencefun={setDependencefun} searchBtn={searchBtn} />
                     </DialogContent>
                 </Dialog>
                 <Dialog
                     open={dialogDisplayItem}
                     aria-labelledby="alert-dialog-title"
                     aria-describedby="alert-dialog-description"
                 >
                     <DialogTitle id="alert-dialog-title">
                         <span style={{ color: 'black', letterSpacing: '3px', fontSize: "24px", display: "inline-block", marginLeft: '12%' }}>表示項目設定</span></DialogTitle>
                     <DialogContent>
                         <MasterDisplayItem checkBoxChange = {checkBoxChange} DisplayProjectsSettingsSelectAll = {DisplayProjectsSettingsSelectAll}/>
                     </DialogContent>
                     <DialogActions>
                         <div style={{ width: "70%", display: 'flex', justifyContent: "start" }}>
                             <Button className="btn" onClick={handleOK} disabled ={settingButton} style={{ height: 40, fontSize: '18px', width: '80px', color: 'black', border: '3px solid black', background: "white", margin: 'auto' }}>設定</Button>
                             <Button className="btn" onClick={closeDialogDisplayItem} style={{ marginLeft: '1px', height: 40, width: "120px", fontSize: '18px', color: 'black', border: '3px solid black', background: "white", margin: 'auto' }}>キャンセル</Button>
                         </div>
                     </DialogActions>
                 </Dialog>
                 <Dialog
                 onClose={handleConfirmClose}
                 aria-labelledby="customized-dialog-title"
                 open={openConfirm}
                 PaperProps={{
                     style: {
                         backgroundColor: '#FFCC00',
                         boxShadow: 'none',
                         width: "400px",
                         height: "220px"
                     },
                 }}>
                 <DialogContent dividers style={{ textAlign: "center", color: "white" }}>
                     <Typography gutterBottom variant="h6" style={{ color: "white", marginTop: "20px" }} className={classes.textFieldDialog}>
                         {errorcode}
                     </Typography>
                     <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textFieldDialog}>
                         {msg1Text}
                     </Typography>
                     <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textField2Dialog}>
                         {msg2Text}
                     </Typography>
                 </DialogContent>
                 <DialogActions>
                     <Button autoFocus onClick={() => {
                         handleConfirmOK();
                     }} color="primary" variant="contained">
                         OK
                     </Button>
                 </DialogActions>
             </Dialog>
                 <Dialog
             open={dialogArItemMasterDialogUpload}
             maxWidth='xl'
             PaperProps={{
                 style: {
                     width: "58%",
                     height: "41%"
                 },
             }}
           >
             <DialogTitle>
               <span style={{ fontWeight: '900' }}>品目マスタアップロード</span>
             </DialogTitle>
             <DialogContent>
                 <MasterDialogUpload closedialogArItemMasterDialogUpload={closedialogArItemMasterDialogUpload} />
             </DialogContent>
           </Dialog>
             </div>
             <Dialog
          open={open2}
          onClose={handleCloseNG2}
          disableEscapeKeyDown={true}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{msg1}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {msgText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="secondary" autoFocus onClick={handleCloseOK2}>
              {msgbtnOK}
            </Button>
          </DialogActions>
        </Dialog>
         </Container>
     );
 };
 export default ArItemMaster;