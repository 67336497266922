global.ArGlob = {
    // EmailEndPoint : "https://0bwpffg27f.execute-api.ap-northeast-1.amazonaws.com/SendEmailWithAuth",
    EmailEndPoint : "https://0bwpffg27f.execute-api.ap-northeast-1.amazonaws.com/SendOrderStatusEmail-lssi-prod",
    ContactEmaiEndPoint: "https://e1u1lt79b3.execute-api.ap-northeast-1.amazonaws.com/arbos-contact-email-lssi-prod",
    HostIpAddress:"https://www.arbrown-arbos.com/OrderList?QuoteNumber=",
    OrderConfirmationAddress:"https://www.arbrown-arbos.com/OrderConfirmation?QuoteNumber=",
    WebHostAddress:"https://www.arbrown-arbos.com/",
    OrderEmd:"https://www.arbrown-arbos.com/OrderEmd?QuoteNumber=",
    priceinfo:"https://www.arbrown-arbos.com/ArPriceInfo?SeqNo=",
   //�J���F"" �A���؊��usilicone.gyoumu_osaka@arbrown.com�v�A�������u�v
   siliconeRequestEmail:"silicone.gyoumu_osaka@arbrown.com",

};