/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import useStyles from "./ArPriceInfoStyle";
import Button from "@material-ui/core/Button";
import moment from "moment";
import Autocomplete from '@mui/material/Autocomplete';
import { dialogText1, ArPriceInfoConst } from "./ArPriceInfoConst";
import { customerByeMail, listArItemMasters } from "../../../graphql/queries";
import { Auth } from "aws-amplify";
import {
    inputCheck, deleteData, modifyData, addData, getPriceinfo,
    getOrderRecipient,
    getlistArItemMasters,
    getlistArEndUsers,
    getlistArCustomerAddresses,
} from "./ArPriceInfoMethod";
import API, { graphqlOperation } from "@aws-amplify/api";
import TextField from '@mui/material/TextField';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from '@material-ui/core/CircularProgress';
import { listArSalesRepresentatives } from "../../../graphql/queries";
import Typography from "@material-ui/core/Typography";
import { Select, MenuItem} from "@material-ui/core";
import { FormControl as FormControlsk } from "@material-ui/core";
export default function ArPriceInfoDialogDetill(param) {
    const classes = useStyles()
    const [searchProgress, setSearchProgress] = useState(false);
    const [dialogText, setDialogText] = useState(dialogText1.dialogText)
    const [DilogDetillDisabled] = useState(ArPriceInfoConst.DilogDetillDisabled)
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const handleClickConfirmOpen = () => {
        setOpenConfirm(true);
    };
    const handleConfirmClose = () => {
        setOpenConfirm(false);
    };
    const handleConfirmOK = async () => {
        setOpenConfirm(false);
    }
    const [isValid, setIsValid] = useState({
        UseGroupClassification: false,
        ItemCode: false,
        MaterialCode: false,
        ItemName: false,
        SalesDocType: false,
        SuppCode: false,
        SuppName: false,
        SuppGroup: false,
        OrderReceiptCode: false,
        OrderName: false,
        ShipmentCode: false,
        DestName: false,
        EUARBmgmtCode: false,
        EndUserName: false,
        TorayEndUser: false,
        TorayEndUserName: false,
        Capacity: false,
        CapacityUnit: false,
        PriceUnit: false,
        RegQty: false,
        OrdQty: false,
        DTDelOrderRec: false,
        PriceTypeSales: false,
        PriceTypePurchase: false,
        SalesUnitPriceScale1: false,
        SalesUnitPriceScale2: false,
        SalesUnitPriceScale3: false,
        SalesUnitPriceScale4: false,
        SalesUnitPriceScale5: false,
        SalesUnitPriceUnitScale1: false,
        SalesUnitPriceUnitScale2: false,
        SalesUnitPriceUnitScale3: false,
        SalesUnitPriceUnitScale4: false,
        SalesUnitPriceUnitScale5: false,
        PurchUnitPriceScale1: false,
        PurchUnitPriceScale2: false,
        PurchUnitPriceScale3: false,
        PurchUnitPriceScale4: false,
        PurchUnitPriceScale5: false,
        PurchUnitPriceUnitScale1: false,
        PurchUnitPriceUnitScale2: false,
        PurchUnitPriceUnitScale3: false,
        PurchUnitPriceUnitScale4: false,
        PurchUnitPriceUnitScale5: false,
        BulkSales: false,
        BulkShippingFee: false,
        CoolDeliveryCharge: false,
        SalespersonCode: false,
        LastOrderDate: false,
        WebOrderNumber: false,
        ExpirationStart: false,
        Expired: false,
        RecordSalesDate: false,
        RecordSalesApplicable: false,
        PurchaseAccountDate: false,
        AcctForPurchasesApplicableble: false,
        SalesRevRate: false,
        SalesRevPrice: false,
        PurchaseRevRate: false,
        PurchaseRevPrice: false,
        ActualFlag: false,
        OrderQuantity: false,
        HistoryNum: false,
        Remarks: false,
        UpDailyPayment: false,
        UpTime: false,
        SeqNo: false,
        id: false,
    });
    const blurfun = async (e) => {
        if (e.target.value.includes("E")) {
            let reg = /^[EU]{2}$/
            if (reg.test(e.target.value)) {
                setDialogText({ ...dialogText, [e.target.name]: e.target.value ? e.target.value : "" })
                setIsValid({ ...isValid, [e.target.name]: false })
            } else {
                e.target.value = ""
                setDialogText({ ...dialogText, [e.target.name]: e.target.value })
            }
        }
        if(e.target.name !== "PriceTypePurchase"){
            if (e.target.value.includes("B")) {
                if (e.target.value === "B0" || e.target.value === "B10" || e.target.value === "B15" || e.target.value === "B20") {
                    setDialogText({ ...dialogText, [e.target.name]: e.target.value ? e.target.value : "" })
                    setIsValid({ ...isValid, [e.target.name]: false })
                } else {
                    e.target.value = ""
                    setDialogText({ ...dialogText, [e.target.name]: e.target.value })
                }
            }
        }
    }
    const dialogTextChange = async (e) => {
        if (e.target.name === "BulkSales") {
            if (!(Number(e.target.value) === 0 || Number(e.target.value) === 1)) {
                return;
            }
        }
        if (e.target.name === "ActualFlag") {
            if (e.target.value < 0 || e.target.value > 2) {
                return;
            }
        }
        if (e.target.name === "PriceTypeSales") {
            if ((!(e.target.value).includes("B")) && (!e.target.value.includes("E"))) {
                    return
            }
        }
        if (e.target.name === "PriceTypePurchase") {
            if (e.target.value !== "A" && e.target.value !== "B" && (!e.target.value.includes("E")) && e.target.value !== "代") {
                e.target.value = ""
            }
        }
        if (e.target.name === "SalesUnitPriceScale1" ||
            e.target.name === "SalesUnitPriceScale2" ||
            e.target.name === "SalesUnitPriceScale3" ||
            e.target.name === "SalesUnitPriceScale4" ||
            e.target.name === "SalesUnitPriceScale5" ||
            e.target.name === "PurchUnitPriceScale1" ||
            e.target.name === "PurchUnitPriceScale2" ||
            e.target.name === "PurchUnitPriceScale3" ||
            e.target.name === "PurchUnitPriceScale4" ||
            e.target.name === "PurchUnitPriceScale5" ||
            e.target.name === "BulkShippingFee" ||
            e.target.name === "CoolDeliveryCharge") {
            e.target.value = moneytonum(e.target.value)
        }
        setDialogText({ ...dialogText, [e.target.name]: e.target.value ? e.target.value : "" })
        if (e.target.value) {
            if ((e.target.name === "SalespersonCode") && e.target.value) {
                let arr = await SalespersonCodeNamefun(e.target.value)
                setSalespersonCodeName(arr.length > 0 ? arr[0].SalespersonName : "")
            }
            setIsValid({ ...isValid, [e.target.name]: false })
        }
    }
    const dialogTextSuppCodeChange = (e, value) => {
        setDialogText({ ...dialogText, SuppCode: value ? value.split(":")[0] : "",SuppName: value ? value.split(":")[1] : ""})
        let suppCode = isValid.SuppCode
        let suppName = isValid.SuppName
        if (value && value.split(":")[0]) {
            suppCode = false
        }
        if (value && value.split(":")[1]) {
            suppName = false
        }
        setIsValid({ ...isValid, SuppCode : suppCode, SuppName:suppName})
    }
    const dialogTextOrderReceiptCodeChange = (e, value) => {
        setDialogText({ ...dialogText, OrderReceiptCode: value ? value.split(":")[0] : "", OrderName: value ? value.split(":")[1] : "" })
        setIsValid({ ...isValid, OrderReceiptCode: false, OrderName: false })
    }
    const dialogTextShipmentCodeChange = (e, value) => {
        setDialogText({ ...dialogText, ShipmentCode: value ? value.split(":")[0] : "", DestName: value ? value.split(":")[1] : "", })
        setIsValid({ ...isValid, ShipmentCode: false, DestName: false })
    }
    const dialogTextEUARBmgmtCodeChange = (e, value) => {
        let torayEndUser = ""
        let torayName = ""
        for (let i = 0; i < TorayEndUserList.length; i++) {
            if (value && TorayEndUserList[i].EndUserCode === value.split(":")[0] && TorayEndUserList[i].EndUserName === value.split(":")[1]) {
                torayEndUser = TorayEndUserList[i].TorayEndUser
                torayName = TorayEndUserList[i].TorayEndUserName
                break;
            }
        }
        setIsValid({...isValid,EUARBmgmtCode:false,EndUserName:false,TorayEndUser:false,TorayEndUserName:false})
        setDialogText({
            ...dialogText,
            TorayEndUser: torayEndUser,
            TorayEndUserName: torayName,
            EUARBmgmtCode: value && value.split(":")[0],
            EndUserName: value && value.split(":")[1]
        })
    }
    const dialogTextBulkSalesChange = (e) => {
        let bulkSales = isValid.BulkSales
        if (e.target.value) {
            bulkSales = false
        }
        setDialogText({...dialogText,BulkSales: e.target.value})
        setIsValid({ ...isValid, BulkSales:bulkSales})
    }
    const dialogTextActualFlagChange = (e) => {
        let actualFlag = isValid.ActualFlag
        if (e.target.value) {
            actualFlag = false
        }
        setDialogText({...dialogText,ActualFlag: e.target.value})
        setIsValid({ ...isValid, ActualFlag:actualFlag})
    }
    function moneytonum(val) {
        let num = val ? val.trim() : ""
        let ss = num.toString();
        if (ss.length === 0) {
            return "";
        }
        return ss.replace(/,/g, "");
    }
    function numtomoney(number, toFixed) {
        let str = [];
        if (!number) return "";
        if (typeof number === "number") number = String(number);
        let tempNumber = number.split(".");
        number = tempNumber[0];
        if (number.length <= 3) {
            if (tempNumber[1] && toFixed !== 0) {
                number += "." + tempNumber[1].slice(0, toFixed);
            }
            return number;
        }
        number
            .split("")
            .reverse()
            .forEach((item, index) => {
                if (index !== 0 && index % 3 === 0) {
                    str.push(",", item);
                } else {
                    str.push(item);
                }
            });
        number = str.reverse().join("");
        if (tempNumber[1] && toFixed !== 0) {
            number += "." + tempNumber[1].slice(0, toFixed);
        }
        return number;
    }
    const dialogTextItemCodeChange = (e, value) => {
        for (let i = 0; i < ItemCodeInfoList.length; i++) {
            if (value && ItemCodeInfoList[i].ItemCode === value.split(":")[0] && ItemCodeInfoList[i].MaterialCode === value.split(":")[1] && ItemCodeInfoList[i].ItemName === value.split(":")[2]) {
                setDialogText({
                    ...dialogText,
                    ItemCode: ItemCodeInfoList[i].ItemCode,
                    MaterialCode: ItemCodeInfoList[i].MaterialCode,
                    ItemName: ItemCodeInfoList[i].ItemName,
                    SuppCode: ItemCodeInfoList[i].SuppCode,
                    SuppName: ItemCodeInfoList[i].SuppName,
                    Capacity: ItemCodeInfoList[i].Capacity,
                    CapacityUnit: ItemCodeInfoList[i].CapacityUnit,
                    PriceUnit: ItemCodeInfoList[i].OrderUnitpriceUnit,
                    DTDelOrderRec: ItemCodeInfoList[i].OrderAdjustment,
                })
                let itemCode = isValid.ItemCode
                let materialCode = isValid.MaterialCode
                let itemName = isValid.ItemCode
                let suppCode = isValid.SuppCode
                let suppName = isValid.SuppName
                let capacity = isValid.Capacity
                let capacityUnit = isValid.CapacityUnit
                let priceUnit = isValid.PriceUnit
                let dTDelOrderRec = isValid.DTDelOrderRec
                if (ItemCodeInfoList[i].ItemCode) {
                    itemCode = false
                }
                if (ItemCodeInfoList[i].MaterialCode) {
                    materialCode = false
                }
                if (ItemCodeInfoList[i].ItemName) {
                    itemName = false
                }
                if (ItemCodeInfoList[i].SuppCode) {
                    suppCode = false
                }
                if (ItemCodeInfoList[i].SuppName) {
                    suppName = false
                }
                if (ItemCodeInfoList[i].Capacity) {
                    capacity = false
                }
                if (ItemCodeInfoList[i].CapacityUnit) {
                    capacityUnit = false
                }
                if (ItemCodeInfoList[i].PriceUnit) {
                    priceUnit = false
                }
                if (ItemCodeInfoList[i].DTDelOrderRec) {
                    dTDelOrderRec = false
                }
                setIsValid({ ...isValid, ItemCode: itemCode, MaterialCode: materialCode, ItemName: itemName, SuppCode: suppCode, SuppName: suppName, Capacity: capacity, CapacityUnit: capacityUnit, PriceUnit: priceUnit, DTDelOrderRec: dTDelOrderRec })
                return;
            }
        }
}
    const SalespersonCodeNamefun = async (code) => {
        let infoArr = []
        let list = await API.graphql(graphqlOperation(listArSalesRepresentatives,
            {
                filter: {
                    Salesperson: { eq: code },
                }
            }));
        if (list.data.listArSalesRepresentatives !== null) {
            let nextToken = list.data.listArSalesRepresentatives.nextToken;
            infoArr = list.data.listArSalesRepresentatives.items;
            while (nextToken !== null) {
                let InvDataSec = await API.graphql(graphqlOperation(listArSalesRepresentatives, {
                    limit: 10000,
                    filter: {
                        Salesperson: { eq: code },
                    }, nextToken: nextToken
                }));
                nextToken = InvDataSec.data.listArSalesRepresentatives.nextToken;
                for (let i = 0; i < InvDataSec.data.listArSalesRepresentatives.items.length; i++) {
                    infoArr.push(InvDataSec.data.listArSalesRepresentatives.items[i]);
                }
            }
        }
        return infoArr
    }
    const [suppCodeList, setSuppCodeList] = useState([])
    const [SuppNameList, setSuppNameList] = useState([])
    const [TorayEndUserList, setTorayEndUserList] = useState([])
    const [OrderReceiptCodeList, setOrderReceiptCodeList] = useState([])
    const [ShipmentCodeList, setShipmentCodeList] = useState([])
    const [ItemCodeList, setItemCodeList] = useState([])
    const [ItemCodeInfoList, setItemCodeInfoList] = useState([])
    const [EUARBmgmtCodeList, setEUARBmgmtCodeList] = useState([])
    const [SalespersonCodeName, setSalespersonCodeName] = useState("")
    const [errorcode, setErrorCode] = React.useState(Object);
    const handleSetErrorcode = (Object) => {
        setErrorCode(Object);
    };
    const [msg1Text, setMsg1Text] = React.useState(Object);
    const handleSetMsg1Text = (Object) => {
        setMsg1Text(Object);
    };
    const [msg2Text, setMsg2Text] = React.useState(Object);
    const handleSetMsg2Text = (Object) => {
        setMsg2Text(Object);
    };
    const [disabledBtn, setDisabledBtn] = useState({
        SaveTemporarily:false,
        BusinessDependence:false,
    })
    const signOut = async () => {
        await Auth.signOut();
        try {
            await Auth.signOut();
        } catch (error) {
            console.log("error signing out: ", error);
        }
        document.location.reload();
    };
    useEffect(() => {
        async function init(){
            setSearchProgress(true)
        let user = ""
        try {
            user = await Auth.currentUserInfo();
        } catch (error) {
            if (error.message === 'No current user') {
                //ユーザープールに障害が発生した場合
                console.log('User pool is invalid.Please reauthenticate.');
                //ログイン画面に戻る
                signOut();
            } else {
                //他のエラーを処理する
                console.log('An error occurred:', error);
            }
        }
        const agencyPriceGroup = await API.graphql(
            graphqlOperation(customerByeMail, {
                Agency_Email: user.attributes.email,
            })
        );
        const agentinfo = agencyPriceGroup.data.customerByeMail !== null ? agencyPriceGroup.data.customerByeMail["items"] : []
        if (agentinfo.length > 0) {
            const Permission = agentinfo[0].Permission
        }
        let type = ArPriceInfoConst.DilogDetill;
        if (type === ArPriceInfoConst.PROC_REF) {
            setDisabledBtn({
                SaveTemporarily:true,
                BusinessDependence:false,
            })
        }
        if (type === ArPriceInfoConst.PROC_DEL){
            setDisabledBtn({
                SaveTemporarily:true,
                BusinessDependence:true,
            })
        }
        let OrderRecipientDataAll = await getOrderRecipient()
        let ItemMaster = await getlistArItemMasters()
        let EndUserInfoLIst = await getlistArEndUsers()
        let listCustomerAddressList = await getlistArCustomerAddresses()
        for (let i = 0; i < EndUserInfoLIst.length; i++) {
            if (EndUserInfoLIst[i].EndUserCode) {
                EUARBmgmtCodeList.push(EndUserInfoLIst[i].EndUserCode + ":" + EndUserInfoLIst[i].Name1)
                TorayEndUserList.push({
                    EndUserCode: EndUserInfoLIst[i].EndUserCode ? EndUserInfoLIst[i].EndUserCode : "",
                    EndUserName: EndUserInfoLIst[i].Name1 ? EndUserInfoLIst[i].Name1 : "",
                    TorayEndUser: EndUserInfoLIst[i].TorayEndUser ? EndUserInfoLIst[i].TorayEndUser : "",
                    TorayEndUserName: EndUserInfoLIst[i].TorayEndUserName ? EndUserInfoLIst[i].TorayEndUserName : ""
                })
            }
        }
        for (let i = 0; i < OrderRecipientDataAll.length; i++) {
            if (OrderRecipientDataAll[i].OrderReceiptCode) {
                OrderReceiptCodeList.push(OrderRecipientDataAll[i].OrderReceiptCode + ":" +
                    OrderRecipientDataAll[i].OrderRecipientName2 +
                    OrderRecipientDataAll[i].OrderRecipientName3 +
                    OrderRecipientDataAll[i].OrderRecipientName4)
            }
        }
        for (let i = 0; i < listCustomerAddressList.length; i++) {
            if (listCustomerAddressList[i].CustomerReceiptCode) {
                ShipmentCodeList.push(listCustomerAddressList[i].CustomerReceiptCode + ":" + listCustomerAddressList[i].Name1)
            }
        }
        for (let i = 0; i < ItemMaster.length; i++) {
            if (ItemMaster[i].SuppCode) {
                suppCodeList.push(ItemMaster[i].SuppCode + ":" + ItemMaster[i].SuppName)
            }
            if (ItemMaster[i].SuppName) {
                SuppNameList.push(ItemMaster[i].SuppName)
            }
            if (ItemMaster[i].SAPCODE) {
                ItemCodeList.push(ItemMaster[i].SAPCODE + ":" + ItemMaster[i].GMID + ":" + ItemMaster[i].ItemText)
                ItemCodeInfoList.push({
                    ItemCode: ItemMaster[i].SAPCODE ? ItemMaster[i].SAPCODE : "",
                    MaterialCode: ItemMaster[i].GMID ? ItemMaster[i].GMID : "",
                    ItemName: ItemMaster[i].ItemText ? ItemMaster[i].ItemText : "",
                    SuppCode: ItemMaster[i].SuppCode ? ItemMaster[i].SuppCode : "",
                    SuppName: ItemMaster[i].SuppName ? ItemMaster[i].SuppName : "",
                    Capacity: ItemMaster[i].Capacity ? ItemMaster[i].Capacity : "",
                    CapacityUnit: ItemMaster[i].CapacityUnit ? ItemMaster[i].CapacityUnit : "",
                    OrderUnitpriceUnit: ItemMaster[i].OrderUnitpriceUnit ? ItemMaster[i].OrderUnitpriceUnit : "",
                    OrderAdjustment: ItemMaster[i].OrderAdjustment ? ItemMaster[i].OrderAdjustment : "",
                })
            }
        }
        if (dialogText.ExpirationStart) {
            dialogText.ExpirationStart = moment(dialogText.ExpirationStart).utcOffset(9).format('YYYY-MM-DD') !== "Invalid date" ? moment(dialogText.ExpirationStart).utcOffset(9).format('YYYY-MM-DD') : ""
        }
        if (dialogText.Expired) {
            dialogText.Expired = moment(dialogText.Expired).utcOffset(9).format('YYYY-MM-DD') !== "Invalid date" ? moment(dialogText.Expired).utcOffset(9).format('YYYY-MM-DD') : ""
        }
        if (dialogText.RecordSalesDate) {
            dialogText.RecordSalesDate = moment(dialogText.RecordSalesDate).utcOffset(9).format('YYYY-MM-DD') !== "Invalid date" ? moment(dialogText.RecordSalesDate).utcOffset(9).format('YYYY-MM-DD') : ""
        }
        if (dialogText.PurchaseAccountDate) {
            dialogText.PurchaseAccountDate = moment(dialogText.PurchaseAccountDate).utcOffset(9).format('YYYY-MM-DD') !== "Invalid date" ? moment(dialogText.PurchaseAccountDate).utcOffset(9).format('YYYY-MM-DD') : ""
        }
        setTorayEndUserList(Array.from(new Set(TorayEndUserList)))
        setOrderReceiptCodeList(Array.from(new Set(OrderReceiptCodeList)))
        setSuppCodeList(Array.from(new Set(suppCodeList)))
        setSuppNameList(Array.from(new Set(SuppNameList)))
        setEUARBmgmtCodeList(Array.from(new Set(EUARBmgmtCodeList)))
        setShipmentCodeList(Array.from(new Set(ShipmentCodeList)))
        setItemCodeList(Array.from(new Set(ItemCodeList)))
        setItemCodeInfoList(ItemCodeInfoList)
        setSearchProgress(false)
    }
    init()
    }, [])

    function addZero(n) {
        return n < 10 ? "0" + n : n + "";
    }
    const dialogOnSubmit = async (upstates) => {
        setSearchProgress(true)
        let type = ArPriceInfoConst.DilogDetill;
        if (upstates === true) {
            let checkresult = []
            if (type === ArPriceInfoConst.PROC_ADD || type === ArPriceInfoConst.PROC_CADD || type === ArPriceInfoConst.PROC_REF) {
                if (!dialogText.UseGroupClassification) {
                    handleSetErrorcode("エラーコード：1002");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("使用グループ区分を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, UseGroupClassification: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, UseGroupClassification: false })
                }
                if (!dialogText.ItemCode) {
                    handleSetErrorcode("エラーコード：1017");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("品目コード(ARB)を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, ItemCode: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, ItemCode: false })
                }
                if (!dialogText.MaterialCode) {
                    handleSetErrorcode("エラーコード：1018");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("Material Codeを入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, MaterialCode: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, MaterialCode: false })
                }
                if (!dialogText.ItemName) {
                    handleSetErrorcode("エラーコード：1019");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("品目名を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, ItemName: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, ItemName: false })
                }
                if (!dialogText.SalesDocType) {
                    handleSetErrorcode("エラーコード：1020");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("販売伝票タイプを入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, SalesDocType: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, SalesDocType: false })
                }
                // 20240306必須→任意に変更
                // if (!dialogText.SuppGroup) {
                //     handleSetErrorcode("エラーコード：1022");
                //     handleSetMsg1Text("必須入力項目");
                //     handleSetMsg2Text("受注先Grを入力してください");
                //     handleClickConfirmOpen();
                //     setIsValid({ ...isValid, SuppGroup: true })
                //     setSearchProgress(false)
                //     return;
                // } else {
                //     setIsValid({ ...isValid, SuppGroup: false })
                // }
                // if (!dialogText.SalespersonCode) {
                //     handleSetErrorcode("エラーコード：1031");
                //     handleSetMsg1Text("必須入力項目");
                //     handleSetMsg2Text("営業担当者コードを入力してください");
                //     handleClickConfirmOpen();
                //     setSearchProgress(false)
                //     setIsValid({ ...isValid, SalespersonCode: true })
                //     return;
                // } else {
                //     setIsValid({ ...isValid, SalespersonCode: false })
                // }
                if (!dialogText.SuppCode) {
                    handleSetErrorcode("エラーコード：1021");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("仕入先コードを入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, SuppCode: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, SuppCode: false })
                }
                if (!dialogText.SuppName) {
                    handleSetErrorcode("エラーコード：1012");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("仕入先名を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, SuppName: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, SuppName: false })
                }
                if (!dialogText.Capacity) {
                    handleSetErrorcode("エラーコード：1027");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("容量を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, Capacity: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, Capacity: false })
                }
                if (!dialogText.CapacityUnit) {
                    handleSetErrorcode("エラーコード：1028");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("容量単位を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, CapacityUnit: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, CapacityUnit: false })
                }
                if (!dialogText.PriceUnit) {
                    handleSetErrorcode("エラーコード：1029");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("価格単位を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, PriceUnit: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, PriceUnit: false })
                }
                if (!dialogText.RegQty) {
                    handleSetErrorcode("エラーコード：1030");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("規定数量を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, RegQty: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, RegQty: false })
                }
                if (!dialogText.PriceTypePurchase) {
                    handleSetErrorcode("エラーコード：1003");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("価格種別を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, PriceTypePurchase: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, PriceTypePurchase: false })
                }
                if (!dialogText.SalesUnitPriceScale1) {
                    handleSetErrorcode("エラーコード：1005");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("販売単価を入力して下さい");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, SalesUnitPriceScale1: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, SalesUnitPriceScale1: false })
                }
                if (!dialogText.PurchUnitPriceScale1 || dialogText.PurchUnitPriceScale1 === "0") {
                    handleSetErrorcode("エラーコード：1006");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("仕入単価を入力して下さい");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, PurchUnitPriceScale1: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, PurchUnitPriceScale1: false })
                }
                if (!dialogText.BulkSales) {
                    handleSetErrorcode("エラーコード：1004");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("小分け販売可否を入力してください");
                    handleClickConfirmOpen();
                    setSearchProgress(false)
                    setIsValid({ ...isValid, BulkSales: true })
                    return;
                } else {
                    setIsValid({ ...isValid, BulkSales: false })
                }
                if (!dialogText.ExpirationStart) {
                    handleSetErrorcode("エラーコード：1032");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("有効期限開始を入力してください");
                    handleClickConfirmOpen();
                    setSearchProgress(false)
                    setIsValid({ ...isValid, ExpirationStart: true })
                    return;
                } else {
                    setIsValid({ ...isValid, ExpirationStart: false })
                }
                if (!dialogText.RecordSalesDate) {
                    handleSetErrorcode("エラーコード：1008");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("売上計上(日付)を入力してください");
                    handleClickConfirmOpen();
                    setSearchProgress(false)
                    setIsValid({ ...isValid, RecordSalesDate: true })
                    return;
                } else {
                    setIsValid({ ...isValid, RecordSalesDate: false })
                }
                if (!dialogText.RecordSalesApplicable) {
                    handleSetErrorcode("エラーコード：1009");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("売上計上(適用)を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, RecordSalesApplicable: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, RecordSalesApplicable: false })
                }
                if (!dialogText.PurchaseAccountDate) {
                    handleSetErrorcode("エラーコード：1010");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("仕入計上(日付)を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, PurchaseAccountDate: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, PurchaseAccountDate: false })
                }
                if (!dialogText.AcctForPurchasesApplicableble) {
                    handleSetErrorcode("エラーコード：1011");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("仕入計上(適用)を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, AcctForPurchasesApplicableble: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, AcctForPurchasesApplicableble: false })
                }
                if (!dialogText.ActualFlag) {
                    handleSetErrorcode("エラーコード：1033");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("実績フラグを入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, ActualFlag: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, ActualFlag: false })
                }
                if (!dialogText.HistoryNum) {
                    handleSetErrorcode("エラーコード：1034");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("履歴番号を入力してください");
                    handleClickConfirmOpen();
                    setIsValid({ ...isValid, HistoryNum: true })
                    setSearchProgress(false)
                    return;
                } else {
                    setIsValid({ ...isValid, HistoryNum: false })
                }
                if ((dialogText.SuppName).includes("ダウ・東レ_BOOK") || (dialogText.SuppName).includes("ダウ・東レ_特価")) {
                    if (!dialogText.TorayEndUser) {
                        handleSetErrorcode("エラーコード：1007");
                        handleSetMsg1Text("必須入力項目");
                        handleSetMsg2Text("東レエンドユーザーが未登録です。登録してください");
                        handleClickConfirmOpen();
                        setSearchProgress(false)
                        setIsValid({ ...isValid, TorayEndUser: true })
                        return;
                    } else {
                        setIsValid({ ...isValid, TorayEndUser: false })
                    }
                }
            }
            if (type === ArPriceInfoConst.PROC_ADD || type === ArPriceInfoConst.PROC_CADD) {
                checkresult = await inputCheck(dialogText);
                if ((checkresult.dataAll).length > 0) {
                    handleSetErrorcode("エラーコード：1002");
                    handleSetMsg1Text("重複エラー");
                    handleSetMsg2Text("重複している箇所を修正して下さい。");
                    handleClickConfirmOpen()
                    setIsValid({ ...isValid, ItemCode:true,ItemName:true,OrderReceiptCode: true, ShipmentCode: true, EUARBmgmtCode: true, MaterialCode: true, SalesDocType: true, SuppGroup: true, SuppCode: true, ActualFlag: true })
                    setSearchProgress(false)
                    return;
                }
            }
            let dialoginfo = JSON.parse(JSON.stringify(dialogText))
            let number = 0
            let info = checkresult.arr || []
            for (let i = 0; i < info.length; i++) {
              if (Number(info[i].SeqNo) > number) {
                number = Number(info[i].SeqNo)
              }
          }
            switch (type) {
                case ArPriceInfoConst.PROC_ADD:
                    dialoginfo.UpDailyPayment = moment(new Date).utcOffset(9).format('YYYYMMDD')
                    dialoginfo.UpTime = addZero(new Date().getHours()) + addZero(new Date().getMinutes()) + addZero(new Date().getSeconds());
                    dialoginfo.SeqNo = "0000000000".substring(0, "0000000000".length - String(Number(number) + 1).length) + `${Number(number) + 1}`;
                    dialoginfo.HistoryNum = "1"
                    dialoginfo.ActualFlag = "0"
                    await addData(dialoginfo)
                    break;
                case ArPriceInfoConst.PROC_CADD:
                    dialoginfo.UpDailyPayment = moment(new Date).utcOffset(9).format('YYYYMMDD')
                    dialoginfo.UpTime = addZero(new Date().getHours()) + addZero(new Date().getMinutes()) + addZero(new Date().getSeconds());
                    dialoginfo.SeqNo = "0000000000".substring(0, "0000000000".length - String(Number(number) + 1).length) + `${Number(number) + 1}`;
                    dialoginfo.HistoryNum = "1"
                    dialoginfo.ActualFlag = "0"
                    await addData(dialoginfo)
                    break;
                case ArPriceInfoConst.PROC_REF:
                    dialoginfo.UpDailyPayment = moment(new Date).utcOffset(9).format('YYYYMMDD')
                    dialoginfo.UpTime = addZero(new Date().getHours()) + addZero(new Date().getMinutes()) + addZero(new Date().getSeconds());
                    await modifyData(dialoginfo)
                    break;
                case ArPriceInfoConst.PROC_DEL:
                    dialoginfo.ActualFlag = "2"
                    await deleteData(dialoginfo)
                    break;
                default:
            }
            setSearchProgress(false)
            param.closeDilogDetill()
            param.searchBtn({
                ActualFlag: dialoginfo.ActualFlag,
                SeqNo:dialoginfo.SeqNo})
        } else {
            let arr = JSON.parse(JSON.stringify(dialogText))
            if (!arr.ItemCode) {
                handleSetErrorcode("エラーコード：1017");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("品目コード(ARB)を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, ItemCode: true })
                setSearchProgress(false)
                return;
            }
            let checkresult = await inputCheck(dialogText);
            let number = 0
          let info = checkresult.arr || []
          for (let i = 0; i < info.length; i++) {
            if (Number(info[i].SeqNo) > number) {
              number = Number(info[i].SeqNo)
            }
        }
            arr.UpDailyPayment = moment(new Date).utcOffset(9).format('YYYYMMDD')
            arr.UpTime = addZero(new Date().getHours()) + addZero(new Date().getMinutes()) + addZero(new Date().getSeconds());
            arr.SeqNo = "0000000000".substring(0, "0000000000".length - String(Number(number) + 1).length) + `${Number(number) + 1}`;
            arr.HistoryNum = "1"
            arr.ActualFlag = "9"
            await addData(arr)
            param.closeDilogDetill()
            param.searchBtn({
                ActualFlag: arr.ActualFlag,
                SeqNo:arr.SeqNo})
        }
    }
    const getListArSalesRepresentatives = async (SalesGroupInfo, v1, v2) => {
        let infoArr = []
        let list = await API.graphql(graphqlOperation(listArSalesRepresentatives,
            {
                filter: {
                    SalesGroup: { eq: SalesGroupInfo },
                    OrderReceiptCode: { eq: v1 },
                    ItemGroup: { eq: v2 }
                }
            }));
        if (list.data.listArSalesRepresentatives !== null) {
            let nextToken = list.data.listArSalesRepresentatives.nextToken;
            infoArr = list.data.listArSalesRepresentatives.items;
            while (nextToken !== null) {
                let InvDataSec = await API.graphql(graphqlOperation(listArSalesRepresentatives, {
                    limit: 10000,
                    filter: {
                        SalesGroup: { eq: SalesGroupInfo },
                        OrderReceiptCode: { eq: v1 },
                        ItemGroup: { eq: v2 }
                    }, nextToken: nextToken
                }));
                nextToken = InvDataSec.data.listArSalesRepresentatives.nextToken;
                for (let i = 0; i < InvDataSec.data.listArSalesRepresentatives.items.length; i++) {
                    infoArr.push(InvDataSec.data.listArSalesRepresentatives.items[i]);
                }
            }
        }
        return infoArr
    }
    const handleSetPurchaseAccountDate = (e) => {
        let purchaseAccountDate = isValid.PurchaseAccountDate
        if (e.target.value){
            purchaseAccountDate = false
        }
        setIsValid({ ...isValid, PurchaseAccountDate: purchaseAccountDate})
        setDialogText({ ...dialogText, PurchaseAccountDate:e.target.value })
    }
    const handleSetAcctForPurchasesApplicableble = (e) => {
         let acctForPurchasesApplicableble = isValid.AcctForPurchasesApplicableble
        if (e.target.value) {
            acctForPurchasesApplicableble = false
        }
        setIsValid({ ...isValid, AcctForPurchasesApplicableble: acctForPurchasesApplicableble})
        setDialogText({ ...dialogText, AcctForPurchasesApplicableble: e.target.value })
    }
    const handleSetSuppGroup = (e) => {
        let suppGroup = isValid.SuppGroup
       if (e.target.value) {
        suppGroup = false
       }
       setIsValid({ ...isValid, SuppGroup: suppGroup})
       setDialogText({ ...dialogText, SuppGroup:e.target.value})
   }
    const handleSetRecordSalesApplicable = (e) => {
        let recordSalesApplicable = isValid.RecordSalesApplicable
        if (e.target.value) {
            recordSalesApplicable = false
        }
        setIsValid({ ...isValid, RecordSalesApplicable: recordSalesApplicable})
        setDialogText({ ...dialogText, RecordSalesApplicable: e.target.value })
    }
    const handleSetRecordSalesDate = (e) => {
        let recordSalesDate = isValid.RecordSalesDate
        if (e.target.value) {
            recordSalesDate = false
        }
        setIsValid({ ...isValid, RecordSalesDate: recordSalesDate})
        setDialogText({ ...dialogText, RecordSalesDate: e.target.value })
    }
    const handleSetExpired = (e) => {
        setDialogText({ ...dialogText, Expired: e.target.value })
    }
    const handleSetExpirationStart = (e) => {
        let expirationStart = isValid.ExpirationStart
        if (e.target.value) {
            expirationStart = false
        }
        setIsValid({ ...isValid, ExpirationStart: expirationStart})
        setDialogText({ ...dialogText, ExpirationStart: e.target.value})
    }
    async function getItemMaster(row) {
        let ItemMaster = [];
        const list = await API.graphql(graphqlOperation(listArItemMasters, {
            limit: 10000,
            filter: {
                GMID: { eq: row.MaterialCode ? row.MaterialCode : "" },
                SAPCODE: { eq: row.ItemCode ? row.ItemCode : '' },
            }
        }));
        if (list.data.listArItemMasters.items && list.data.listArItemMasters.items.length > 0) {
            ItemMaster = list.data.listArItemMasters.items
            let nextToken = list.data.listArItemMasters.nextToken;
            while (nextToken !== null) {
                let InvDataSec = await API.graphql(
                    graphqlOperation(listArItemMasters, {
                        limit: 10000,
                        nextToken: nextToken,
                        filter: {
                            GMID: { eq: row.MaterialCode ? row.MaterialCode : "" },
                            SAPCODE: { eq: row.ItemCode ? row.ItemCode : '' },
                        }
                    }))
                if (InvDataSec.data.listArItemMasters !== null) {
                    nextToken = InvDataSec.data.listArItemMasters.nextToken;
                    for (
                        let i = 0;
                        i < InvDataSec.data.listArItemMasters.items.length;
                        i++
                    ) {
                        ItemMaster.push(InvDataSec.data.listArItemMasters.items[i]);
                    }
                }
            }
        }
        return ItemMaster
    }
    const BulkShippingFeeUnit = (row)=>{
        let unit = ""
        if (row.SuppName === "長瀬産業名古屋(消泡剤)_特価" || row.SuppName === "長瀬産業名古屋(変性オイル)_特価" || row.SuppName === "長瀬産業名古屋(シラン・エマルジョン)_特価" || row.SuppName === "長瀬産業名古屋(塗料コーティング)_特価") {
          unit = "KG"
        }else{
          unit = "オーダー"
        }
        return unit
      }
    const SalesConfirmClick = async () => {
        let dialoginfo = JSON.parse(JSON.stringify(dialogText))
        setSearchProgress(true)
        let type = ArPriceInfoConst.DilogDetill;
        if (type === ArPriceInfoConst.PROC_ADD || type === ArPriceInfoConst.PROC_CADD) {
            let checkresult = []
            if (!dialogText.UseGroupClassification) {
                handleSetErrorcode("エラーコード：1002");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("使用グループ区分を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, UseGroupClassification: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, UseGroupClassification: false })
            }
            if (!dialogText.ItemCode) {
                handleSetErrorcode("エラーコード：1017");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("品目コード(ARB)を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, ItemCode: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, ItemCode: false })
            }
            if (!dialogText.MaterialCode) {
                handleSetErrorcode("エラーコード：1018");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("Material Codeを入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, MaterialCode: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, MaterialCode: false })
            }
            if (!dialogText.ItemName) {
                handleSetErrorcode("エラーコード：1019");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("品目名を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, ItemName: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, ItemName: false })
            }
            if (!dialogText.SalesDocType) {
                handleSetErrorcode("エラーコード：1020");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("販売伝票タイプを入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, SalesDocType: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, SalesDocType: false })
            }
            // if (!dialogText.SuppGroup) {
            //     handleSetErrorcode("エラーコード：1022");
            //     handleSetMsg1Text("必須入力項目");
            //     handleSetMsg2Text("受注先Grを入力してください");
            //     handleClickConfirmOpen();
            //     setIsValid({ ...isValid, SuppGroup: true })
            //     setSearchProgress(false)
            //     return;
            // } else {
            //     setIsValid({ ...isValid, SuppGroup: false })
            // }
            // if (!dialogText.SalespersonCode) {
            //     handleSetErrorcode("エラーコード：1031");
            //     handleSetMsg1Text("必須入力項目");
            //     handleSetMsg2Text("営業担当者コードを入力してください");
            //     handleClickConfirmOpen();
            //     setSearchProgress(false)
            //     setIsValid({ ...isValid, SalespersonCode: true })
            //     return;
            // } else {
            //     setIsValid({ ...isValid, SalespersonCode: false })
            // }
            if (!dialogText.SuppCode) {
                handleSetErrorcode("エラーコード：1021");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("仕入先コードを入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, SuppCode: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, SuppCode: false })
            }
            if (!dialogText.SuppName) {
                handleSetErrorcode("エラーコード：1012");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("仕入先名を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, SuppName: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, SuppName: false })
            }
            if (!dialogText.Capacity) {
                handleSetErrorcode("エラーコード：1027");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("容量を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, Capacity: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, Capacity: false })
            }
            if (!dialogText.CapacityUnit) {
                handleSetErrorcode("エラーコード：1028");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("容量単位を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, CapacityUnit: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, CapacityUnit: false })
            }
            if (!dialogText.PriceUnit) {
                handleSetErrorcode("エラーコード：1029");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("価格単位を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, PriceUnit: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, PriceUnit: false })
            }
            if (!dialogText.RegQty) {
                handleSetErrorcode("エラーコード：1030");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("規定数量を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, RegQty: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, RegQty: false })
            }
            if (!dialogText.PriceTypePurchase) {
                handleSetErrorcode("エラーコード：1003");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("価格種別を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, PriceTypePurchase: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, PriceTypePurchase: false })
            }
            if (!dialogText.SalesUnitPriceScale1) {
                handleSetErrorcode("エラーコード：1005");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("販売単価を入力して下さい");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, SalesUnitPriceScale1: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, SalesUnitPriceScale1: false })
            }
            if (!dialogText.PurchUnitPriceScale1 || dialogText.PurchUnitPriceScale1 === "0") {
                handleSetErrorcode("エラーコード：1006");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("仕入単価を入力して下さい");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, PurchUnitPriceScale1: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, PurchUnitPriceScale1: false })
            }
            if (!dialogText.BulkSales) {
                handleSetErrorcode("エラーコード：1004");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("小分け販売可否を入力してください");
                handleClickConfirmOpen();
                setSearchProgress(false)
                setIsValid({ ...isValid, BulkSales: true })
                return;
            } else {
                setIsValid({ ...isValid, BulkSales: false })
            }
            if (!dialogText.ExpirationStart) {
                handleSetErrorcode("エラーコード：1032");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("有効期限開始を入力してください");
                handleClickConfirmOpen();
                setSearchProgress(false)
                setIsValid({ ...isValid, ExpirationStart: true })
                return;
            } else {
                setIsValid({ ...isValid, ExpirationStart: false })
            }
            if (!dialogText.RecordSalesDate) {
                handleSetErrorcode("エラーコード：1008");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("売上計上(日付)を入力してください");
                handleClickConfirmOpen();
                setSearchProgress(false)
                setIsValid({ ...isValid, RecordSalesDate: true })
                return;
            } else {
                setIsValid({ ...isValid, RecordSalesDate: false })
            }
            if (!dialogText.RecordSalesApplicable) {
                handleSetErrorcode("エラーコード：1009");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("売上計上(適用)を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, RecordSalesApplicable: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, RecordSalesApplicable: false })
            }
            if (!dialogText.PurchaseAccountDate) {
                handleSetErrorcode("エラーコード：1010");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("仕入計上(日付)を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, PurchaseAccountDate: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, PurchaseAccountDate: false })
            }
            if (!dialogText.AcctForPurchasesApplicableble) {
                handleSetErrorcode("エラーコード：1011");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("仕入計上(適用)を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, AcctForPurchasesApplicableble: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, AcctForPurchasesApplicableble: false })
            }
            if (!dialogText.ActualFlag) {
                handleSetErrorcode("エラーコード：1033");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("実績フラグを入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, ActualFlag: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, ActualFlag: false })
            }
            if (!dialogText.HistoryNum) {
                handleSetErrorcode("エラーコード：1034");
                handleSetMsg1Text("必須入力項目");
                handleSetMsg2Text("履歴番号を入力してください");
                handleClickConfirmOpen();
                setIsValid({ ...isValid, HistoryNum: true })
                setSearchProgress(false)
                return;
            } else {
                setIsValid({ ...isValid, HistoryNum: false })
            }
            if ((dialogText.SuppName).includes("ダウ・東レ_BOOK") || (dialogText.SuppName).includes("ダウ・東レ_特価")) {
                if (!dialogText.TorayEndUser) {
                    handleSetErrorcode("エラーコード：1007");
                    handleSetMsg1Text("必須入力項目");
                    handleSetMsg2Text("東レエンドユーザーが未登録です。登録してください");
                    handleClickConfirmOpen();
                    setSearchProgress(false)
                    setIsValid({ ...isValid, TorayEndUser: true })
                    return;
                } else {
                    setIsValid({ ...isValid, TorayEndUser: false })
                }
            }
        try {
            checkresult = await inputCheck(dialogText);
            if ((checkresult.dataAll).length > 0) {
                handleSetErrorcode("エラーコード：1002");
                handleSetMsg1Text("重複エラー");
                handleSetMsg2Text("重複している箇所を修正して下さい。");
                handleClickConfirmOpen()
                setIsValid({ ...isValid, ItemCode:true,OrderReceiptCode: true, ShipmentCode: true, EUARBmgmtCode: true, MaterialCode: true, SalesDocType: true, SuppGroup: true, SuppCode: true, ActualFlag: true })
                setSearchProgress(false)
                return;
            }
        } catch (error) {
            if (error.message === 'No current user') {
                //ユーザープールに障害が発生した場合
                console.log('User pool is invalid.Please reauthenticate.');
                //ログイン画面に戻る
                signOut();
            } else {
                //他のエラーを処理する
                console.log('An error occurred:', error);
            }
          }
          let number = 0
          let info = checkresult.arr || []
          for (let i = 0; i < info.length; i++) {
            if (Number(info[i].SeqNo) > number) {
              number = Number(info[i].SeqNo)
            }
        }
            dialoginfo.UpDailyPayment = moment(new Date).utcOffset(9).format('YYYYMMDD')
            dialoginfo.UpTime = addZero(new Date().getHours()) + addZero(new Date().getMinutes()) + addZero(new Date().getSeconds());
            dialoginfo.SeqNo = "0000000000".substring(0, "0000000000".length - String(Number(number) + 1).length) + `${Number(number) + 1}`;
            dialoginfo.ActualFlag = "9"
            await addData(dialoginfo)
        }
        let SalesGroupInfo = "162";
        const { accessToken } = await Auth.currentSession();
        const cognitogroups = accessToken.payload['cognito:groups'];
        if (cognitogroups && cognitogroups[0] === "silicone162") {
            SalesGroupInfo = '162'
          }else if (cognitogroups && cognitogroups[0] === "silicone141"){
            SalesGroupInfo = '141'
          }else if (cognitogroups && cognitogroups[0] === "silicone181"){
            SalesGroupInfo = '181'
          }
        let ItemMaster = await getItemMaster(dialoginfo)
        let v1 = dialoginfo.OrderReceiptCode ? dialoginfo.OrderReceiptCode : ""
        let v2 = ItemMaster.length > 0 ? (ItemMaster[0].ItemGroup ? ItemMaster[0].ItemGroup : "") : ""
        let infoArr = await getListArSalesRepresentatives(SalesGroupInfo, v1, v2);
        let Emails = []
        let msgUrlT = global.ArGlob.priceinfo + dialoginfo.SeqNo;
        let unit = BulkShippingFeeUnit(dialoginfo)
        const emailContent = `
        <p>
  お疲れ様です。<br>
  下記の通り価格の御確認申し上げます。<br><br>
        <table>
        <tr>
          <td align="right">管理番号：</td>
          <td>${dialoginfo.OrderDetailsNumber ? dialoginfo.OrderDetailsNumber : ""}</td>
        </tr>
        <tr>
          <td align="right">受注先：</td>
          <td>${dialoginfo.OrderName ? dialoginfo.OrderName : ""}</td>
        </tr>
        <tr>
          <td align="right">品名：</td>
          <td>${dialoginfo.ItemCode ? dialoginfo.ItemCode : ""}</td>
        </tr>
        <tr>
          <td align="right">数量：</td>
          <td>${dialoginfo.OrderQuantity ? dialoginfo.OrderQuantity + "/" + dialoginfo.CapacityUnit : ""}</td>
        </tr>
        <tr>
          <td align="right">規定数量(MOQ)：</td>
          <td>${dialoginfo.RegQty ? dialoginfo.RegQty + "/" + dialoginfo.PriceUnit : ""}</td>
        </tr>
        <tr>
          <td align="right">小分け可否：</td>
          <td>${dialoginfo.BulkSales === '0' ? "不可" : "可"}</td>
        </tr>
        <tr>
          <td align="right">小分け手数料：</td>
          <td>\\${Number(moneytonum(dialoginfo.BulkShippingFee)) !== 0 ? numtomoney(moneytonum(dialoginfo.BulkShippingFee)) + '/' + unit : "0"}</td>
        </tr>
        <tr>
          <td align="right">出荷先：</td>
          <td>${dialoginfo.DestName ? dialoginfo.DestName : ""}</td>
        </tr>
        <tr>
          <td align="right">エンドユーザー：</td>
          <td>${dialoginfo.EndUserName ? dialoginfo.EndUserName : ""}</td>
        </tr>
        <tr>
          <td align="right">備考：</td>
          <td>${dialoginfo.Remarks ? dialoginfo.Remarks : ""}</td>
        </tr>
        <tr>
          <td align="right">ARBOS URL：</td>
          <td>${msgUrlT ? `<a href="${msgUrlT}">${msgUrlT}</a>` : ""}</td>
        </tr>
      </table>
      </p>
`;
        infoArr.length > 0 && infoArr.forEach((item) => { Emails.push(item.SalespersonEmail) })
        const bodyContent = {
            templete: "a7",
            subject: `エア・ブラウンARBOS_営業確認_${dialoginfo.OrderReceiptCode}_${dialoginfo.ItemCode ? dialoginfo.ItemCode : ""}`,
            Cc: global.ArGlob.siliconeRequestEmail,
            msgUrl: "",
            txtEnd: "",
            receiveUser: "",
            toAgencyEmails: Emails,
            message:emailContent
        };
        const body = JSON.stringify(bodyContent);
        const requestOptions = {
            method: "POST",
            body,
        };
        await fetch(global.ArGlob.EmailEndPoint, requestOptions);
        param.closeDilogDetill()
        param.searchBtn({
            ActualFlag: dialoginfo.ActualFlag,
            SeqNo:dialoginfo.SeqNo})
        setSearchProgress(false)
    }
    const handleKeyCode = (e)=> {
        if (e.keyCode === 13) {
          e.preventDefault()
        }
      }
    return (
        <div style={{ width: "1400px", fontSize: "14px" }}>
            <div style={{ display: "flex", height: "40px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "145px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>使用グループ区分</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "40px", height: "25px", borderRadius: "4px", borderColor: isValid.UseGroupClassification ? "pink" : "#707070" }}
                                value={dialogText.UseGroupClassification || ""}
                                name="UseGroupClassification"
                                id="dialogTextUseGroupClassification"
                                disabled={DilogDetillDisabled.dialogTextUseGroupClassification}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                        <span>(1:共通、2:EMS、3:EMC、4:EMW)</span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px", marginLeft: "60px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "118px" }}>
                            {/* <span className={classes.textRequired}>*</span> */}
                            <span className={classes.textlabelSpanDialog}>受注先Gr</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block",marginLeft:"10px"}}>
                            <FormControlsk fullWidth>
                               <Select
                                 className="MuiSelect-filled.MuiSelect-filled"
                                 style={{ width: "180px", height: "25px", borderRadius: "4px",
                                 border: "1px solid",
                                 borderColor: isValid.SuppGroup ? "pink" : "#707070" }}
                                value={dialogText.SuppGroup || ""}
                                name="SuppGroup"
                                id="dialogTextSuppGroup"
                                disabled={DilogDetillDisabled.dialogTextSuppGroup}
                                onChange={(e) => { handleSetSuppGroup(e) }}
                               >
                                 <MenuItem key="" value="" >　</MenuItem>
                                 <MenuItem key="B0" value="B0" >B0:新規2次店</MenuItem>
                                 <MenuItem key="B10" value="B10">B10:一般代理店</MenuItem>
                                 <MenuItem key="B15"value="B15">B15:エンドユーザー</MenuItem>
                                 <MenuItem key="B20"value="B20">B20:相見積もり</MenuItem>
                               </Select>
                             </FormControlsk>
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>販売伝票タイプ</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "100px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesDocType ? "pink" : "#707070" }}
                                value={dialogText.SalesDocType || ""}
                                name="SalesDocType"
                                id="dialogTextSalesDocType"
                                disabled={DilogDetillDisabled.dialogTextSalesDocType}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "180px" }}>
                            {/* <span className={classes.textRequired}>*</span> */}
                            <span className={classes.textlabelSpanDialog}>営業担当者コード</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "80px", height: "25px", borderRadius: "4px", borderColor: isValid.SalespersonCode ? "pink" : "#707070" }}
                                value={dialogText.SalespersonCode || ""}
                                name="SalespersonCode"
                                id="dialogTextSalespersonCode"
                                disabled={DilogDetillDisabled.dialogTextSalespersonCode}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                        <span style={{ display: "inline-block", width: "61px" }}>{SalespersonCodeName ? `${(SalespersonCodeName)}` : ""}</span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "40px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "140px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>品目</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <Autocomplete
                                className={classes.autocomplete}
                                sx={{
                                    display: 'inline-block',
                                    '& input': {
                                        width: 200,
                                        height: 0,
                                        bgcolor: 'background.paper',
                                        color: (theme) =>
                                            theme.palette.getContrastText(theme.palette.background.paper),
                                    },
                                }}
                                options={ItemCodeList}
                                style={{ width: "300px", height: "25px", borderRadius: "4px", marginLeft: "4%" }}
                                value={dialogText.ItemCode || ""}
                                name="ItemCode"
                                id="dialogTextItemCode"
                                freeSolo = {true}
                                disabled={DilogDetillDisabled.dialogTextItemCode}
                                onInputChange={(e,value)=>{dialogTextItemCodeChange(e,value)}}
                                onChange={(e,value) => { dialogTextItemCodeChange(e,value) }}
                                renderInput={(params) => <TextField {...params} label="" variant="outlined" style={{ borderRadius: "4px", border: isValid.ItemCode === true ? "1px solid pink" : "1px solid #707070", }} />}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>Material Code</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "87px", height: "25px", borderRadius: "4px", borderColor: isValid.MaterialCode ? "pink" : "#707070" }}
                                value={dialogText.MaterialCode || ""}
                                name="MaterialCode"
                                id="dialogTextMaterialCode"
                                disabled={DilogDetillDisabled.dialogTextMaterialCode}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>品目名</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "522px", height: "25px", borderRadius: "4px", borderColor: isValid.ItemName ? "pink" : "#707070" }}
                                value={dialogText.ItemName || ""}
                                name="ItemName"
                                id="dialogTextItemName"
                                disabled={DilogDetillDisabled.dialogTextItemName}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "40px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "140px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>仕入先コード</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <Autocomplete
                                className={classes.autocomplete}
                                sx={{
                                    display: 'inline-block',
                                    '& input': {
                                        width: 200,
                                        height: 0,
                                        bgcolor: 'background.paper',
                                        color: (theme) =>
                                            theme.palette.getContrastText(theme.palette.background.paper),
                                    },
                                }}
                                options={suppCodeList}
                                style={{ width: "200px", height: "25px", borderRadius: "4px", marginLeft: "5%", }}
                                value={dialogText.SuppCode || ""}
                                name="SuppCode"
                                id="dialogTextSuppCode"
                                disabled={DilogDetillDisabled.dialogTextSuppCode}
                                onChange={(e, value) => { dialogTextSuppCodeChange(e, value) }}
                                renderInput={(params) => <TextField {...params} label="" variant="outlined" style={{ borderRadius: "4px", border: isValid.SuppCode ? "1px solid pink" : "1px solid #707070" }} />}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px", marginLeft: "5.3%" }}>
                        <label className={classes.textlabelDialog}>
                        <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>仕入先名称</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "604px", height: "25px", borderRadius: "4px", borderColor: isValid.SuppName ? "pink" : "#707070" }}
                                value={dialogText.SuppName || ""}
                                name="SuppName"
                                id="dialogTextSuppName"
                                disabled={DilogDetillDisabled.dialogTextSuppName}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "40px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "140px" }}>
                            <span className={classes.textRequired}></span>
                            <span className={classes.textlabelSpanDialog}>受注先コード</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <Autocomplete
                                className={classes.autocomplete}
                                sx={{
                                    display: 'inline-block',
                                    '& input': {
                                        width: 200,
                                        height: 0,
                                        bgcolor: 'background.paper',
                                        color: (theme) =>
                                            theme.palette.getContrastText(theme.palette.background.paper),
                                    },
                                }}
                                options={OrderReceiptCodeList}
                                style={{ width: "200px", height: "25px", borderRadius: "4px", marginLeft: "5%" }}
                                value={dialogText.OrderReceiptCode || ""}
                                name="OrderReceiptCode"
                                id="dialogTextOrderReceiptCode"
                                disabled={DilogDetillDisabled.dialogTextOrderReceiptCode}
                                onChange={(e, value) => { dialogTextOrderReceiptCodeChange(e, value) }}
                                renderInput={(params) => <TextField {...params} label="" variant="outlined" style={{ borderRadius: "4px", border:isValid.OrderReceiptCode ? "1px solid pink" : "1px solid #707070"}} />}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px", marginLeft: "5.3%" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textRequired}></span>
                            <span className={classes.textlabelSpanDialog}>受注先名称</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "604px", height: "25px", borderRadius: "4px", borderColor: "#707070" }}
                                value={dialogText.OrderName || ""}
                                name="OrderName"
                                id="dialogTextOrderName"
                                disabled={DilogDetillDisabled.dialogTextOrderName}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "40px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "140px" }}>
                            <span className={classes.textlabelSpanDialog}>出荷先コード</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <Autocomplete
                                className={classes.autocomplete}
                                sx={{
                                    display: 'inline-block',
                                    '& input': {
                                        width: 200,
                                        height: 0,
                                        bgcolor: 'background.paper',
                                        color: (theme) =>
                                            theme.palette.getContrastText(theme.palette.background.paper),
                                    },
                                }}
                                options={ShipmentCodeList}
                                style={{ width: "200px", height: "25px", borderRadius: "4px", marginLeft: "5%", }}
                                value={dialogText.ShipmentCode || ""}
                                name="ShipmentCode"
                                id="dialogTextShipmentCode"
                                disabled={DilogDetillDisabled.dialogTextShipmentCode}
                                onChange={(e, value) => { dialogTextShipmentCodeChange(e, value) }}
                                renderInput={(params) => <TextField {...params} label="" variant="outlined" style={{ borderRadius: "4px", border: isValid.ShipmentCode ? "1px solid pink" : "1px solid #707070" }} />}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px", marginLeft: "5.3%" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textlabelSpanDialog}>出荷先名称</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "604px", height: "25px", borderRadius: "4px", borderColor: isValid.DestName ? "pink" : "#707070" }}
                                value={dialogText.DestName || ""}
                                name="DestName"
                                id="dialogTextDestName"
                                disabled={DilogDetillDisabled.dialogTextDestName}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "40px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "140px" }}>
                            <span className={classes.textRequired}></span>
                            <span className={classes.textlabelSpanDialog}>EU(ARB管理)コード</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <Autocomplete
                                className={classes.autocomplete}
                                sx={{
                                    display: 'inline-block',
                                    '& input': {
                                        width: 200,
                                        height: 0,
                                        bgcolor: 'background.paper',
                                        color: (theme) =>
                                            theme.palette.getContrastText(theme.palette.background.paper),
                                    },
                                }}
                                options={EUARBmgmtCodeList}
                                style={{ width: "200px", height: "25px", borderRadius: "4px", marginLeft: "5.3%", }}
                                value={dialogText.EUARBmgmtCode || ""}
                                name="EUARBmgmtCode"
                                id="dialogTextEUARBmgmtCode"
                                disabled={DilogDetillDisabled.dialogTextEUARBmgmtCode}
                                onChange={(e, value) => { dialogTextEUARBmgmtCodeChange(e, value) }}
                                renderInput={(params) => <TextField {...params} label="" variant="outlined" style={{ borderRadius: "4px", border:isValid.EUARBmgmtCode ? "1px solid pink" : "1px solid #707070" }} />}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px", marginLeft: "5.3%" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textRequired}></span>
                            <span className={classes.textlabelSpanDialog}>エンドユーザー名称</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "604px", height: "25px", borderRadius: "4px", borderColor: "#707070" }}
                                value={dialogText.EndUserName || ""}
                                name="EndUserName"
                                id="dialogTextEndUserName"
                                disabled={DilogDetillDisabled.dialogTextEndUserName}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "40px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "142px" }}>
                            <span className={classes.textlabelSpanDialog}>DTエンドユーザー</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "200px", height: "25px", borderRadius: "4px", borderColor: isValid.EndUserName ? "pink" : "#707070" }}
                                value={dialogText.TorayEndUser || ""}
                                name="TorayEndUser"
                                id="dialogTextTorayEndUser"
                                disabled={DilogDetillDisabled.dialogTextTorayEndUser}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px", marginLeft: "3%" }}>
                        <label className={classes.textlabelDialog}>
                            <span className={classes.textlabelSpanDialog}>DTエンドユーザー名</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "604px", height: "25px", borderRadius: "4px", borderColor: isValid.TorayEndUserName ? "pink" : "#707070" }}
                                value={dialogText.TorayEndUserName || ""}
                                name="TorayEndUserName"
                                id="dialogTextTorayEndUserName"
                                disabled={DilogDetillDisabled.dialogTextTorayEndUserName}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "40px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "60px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>容量</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.Capacity ? "pink" : "#707070" }}
                                value={dialogText.Capacity || ""}
                                name="Capacity"
                                id="dialogTextCapacity"
                                disabled={DilogDetillDisabled.dialogTextCapacity}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "90px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>容量単位</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.CapacityUnit ? "pink" : "#707070" }}
                                value={dialogText.CapacityUnit || ""}
                                name="CapacityUnit"
                                id="dialogTextCapacityUnit"
                                disabled={DilogDetillDisabled.dialogTextCapacityUnit}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "90px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>価格単位</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PriceUnit ? "pink" : "#707070" }}
                                value={dialogText.PriceUnit || ""}
                                name="PriceUnit"
                                id="dialogTextPriceUnit"
                                disabled={DilogDetillDisabled.dialogTextPriceUnit}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "90px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>規定数量</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.RegQty ? "pink" : "#707070" }}
                                value={dialogText.RegQty || ""}
                                name="RegQty"
                                id="dialogTextRegQty"
                                disabled={DilogDetillDisabled.dialogTextRegQty}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "90px" }}>
                            <span className={classes.textRequired}></span>
                            <span className={classes.textlabelSpanDialog}>1注文可能数</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.OrdQty ? "pink" : "#707070" }}
                                value={dialogText.OrdQty || ""}
                                name="OrdQty"
                                id="dialogTextOrdQty"
                                disabled={DilogDetillDisabled.dialogTextOrdQty}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "230px" }}>
                            <span className={classes.textRequired}></span>
                            <span className={classes.textlabelSpanDialog}>DT直送/受注後手配品(輸入品含む)</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "145px", height: "25px", borderRadius: "4px", borderColor: isValid.DTDelOrderRec ? "pink" : "#707070" }}
                                value={dialogText.DTDelOrderRec || ""}
                                name="DTDelOrderRec"
                                id="dialogTextDTDelOrderRec"
                                disabled={DilogDetillDisabled.dialogTextDTDelOrderRec}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div style={{ display: "flex", height: "40px" }}>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "140px" }}>
                            <span className={classes.textRequired}></span>
                            <span className={classes.textlabelSpanDialog}>価格種別（販売）</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PriceTypeSales ? "pink" : "#707070" }}
                                value={dialogText.PriceTypeSales ? dialogText.PriceTypeSales : ""}
                                name="PriceTypeSales"
                                id="dialogTextPriceTypeSales"
                                disabled={DilogDetillDisabled.dialogTextPriceTypeSales}
                                onChange={(e) => { dialogTextChange(e) }}
                                onBlur={(e) => blurfun(e)}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "140px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>価格種別（仕入）</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PriceTypePurchase ? "pink" : "#707070" }}
                                value={dialogText.PriceTypePurchase || ""}
                                name="PriceTypePurchase"
                                id="dialogTextPriceTypePurchase"
                                disabled={DilogDetillDisabled.dialogTextPriceTypePurchase}
                                onChange={(e) => { dialogTextChange(e) }}
                                onBlur={(e) => blurfun(e)}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "180px" }}>
                            <span className={classes.textRequired}>*</span>
                            <span className={classes.textlabelSpanDialog}>出荷単位時のﾊﾞﾗ販売可否</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block", marginLeft:"10px"}}>
                            <FormControlsk fullWidth>
                             <Select
                                 className="MuiSelect-filled.MuiSelect-filled"
                                 style={{ width: "110px", height: "25px", borderRadius: "4px",
                                 border: "1px solid",
                                 borderColor: isValid.BulkSales ? "pink" : "#707070" }}
                                 value={dialogText.BulkSales || ""}
                                 name="BulkSales"
                                 id="dialogTextBulkSales"
                                 disabled={DilogDetillDisabled.dialogTextBulkSales}
                                onChange={(e) => { dialogTextBulkSalesChange(e) }}
                               >
                                 <MenuItem key="0" value="0" >0:バラ不可</MenuItem>
                                 <MenuItem key="1" value="1">1:バラ可</MenuItem>
                               </Select>
                               </FormControlsk>
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "140px" }}>
                            <span className={classes.textlabelSpanDialog}>ﾊﾞﾗ出荷手数料</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.BulkShippingFee ? "pink" : "#707070" }}
                                value={dialogText.BulkShippingFee ? numtomoney(moneytonum(dialogText.BulkShippingFee)) : ""}
                                name="BulkShippingFee"
                                id="dialogTextBulkShippingFee"
                                disabled={DilogDetillDisabled.dialogTextBulkShippingFee}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
                <div className={classes.textDivDialog}>
                    <InputGroup className="mb-3" style={{ height: "30px" }}>
                        <label className={classes.textlabelDialog} style={{ width: "142px" }}>
                            <span className={classes.textlabelSpanDialog}>クール便送料</span>
                        </label>
                        <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.CoolDeliveryCharge ? "pink" : "#707070" }}
                                value={dialogText.CoolDeliveryCharge ? numtomoney(moneytonum(dialogText.CoolDeliveryCharge)) : ""}
                                name="CoolDeliveryCharge"
                                id="dialogTextCoolDeliveryCharge"
                                disabled={DilogDetillDisabled.dialogTextCoolDeliveryCharge}
                                onChange={(e) => { dialogTextChange(e) }}
                            />
                        </span>
                    </InputGroup>
                </div>
            </div>
            <div className={classes.fristBorder}>
                <div className={classes.fristBorderDiv} style={{ width: "55%" }}>
                    <div style={{ textAlign: "center" }}>Price</div>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "70px", textAlign: "center", lineHeight: "63px" }}>販売単価</div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span className={classes.textRequired}>*</span>
                                <span>Scale1</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesUnitPriceScale1 ? "pink" : "#707070" }}
                                    value={dialogText.SalesUnitPriceScale1 ? numtomoney(moneytonum(dialogText.SalesUnitPriceScale1)) : ""}
                                    name="SalesUnitPriceScale1"
                                    id="dialogTextSalesUnitPriceScale1"
                                    disabled={DilogDetillDisabled.dialogTextSalesUnitPriceScale1}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale2</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesUnitPriceScale2 ? "pink" : "#707070" }}
                                    value={numtomoney(moneytonum(dialogText.SalesUnitPriceScale2)) || ""}
                                    name="SalesUnitPriceScale2"
                                    id="dialogTextSalesUnitPriceScale2"
                                    disabled={DilogDetillDisabled.dialogTextSalesUnitPriceScale2}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale3</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesUnitPriceScale3 ? "pink" : "#707070" }}
                                    value={numtomoney(moneytonum(dialogText.SalesUnitPriceScale3)) || ""}
                                    name="SalesUnitPriceScale3"
                                    id="dialogTextSalesUnitPriceScale3"
                                    disabled={DilogDetillDisabled.dialogTextSalesUnitPriceScale3}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale4</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesUnitPriceScale4 ? "pink" : "#707070" }}
                                    value={numtomoney(moneytonum(dialogText.SalesUnitPriceScale4)) || ""}
                                    name="SalesUnitPriceScale4"
                                    id="dialogTextSalesUnitPriceScale4"
                                    disabled={DilogDetillDisabled.dialogTextSalesUnitPriceScale4}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale5</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesUnitPriceScale5 ? "pink" : "#707070" }}
                                    value={numtomoney(moneytonum(dialogText.SalesUnitPriceScale5)) || ""}
                                    name="SalesUnitPriceScale5"
                                    id="dialogTextSalesUnitPriceScale5"
                                    disabled={DilogDetillDisabled.dialogTextSalesUnitPriceScale5}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div className={classes.fristBorderDiv} style={{ borderLeft: "3px solid #DDD" }}>
                    <div style={{ textAlign: "center" }}>Unit</div>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale1</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesUnitPriceUnitScale1 ? "pink" : "#707070" }}
                                    value={dialogText.SalesUnitPriceUnitScale1 || ""}
                                    name="SalesUnitPriceUnitScale1"
                                    id="dialogTextSalesUnitPriceUnitScale1"
                                    disabled={DilogDetillDisabled.dialogTextSalesUnitPriceUnitScale1}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale2</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesUnitPriceUnitScale2 ? "pink" : "#707070" }}
                                    value={dialogText.SalesUnitPriceUnitScale2 || ""}
                                    name="SalesUnitPriceUnitScale2"
                                    id="dialogTextSalesUnitPriceUnitScale2"
                                    disabled={DilogDetillDisabled.dialogTextSalesUnitPriceUnitScale2}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale3</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesUnitPriceUnitScale3 ? "pink" : "#707070" }}
                                    value={dialogText.SalesUnitPriceUnitScale3 || ""}
                                    name="SalesUnitPriceUnitScale3"
                                    id="dialogTextSalesUnitPriceUnitScale3"
                                    disabled={DilogDetillDisabled.dialogTextSalesUnitPriceUnitScale3}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale4</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesUnitPriceUnitScale4 ? "pink" : "#707070" }}
                                    value={dialogText.SalesUnitPriceUnitScale4 || ""}
                                    name="SalesUnitPriceUnitScale4"
                                    id="dialogTextSalesUnitPriceUnitScale4"
                                    disabled={DilogDetillDisabled.dialogTextSalesUnitPriceUnitScale4}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale5</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesUnitPriceUnitScale5 ? "pink" : "#707070" }}
                                    value={dialogText.SalesUnitPriceUnitScale5 || ""}
                                    name="SalesUnitPriceUnitScale5"
                                    id="dialogTextSalesUnitPriceUnitScale5"
                                    disabled={DilogDetillDisabled.dialogTextSalesUnitPriceUnitScale5}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: "10px", width: "100%", }}></div>
            <div className={classes.fristBorder}>
                <div className={classes.fristBorderDiv} style={{ width: "55%" }}>
                    <div style={{ textAlign: "center" }}>Price</div>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "70px", textAlign: "center", lineHeight: "63px" }}>仕入単価</div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span className={classes.textRequired}>*</span>
                                <span>Scale1</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchUnitPriceScale1 ? "pink" : "#707070" }}
                                    value={numtomoney(moneytonum(dialogText.PurchUnitPriceScale1)) || ""}
                                    name="PurchUnitPriceScale1"
                                    id="dialogTextPurchUnitPriceScale1"
                                    disabled={DilogDetillDisabled.dialogTextPurchUnitPriceScale1}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale2</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchUnitPriceScale2 ? "pink" : "#707070" }}
                                    value={numtomoney(moneytonum(dialogText.PurchUnitPriceScale2)) || ""}
                                    name="PurchUnitPriceScale2"
                                    id="dialogTextPurchUnitPriceScale2"
                                    disabled={DilogDetillDisabled.dialogTextPurchUnitPriceScale2}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale3</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchUnitPriceScale3 ? "pink" : "#707070" }}
                                    value={numtomoney(moneytonum(dialogText.PurchUnitPriceScale3)) || ""}
                                    name="PurchUnitPriceScale3"
                                    id="dialogTextPurchUnitPriceScale3"
                                    disabled={DilogDetillDisabled.dialogTextPurchUnitPriceScale3}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale4</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchUnitPriceScale4 ? "pink" : "#707070" }}
                                    value={numtomoney(moneytonum(dialogText.PurchUnitPriceScale4)) || ""}
                                    name="PurchUnitPriceScale4"
                                    id="dialogTextPurchUnitPriceScale4"
                                    disabled={DilogDetillDisabled.dialogTextPurchUnitPriceScale4}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale5</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchUnitPriceScale5 ? "pink" : "#707070" }}
                                    value={numtomoney(moneytonum(dialogText.PurchUnitPriceScale5)) || ""}
                                    name="PurchUnitPriceScale5"
                                    id="dialogTextPurchUnitPriceScale5"
                                    disabled={DilogDetillDisabled.dialogTextPurchUnitPriceScale5}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div className={classes.fristBorderDiv} style={{ borderLeft: "3px solid #DDD" }}>
                    <div style={{ textAlign: "center" }}>Unit</div>
                    <div style={{ display: "flex" }}>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale1</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchUnitPriceUnitScale1 ? "pink" : "#707070" }}
                                    value={dialogText.PurchUnitPriceUnitScale1 || ""}
                                    name="PurchUnitPriceUnitScale1"
                                    id="dialogTextPurchUnitPriceUnitScale1"
                                    disabled={DilogDetillDisabled.dialogTextPurchUnitPriceUnitScale1}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale2</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchUnitPriceUnitScale2 ? "pink" : "#707070" }}
                                    value={dialogText.PurchUnitPriceUnitScale2 || ""}
                                    name="PurchUnitPriceUnitScale2"
                                    id="dialogTextPurchUnitPriceUnitScale2"
                                    disabled={DilogDetillDisabled.dialogTextPurchUnitPriceUnitScale2}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale3</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchUnitPriceUnitScale3 ? "pink" : "#707070" }}
                                    value={dialogText.PurchUnitPriceUnitScale3 || ""}
                                    name="PurchUnitPriceUnitScale3"
                                    id="dialogTextPurchUnitPriceUnitScale3"
                                    disabled={DilogDetillDisabled.dialogTextPurchUnitPriceUnitScale3}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale4</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchUnitPriceUnitScale4 ? "pink" : "#707070" }}
                                    value={dialogText.PurchUnitPriceUnitScale4 || ""}
                                    name="PurchUnitPriceUnitScale4"
                                    id="dialogTextPurchUnitPriceUnitScale4"
                                    disabled={DilogDetillDisabled.dialogTextPurchUnitPriceUnitScale4}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ width: "105px" }}>
                            <div style={{ textAlign: "center" }}>
                                <span>Scale5</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "89px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchUnitPriceUnitScale5 ? "pink" : "#707070" }}
                                    value={dialogText.PurchUnitPriceUnitScale5 || ""}
                                    name="PurchUnitPriceUnitScale5"
                                    id="dialogTextPurchUnitPriceUnitScale5"
                                    disabled={DilogDetillDisabled.dialogTextPurchUnitPriceUnitScale5}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: "10px", width: "100%", }}></div>
            <div style={{ display: "flex", height: "90px", width: "100%" }}>
                <div style={{ width: "296px", height: "100%", border: "3px solid #DDD", marginLeft: "30px", textAlign: "center" }}>
                    <div>有効期限</div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>
                                <span className={classes.textRequired}>*</span>
                                <span>開始</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <TextField
                                    label=""
                                    type="date"
                                    className={classes.textField}
                                    style={{ width: "130px", border: "1px solid", height: "25px", borderRadius: "4px", borderColor: isValid.ExpirationStart ? "pink" : "#707070" }}
                                    value={dialogText.ExpirationStart || ""}
                                    name="ExpirationStart"
                                    InputProps={{
                                        disableUnderline: true, style: { fontSize: "14px", height: "25px"}
                                    }}
                                    id="dialogTextExpirationStart"
                                    disabled={DilogDetillDisabled.dialogTextExpirationStart}
                                    onKeyPress={(e) => { e.preventDefault(); return false; }}
                                    onChange={(e) => handleSetExpirationStart(e)}
                                />
                            </span>
                        </div>
                        <div style={{ lineHeight: "63px", marginLeft: "8px" }}>～</div>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>終了</div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <TextField
                                    label=""
                                    type="date"
                                    className={classes.textField}
                                    style={{ width: "130px", border: "1px solid", height: "25px", borderRadius: "4px", borderColor: isValid.Expired ? "pink" : "#707070" }}
                                    value={dialogText.Expired}
                                    name="Expired"
                                    id="dialogTextExpired"
                                    disabled={DilogDetillDisabled.dialogTextExpired}
                                    onChange={(e) => handleSetExpired(e)}
                                    onKeyPress={(e) => { e.preventDefault(); return false; }}
                                    InputProps={{
                                        disableUnderline: true, style: { fontSize: "14px", height: "25px" }
                                    }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div style={{ width: "296px", height: "100%", border: "3px solid #DDD", marginLeft: "10px", textAlign: "center" }}>
                    <div>売上計上</div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>
                                <span className={classes.textRequired}>*</span>
                                <span>日付</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <TextField
                                    label=""
                                    type="date"
                                    className={classes.textField}
                                    style={{ width: "130px", border: "1px solid", height: "25px", borderRadius: "4px", borderColor: isValid.RecordSalesDate ? "pink" : "#707070" }}
                                    value={dialogText.RecordSalesDate}
                                    name="RecordSalesDate"
                                    id="dialogTextRecordSalesDate"
                                    disabled={DilogDetillDisabled.dialogTextRecordSalesDate}
                                    onKeyPress={(e) => { e.preventDefault(); return false; }}
                                    onChange={(e) => handleSetRecordSalesDate(e)}
                                    InputProps={{
                                        disableUnderline: true, style: { fontSize: "14px", height: "25px" }
                                    }}
                                />
                            </span>
                        </div>
                        <div style={{ lineHeight: "63px", marginLeft: "8px" }}>　</div>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>
                                <span className={classes.textRequired}>*</span>
                                <span>適用</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                 <FormControlsk fullWidth>
                               <Select
                                 className="MuiSelect-filled.MuiSelect-filled"
                                 style={{ width: "130px", border: "1px solid", height: "25px", borderRadius: "4px", borderColor: isValid.RecordSalesApplicable ? "pink" : "#707070" }}
                                 value={dialogText.RecordSalesApplicable}
                                 name="RecordSalesApplicable"
                                 id="dialogTextRecordSalesApplicable"
                                 onChange={(e) => handleSetRecordSalesApplicable(e)}
                                 disabled={DilogDetillDisabled.dialogTextRecordSalesApplicable}
                               >
                                 <MenuItem value="" key=""></MenuItem>
                                 <MenuItem
                                   key={"受注日"}
                                   value={"受注日"}
                                 >
                                   {"受注日"}
                                 </MenuItem>
                                 <MenuItem
                                   key={"出荷日"}
                                   value={"出荷日"}
                                 >
                                   {"出荷日"}
                                 </MenuItem>
                                 <MenuItem
                                   key={"納品日"}
                                   value={"納品日"}
                                 >
                                   {"納品日"}
                                 </MenuItem>
                               </Select>
                             </FormControlsk>
                            </span>
                        </div>
                    </div>
                </div>
                <div style={{ width: "296px", height: "100%", border: "3px solid #DDD", marginLeft: "10px", textAlign: "center" }}>
                    <div>仕入計上</div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>
                                <span className={classes.textRequired}>*</span>
                                <span>日付</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <TextField
                                    label=""
                                    type="date"
                                    className={classes.textField}
                                    style={{ width: "130px", border: "1px solid", height: "25px", borderRadius: "4px", borderColor: isValid.PurchaseAccountDate ? "pink" : "#707070" }}
                                    value={dialogText.PurchaseAccountDate}
                                    name="PurchaseAccountDate"
                                    id="dialogTextPurchaseAccountDate"
                                    disabled={DilogDetillDisabled.dialogTextPurchaseAccountDate}
                                    onKeyPress={(e) => { e.preventDefault(); return false; }}
                                    onChange={(e) => handleSetPurchaseAccountDate(e)}
                                    InputProps={{
                                        disableUnderline: true, style: { fontSize: "14px", height: "25px" }
                                    }}
                                />
                            </span>
                        </div>
                        <div style={{ lineHeight: "63px", marginLeft: "8px" }}>　</div>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>
                                <span className={classes.textRequired}>*</span>
                                <span>適用</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControlsk fullWidth>
                               <Select
                                 className="MuiSelect-filled.MuiSelect-filled"
                                 style={{
                                    width: "130px", height: "25px", borderRadius: "4px",
                                    border: "1px solid",
                                    borderColor: isValid.AcctForPurchasesApplicableble ? "pink" : "#707070"
                                }}
                                name="AcctForPurchasesApplicableble"
                                id="dialogTextAcctForPurchasesApplicableble"
                                value={dialogText.AcctForPurchasesApplicableble}
                                onChange={(e) => handleSetAcctForPurchasesApplicableble(e)}
                                disabled={DilogDetillDisabled.dialogTextAcctForPurchasesApplicableble}
                               >
                                 <MenuItem value="" key=""></MenuItem>
                                 <MenuItem
                                   key={"受注日"}
                                   value={"受注日"}
                                 >
                                   {"受注日"}
                                 </MenuItem>
                                 <MenuItem
                                   key={"出荷日"}
                                   value={"出荷日"}
                                 >
                                   {"出荷日"}
                                 </MenuItem>
                                 <MenuItem
                                   key={"納品日"}
                                   value={"納品日"}
                                 >
                                   {"納品日"}
                                 </MenuItem>
                               </Select>
                             </FormControlsk>
                            </span>
                        </div>
                    </div>
                </div>
                <div style={{ width: "330px", height: "100%", border: "3px solid #DDD", marginLeft: "10px", textAlign: "center" }}>
                    <div>販売実績情報</div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>前回受注年月日</div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "100px", height: "25px", borderRadius: "4px", borderColor: isValid.LastOrderDate ? "pink" : "#707070" }}
                                    value={dialogText.LastOrderDate || ""}
                                    name="LastOrderDate"
                                    id="dialogTextLastOrderDate"
                                    disabled={DilogDetillDisabled.dialogTextLastOrderDate}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ lineHeight: "63px", marginLeft: "8px", width: "14px" }}></div>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>WEB受注番号</div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "183px", height: "25px", borderRadius: "4px", borderColor: isValid.WebOrderNumber ? "pink" : "#707070" }}
                                    value={dialogText.WebOrderNumber || ""}
                                    name="WebOrderNumber"
                                    id="dialogTextWebOrderNumber"
                                    disabled={DilogDetillDisabled.dialogTextWebOrderNumber}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: "10px", width: "100%", }}></div>
            <div style={{ display: "flex", height: "90px", width: "100%" }}>
                <div style={{ width: "296px", height: "100%", border: "3px solid #DDD", marginLeft: "30px", textAlign: "center" }}>
                    <div>価格改訂(販売)</div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>
                                <span>改訂率</span>
                            </div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesRevRate ? "pink" : "#707070" }}
                                    value={dialogText.SalesRevRate || ""}
                                    name="SalesRevRate"
                                    id="dialogTextSalesRevRate"
                                    disabled={DilogDetillDisabled.dialogTextSalesRevRate}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ lineHeight: "63px", marginRight: "8px" }}>%</div>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>改訂額</div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.SalesRevPrice ? "pink" : "#707070" }}
                                    value={dialogText.SalesRevPrice || ""}
                                    name="SalesRevPrice"
                                    id="dialogTextSalesRevPrice"
                                    disabled={DilogDetillDisabled.dialogTextSalesRevPrice}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div style={{ width: "296px", height: "100%", border: "3px solid #DDD", marginLeft: "10px", textAlign: "center" }}>
                    <div>価格改訂(仕入)</div>
                    <div style={{ display: "flex", width: "100%" }}>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>改訂率</div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchaseRevRate ? "pink" : "#707070" }}
                                    value={dialogText.PurchaseRevRate || ""}
                                    name="PurchaseRevRate"
                                    id="dialogTextPurchaseRevRate"
                                    disabled={DilogDetillDisabled.dialogTextPurchaseRevRate}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                        <div style={{ lineHeight: "63px", marginRight: "8px" }}>%</div>
                        <div>
                            <div style={{ position: "relative", left: "5px" }}>改訂額</div>
                            <span className={classes.textDivDialog} style={{ display: "inline-block", width: "100%", textAlign: "center", position: "relative", left: "5px" }}>
                                <FormControl
                                    className={classes.textField}
                                    style={{ width: "110px", height: "25px", borderRadius: "4px", borderColor: isValid.PurchaseRevPrice ? "pink" : "#707070" }}
                                    value={dialogText.PurchaseRevPrice || ""}
                                    name="PurchaseRevPrice"
                                    id="dialogTextPurchaseRevPrice"
                                    disabled={DilogDetillDisabled.dialogTextPurchaseRevPrice}
                                    onChange={(e) => { dialogTextChange(e) }}
                                />
                            </span>
                        </div>
                    </div>
                </div>
                <div style={{ marginLeft: "10px" }}>
                    <div style={{ display: "flex" }}>
                        <div className={classes.textDivDialog}>
                            <InputGroup className="mb-3" style={{ height: "30px" }}>
                                <label className={classes.textlabelDialog} style={{ width: "80px" }}>
                                    <span className={classes.textRequired}>*</span>
                                    <span className={classes.textlabelSpanDialog}>実績フラグ</span>
                                </label>
                                <span className={classes.textDivDialog} style={{ display: "inline-block",marginLeft:"10px" }}>
                                    <FormControlsk fullWidth>
                                    <Select
                                 className="MuiSelect-filled.MuiSelect-filled"
                                 style={{ width: "120px", height: "25px", borderRadius: "4px",
                                 border: "1px solid",
                                 borderColor: isValid.ActualFlag ? "pink" : "#707070" }}
                                 value={dialogText.ActualFlag || ""}
                                 name="ActualFlag"
                                 id="dialogTextActualFlag"
                                 disabled={DilogDetillDisabled.dialogTextActualFlag}
                                onChange={(e) => { dialogTextActualFlagChange(e) }}
                               >
                                 <MenuItem key="0" value="0" >0:有効</MenuItem>
                                 <MenuItem key="1" value="1">1:営業確認</MenuItem>
                                 <MenuItem key="2" value="2" >2:使用不可</MenuItem>
                                 <MenuItem key="9" value="9">9:一時保存</MenuItem>
                               </Select>
                               </FormControlsk>
                                </span>
                            </InputGroup>
                        </div>
                        <div className={classes.textDivDialog}>
                            <InputGroup className="mb-3" style={{ height: "30px" }}>
                                <label className={classes.textlabelDialog} style={{ width: "80px" }}>
                                    <span className={classes.textRequired}>*</span>
                                    <span className={classes.textlabelSpanDialog}>履歴番号</span>
                                </label>
                                <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                                    <FormControl
                                        className={classes.textField}
                                        style={{ width: "70px", height: "25px", borderRadius: "4px", borderColor: isValid.HistoryNum ? "pink" : "#707070" }}
                                        value={dialogText.HistoryNum || ""}
                                        name="HistoryNum"
                                        id="dialogTextHistoryNum"
                                        disabled={DilogDetillDisabled.dialogTextHistoryNum}
                                        onChange={(e) => { dialogTextChange(e) }}
                                    />
                                </span>
                            </InputGroup>
                        </div>
                        <div className={classes.textDivDialog}>
                            <InputGroup className="mb-3" style={{ height: "30px" }}>
                                <label className={classes.textlabelDialog} style={{ width: "50px" }}>
                                    <span className={classes.textlabelSpanDialog}>Seq</span>
                                </label>
                                <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                                    <FormControl
                                        className={classes.textField}
                                        style={{ width: "119px", height: "25px", borderRadius: "4px", borderColor: isValid.SeqNo ? "pink" : "#707070" }}
                                        value={dialogText.SeqNo || ""}
                                        name="SeqNo"
                                        id="dialogTextSeqNo"
                                        disabled={DilogDetillDisabled.dialogTextSeqNo}
                                        onChange={(e) => { dialogTextChange(e) }}
                                    />
                                </span>
                            </InputGroup>
                        </div>
                    </div>
                    <div className={classes.textDivDialog} style={{ marginTop: "5px" }}>
                        <InputGroup className="mb-3" style={{ height: "30px" }}>
                            <label className={classes.textlabelDialog} style={{ width: "80px", textAlign: "left" }}>
                                <span className={classes.textlabelSpanDialog}>備考</span>
                            </label>
                            <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                                <FormControl
                                    className={classes.textField}
                                    as="textarea"
                                    style={{ width: "500px", height: "70px", borderRadius: "4px", resize: "none", borderColor: isValid.Remarks ? "pink" : "#707070" }}
                                    value={dialogText.Remarks || ""}
                                    name="Remarks"
                                    id="dialogTextRemarks"
                                    disabled={DilogDetillDisabled.dialogTextRemarks}
                                    onChange={(e) => { dialogTextChange(e) }}
                                    placeholder="200文字以内で入力してください"
                                    maxLength={200}
                                    onKeyDown={(e)=>handleKeyCode(e)}
                                />
                            </span>
                        </InputGroup>
                    </div>
                </div>
            </div>
            <div style={{ width: "100%", height: "25px" }}></div>
            <div style={{ width: "100%", height: "40px", textAlign: "right" }}>
                <Button className="btn" disabled={disabledBtn.BusinessDependence} onClick={() => SalesConfirmClick()} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "RGB(255,102,0)", border: "1px solid #000", marginRight: '10px', borderRadius: '8px' }}>営業依頼
                </Button>
                <Button className="btn" onClick={() => param.closeDilogDetill()} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "#000", marginRight: '10px', borderRadius: '8px' }}>戻&nbsp;&nbsp;&nbsp;る
                </Button>
                <Button className="btn" disabled={disabledBtn.SaveTemporarily} onClick={() => dialogOnSubmit(false)} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "#000", marginRight: '10px', borderRadius: '8px' }}>一時保存
                </Button>
                <Button className="btn" onClick={() => dialogOnSubmit(true)} style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "red", border: "1px solid #000", marginRight: '50px', borderRadius: '8px' }}>登&nbsp;&nbsp;&nbsp;録
                </Button>
            </div>
            <div style={{ position: 'fixed',top: '50%', left: "50%", display: searchProgress === true ? "block" : "none" }}>
                <CircularProgress/>
            </div>
            <Dialog
                onClose={handleConfirmClose}
                aria-labelledby="customized-dialog-title"
                open={openConfirm}
                PaperProps={{
                    style: {
                        backgroundColor: '#FFCC00',
                        boxShadow: 'none',
                        width: "533px",
                        height: "220px"
                    },
                }}>
                <DialogContent dividers style={{ textAlign: "center", color: "white" }}>
                    <Typography gutterBottom variant="h6" style={{ color: "white", marginTop: "10px" }} className={classes.textFieldDialog}>
                        {errorcode}
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textFieldDialog}>
                        {msg1Text}
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textField2Dialog}>
                        {msg2Text}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        handleConfirmOK();
                    }} color="primary" variant="contained">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}