import React, { useEffect, useReducer } from "react";
import { InputGroup, FormControl, Container } from "react-bootstrap";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, InputBase } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import ArToast from "../components/ArToast";
import { API, graphqlOperation } from "aws-amplify";
import { arCustomerByAgencyID, getArAZCustomerCode } from "../graphql/queries";
import { createArCustomer, CreateArCustomerAddress } from "../graphql/mutations";
import { green } from "@material-ui/core/colors";
import { Row, Col } from "react-bootstrap";
import { FormControl as FormControlsk } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { Select, MenuItem } from "@material-ui/core";

import { useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
  },
  content: {
    textAlign: "center",
    maxWidth: "50%",
    margin: "auto",
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  rootl: {
    maxWidth: "100%",
  },
  card1: {
    maxWidth: "50%",
    minWidth: "50%",
    width: "50%",
  },
  card2: {
    maxWidth: "100%",
  },
  table: {
    minWidth: 700,
  },
  right: {
    position: "absolute",
    right: "25%",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "20ch",
    backgroundColor: "transparent",
  },
  textFieldNm: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "60ch",
  },
  textKField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "60ch",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  // avatar: {
  //   backgroundColor: red[500],
  // },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: '0px',
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);
const QUERY = "QUERY";
// let CustomerAccountGroup = "";
// let HonorificTitleKey = "";
let name1 = "";
let name2 = "";
let name3 = "";
let name4 = "";
let city = "";
let SearchTerm = "";
let PlaceName = "";
let PlaceName3 = "";
let PostCode = "";
// let CountryCode = "";
// let LanguageCode = "";
let FirstPhoneNumber = "";
let faxNumber = "";
let agencyId = "";
let OrderRecipientCodeId = "";
let OrderRecipientName = "";
let type = "";
let TransactionClassification = "";
const initialcontractor = { Invs: [] };


export default function AddAddress() {

    const reducercontractor = (state, action) => {
        let obj = [];
        switch (action.type) {
            case QUERY:
        for (let i = 0; i < action.Invs.length; i++) {
          obj.push(action.Invs[i].CustomerCode + ":" + action.Invs[i].Name1);
                    if (i === 0) {
                        handlecontractorId(action.Invs[0].CustomerCode);
                        OrderRecipientName = action.Invs[i].Name1
                    }
                }
                setContractor(obj[0]);
                return { ...state, Invs: obj };
            default:
                return state;
        }
    };
    function getStyles(name, contractorGroup, theme) {
        return {
            fontWeight:
                contractorGroup.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 100 * 4.5 + 9,
                width: 400,
            },
        },
    };

  const [contractorGroup, setContractor] = React.useState("");
  const theme = useTheme();
  const [toast, setToast] = React.useState({
    open: false,
    message: "send Email success",
    severity: "error",
  });
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const location = useLocation();
  const handlecontractorId = (Object) => {
    OrderRecipientCodeId = Object;
  };

  let [selectContractorOptions, setContractorGroup] = useReducer(
    reducercontractor,
    initialcontractor
  );
  useEffect(async () => {
    agencyId = location.state.CustomerCodeKey;
    type = location.state.type;
    const ContractorList = await API.graphql(
      graphqlOperation(arCustomerByAgencyID, {
        CustomerCodeKey: agencyId,
        filter: { AccounKey: { eq: "SP" } },
      })
    );
    if (ContractorList.data.arCustomerByAgencyID.items.length > 0) {
      setContractorGroup({
        type: QUERY,
        Invs: ContractorList.data.arCustomerByAgencyID.items,
      });
    }
    if (agencyId === "" || agencyId === undefined) {
      setToast({
        open: true,
        message: "error",
        severity: "error",
      });
    }
  }, []);

    const handleContractorChange = (event) => {
        setContractor(event.target.value);
        let Contractor_temp = event.target.value;
        let ContractorID = Contractor_temp.split(":");
        handlecontractorId(ContractorID[0]);
        OrderRecipientName = ContractorID[1]
    };

    const handleCreateAddress = () => {
        setLoading(true);
        let errorMsg = "";
        if (name1 === '') {
            errorMsg = "　※名称1を入力してください";
        }
        else if (name2 === '') {
            errorMsg = "　※名称2を入力してください";
        }
        else if (name3 === '') {
            errorMsg = "　※名称3を入力してください";
        }
        else if (SearchTerm === '') {
            errorMsg = "　※検索語句1を入力してください";
        }
        else if (PlaceName === '') {
            errorMsg = "　※地名を入力してください";
        }
        else if (PostCode === '') {
            errorMsg = "　※郵便番号を入力してください";
        }
        else if (FirstPhoneNumber === '') {
            errorMsg = "　※電話番号を入力してください";
        }
        if (errorMsg !== '') {
            setToast({
                open: true,
                message: errorMsg,
                severity: "error"
            })
            setLoading(false);
            return
        }
        createAddress();
    }
    const createAddress = async () => {
        if (type === 'enduser') {
            let ArAzInfo = await API.graphql(graphqlOperation(getArAZCustomerCode, { id: '1' }));
            let codeNumber = ArAzInfo.data.getArAZCustomerCode['CustomerCodeNumber'];
            let initialCode = 'AZ00000001';
            initialCode = initialCode.slice(0, initialCode.length - codeNumber.toString().length) + codeNumber
            await API.graphql(graphqlOperation(createArCustomer, {
                input: {
                    CustomerCodeKey: agencyId,
                    EndUserCode: initialCode,
                    Name1: name1,
                    Name2: name2,
                    Name3: name3,
                    Name4: name4,
                    SearchTerm: SearchTerm,
                    OrderRecipientCode: OrderRecipientCodeId,
                    OrderRecipientName: OrderRecipientName,
                    OutFlag: '1',
                }
            }));
            setLoading(false);
            history.back();
        } else {
            let ArAzInfo = await API.graphql(graphqlOperation(getArAZCustomerCode, { id: '1' }));
            let codeNumber = ArAzInfo.data.getArAZCustomerCode['CustomerCodeNumber'];
            let initialCode = 'WD00000000';
            initialCode = initialCode.slice(0, initialCode.length - codeNumber.toString().length) + codeNumber
            let CustomerAddress = await API.graphql(graphqlOperation(CreateArCustomerAddress, {
                input: {
                    CustomerReceiptCode: initialCode,
                    Name1: name1,
                    Name2: name2,
                    Name3: name3,
                    Name4: name4,
                    SearchTerm1: SearchTerm,
                    PlaceName: PlaceName,
                    PlaceName3: PlaceName3,
                    PostCode: PostCode,
                    Area: '東京都',
                    AreaCode: '13',
                    city: city,
                    Tel: FirstPhoneNumber,
                    Fax: faxNumber,
                    OrderRecipientCode: OrderRecipientCodeId,
                    TransactionClassification: TransactionClassification,
                    OutFlag: '1',
                    CustomerCodeKey: agencyId,
                }
            }));
            console.log("CustomerAddress", CustomerAddress)
            setLoading(false);
            history.back();
        }
    }

    return (
        <Container style={{ 'minWidth': '85vw', 'minHeight': '84vh' }} >
            <Grid>
                <Row
                    style={{
                        borderRadius: "0.25em",
                        color: "purple",
                        //textAlign: 'center',
                        padding: "0.5em",
                    }}
                >
                    <Col>
                        <h3>出荷先新規申請</h3>
                    </Col>
                </Row>
            </Grid>
            <div className={classes.content} >

                <InputGroup className="mb-3" style={{ marginTop: '10px' }}>
                    <InputGroup.Text className={classes.textField} id="basic-name">受注先：</InputGroup.Text>
                    <FormControlsk className={classes.textKField} >
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={contractorGroup}
                            onChange={handleContractorChange}
                            input={<BootstrapInput />}
                            MenuProps={MenuProps}
                            autoWidth
                        >
                            {selectContractorOptions.Invs.map((name) => (
                                <MenuItem
                                    key={name}
                                    value={name}
                                    style={getStyles(name, contractorGroup, theme)}
                                >
                                    {name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControlsk>
                </InputGroup>


                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">出荷先名称</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="NameDialog"
                        className={classes.textField}
                        onChange={(e) => { name1 = e.target.value }}
                        maxLength="40"
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">出荷先正式名称</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="Name1Dialog"
                        className={classes.textField}
                        onChange={(e) => { name2 = e.target.value }}
                        maxLength="40"
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">出荷先名称2</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="Name2Dialog"
                        className={classes.textField}
                        onChange={(e) => { name3 = e.target.value }}
                        maxLength="40"
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">出荷先名称3</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="Name3Dialog"
                        className={classes.textField}
                        onChange={(e) => { name4 = e.target.value }}
                        maxLength="40"
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">検索語句1</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="SearchTerm1Dialog"
                        className={classes.textField}
                        onChange={(e) => { SearchTerm = e.target.value }}
                        maxLength="20"
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">郵便番号</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="PostCode"
                        className={classes.textField}
                        onChange={(e) => { PostCode = e.target.value }}
                        maxLength="10"
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">地域 (都道府県)</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="AreaDialog"
                        className={classes.textField}
                        value="13：東京都"
                        readOnly={true}
                        maxLength="20"
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">地名</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="PlaceName"
                        className={classes.textField}
                        onChange={(e) => { PlaceName = e.target.value }}
                        maxLength="60"
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">市区町村</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="city"
                        className={classes.textField}
                        onChange={(e) => { city = e.target.value }}
                        maxLength="40"
                    />
                </InputGroup>
                <InputGroup className="mb-3" style={{ display: 'none' }}>
                    <InputGroup.Text className={classes.textField} id="basic-name" >地名3</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="name4"
                        className={classes.textField}
                        onChange={(e) => { PlaceName3 = e.target.value }}
                        maxLength="40"
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">電話番号</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="FirstPhoneNumberDialog"
                        className={classes.textField}
                        onChange={(e) => { FirstPhoneNumber = e.target.value }}
                        maxLength="30"
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">FAX</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="faxNumber"
                        className={classes.textField}
                        onChange={(e) => { faxNumber = e.target.value }}
                        maxLength="30"
                    />
                </InputGroup>
                <InputGroup className="mb-3">
                    <InputGroup.Text className={classes.textField} id="basic-name">取引区分</InputGroup.Text>
                    <FormControl
                        placeholder=""
                        aria-label=""
                        aria-describedby="basic-name"
                        id="LanguageCodeDialog"
                        className={classes.textField}
                        onChange={(e) => { TransactionClassification = e.target.value }}
                        maxLength="1"
                    />
                </InputGroup>
                <div className={classes.right}>
                    <div className={classes.wrapper}>
                        {/* <Button color='primary' variant='contained' onClick={handleCreateAddress} disabled={loading}>出荷先新規申請</Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />} */}
                        <Button
                            className="btn btn-danger"
                            onClick={handleCreateAddress}
                            disabled={loading}
                        >
                            登録
                        </Button>
                        {loading && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}
                    </div>
                </div>
            </div>
            <ArToast
                open={toast.open}
                message={toast.message}
                autoHideDuration={1500}
                severity={toast.severity}
                handleClose={() => {
                    setToast({ open: false });
                }}
            />
        </Container>
    )
}
