import { InputGroup, FormControl } from "react-bootstrap";
import useStyles from "./ArPriceInfoStyle";
import React, {useState,useEffect} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import API, { graphqlOperation } from "@aws-amplify/api";
import { Auth }from "aws-amplify";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import {customerByeMail} from"../../../graphql/queries"
import CircularProgress from '@material-ui/core/CircularProgress';
export default function ArPriceListMasterDownload(param) {
    const classes = useStyles()
    const [InputValue,setInputValue] = useState("")
    const [isValid,setIsValid] = useState(false)
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [searchProgress, setSearchProgress] = useState(false);
    const handleClickConfirmOpen = () => {
        setOpenConfirm(true);
    };
    const handleConfirmClose = () => {
        setOpenConfirm(false);
    };
    const handleConfirmOK = async () => {
        setOpenConfirm(false);
    }
    const [errorcode, setErrorCode] = React.useState(Object);
    const handleSetErrorcode = (Object) => {
        setErrorCode(Object);
    };
    const [msg1Text, setMsg1Text] = React.useState(Object);
    const handleSetMsg1Text = (Object) => {
        setMsg1Text(Object);
    };
    const [msg2Text, setMsg2Text] = React.useState(Object);
    const handleSetMsg2Text = (Object) => {
        setMsg2Text(Object);
    };
    const submit = ()=>{
        if(!InputValue){
            handleSetErrorcode("エラーコード：1014");
            handleSetMsg1Text("必須入力項目");
            handleSetMsg2Text("データ格納場所指定を入力してください");
            handleClickConfirmOpen();
            setIsValid(true)
            return;
        }
        param.closedialogListMasterDownload()
        setIsValid(false)
    }
    const TextChange = (e)=>{
        setInputValue(e.target.value)
        if(e.target.value){
        setIsValid(false)
        }
    }
    const signOut = async () => {
        await Auth.signOut();
        try {
            await Auth.signOut();
        } catch (error) {
            console.log("error signing out: ", error);
        }
        document.location.reload();
    };
    useEffect(async() => {
        setSearchProgress(true)
        let user = ""
        try {
            user = await Auth.currentUserInfo();
        } catch (error) {
            if (error.message === 'No current user') {
                //ユーザープールに障害が発生した場合
                console.log('User pool is invalid.Please reauthenticate.');
                //ログイン画面に戻る
                signOut();
            } else {
                //他のエラーを処理する
                console.log('An error occurred:', error);
            }
        }
         const agencyPriceGroup = await API.graphql(
           graphqlOperation(customerByeMail, {
             Agency_Email: user.attributes.email,
           })
         );
         const agentinfo = agencyPriceGroup.data.customerByeMail !== null ? agencyPriceGroup.data.customerByeMail["items"] : []
         if (agentinfo.length > 0) {
            const Permission = agentinfo[0].Permission
            console.log(Permission)
         }
         setSearchProgress(false)
        },[])
    return (
        <div style={{ width: "800px", fontSize: "14px" }}>
            <div style={{ height: "60px", marginLeft: "10px" }}>データ格納場所指定を入力してください。</div>
            <div className={classes.textDivDialog} style={{ height: "90px" }}>
                <InputGroup className="mb-3" style={{ height: "30px" }}>
                    <label className={classes.textlabelDialog} style={{ width: "140px", textAlign: "left" }}>
                        <span className={classes.textlabelSpanDialog}>データ格納場所指定</span>
                    </label>
                    <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                        <FormControl
                            className={classes.textField}
                            style={{ width: "480px", height: "25px", borderRadius: "4px",borderColor:isValid ? "pink" : "#707070" }}
                            name=""
                            id=""
                            value ={InputValue}
                            onChange={(e) => {TextChange(e) }}
                        />
                    </span>
                </InputGroup>
            </div>
            <div style={{ width: "100%", height: "40px", textAlign: "right" }}>
                <Button className="btn" onClick={()=>submit()} style={{ height: 40, width: 70, fontSize: '15px', color: 'white', background: "#000", marginRight: '10px', borderRadius: '8px' }}>実&nbsp;&nbsp;&nbsp;行
                </Button>
                <Button onClick={()=>param.closedialogListMasterDownload()} className="btn" style={{ height: 40, width: 100, fontSize: '15px', color: 'white', background: "#000", border: "1px solid #000", marginRight: '50px', borderRadius: '8px' }}>キャンセル
                </Button>
            </div>
            <Dialog
                onClose={handleConfirmClose}
                aria-labelledby="customized-dialog-title"
                open={openConfirm}
                PaperProps={{
                    style: {
                        backgroundColor: '#FFCC00',
                        boxShadow: 'none',
                        width: "533px",
                        height: "220px"
                    },
                }}>
                <DialogContent dividers style={{ textAlign: "center", color: "white" }}>
                    <Typography gutterBottom variant="h6" style={{ color: "white", marginTop: "10px" }} className={classes.textFieldDialog}>
                        {errorcode}
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textFieldDialog}>
                        {msg1Text}
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textField2Dialog}>
                        {msg2Text}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        handleConfirmOK();
                    }} color="primary" variant="contained">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <div style={{ position: "fixed", left: "50%", top: "50%", display: searchProgress === true ? "block" : "none" }}>
                    <CircularProgress />
                </div>
        </div>
    )
}