/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  FooterLogIn.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary FooterLogIn
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
import React from "react";
import { Layout } from "antd";

const FooterLogIn = () => {
  const year = new Date().getFullYear();
  const { Footer } = Layout;
  return (
    <Footer
      style={{
        textAlign: "center",
        backgroundColor: "white",
        width: "100%",
        display: "inline-flex",
        justifyContent: "space-around",
        alignItems: "center",
        alignContent: "center",
      }}
    >
      <p style={{ color: "#999999", fontSize: "12px", width: "" }}>
        {year} Copyright©ARBROWN Co.,jp Ltd,All right reserved.Ver 2.0
      </p>
    </Footer>
  );
};
export default FooterLogIn