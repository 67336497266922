/* eslint-disable no-unused-vars */
/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  terms.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary サイトポリシー画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
// pages
import Meta from "../components/Meta";
import { useState } from "react";
import React, { Container, Row } from "react-bootstrap";
// import { Link } from "react-router-dom";
/*  AWS標準設定 with Login Auth. */
import Amplify from "aws-amplify";
import awsconfig from "../aws-exports";
// import ShowPdf from "../components/ShowPdf";
import Pdf from "react-pdf-js";
import { Pagination } from "@material-ui/lab";
import { PicRightOutlined } from "@ant-design/icons";

Amplify.configure(awsconfig);

const pageTitle = "AR BROWN Order System";

const terms = () => {

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  const handleChange = (event, value) => {
    setPageNumber(value);
  };

  return (
    <Container
      style={{
        backgroundColor: "#999",
        minWidth: "100%",
        minHeight: "74vh",
        padding: "10px 25% 10px 25%",
      }}
    >
      <Meta title={pageTitle} />
      <Row >
        <Pdf
          file="https://arbos-file-lssi-prd-bucket.s3.ap-northeast-1.amazonaws.com/public/pdf/manual.pdf"
          scale={1.5}
          page={pageNumber}
          onDocumentComplete={(pages => {
            setNumPages(pages)
          })}
        />
        <Pagination count={numPages} defaultPage={pageNumber} onChange={handleChange} />

        {/* <ShowPdf/> */}
        <div>
        </div>
      </Row>
    </Container>
  );
};
//<a style={{ color: '#FFFFFF' }}>お問い合わせフォーム</a>
export default terms;
