/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  App.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary 登録画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
// 依頼
import React, { useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// Layout
import Layout from "./layout/Layout";
// pages
import Home from "./pages/Home";
import Agent from "./pages/Agent";
import UserList from "./pages/UserList";
import Contact from "./pages/Contact";
import InventorySearch from "./pages/InventorySearch";
import InventorySearchEmd from "./pages/InventorySearchEmd";
import NotFound from "./pages/NotFound";
import Order from "./pages/Order";
import OrderConfirmation from "./pages/OrderConfirmation";
import OrderList from "./pages/OrderList";
import OrderEmd from "./pages/OrderEmd";
import FooterLogin from "./components/FooterLogIn";
import HeaderLogin from "./components/HeaderLogin";
import privacy from "./pages/privacy";
import terms from "./pages/terms";
import manual from "./pages/manual";
import ArItemMaster from "./pages/views/ArItemMaster/ArItemMaster.js"
import ArPriceInfo from "./pages/views/ArPriceInfo/ArPriceInfo.js";
// page content
import Meta from "./components/Meta";
// AWS標準設定 with Login Auth.
import logo_icon from "./components/logoLogin.svg";
import Amplify, { I18n } from "aws-amplify";
// import awsconfig from "./aws-exports";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";



const awsconfig = {
  "aws_project_region": "ap-northeast-1",
  "aws_cognito_identity_pool_id": "ap-northeast-1:1772c05f-2242-4404-aabc-eb0a38a2387f",
  "aws_cognito_region": "ap-northeast-1",
  "aws_user_pools_id": "ap-northeast-1_hvoodXdY4",
  "aws_user_pools_web_client_id": "10k549prmpb82nmj9nnsulgilb",
  "oauth": {},
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ],
  "aws_user_files_s3_bucket": "arbos-file-lssi-prd-bucket",
  "aws_user_files_s3_bucket_region": "ap-northeast-1",
  "aws_appsync_graphqlEndpoint": "https://vyttapgsgjg2lc5uivhitdzdse.appsync-api.ap-northeast-1.amazonaws.com/graphql",
  "aws_appsync_region": "ap-northeast-1",
  "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};



import {
  AuthState,
  onAuthUIStateChange,
  Translations,
} from "@aws-amplify/ui-components";
import ja from "@aws-amplify-jp/vocabulary-ja";
import { makeStyles } from "@material-ui/core/styles";

import AuthRoute from "./components/AuthRoute";
import AddAddress from "./pages/AddAddress";
import EndUserSpecialPricing from"./pages/EndUserSpecialPricing"
const pageTitle = "ARBOS AR BROWN Order System";


Amplify.configure(awsconfig);

// 自動定義
I18n.setLanguage("ja-JP");
I18n.putVocabulariesForLanguage("ja-JP", ja(Translations));
I18n.putVocabulariesForLanguage("ja-JP", {
  [Translations.FORGOT_PASSWORD_TEXT]: "パスワードをお忘れの方は",
  [Translations.RESET_PASSWORD_TEXT]: "こちら",
});

const useStyles = makeStyles(() => ({
  img: {
    width: "100%",
    height: "50px",
  },
  logo: {
    width: "239px",
    height: "69px",
  },
  img2: {
    width: "570px",
    height: "450px",
    display: "flex",
    "justify-content": "center",
  },
  font: {
    "text-align": "right!important",
    "vertical-align": "middle!important",
    fontSize: "120%",
    "margin-right": "0rem",
  },
  authenticator: {
    display: "flex",
    maxHeight: "auto",
    maxWidth: "18rem",
    lineHeight: "1em",
    "--container-height": "50vh",
    "--container-display": "center",
    "--box-shadow": 0,
    "--border-box": 0,
    "--amplify-padding": 0,
    "--width": "auto",
    "--padding": 0,
    "--amplify-background-color": "none",
    "--amplify-width": "20rem",
  },
}));
const App = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
  const classes = useStyles();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);
  return authState === AuthState.SignedIn && user ? (
    <Layout>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/Agent" component={Agent} />
        <Route path="/Contact" component={Contact} />
        <Route path="/Home" component={Home} />
        <Route path="/privacy" component={privacy} />
        <Route path="/terms" component={terms} />
        <Route path="/manual" component={manual} />
        <Route path="/InventorySearch" component={InventorySearch} />
        <Route path="/InventorySearchEmd" component={InventorySearchEmd} />
        <AuthRoute path="/Order" component={Order} />
        <AuthRoute path="/OrderConfirmation" component={OrderConfirmation} />
        <Route path="/OrderList" component={OrderList} />
        <Route path="/OrderEmd" component={OrderEmd} />
        <Route path="/UserList" component={UserList} />
        <Route path="/AddAddress" component={AddAddress} />
        <Route path="/ArItemMaster" component={ArItemMaster} />
        <Route path="/ArPriceInfo" component={ArPriceInfo} />
        <Route path="/EndUserSpecialPricing" component={EndUserSpecialPricing} />
        <Route component={NotFound} />
      </Switch>
    </Layout>
  ) : (
    <>
      <div style={{ maxHeight: "100vh", overflow: "hidden" }}>
        <HeaderLogin />
        <div
          style={{
            background: "linear-gradient(#FFFFFF, #CDCDCD)",
            height: "81vh",
            width: "auto",
            display: "flex",
          }}
        >
          <div style={{ width: "full", padding: "1rem", margin: "auto" }}>
            <div>
              <Meta title={pageTitle} />
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  margin: "auto",
                  marginRight: "3rem",
                }}
              >
                <img
                  src={logo_icon}
                  alt="ARBOS AR BROWN Order System"
                  className="loginLogo"
                ></img>
              </div>
              <div className="auth">
                <AmplifyAuthenticator className={classes.authenticator}>
                  <AmplifySignIn
                    headerText='ARBOS　　　　　　　　　　　　　　　　　　　　AR BROWN Order System'
                    slot='sign-in'
                    hideSignUp={true}
                  />
                </AmplifyAuthenticator>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  margin: "auto",
                  marginRight: "1rem",
                  alignContent: "flex-end"
                }}
              >
                お問い合わせ窓口<br></br>arbos_info@arbrown.com
              </div>
            </div>
          </div>
        </div>
        <FooterLogin />
      </div>
    </>
  );
};
export default App;
