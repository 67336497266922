/* eslint-disable no-unused-vars */
/* eslint-disable no-irregular-whitespace */
/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  Orderlist.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2022/03/30 Rayoo)li : 新規作成
 *
 * Summary Orderlist画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
import React, { useEffect, useState } from "react";
import API, { graphqlOperation } from "@aws-amplify/api";
import Amplify, { Auth } from "aws-amplify";
import BootstrapTable from "react-bootstrap-table-next";
import { Container, InputGroup, FormControl, Row, Col} from "react-bootstrap";
import Button from "@material-ui/core/Button";
import useStyles from "./ArPriceInfoStyle";
import { customerByeMail, } from "../../../graphql/queries";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { defaultSorted, options, openValueInit,ArPriceInfoConst} from "./ArPriceInfoConst"
import {checkedMasterlist, searchData, openDilogDetill,
    getOrderRecipient,
    getlistArItemMasters,
    getlistArEndUsers,
    getlistArCustomerAddresses,
} from "./ArPriceInfoMethod";
import { listArPriceInfos } from "../../../graphql/queries"
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ArPriceInfoDisplayItem from "./ArPriceInfoDisplayItem";
import ArPriceInfoDialogDetill from "./ArPriceInfoDialogDetill";
import ArPriceListMasterDownload from "./ArPriceListMasterDownload";
import ArPriceRevisionScreen from "./ArPriceRevisionScreen";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from '@material-ui/core/CircularProgress';
const { SearchBar, ClearSearchButton } = Search;
import qs from 'qs';
const ArPriceInfo = () => {
    const classes = useStyles()
    const [searchValue, setSearchValue] = useState({
        ItemCode: "",
        SuppName: "",
        OrderReceiptCode: "",
        ShipmentCode: "",
        ActualFlag: "0",
        EUARBmgmtCode: "",
    })
    const headerStyle = {
        backgroundColor: "white",
        borderBottom: "3px solid #804F29",
        fontSize: "70%",
        minWidth: "140px",
        padding: 0,
        margin: 0
      };
    const searchValueChange = (e) => {
        setSearchValue({ ...searchValue, [e.target.name]: e.target.value })
    }
    const [SuppNameList, setSuppNameList] = useState([])
    const [OrderReceiptCodeList, setOrderReceiptCodeList] = useState([])
    const [ShipmentCodeList, setShipmentCodeList] = useState([])
    const [ItemCodeList, setItemCodeList] = useState([])
    const [EUARBmgmtCodeList, setEUARBmgmtCodeList] = useState([])
    // リスト内の既定の並べ替え
    const DefaultSorted = defaultSorted;
    const [arPriceInfo, setarPriceInfo] = useState([]);
    const [searchProgress, setSearchProgress] = useState(false);
    const [dilogDetill, setdilogDetill] = useState(false)
    const [dialogListMasterDownload, setdialogListMasterDownload] = useState(false)
    const [dialogArPriceRevisionScreen, setdialogArPriceRevisionScreen] = useState(false)
    const [displayItemSetting, setDisplayItemSetting] = useState(sessionStorage.getItem("displayItemSettingPriceObj") ? JSON.parse(sessionStorage.getItem("displayItemSettingPriceObj")) : openValueInit);
    // テーブル内のリストの形式
    function numtomoney(number, toFixed) {
        let str = [];
        if (!number) return 0;
        if (typeof number === "number") number = String(number);
        let tempNumber = number.split(".");
        number = tempNumber[0];
        if (number.length <= 3) {
          if (tempNumber[1] && toFixed !== 0) {
            number += "." + tempNumber[1].slice(0, toFixed);
          }
          return number;
        }
        number
          .split("")
          .reverse()
          .forEach((item, index) => {
            if (index !== 0 && index % 3 === 0) {
              str.push(",", item);
            } else {
              str.push(item);
            }
          });
        number = str.reverse().join("");
        if (tempNumber[1] && toFixed !== 0) {
          number += "." + tempNumber[1].slice(0, toFixed);
        }
        return number;
      }
    const columns =
          [
            { dataField: "id", text: "ID", hidden: true,csvExport: false },
            { dataField: "UseGroupClassification", text: "使用グループ区分", hidden: displayItemSetting.UseGroupClassification === true ? false : true, headerStyle: { ...headerStyle, minWidth: "40px" }, style: { textAlign: "right", padding: 0, margin: 0 } },
            { dataField: "ItemCode", text: "品目", hidden: displayItemSetting.itemCode === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "MaterialCode", text: "Material Code", hidden: displayItemSetting.MaterialCode === true ? false : true, headerStyle: { ...headerStyle, minWidth: "60px" }, style: { padding: 0, margin: 0 } },
            { dataField: "ItemName", text: "品目名", hidden: displayItemSetting.ItemName === true ? false : true, headerStyle: { ...headerStyle, minWidth: "512px" }, style: { padding: 0, margin: 0 } },
            { dataField: "SalesDocType", text: "販売伝票タイプ", hidden: displayItemSetting.SalesDocType === true ? false : true, headerStyle: { ...headerStyle, minWidth: "60px" }, style: { padding: 0, margin: 0 } },
            { dataField: "SuppCode", text: "仕入先コード", hidden: displayItemSetting.suppCode === true ? false : true, headerStyle: { ...headerStyle, minWidth: "90px" }, style: { padding: 0, margin: 0 } },
            { dataField: "SuppName", text: "仕入先名称", hidden: displayItemSetting.SuppName === true ? false : true, headerStyle: { ...headerStyle, minWidth: "200px" }, style: { minWidth: "341px", padding: 0, margin: 0 } },
            { dataField: "SuppGroup", text: "受注先Gr", hidden: displayItemSetting.SuppGroup === true ? false : true, headerStyle: { ...headerStyle, maxWidth: "30px", minWidth: "30px" }, style: { maxWidth: "30px", minWidth: "30px", padding: 0, margin: 0 } },
            { dataField: "OrderReceiptCode", text: "受注先コード", hidden: displayItemSetting.OrderReceiptCode === true ? false : true, headerStyle: { ...headerStyle, minWidth: "120px" }, style: { maxWidth: "120px", minWidth: "120px", padding: 0, margin: 0 } },
            { dataField: "OrderName", text: "受注先名称", hidden: displayItemSetting.OrderName === true ? false : true, headerStyle: { ...headerStyle, minWidth: "320px" }, style: { padding: 0, margin: 0 } },
            { dataField: "ShipmentCode", text: "出荷先コード", hidden: displayItemSetting.shipmentCode === true ? false : true, headerStyle: { ...headerStyle, minWidth: "100px" }, style: { padding: 0, margin: 0 } },
            { dataField: "DestName", text: "出荷先名称", hidden: displayItemSetting.DestName === true ? false : true, headerStyle: { ...headerStyle, minWidth: "200px" }, style: { maxWidth: "240px", minWidth: "240px", padding: 0, margin: 0 }, },
            { dataField: "EUARBmgmtCode", text: "EU(ARB管理)コード", hidden: displayItemSetting.EUARBmgmtCode === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "EndUserName", text: "エンドユーザー名称", hidden: displayItemSetting.endUserName === true ? false : true, headerStyle: { ...headerStyle, minWidth: "300px" }, style: { maxWidth: "300px", minWidth: "300px", padding: 0, margin: 0 } },
            { dataField: "TorayEndUser", text: "東レエンドユーザー", hidden: displayItemSetting.TorayEndUser === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "TorayEndUserName", text: "東レエンドユーザー名", hidden: displayItemSetting.TorayEndUserName === true ? false : true, headerStyle: { ...headerStyle, minWidth: "250px" }, style: { padding: 0, margin: 0 } },
            { dataField: "Capacity", text: "容量", hidden: displayItemSetting.Capacity === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "CapacityUnit", text: "容量単位", hidden: displayItemSetting.CapacityUnit === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "PriceUnit", text: "価格単位", hidden: displayItemSetting.PriceUnit === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "RegQty", text: "規定数量", hidden: displayItemSetting.RegQty === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "OrdQty", text: "1注文可能数", hidden: displayItemSetting.OrdQty === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "DTDelOrderRec", text: "DT直送/受注後手配品(輸入品含む)", hidden: displayItemSetting.DTDelOrderRec === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "PriceTypeSales", text: "価格種別（販売）", hidden: displayItemSetting.PriceTypeSales === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "PriceTypePurchase", text: "価格種別（仕入）", hidden: displayItemSetting.PriceTypePurchase === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "SalesUnitPriceScale1", text: "販売単価(Price Scale1)", hidden: displayItemSetting.SalesUnitPriceScale1 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 },
            formatter: (cellContent, row) => {
              if (row.SalesUnitPriceScale1 !== null) {
                return (
                  <p>{numtomoney(row.SalesUnitPriceScale1 === "Book無し" ? "" : row.SalesUnitPriceScale1)}</p>
                );
              }
            }},
            { dataField: "SalesUnitPriceScale2", text: "販売単価(Price Scale2)", hidden: displayItemSetting.SalesUnitPriceScale2 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 },
            formatter: (cellContent, row) => {
              if (row.SalesUnitPriceScale2 !== null) {
                return (
                  <p>{numtomoney(row.SalesUnitPriceScale2 === "Book無し" ? "" : row.SalesUnitPriceScale2)}</p>
                );
              }
            }, },
            { dataField: "SalesUnitPriceScale3", text: "販売単価(Price Scale3)", hidden: displayItemSetting.SalesUnitPriceScale3 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 },
            formatter: (cellContent, row) => {
              if (row.SalesUnitPriceScale3 !== null) {
                return (
                  <p>{numtomoney(row.SalesUnitPriceScale3 === "Book無し" ? "" : row.SalesUnitPriceScale3)}</p>
                );
              }
            }},
            { dataField: "SalesUnitPriceScale4", text: "販売単価(Price Scale4)", hidden: displayItemSetting.SalesUnitPriceScale4 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 },
             formatter: (cellContent, row) => {
              if (row.SalesUnitPriceScale4 !== null) {
                return (
                  <p>{numtomoney(row.SalesUnitPriceScale4 === "Book無し" ? "" : row.SalesUnitPriceScale4)}</p>
                );
              }
            } },
            { dataField: "SalesUnitPriceScale5", text: "販売単価(Price Scale5)", hidden: displayItemSetting.SalesUnitPriceScale5 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 },
            formatter: (cellContent, row) => {
              if (row.SalesUnitPriceScale5 !== null) {
                return (
                  <p>{numtomoney(row.SalesUnitPriceScale5 === "Book無し" ? "" : row.SalesUnitPriceScale5)}</p>
                );
              }
            } },
            { dataField: "SalesUnitPriceUnitScale1", text: "販売単価(Unit Scale1)", hidden: displayItemSetting.SalesUnitPriceUnitScale1 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "SalesUnitPriceUnitScale2", text: "販売単価(Unit Scale2)", hidden: displayItemSetting.SalesUnitPriceUnitScale2 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "SalesUnitPriceUnitScale3", text: "販売単価(Unit Scale3)", hidden: displayItemSetting.SalesUnitPriceUnitScale3 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "SalesUnitPriceUnitScale4", text: "販売単価(Unit Scale4)", hidden: displayItemSetting.SalesUnitPriceUnitScale4 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "SalesUnitPriceUnitScale5", text: "販売単価(Unit Scale5)", hidden: displayItemSetting.SalesUnitPriceUnitScale5 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "PurchUnitPriceScale1", text: "仕入単価(Price Scale1)", hidden: displayItemSetting.PurchUnitPriceScale1 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 },
            formatter: (cellContent, row) => {
              if (row.PurchUnitPriceScale1 !== null) {
                return (
                  <p>{numtomoney(row.PurchUnitPriceScale1 === "Book無し" ? "" : row.PurchUnitPriceScale1)}</p>
                );
              }
            } },
            { dataField: "PurchUnitPriceScale2", text: "仕入単価(Price Scale2)", hidden: displayItemSetting.PurchUnitPriceScale2 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 },
            formatter: (cellContent, row) => {
              if (row.PurchUnitPriceScale2 !== null) {
                return (
                  <p>{numtomoney(row.PurchUnitPriceScale2 === "Book無し" ? "" : row.PurchUnitPriceScale2)}</p>
                );
              }
            } },
            { dataField: "PurchUnitPriceScale3", text: "仕入単価(Price Scale3)", hidden: displayItemSetting.PurchUnitPriceScale3 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 },
            formatter: (cellContent, row) => {
              if (row.PurchUnitPriceScale3 !== null) {
                return (
                  <p>{numtomoney(row.PurchUnitPriceScale3 === "Book無し" ? "" : row.PurchUnitPriceScale3)}</p>
                );
              }
            } },
            { dataField: "PurchUnitPriceScale4", text: "仕入単価(Price Scale4)", hidden: displayItemSetting.PurchUnitPriceScale4 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 },
            formatter: (cellContent, row) => {
              if (row.PurchUnitPriceScale4 !== null) {
                return (
                  <p>{numtomoney(row.PurchUnitPriceScale4 === "Book無し" ? "" : row.PurchUnitPriceScale4)}</p>
                );
              }
            }},
            { dataField: "PurchUnitPriceScale5", text: "仕入単価(Price Scale5)", hidden: displayItemSetting.PurchUnitPriceScale5 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 },
            formatter: (cellContent, row) => {
              if (row.PurchUnitPriceScale5 !== null) {
                return (
                  <p>{numtomoney(row.PurchUnitPriceScale5 === "Book無し" ? "" : row.PurchUnitPriceScale5)}</p>
                );
              }
            } },
            { dataField: "PurchUnitPriceUnitScale1", text: "仕入単価(Unit Scale1)", hidden: displayItemSetting.PurchUnitPriceUnitScale1 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "PurchUnitPriceUnitScale2", text: "仕入単価(Unit Scale2)", hidden: displayItemSetting.PurchUnitPriceUnitScale2 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "PurchUnitPriceUnitScale3", text: "仕入単価(Unit Scale3)", hidden: displayItemSetting.PurchUnitPriceUnitScale3 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "PurchUnitPriceUnitScale4", text: "仕入単価(Unit Scale4)", hidden: displayItemSetting.PurchUnitPriceUnitScale4 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "PurchUnitPriceUnitScale5", text: "仕入単価(Unit Scale5)", hidden: displayItemSetting.PurchUnitPriceUnitScale5 === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "BulkSales", text: "出荷単位時のﾊﾞﾗ販売可否", hidden: displayItemSetting.BulkSales === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "BulkShippingFee", text: "ﾊﾞﾗ出荷手数料", hidden: displayItemSetting.BulkShippingFee === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "CoolDeliveryCharge", text: "クール便送料", hidden: displayItemSetting.CoolDeliveryCharge === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "SalespersonCode", text: "営業担当者コード", hidden: displayItemSetting.SalespersonCode === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "LastOrderDate", text: "前回受注年月日", hidden: displayItemSetting.LastOrderDate === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "WebOrderNumber", text: "WEB受注番号", hidden: displayItemSetting.WebOrderNumber === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "ExpirationStart", text: "有効期限開始", hidden: displayItemSetting.ExpirationStart === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "Expired", text: "有効期限終了", hidden: displayItemSetting.Expired === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "RecordSalesDate", text: "売上計上(日付)", hidden: displayItemSetting.RecordSalesDate === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "RecordSalesApplicable", text: "売上計上(適用)", hidden: displayItemSetting.RecordSalesApplicable === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "PurchaseAccountDate", text: "仕入計上(日付)", hidden: displayItemSetting.PurchaseAccountDate === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "AcctForPurchasesApplicableble", text: "仕入計上(適用)", hidden: displayItemSetting.AcctForPurchasesApplicableble === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "SalesRevRate", text: "販売改訂率(%)", hidden: displayItemSetting.SalesRevRate === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "SalesRevPrice", text: "販売改訂価格", hidden: displayItemSetting.SalesRevPrice === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "PurchaseRevRate", text: "仕入改訂率(%)", hidden: displayItemSetting.PurchaseRevRate === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "PurchaseRevPrice", text: "仕入改訂価格", hidden: displayItemSetting.PurchaseRevPrice === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "ActualFlag", text: "実績フラグ", hidden: displayItemSetting.ActualFlag === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "HistoryNum", text: "履歴番号", hidden: displayItemSetting.HistoryNum === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "Remarks", text: "社内備考", hidden: displayItemSetting.Remarks === true ? false : true, headerStyle: { ...headerStyle, minWidth: "500px"}, style: { padding: 0, margin: 0 } },
            { dataField: "UpDailyPayment", text: "更新日付", hidden: displayItemSetting.UpDailyPayment === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "UpTime", text: "更新時刻", hidden: displayItemSetting.UpTime === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
            { dataField: "SeqNo", text: "SeqNo.", hidden: displayItemSetting.SeqNo === true ? false : true, headerStyle, style: { padding: 0, margin: 0 } },
          ]
    const [dialogDisplayItem, setdialogdialogDisplayItem] = useState(false)
    const opendialogListMasterDownload = function () {
        setdialogListMasterDownload(true)
    }
    const closedialogListMasterDownload = function () {
        setdialogListMasterDownload(false)
    }
    const opendialogArPriceRevisionScreen = function () {
        setdialogArPriceRevisionScreen(true)
    }
    const closedialogArPriceRevisionScreen = function () {
        setdialogArPriceRevisionScreen(false)
    }
    const closeDialogDisplayItem = function () {
        setdialogdialogDisplayItem(false)
    }
    const [SelectAllFlag,setSelectAllFlag] = useState(true)
    const [settingButton,setSettingButton] = useState(false)
    const DisplayProjectsSettingsSelectAll = ()=>{
        let flag = !SelectAllFlag
        for (let item in openValueInit) {
          let dom = document.querySelector(`#${item}`)
          dom.checked = flag
        }
        if(!flag){
            setSettingButton(true)
          }else{
            setSettingButton(false)
          }
        setSelectAllFlag(flag)
      }
    const checkBoxChange = (val,val2)=>{
      document.getElementsByName("selectAll")[0].checked = val
      setSelectAllFlag(val)
      setSettingButton(val2)
    }
    // 設定ボタンが押されました
    const handleOK = () => {
        const checkedlist = checkedMasterlist();
        setDisplayItemSetting(checkedlist)
        closeDialogDisplayItem()
    };
    const openDialogDisplayItem = async function () {
        let SelectAllFlagInit = true
        await setdialogdialogDisplayItem(true)
        let checkedList = displayItemSetting
        for (let item in checkedList) {
            let dom = document.querySelector(`#${item}`)
            dom.checked = checkedList[item]
            if(checkedList[item] === false){
                SelectAllFlagInit = false
              }
        }
        document.getElementsByName("selectAll")[0].checked = SelectAllFlagInit
        setSelectAllFlag(SelectAllFlagInit)
        setSettingButton(false)
    }
    const closeDilogDetill = function () {
        setdilogDetill(false)
    }
    const signOut = async () => {
        await Auth.signOut();
        try {
            await Auth.signOut();
        } catch (error) {
            console.log("error signing out: ", error);
        }
        document.location.reload();
    };
    // 検索ボタンが押されました
    const [orderselect_row,setorderselect_row] = useState([]);
const selectRow = {
    mode: 'radio',
    selectionHeaderRenderer: () => "選択",
    bgColor: "white",
    selected: orderselect_row,
    clickToSelect: true,
    headerColumnStyle: {
        backgroundColor: "white",
        minWidth:"50px",
        borderBottom: "3px solid #804F29",
        fontSize: "70%"
    },
    onSelect: (row, isSelect) => {
        if (isSelect) {
            orderselect_row.length = 0;
            orderselect_row.push(row.id);
        }
    },
};
    const searchBtn = async (val) => {
        setSearchProgress(true)
        let arr = [];
        try {
            if(val){
            searchValue.ActualFlag = val.ActualFlag
            arr = await searchData(searchValue)
            arr.map(item=>{
                if(item.SeqNo === val.SeqNo){
                    orderselect_row.length = 0;
                    orderselect_row.push(item.id);
                }
            })
        }else{
            arr = await searchData(searchValue)
            }
        } catch (error) {
            if (error.message === 'No current user') {
                // ユーザープールに障害が発生した場合
                console.log('User pool is invalid.Please reauthenticate.');
                // ログイン画面に戻る
                signOut();
            } else {
                // 他のエラーを処理する
                console.log('An error occurred:', error);
            }
        }
        setSearchValue(searchValue)
        setarPriceInfo(arr)
        setSearchProgress(false)
    }
    const searchClear = ()=>{
        setSearchValue({
        ItemCode: "",
        SuppName: "",
        OrderReceiptCode: "",
        ShipmentCode: "",
        ActualFlag: "0",
        EUARBmgmtCode: "",
          })
        setarPriceInfo([])
    }
    const [btnDisable, setBtnDisabled] = useState({
        btn1: false,
        btn2: false,
        btn3: false,
        btn4: false,
        btn5: false,
    })
      const MyExportCSV2 = (props) => {
        const handleClick = () => {
          props.onExport();
        };
        return (
            <Button className="btn " onClick={handleClick}　style={{cursor:btnDisable.btn1 === true ? 'no-drop' : "pointer", height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '10px', borderRadius: '8px' }}>ダウンロード</Button>
        );
      };
    useEffect(async() => {
        setSearchProgress(true)
        let user = ""
        try {
            user = await Auth.currentUserInfo();
        } catch (error) {
            if (error.message === 'No current user') {
                // ユーザープールに障害が発生した場合
                console.log('User pool is invalid.Please reauthenticate.');
                // ログイン画面に戻る
                signOut();
            } else {
                // 他のエラーを処理する
                console.log('An error occurred:', error);
            }
        }
        const agencyPriceGroup = await API.graphql(
            graphqlOperation(customerByeMail, {
                Agency_Email: user.attributes.email,
            })
        );
        const agentinfo = agencyPriceGroup.data.customerByeMail !== null ? agencyPriceGroup.data.customerByeMail["items"] : []
        if (agentinfo.length > 0) {
            if (agentinfo[0].Permission !== "1" && agentinfo[0].Permission !== "2") {
                setBtnDisabled({
                    btn1: true,
                    btn2: true,
                    btn3: true,
                    btn4: true,
                    btn5: true,
                })
            } else {
                setBtnDisabled({
                    btn1: false,
                    btn2: false,
                    btn3: false,
                    btn4: false,
                    btn5: false,
                })
            }
        }
        let OrderRecipientDataAll = await getOrderRecipient()
        let ItemMaster = await getlistArItemMasters()
        let EndUserInfoLIst = await getlistArEndUsers()
        let listCustomerAddressList = await getlistArCustomerAddresses()
        for (let i = 0; i < EndUserInfoLIst.length; i++) {
            if (EndUserInfoLIst[i].EndUserCode) {
                EUARBmgmtCodeList.push(EndUserInfoLIst[i].EndUserCode + ":" + EndUserInfoLIst[i].Name1)
            }
        }
        for (let i = 0; i < OrderRecipientDataAll.length; i++) {
            if (OrderRecipientDataAll[i].OrderReceiptCode) {
                OrderReceiptCodeList.push(OrderRecipientDataAll[i].OrderReceiptCode + ":" +
                    OrderRecipientDataAll[i].OrderRecipientName2 +
                    OrderRecipientDataAll[i].OrderRecipientName3 +
                    OrderRecipientDataAll[i].OrderRecipientName4)
            }
        }
        for (let i = 0; i < listCustomerAddressList.length; i++) {
            if (listCustomerAddressList[i].CustomerReceiptCode) {
                ShipmentCodeList.push(listCustomerAddressList[i].CustomerReceiptCode + ":" + listCustomerAddressList[i].Name1)
            }
        }
        for (let i = 0; i < ItemMaster.length; i++) {
            if (ItemMaster[i].SuppName) {
                SuppNameList.push(ItemMaster[i].SuppName)
            }
            if (ItemMaster[i].SAPCODE) {
                ItemCodeList.push(ItemMaster[i].GMID + ":" + ItemMaster[i].SAPCODE)
            }
        }
        setOrderReceiptCodeList(Array.from(new Set(OrderReceiptCodeList)))
        setSuppNameList(Array.from(new Set(SuppNameList)))
        setEUARBmgmtCodeList(Array.from(new Set(EUARBmgmtCodeList)))
        setShipmentCodeList(Array.from(new Set(ShipmentCodeList)))
        setItemCodeList(Array.from(new Set(ItemCodeList)))
        if (location.search) {
            let { SeqNo } = qs.parse(location.search.replace(/^\?/, ''))
            if (SeqNo) {
                try {
                    let dataAll = [];
                    const listArOrder = await API.graphql(graphqlOperation(listArPriceInfos, { limit: 10000, filter: { SeqNo: { eq: SeqNo } } }));
                    if (listArOrder.data.listArPriceInfos !== null) {
                        let nextToken = listArOrder.data.listArPriceInfos.nextToken;
                        dataAll = listArOrder.data.listArPriceInfos.items;
                        while (nextToken !== null) {
                          let InvDataSec = await API.graphql(graphqlOperation(listArPriceInfos,
                            { limit: 10000, filter: { SeqNo: { eq: SeqNo }},nextToken:nextToken}));
                          if (InvDataSec.data.listArPriceInfos !== null) {
                            nextToken = InvDataSec.data.listArPriceInfos.nextToken;
                            for (
                              let i = 0;
                              i < InvDataSec.data.listArPriceInfos.items.length;
                              i++
                            ) {
                                dataAll.push(InvDataSec.data.listArPriceInfos.items[i]);
                            }
                          }
                        }
                      }
                    setarPriceInfo(dataAll)
                    setSearchValue({ ...searchValue, ActualFlag: dataAll.length > 0 ? dataAll[0].ActualFlag : "0" })
                } catch (error) {
                    if (error.message === 'No current user') {
                        // ユーザープールに障害が発生した場合
                        console.log('User pool is invalid.Please reauthenticate.');
                        // ログイン画面に戻る
                        signOut();
                    } else {
                        // 他のエラーを処理する
                        console.log('An error occurred:', error);
                    }
                }
            }
        }
        setSearchProgress(false)
    }, []);
    const TextSuppNameChange = (e, value) => {
        setSearchValue({ ...searchValue, SuppName: value ? value : "" })
    }
    const TextOrderReceiptCodeChange = (e, value) => {
        setSearchValue({ ...searchValue, OrderReceiptCode: value ? value.split(":")[0] : "" })
    }
    const TextShipmentCodeChange = (e, value) => {
        setSearchValue({ ...searchValue, ShipmentCode: value ? value.split(":")[0] : "" })
    }
    const TextEUARBmgmtCodeChange = (e, value) => {
        setSearchValue({ ...searchValue, EUARBmgmtCode: value ? value.split(":")[0] : "" })
    }
    const TextItemCodeChange = (e, value) => {
        setSearchValue({ ...searchValue, ItemCode: value ? value.split(":")[1] : "" })
    }
    return (
        <Container
            style={{ backgroundColor: "", minWidth: "100%", minHeight: "74vh", height: '100%', position: 'relative' }}
        >
            <div style={{ minWidth: "1000px", }}>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', marginBottom: "10px" }}>
                        <h4
                            style={{
                                textAlign: "left",
                                color: "black",
                                height: 30,
                                width: '16%',
                                borderLeft: "4px solid #804F29",
                                paddingLeft: "4px",
                            }}
                        >
                            価格表一覧(Si)
                        </h4>
                        <div style={{ width: '80%' }}>
                            <div style={{ width: '100%', height: 177, display: 'flex' }}>
                                <div style={{ width: '90%', minWidth: "90%", height: 177, border: '2px solid rgb(191,191,191)', display: 'flex', flexWrap: 'wrap', padding: "10px 10px 0px 10px" }}>
                                    <div style={{ width: "50%", height: '2px' }}>
                                        <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap", }}>
                                            <InputGroup.Text
                                                className={classes.textlabel}
                                                id="basic-name"
                                                style={{ borderRadius: "0px"}}
                                            >品目
                                            </InputGroup.Text>
                                            <Autocomplete
                                                className={classes.autocomplete}
                                                sx={{
                                                    display: 'inline-block',
                                                    '& input': {
                                                        width: 300,
                                                        height: 0,
                                                    },
                                                }}
                                                options={ItemCodeList}
                                                id="ItemCode"
                                                style={{
                                                    width: "78%",
                                                    height: "25px",
                                                    borderRadius: "4px",
                                                }}
                                                value={searchValue.ItemCode}
                                                name="ItemCode"
                                                onChange={(e, value) => { TextItemCodeChange(e, value) }}
                                                renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                                isOptionEqualToValue={(option, value) => option === value || option === "" || value === ""}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div style={{ width: "50%", height: '25px' }}>
                                        <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap", }}>
                                            <InputGroup.Text
                                                className={classes.textlabel}
                                                id="basic-name"
                                                style={{ borderRadius: "0px"}}
                                            >受注先
                                            </InputGroup.Text>
                                            <Autocomplete
                                                className={classes.autocomplete}
                                                sx={{
                                                    display: 'inline-block',
                                                    '& input': {
                                                        width: 300,
                                                        height: 0,
                                                    },
                                                }}
                                                options={OrderReceiptCodeList}
                                                style={{
                                                    width: "78%",
                                                    height: "25px",
                                                    borderRadius: "4px",
                                                }}
                                                value={searchValue.OrderReceiptCode}
                                                name="OrderReceiptCode"
                                                onChange={(e, value) => { TextOrderReceiptCodeChange(e, value) }}
                                                renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                                isOptionEqualToValue={(option, value) => option === value || option === "" || value === ""}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div style={{ width: "50%", height: '25px' }}>
                                        <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap", }}>
                                            <InputGroup.Text
                                                className={classes.textlabel}
                                                id="basic-name"
                                                style={{ borderRadius: "0px"}}
                                            >仕入先
                                            </InputGroup.Text>
                                            <Autocomplete
                                                className={classes.autocomplete}
                                                sx={{
                                                    display: 'inline-block',
                                                    '& input': {
                                                        width: 300,
                                                        height: 0,
                                                    },
                                                }}
                                                options={SuppNameList}
                                                maxLength="18"
                                                style={{
                                                    width: "78%",
                                                    height: "25px",
                                                    borderRadius: "4px",
                                                }}
                                                value={searchValue.SuppName}
                                                name="SuppName"
                                                onChange={(e, value) => { TextSuppNameChange(e, value) }}
                                                renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                                isOptionEqualToValue={(option, value) => option === value || option === "" || value === ""}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div style={{ width: "50%", height: '25px' }}>
                                        <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap", }}>
                                            <InputGroup.Text
                                                className={classes.textlabel}
                                                id="basic-name"
                                                style={{ borderRadius: "0px"}}
                                            >出荷先
                                            </InputGroup.Text>
                                            <Autocomplete
                                                className={classes.autocomplete}
                                                sx={{
                                                    display: 'inline-block',
                                                    '& input': {
                                                        width: 300,
                                                        height: 0,
                                                    },
                                                }}
                                                options={ShipmentCodeList}
                                                style={{
                                                    width: "78%",
                                                    height: "25px",
                                                    borderRadius: "4px",
                                                }}
                                                value={searchValue.ShipmentCode}
                                                name="ShipmentCode"
                                                id="ShipmentCode"
                                                onChange={(e, value) => { TextShipmentCodeChange(e, value) }}
                                                renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                                isOptionEqualToValue={(option, value) => option === value || option === "" || value === ""}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div style={{ width: "50%", height: '25px',marginBottom:"1%" }}>
                                    <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap", }}>
                                            <InputGroup.Text
                                                className={classes.textlabel}
                                                id="basic-name"
                                                style={{ borderRadius: "0px"}}
                                            >実績フラグ
                                            </InputGroup.Text>
                                            <InputGroup className={`form-control ${classes.raidoCheack}`} style={{ minHeight: "33px",height:"auto",maxHeight:"50px", width: "78%", fontSize: "12px" }}>
                                                <div className={classes.raidoCheackDiv}>                              <input type="radio" onChange={(e) => { searchValueChange(e) }} name="ActualFlag" checked={searchValue.ActualFlag === "0"} value="0" />有効</div>
                                                <div style={{ paddingLeft: "7px" }} className={classes.raidoCheackDiv}><input onChange={(e) => { searchValueChange(e) }} checked={searchValue.ActualFlag === "1"} type="radio" name="ActualFlag" value="1" />営業確認</div>
                                                <div style={{ paddingLeft: "7px" }} className={classes.raidoCheackDiv}><input onChange={(e) => { searchValueChange(e) }} checked={searchValue.ActualFlag === "2"} type="radio" name="ActualFlag" value="2" />失効</div>
                                                <div style={{ paddingLeft: "7px" }} className={classes.raidoCheackDiv}><input onChange={(e) => { searchValueChange(e) }} checked={searchValue.ActualFlag === "9"} type="radio" name="ActualFlag" value="9" />一時保存</div>
                                            </InputGroup>
                                        </InputGroup>
                                    </div>
                                    <div style={{ width: "50%", height: '25px', }}>
                                    <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap", }}>
                                            <InputGroup.Text
                                                className={classes.textlabel}
                                                id="basic-name"
                                                style={{ borderRadius: "0px"}}
                                            >エンドユーザー
                                            </InputGroup.Text>
                                            <Autocomplete
                                                className={classes.autocomplete}
                                                sx={{
                                                    display: 'inline-block',
                                                    '& input': {
                                                        width: 300,
                                                        height: 0,
                                                    },
                                                }}
                                                options={EUARBmgmtCodeList}
                                                id="EndUserName"
                                                style={{
                                                    width: "78%",
                                                    height: "25px",
                                                    borderRadius: "4px",
                                                }}
                                                value={searchValue.EUARBmgmtCode}
                                                name="EUARBmgmtCode"
                                                onChange={(e, value) => { TextEUARBmgmtCodeChange(e, value) }}
                                                renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                                isOptionEqualToValue={(option, value) => option === value || option === "" || value === ""}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div style={{margin:"auto"}}>
                                        <Button className="btn" onClick={() => searchBtn()} style={{ height: 40, width: 120, fontSize: '16px', color: 'black', border: "3px solid black", background: "white",marginRight:'10px'}}>検索</Button>
                                        <Button className="btn" onClick={() => searchClear()} style={{ height: 40, width: 120, fontSize: '16px', color: 'black', border: '3px solid black', background: "white"}}>Clear</Button>
                                    </div>
                                    </div>
                                <div style={{ width: '10%', height: 177, overflow: 'auto', minWidth: '200px', marginLeft: '10px' }}>
                                    <Button className="btn" onClick={() => openDialogDisplayItem()} style={{ height: 40, marginTop: '134px', width: 140, fontSize: '16px', border: '3px solid black', color: 'black', background: "white", float: 'left', marginRight: '10px' }}>表示項目設定
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ToolkitProvider
                        keyField="id"
                        data={arPriceInfo}
                        columns={columns}
                        headerWrapperClasses="foo"
                        wrapperClasses="boo"
                        columnToggle
                        exportCSV={ {
                        fileName: '価格表一覧.csv',
                        noAutoBOM: false,
                        blobType: 'text/plain;charset=Shift-JIS',
                        exportAll: true,
                        onlyExportSelection: false
                    }}
                    >
                        {
                            (props) => (
                                <div>
                                    {/* <div>
                                        <Row>
                                            <Col></Col>
                                            <Col></Col>
                                            <Col></Col>
                                            <Col></Col>
                                            <Col></Col>
                                            <Col></Col>
                                            </Row>
                                    </div>
                                    <br></br> */}
                                    {/* <div style={{ width: '100%', height: '100%', display: 'none' }}>
                                        <SearchBar
                                            className="form-control"

                                            placeholder=" "
                                            style={{
                                                fontSize: "14px",
                                                height: "auto",
                                                padding: "8px 16px",
                                                lineHeight: "1.3333333",
                                                borderRadius: "2px",
                                            }}
                                            {...props.searchProps}
                                        />
                                        <ClearSearchButton
                                            className="btn btn-dark btn-md "
                                            {...props.searchProps}
                                        />
                                    </div> */}
                                    <div style={{ width: '100%', height: '100%', overflow: "auto" }}>
                                        <BootstrapTable
                                            bootstrap4
                                            striped={true}
                                            selectRow={selectRow}
                                            id="table"
                                            hover={true}
                                            headerStyle
                                            keyField="id"
                                            condensed
                                            data={arPriceInfo}
                                            columns={columns}
                                            defaultSorted={DefaultSorted}
                                            {...props.baseProps}
                                            pagination={paginationFactory(options)}
                                            noDataIndication={"no results found"}
                                        />
                                    </div>
                                    <div style={{ width: '1px', height: '80px', bottom: '20px' }}></div>
                                <div style={{ width: '100%',bottom: '20px', position: 'absolute', textAlign: 'right', display: "flex", flexWrap: "nowrap", justifyContent: "flex-end" }}>
                                {/* <MyExportCSV2 className="btn" style={{ height: 40, width: 120, fontSize: '15px', color: 'white', background: "#000", marginRight: '10px', borderRadius: '8px' }}　{...props.csvProps} >ダウンロード</MyExportCSV2 > */}
                                <MyExportCSV2 　{...props.csvProps} ></MyExportCSV2 >
                                    {/* <Button className="btn" onClick={() => opendialogListMasterDownload()} </Button> */}
                                    <Button className="btn" disabled={btnDisable.btn1} onClick={() => opendialogArPriceRevisionScreen()} style={{ cursor: btnDisable.btn1 === true ? 'no-drop' : "pointer", height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '10px', borderRadius: '8px' }}>価格改定
                                    </Button>
                                    <Button className="btn" disabled={btnDisable.btn2} onClick={() => openDilogDetill("新規登録", setdilogDetill, arPriceInfo,orderselect_row)} style={{ cursor: btnDisable.btn2 === true ? 'no-drop' : "pointer", height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '10px', borderRadius: '8px' }}>新規登録
                                    </Button>
                                    <Button className="btn" disabled={btnDisable.btn3} onClick={() => openDilogDetill("参照登録", setdilogDetill, arPriceInfo,orderselect_row)} style={{ cursor: btnDisable.btn3 === true ? 'no-drop' : "pointer", height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '10px', borderRadius: '8px' }}>参照登録
                                    </Button>
                                    <Button className="btn" disabled={btnDisable.btn4} onClick={() => openDilogDetill("変更", setdilogDetill, arPriceInfo,orderselect_row)} style={{ cursor: btnDisable.btn4 === true ? 'no-drop' : "pointer", height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '10px', borderRadius: '8px' }}>変&nbsp;&nbsp;&nbsp;更
                                    </Button>
                                    <Button className="btn" disabled={btnDisable.btn5} onClick={() => openDilogDetill("削除", setdilogDetill, arPriceInfo,orderselect_row)} style={{ cursor: btnDisable.btn5 === true ? 'no-drop' : "pointer", height: 40, width: 120, fontSize: '15px', color: 'white', background: "#001529", marginRight: '30px', borderRadius: '8px' }}>削&nbsp;&nbsp;&nbsp;除
                                    </Button>
                                    </div>
                                </div>
                            )
                        }
                    </ToolkitProvider>
                </div>

                <div style={{ position: "fixed", left: "50%", top: "50%", display: searchProgress === true ? "block" : "none" }}>
                    <CircularProgress />
                </div>
                <Dialog
                    open={dilogDetill}
                    maxWidth='xl'
                >
                    <DialogTitle>
                        <span style={{ fontWeight: '900' }}>価格表詳細画面{"(" + ArPriceInfoConst.DilogDetill + ")"}</span>
                    </DialogTitle>
                    <DialogContent>
                        <ArPriceInfoDialogDetill closeDilogDetill={closeDilogDetill} searchBtn={searchBtn} />
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={dialogDisplayItem}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        <span style={{ color: 'black', letterSpacing: '3px', fontSize: "24px", display: "inline-block", marginLeft: '12%' }}>表示項目設定</span></DialogTitle>
                    <DialogContent>
                        <ArPriceInfoDisplayItem checkBoxChange = {checkBoxChange} DisplayProjectsSettingsSelectAll = {DisplayProjectsSettingsSelectAll}/>
                    </DialogContent>
                    <DialogActions>
                        <div style={{ width: "70%", display: 'flex', justifyContent: "start" }}>
                            <Button className="btn" disabled={settingButton} onClick={() => handleOK()} style={{ height: 40, fontSize: '18px', width: '80px', color: 'black', border: '3px solid black', background: "white", margin: 'auto' }}>設定</Button>
                            <Button className="btn" onClick={() => closeDialogDisplayItem()} style={{ marginLeft: '1px', height: 40, width: "120px", fontSize: '18px', color: 'black', border: '3px solid black', background: "white", margin: 'auto' }}>キャンセル</Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={dialogListMasterDownload}
                    maxWidth='xl'
                >
                    <DialogTitle>
                        <span style={{ fontWeight: '900' }}>価格表マスタダウンロード</span>
                    </DialogTitle>
                    <DialogContent>
                        <ArPriceListMasterDownload closedialogListMasterDownload={closedialogListMasterDownload} />
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={dialogArPriceRevisionScreen}
                    maxWidth='xl'
                    scroll="paper"
                >
                    <DialogTitle>
                        <span style={{ fontWeight: '900' }}>【価格改定用】価格表マスタ ダウンロード/アップロード画面</span>
                    </DialogTitle>
                    <DialogContent >
                        <ArPriceRevisionScreen closedialogArPriceRevisionScreen={closedialogArPriceRevisionScreen} SuppNameList={SuppNameList}/>
                    </DialogContent>
                </Dialog>
            </div>
        </Container>
    );
};

export default ArPriceInfo;