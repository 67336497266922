import { InputGroup } from "react-bootstrap";
import useStyles from "./ArPriceInfoStyle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import InputBase from '@material-ui/core/InputBase';
// import API, { graphqlOperation } from "@aws-amplify/api";
import { Auth,Storage } from "aws-amplify";
import Autocomplete from '@mui/material/Autocomplete';
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import { customerByeMail } from "../../../graphql/queries"
import TextField from '@mui/material/TextField';
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react"
import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as XLSX from 'xlsx';
import moment from "moment";
import * as FileSaver from "file-saver";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';

import { searchData } from "./ArPriceInfoMethod";

import { queryArPrice } from "./ArPriceInfoMethod";
export default function ArPriceRevisionScreen(param) {
    const classes = useStyles()
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [openSumbit, setopenSumbit] = React.useState(false);
    const [searchProgress, setSearchProgress] = useState(false);
    const [fileHandle, setSaveFile] = useState(Object);
    const [olduploadfile, setOldUploadFile] = useState(null);
    const [newuploadfile, setNewUploadFile] = useState(null);
    const [logData, setLogData] = useState([])
    const [logOpen, setLogOpen] = useState(false)
    // const [priceData,setPriceData] = useState(Object)
    // const [uniqueData,setUniqueData] = useState([])
    const handleClickConfirmOpen = () => {
        setOpenConfirm(true);
    };
    const handleConfirmClose = () => {
        setOpenConfirm(false);
    };
    const handleConfirmOK = async () => {
        setOpenConfirm(false);
    }
    const dialogTextsuppNameChange = (e,value) => {

        setinputValue({ ...inputValue, suppName: value ? value : ""})
        setIsValid({ ...isValid, suppName: false })
    }

    const dialogTextChange = (name, value) => {
        if(name === "oldstorageLocal1"){
            setinputValue({ ...inputValue, oldstorageLocal1: value })
            setIsValid({ ...isValid, oldstorageLocal1: false })
        }else if(name === "oldstorageLocal2"){
            setinputValue({ ...inputValue, oldstorageLocal2: value })
            setIsValid({ ...isValid, oldstorageLocal2: false })
        }else if(name === "newstorageLocal2"){
            setinputValue({ ...inputValue, newstorageLocal2: value })
        }
    }

    const [inputValue, setinputValue] = useState({
        suppName: "",
        checkBox1: true,
        checkBox2: false,
        oldstorageLocal1: "",
        oldstorageLocal2: "",
        newstorageLocal2: "",
    })
    const [errorcode, setErrorCode] = React.useState(Object);
    const handleSetErrorcode = (Object) => {
        setErrorCode(Object);
    };
    const [msg1Text, setMsg1Text] = React.useState(Object);
    const handleSetMsg1Text = (Object) => {
        setMsg1Text(Object);
    };
    const [msg2Text, setMsg2Text] = React.useState(Object);
    const handleSetMsg2Text = (Object) => {
        setMsg2Text(Object);
    };
    const Executionlog = () => {
        // param.closedialogArPriceRevisionScreen()
        setLogOpen(true)
    }
    const handlelogClose = () => {
        setLogOpen(false)
    }

    const [isValid, setIsValid] = useState({
        suppName: "",
        oldstorageLocal1: "",
        oldstorageLocal2: "",
        newstorageLocal2: ""
    })

    const areDatesConsecutive = (dateStr1, dateStr2) => {

        const moment1 = moment(dateStr1, 'YYYYMMDD');
        const moment2 = moment(dateStr2, 'YYYYMMDD');
        if (!moment1.isValid() || !moment2.isValid()) {
            return false;
        }
        return moment2.diff(moment1, 'days') === 1;
    }

    const isValidDate = (dateStr) => {
        const moment1 = moment(dateStr, 'YYYYMMDD');
        if (!moment1.isValid()) {
            return true;
        }

        return false
    }

    const checkoldxlsx = (item, newitem, msg)=>{
        if(item[50] && isValidDate(item[50])){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}、SeqNo：${item[65]} は、年月日入力欄の入力形式が「YYYYMMDD」ではないため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[51] && isValidDate(item[51])){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}、SeqNo：${item[65]} は、年月日入力欄の入力形式が「YYYYMMDD」ではないため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if((!item[51] && newitem.length === 1) || (item[51] && newitem.length === 0)){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}、SeqNo：${item[65]}は、「有効期限終了」が異常なため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[52] && isValidDate(item[52])){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}、SeqNo：${item[65]} は、年月日入力欄の入力形式が「YYYYMMDD」ではないため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[51] && item[51] > item[52]){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}、SeqNo：${item[65]}は、「有効期限終了」が「売上計上（日付）」より後の日付なため処理を停止しました。`)
            const logcsv = msg.join("\n")
           setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[54] && isValidDate(item[54])){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}、SeqNo：${item[65]} は、年月日入力欄の入力形式が「YYYYMMDD」ではないため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[51] && item[51] > item[54]){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}、SeqNo：${item[65]} は、「有効期限終了」が「仕入計上（日付）」より後の日付なため処理を停止しました。`)
            const logcsv = msg.join("\n")
           setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(!["受注日","出荷日","納品日"].includes(item[53])){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}、SeqNo：${item[65]}は、「売上計上（適用）」に想定外の記載があるため処理を停止しました。 `)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(!["受注日","出荷日","納品日"].includes(item[55])){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}、SeqNo：${item[65]}は、「仕入計上（適用）」に想定外の記載があるため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(!["1","2"].includes(item[61])){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}、SeqNo：${item[65]}は、「履歴番号」に異常が見つかったため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        return true;

    }

    const checknewxlsx = (item,olditem, msg)=>{
        if(item[65]){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、「SeqNo」が入力されているため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[51]){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、「有効期限終了」が入力されているため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(!item[50]){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、「有効期限開始」が未入力なため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[50] && isValidDate(item[50])){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、年月日入力欄の入力形式が「YYYYMMDD」ではないため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if (item[65] || !areDatesConsecutive(olditem[51], item[50])) {
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、「有効期限開始」が価格表マスタの「有効期限終了」と連続していないため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[65] || !item[52]){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、SeqNoが入力かつ「売上計上（日付）」が未入力なため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[52] && isValidDate(item[52])){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、年月日入力欄の入力形式が「YYYYMMDD」ではないため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[65] || !item[54]){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、SeqNoが入力かつ「仕入計上（日付）」が未入力なため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[54] && isValidDate(item[54])){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、年月日入力欄の入力形式が「YYYYMMDD」ではないため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[65] || !areDatesConsecutive(olditem[52], item[52])) {
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、「売上計上(日付)」が価格表マスタの「売上計上(日付)」と連続していないか空白なため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[65] || !areDatesConsecutive(olditem[54], item[54])) {
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、「仕入計上(日付)」が価格表マスタの「仕入計上(日付)」と連続していないか空白なため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[65] || !item[56]){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、SeqNoが入力かつ「販売改訂率（％）」が未入力なため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[65] || !item[57]){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]} は、SeqNoが入力かつ「販売改訂価格」が未入力なため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[65] || !item[58]){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、SeqNoが入力かつ「仕入改訂率（％）」が未入力なため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        if(item[65] || !item[59]){
            msg.push(`  仕入先：${item[5]}、MaterialCode：${item[2]}は、SeqNoが入力かつ「仕入改訂価格」が未入力なため処理を停止しました。`)
            const logcsv = msg.join("\n")
            setLogData(msg)
            const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
            return false;
        }
        return true


    }
    const readXlsxFile = async (priceData,uniqueData) => {

        try {
        let oldjsonData = [];
        let newjsonData = [];
        let oldlogmsg = [];
        let newlogmsg = [];
        let s3csvData = [];
        // let maxseqNo = "";
        if(olduploadfile){
            const arrayBuffer = await olduploadfile.arrayBuffer();
            const workbook = XLSX.read(arrayBuffer, { type: 'array' });
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false, defval: "" });

            oldjsonData = jsonData.filter(val=>val[1] !== "")
        }
        if(newuploadfile){
          const arrayBuffer = await newuploadfile.arrayBuffer();
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1, raw: false, defval: "" });

          newjsonData = jsonData.filter(val=>val[1] !== "")
        }


        // const priceData = await queryArPrice();


        // const uniqueDataMap = new Map();
        // priceData.dataAll?.forEach(item => {
        // const key = Object.values(item).slice(0, -2).join('-');
        // if (!uniqueDataMap.has(key) || parseInt(item.HistoryNum) > parseInt(uniqueDataMap.get(key).HistoryNum)) {
        //     uniqueDataMap.set(key, item);
        // }
        // // if(maxseqNo < item.SeqNo){
        // //   maxseqNo = item.SeqNo
        // // }
        // });
        // const uniqueData = Array.from(uniqueDataMap.values());
        oldlogmsg.push("価格表取り込みエラー")
        oldlogmsg.push("")
        oldlogmsg.push("1.既存（改定前）の価格表データ一覧ファイル")
        for(let i = 1; i < oldjsonData.length; i++){
            if(!oldjsonData[i][65].trim()){
                oldlogmsg.push(`  仕入先：${oldjsonData[i][5]}、MaterialCode：${oldjsonData[i][2]} は、SeqNoが記載されていないため反映しませんでした`)
            }else if(priceData.seqnolist.includes(oldjsonData[i][65].trim().padStart(10, "0"))){
                const historyNumlist = uniqueData.filter((item)=>(
                    item.ItemCode === oldjsonData[i][1] &&
                    item.MaterialCode === oldjsonData[i][2] &&
                    item.ItemName === oldjsonData[i][3] &&
                    item.OrderReceiptCode === oldjsonData[i][8] &&
                    item.SuppGroup === oldjsonData[i][7] &&
                    item.ShipmentCode === oldjsonData[i][10] &&
                    item.EUARBmgmtCode === oldjsonData[i][12]
                    ))
                const historyNum = historyNumlist[0]?.HistoryNum
                if(historyNum !== oldjsonData[i][61]){
                    oldlogmsg.push(`  仕入先：${oldjsonData[i][5]}、MaterialCode：${oldjsonData[i][2]}、SeqNo：${oldjsonData[i][65]}  は、最新の履歴番号ではないため反映しませんでした。`)
                    break
                }
                const newitem = newjsonData.filter((item)=>(
                    item[1] === oldjsonData[i][1] &&
                    item[2] === oldjsonData[i][2] &&
                    item[3] === oldjsonData[i][3] &&
                    item[8] === oldjsonData[i][8] &&
                    item[7] === oldjsonData[i][7] &&
                    item[10] === oldjsonData[i][10] &&
                    item[12] === oldjsonData[i][12]
                    ))
                const oldcheck = checkoldxlsx(oldjsonData[i], newitem, oldlogmsg)

                if(!oldcheck){
                 setLogOpen(true)
                 setIsValid({ ...isValid, oldstorageLocal2:true })
                return
                }
                let item = {
                    status:"",
                    UseGroupClassification:"",
                    ItemCode:"",
                    MaterialCode:"",
                    ItemName:"",
                    SalesDocType:"",
                    SuppCode:"",
                    SuppName:"",
                    SuppGroup:"",
                    OrderReceiptCode:"",
                    OrderName:"",
                    ShipmentCode:"",
                    DestName:"",
                    EUARBmgmtCode:"",
                    EndUserName:"",
                    TorayEndUser:"",
                    TorayEndUserName:"",
                    Capacity:"",
                    CapacityUnit:"",
                    PriceUnit:"",
                    RegQty:"",
                    OrdQty:"",
                    DTDelOrderRec:"",
                    PriceTypeSales:"",
                    PriceTypePurchase:"",
                    SalesUnitPriceScale1:"",
                    SalesUnitPriceScale2:"",
                    SalesUnitPriceScale3:"",
                    SalesUnitPriceScale4:"",
                    SalesUnitPriceScale5:"",
                    SalesUnitPriceUnitScale1:"",
                    SalesUnitPriceUnitScale2:"",
                    SalesUnitPriceUnitScale3:"",
                    SalesUnitPriceUnitScale4:"",
                    SalesUnitPriceUnitScale5:"",
                    PurchUnitPriceScale1:"",
                    PurchUnitPriceScale2:"",
                    PurchUnitPriceScale3:"",
                    PurchUnitPriceScale4:"",
                    PurchUnitPriceScale5:"",
                    PurchUnitPriceUnitScale1:"",
                    PurchUnitPriceUnitScale2:"",
                    PurchUnitPriceUnitScale3:"",
                    PurchUnitPriceUnitScale4:"",
                    PurchUnitPriceUnitScale5:"",
                    BulkSales:"",
                    BulkShippingFee:"",
                    CoolDeliveryCharge:"",
                    SalespersonCode:"",
                    LastOrderDate:"",
                    WebOrderNumber:"",
                    ExpirationStart:"",
                    Expired:"",
                    RecordSalesDate:"",
                    RecordSalesApplicable:"",
                    PurchaseAccountDate:"",
                    AcctForPurchasesApplicableble:"",
                    SalesRevRate:"",
                    SalesRevPrice:"",
                    PurchaseRevRate:"",
                    PurchaseRevPrice:"",
                    ActualFlag:"",
                    HistoryNum:"",
                    Remarks:"",
                    UpDailyPayment:"",
                    UpTime:"",
                    SeqNo:"",
                }
                if(newuploadfile){
                    item.status = "2"
                    item.ItemCode = oldjsonData[i][1]
                    item.MaterialCode = oldjsonData[i][2]
                    item.ItemName = oldjsonData[i][3]
                    item.OrderReceiptCode = oldjsonData[i][8]
                    item.SuppGroup = oldjsonData[i][7]
                    item.ShipmentCode = oldjsonData[i][10]
                    item.EUARBmgmtCode = oldjsonData[i][12]
                    item.Expired = oldjsonData[i][51]
                    item.RecordSalesDate = oldjsonData[i][52]
                    item.RecordSalesApplicable = oldjsonData[i][53]
                    item.PurchaseAccountDate = oldjsonData[i][54]
                    item.AcctForPurchasesApplicableble = oldjsonData[i][55]
                    item.SeqNo = oldjsonData[i][65]
                }else{
                    item.status = "3"
                    item.ItemCode = oldjsonData[i][1]
                    item.MaterialCode = oldjsonData[i][2]
                    item.ItemName = oldjsonData[i][3]
                    item.OrderReceiptCode = oldjsonData[i][8]
                    item.SuppGroup = oldjsonData[i][7]
                    item.ShipmentCode = oldjsonData[i][10]
                    item.EUARBmgmtCode = oldjsonData[i][12]
                    item.SalesUnitPriceScale1 = oldjsonData[i][24]
                    item.SalesUnitPriceScale2 = oldjsonData[i][25]
                    item.SalesUnitPriceScale3 = oldjsonData[i][26]
                    item.SalesUnitPriceScale4 = oldjsonData[i][27]
                    item.SalesUnitPriceScale5 = oldjsonData[i][28]
                    item.SalesUnitPriceUnitScale1 = oldjsonData[i][29]
                    item.SalesUnitPriceUnitScale2 = oldjsonData[i][30]
                    item.SalesUnitPriceUnitScale3 = oldjsonData[i][31]
                    item.SalesUnitPriceUnitScale4 = oldjsonData[i][32]
                    item.SalesUnitPriceUnitScale5 = oldjsonData[i][33]
                    item.PurchUnitPriceScale1 = oldjsonData[i][34]
                    item.PurchUnitPriceScale2 = oldjsonData[i][35]
                    item.PurchUnitPriceScale3 = oldjsonData[i][36]
                    item.PurchUnitPriceScale4 = oldjsonData[i][37]
                    item.PurchUnitPriceScale5 = oldjsonData[i][38]
                    item.PurchUnitPriceUnitScale1 = oldjsonData[i][39]
                    item.PurchUnitPriceUnitScale2 = oldjsonData[i][40]
                    item.PurchUnitPriceUnitScale3 = oldjsonData[i][41]
                    item.PurchUnitPriceUnitScale4 = oldjsonData[i][42]
                    item.PurchUnitPriceUnitScale5 = oldjsonData[i][43]
                    item.ExpirationStart = oldjsonData[i][50]
                    item.RecordSalesDate = oldjsonData[i][52]
                    item.RecordSalesApplicable = oldjsonData[i][53]
                    item.PurchaseAccountDate = oldjsonData[i][54]
                    item.AcctForPurchasesApplicableble = oldjsonData[i][55]
                    item.SalesRevRate = oldjsonData[i][56]
                    item.SalesRevPrice = oldjsonData[i][57]
                    item.PurchaseRevRate = oldjsonData[i][58]
                    item.PurchaseRevPrice = oldjsonData[i][59]
                    item.ActualFlag = "0"
                    // item.HistoryNum = oldjsonData[i][61]
                    item.SeqNo = oldjsonData[i][65]
                }
                s3csvData.push(item)
            }else{
                oldlogmsg.push(`  仕入先：${oldjsonData[i][5]}、MaterialCode：${oldjsonData[i][2]}、SeqNo：${oldjsonData[i][65]} は、価格表マスタに情報が存在しないため反映しませんでした。`)
            }
        }
        if(oldlogmsg.length === 3){
            oldlogmsg.push("エラーなし")
            oldlogmsg.push("")
        }

        if(newuploadfile){
            newlogmsg.push("2.価格改定の新規追加用価格表データ一覧ファイル")
            for(let i = 1; i < newjsonData.length; i++){
                const olditem = oldjsonData.filter((item)=>(
                    item[1] === newjsonData[i][1] &&
                    item[2] === newjsonData[i][2] &&
                    item[3] === newjsonData[i][3] &&
                    item[8] === newjsonData[i][8] &&
                    item[7] === newjsonData[i][7] &&
                    item[10] === newjsonData[i][10] &&
                    item[12] === newjsonData[i][12] &&
                    item[65].trim() !== ""
                    ))
                if(olditem.length > 0){
                    const dataitem = priceData.arr.filter((item)=>(
                        item.ItemCode === newjsonData[i][1] &&
                        item.MaterialCode === newjsonData[i][2] &&
                        item.ItemName === newjsonData[i][3] &&
                        item.OrderReceiptCode === newjsonData[i][8] &&
                        item.SuppGroup === newjsonData[i][7] &&
                        item.ShipmentCode === newjsonData[i][10] &&
                        item.EUARBmgmtCode === newjsonData[i][12]
                        ))
                    if(dataitem.length > 0){
                        const historyNumlist = uniqueData.filter((item)=>(
                            item.ItemCode === newjsonData[i][1] &&
                            item.MaterialCode === newjsonData[i][2] &&
                            item.ItemName === newjsonData[i][3] &&
                            item.OrderReceiptCode === newjsonData[i][8] &&
                            item.SuppGroup === newjsonData[i][7] &&
                            item.ShipmentCode === newjsonData[i][10] &&
                            item.EUARBmgmtCode === newjsonData[i][12]
                            ))
                        const historyNum = historyNumlist[0]?.HistoryNum
                        if(historyNum === "1" || historyNum === "2"){
                            const compareitem = dataitem.filter((item)=>(item.HistoryNum === "2"))

                            if(historyNum === "2"){
                                newlogmsg.push(`  仕入先：${newjsonData[i][5]}、MaterialCode：${newjsonData[i][2]}、SeqNo：${compareitem[0]?.SeqNo}は、既に作成されているため反映しませんでした。。`)
                            }else{
                                const dbitem = dataitem.filter((item)=>(item.HistoryNum === "1"))

                                const conmsg = oldlogmsg.concat(newlogmsg)
                                if(dbitem.length > 0){
                                    const newcheck = checknewxlsx(newjsonData[i], olditem?.[0], conmsg)

                                    if(!newcheck){
                                        setLogOpen(true)
                                        setIsValid({ ...isValid, newstorageLocal2:true })
                                        return
                                    }
                                    let item = {
                                        status:"1",
                                        UseGroupClassification: dbitem[0].UseGroupClassification ? dbitem[0].UseGroupClassification : "",
                                        ItemCode: dbitem[0].ItemCode ? dbitem[0].ItemCode : "",
                                        MaterialCode: dbitem[0].MaterialCode ? dbitem[0].MaterialCode : "",
                                        ItemName: dbitem[0].ItemName ? dbitem[0].ItemName : "",
                                        SalesDocType: dbitem[0].SalesDocType ? dbitem[0].SalesDocType : "",
                                        SuppCode: dbitem[0].SuppCode ? dbitem[0].SuppCode : "",
                                        SuppName: dbitem[0].SuppName ? dbitem[0].SuppName : "",
                                        SuppGroup: dbitem[0].SuppGroup ? dbitem[0].SuppGroup : "",
                                        OrderReceiptCode: dbitem[0].OrderReceiptCode ? dbitem[0].OrderReceiptCode : "",
                                        OrderName: dbitem[0].OrderName ? dbitem[0].OrderName : "",
                                        ShipmentCode: dbitem[0].ShipmentCode ? dbitem[0].ShipmentCode : "",
                                        DestName: dbitem[0].DestName ? dbitem[0].DestName : "",
                                        EUARBmgmtCode: dbitem[0].EUARBmgmtCode ? dbitem[0].EUARBmgmtCode : "",
                                        EndUserName: dbitem[0].EndUserName ? dbitem[0].EndUserName : "",
                                        TorayEndUser: dbitem[0].TorayEndUser ? dbitem[0].TorayEndUser : "",
                                        TorayEndUserName: dbitem[0].TorayEndUserName ? dbitem[0].TorayEndUserName : "",
                                        Capacity: dbitem[0].Capacity ? dbitem[0].Capacity : "",
                                        CapacityUnit: dbitem[0].CapacityUnit ? dbitem[0].CapacityUnit : "",
                                        PriceUnit: dbitem[0].PriceUnit ? dbitem[0].PriceUnit : "",
                                        RegQty: dbitem[0].RegQty ? dbitem[0].RegQty : "",
                                        OrdQty: dbitem[0].OrdQty ? dbitem[0].OrdQty : "",
                                        DTDelOrderRec: dbitem[0].DTDelOrderRec ? dbitem[0].DTDelOrderRec : "",
                                        PriceTypeSales: dbitem[0].PriceTypeSales ? dbitem[0].PriceTypeSales : "",
                                        PriceTypePurchase: dbitem[0].PriceTypePurchase ? dbitem[0].PriceTypePurchase : "",
                                        SalesUnitPriceScale1: dbitem[0].SalesUnitPriceScale1 ? dbitem[0].SalesUnitPriceScale1 : "",
                                        SalesUnitPriceScale2: dbitem[0].SalesUnitPriceScale2 ? dbitem[0].SalesUnitPriceScale2 : "",
                                        SalesUnitPriceScale3: dbitem[0].SalesUnitPriceScale3 ? dbitem[0].SalesUnitPriceScale3 : "",
                                        SalesUnitPriceScale4: dbitem[0].SalesUnitPriceScale4 ? dbitem[0].SalesUnitPriceScale4 : "",
                                        SalesUnitPriceScale5: dbitem[0].SalesUnitPriceScale5 ? dbitem[0].SalesUnitPriceScale5 : "",
                                        SalesUnitPriceUnitScale1: dbitem[0].SalesUnitPriceUnitScale1 ? dbitem[0].SalesUnitPriceUnitScale1 : "",
                                        SalesUnitPriceUnitScale2: dbitem[0].SalesUnitPriceUnitScale2 ? dbitem[0].SalesUnitPriceUnitScale2 : "",
                                        SalesUnitPriceUnitScale3: dbitem[0].SalesUnitPriceUnitScale3 ? dbitem[0].SalesUnitPriceUnitScale3 : "",
                                        SalesUnitPriceUnitScale4: dbitem[0].SalesUnitPriceUnitScale4 ? dbitem[0].SalesUnitPriceUnitScale4 : "",
                                        SalesUnitPriceUnitScale5: dbitem[0].SalesUnitPriceUnitScale5 ? dbitem[0].SalesUnitPriceUnitScale5 : "",
                                        PurchUnitPriceScale1: dbitem[0].PurchUnitPriceScale1 ? dbitem[0].PurchUnitPriceScale1 : "",
                                        PurchUnitPriceScale2: dbitem[0].PurchUnitPriceScale2 ? dbitem[0].PurchUnitPriceScale2 : "",
                                        PurchUnitPriceScale3: dbitem[0].PurchUnitPriceScale3 ? dbitem[0].PurchUnitPriceScale3 : "",
                                        PurchUnitPriceScale4: dbitem[0].PurchUnitPriceScale4 ? dbitem[0].PurchUnitPriceScale4 : "",
                                        PurchUnitPriceScale5: dbitem[0].PurchUnitPriceScale5 ? dbitem[0].PurchUnitPriceScale5 : "",
                                        PurchUnitPriceUnitScale1: dbitem[0].PurchUnitPriceUnitScale1 ? dbitem[0].PurchUnitPriceUnitScale1 : "",
                                        PurchUnitPriceUnitScale2: dbitem[0].PurchUnitPriceUnitScale2 ? dbitem[0].PurchUnitPriceUnitScale2 : "",
                                        PurchUnitPriceUnitScale3: dbitem[0].PurchUnitPriceUnitScale3 ? dbitem[0].PurchUnitPriceUnitScale3 : "",
                                        PurchUnitPriceUnitScale4: dbitem[0].PurchUnitPriceUnitScale4 ? dbitem[0].PurchUnitPriceUnitScale4 : "",
                                        PurchUnitPriceUnitScale5: dbitem[0].PurchUnitPriceUnitScale5 ? dbitem[0].PurchUnitPriceUnitScale5 : "",
                                        BulkSales: dbitem[0].BulkSales ? dbitem[0].BulkSales : "",
                                        BulkShippingFee: dbitem[0].BulkShippingFee ? dbitem[0].BulkShippingFee : "",
                                        CoolDeliveryCharge: dbitem[0].CoolDeliveryCharge ? dbitem[0].CoolDeliveryCharge : "",
                                        SalespersonCode: dbitem[0].SalespersonCode ? dbitem[0].SalespersonCode : "",
                                        LastOrderDate: dbitem[0].LastOrderDate ? dbitem[0].LastOrderDate : "",
                                        WebOrderNumber: dbitem[0].WebOrderNumber ? dbitem[0].WebOrderNumber : "",
                                        ExpirationStart: dbitem[0].ExpirationStart ? dbitem[0].ExpirationStart : "",
                                        Expired: dbitem[0].Expired ? dbitem[0].Expired : "",
                                        RecordSalesDate: dbitem[0].RecordSalesDate ? dbitem[0].RecordSalesDate : "",
                                        RecordSalesApplicable: dbitem[0].RecordSalesApplicable ? dbitem[0].RecordSalesApplicable : "",
                                        PurchaseAccountDate: dbitem[0].PurchaseAccountDate ? dbitem[0].PurchaseAccountDate : "",
                                        AcctForPurchasesApplicableble: dbitem[0].AcctForPurchasesApplicableble ? dbitem[0].AcctForPurchasesApplicableble : "",
                                        SalesRevRate: dbitem[0].SalesRevRate ? dbitem[0].SalesRevRate : "",
                                        SalesRevPrice: dbitem[0].SalesRevPrice ? dbitem[0].SalesRevPrice : "",
                                        PurchaseRevRate: dbitem[0].PurchaseRevRate ? dbitem[0].PurchaseRevRate : "",
                                        PurchaseRevPrice: dbitem[0].PurchaseRevPrice ? dbitem[0].PurchaseRevPrice : "",
                                        ActualFlag: dbitem[0].ActualFlag ? dbitem[0].ActualFlag : "",
                                        HistoryNum: dbitem[0].HistoryNum ? dbitem[0].HistoryNum : "",
                                        Remarks: dbitem[0].Remarks ? dbitem[0].Remarks : "",
                                        UpDailyPayment: dbitem[0].UpDailyPayment ? dbitem[0].UpDailyPayment : "",
                                        UpTime: dbitem[0].UpTime ? dbitem[0].UpTime : "",
                                        SeqNo: dbitem[0].SeqNo ? dbitem[0].SeqNo : "",
                                    }
                                    item.SalesUnitPriceScale1 = newjsonData[i][24]
                                    item.SalesUnitPriceScale2 = newjsonData[i][25]
                                    item.SalesUnitPriceScale3 = newjsonData[i][26]
                                    item.SalesUnitPriceScale4 = newjsonData[i][27]
                                    item.SalesUnitPriceScale5 = newjsonData[i][28]
                                    item.SalesUnitPriceUnitScale1 = newjsonData[i][29]
                                    item.SalesUnitPriceUnitScale2 = newjsonData[i][30]
                                    item.SalesUnitPriceUnitScale3 = newjsonData[i][31]
                                    item.SalesUnitPriceUnitScale4 = newjsonData[i][32]
                                    item.SalesUnitPriceUnitScale5 = newjsonData[i][33]
                                    item.PurchUnitPriceScale1 = newjsonData[i][34]
                                    item.PurchUnitPriceScale2 = newjsonData[i][35]
                                    item.PurchUnitPriceScale3 = newjsonData[i][36]
                                    item.PurchUnitPriceScale4 = newjsonData[i][37]
                                    item.PurchUnitPriceScale5 = newjsonData[i][38]
                                    item.PurchUnitPriceUnitScale1 = newjsonData[i][39]
                                    item.PurchUnitPriceUnitScale2 = newjsonData[i][40]
                                    item.PurchUnitPriceUnitScale3 = newjsonData[i][41]
                                    item.PurchUnitPriceUnitScale4 = newjsonData[i][42]
                                    item.PurchUnitPriceUnitScale5 = newjsonData[i][43]
                                    item.ExpirationStart = newjsonData[i][50]
                                    item.Expired = newjsonData[i][51]
                                    item.RecordSalesDate = newjsonData[i][52]
                                    item.RecordSalesApplicable = newjsonData[i][53]
                                    item.PurchaseAccountDate = newjsonData[i][54]
                                    item.AcctForPurchasesApplicableble = newjsonData[i][55]
                                    item.SalesRevRate = newjsonData[i][56]
                                    item.SalesRevPrice = newjsonData[i][57]
                                    item.PurchaseRevRate = newjsonData[i][58]
                                    item.PurchaseRevPrice = newjsonData[i][59]
                                    item.ActualFlag = "0"
                                    item.HistoryNum = "2"
                                    //   item.SeqNo = String(parseInt(maxseqNo) + 1)
                                    //   newlogmsg.push(`仕入先：${newjsonData[i][5]}、MaterialCode：${newjsonData[i][2]}、SeqNo：${newjsonData[i][65]} で追加しました。`)
                                    newlogmsg.push(`  仕入先：${newjsonData[i][5]}、MaterialCode：${newjsonData[i][2]} で追加しました。`)
                                    //   maxseqNo = String(parseInt(maxseqNo) + 1)
                                    s3csvData.push(item)
                                }
                            }
                        }
                    }
                }

            }
            if(newlogmsg.length === 1){
                newlogmsg.push("エラーなし")
            }
        }
        const header = [
            "Status", "UseGroupClassification", "ItemCode", "MaterialCode", "ItemName", "SalesDocType", "SuppCode", "SuppName", "SuppGroup", "OrderReceiptCode", "OrderName", "ShipmentCode", "DestName", "EUARBmgmtCode", "EndUserName", "TorayEndUser", "TorayEndUserName",
            "Capacity", "CapacityUnit", "PriceUnit", "RegQty", "OrdQty", "DTDelOrderRec", "PriceTypeSales", "PriceTypePurchase", "SalesUnitPriceScale1", "SalesUnitPriceScale2", "SalesUnitPriceScale3", "SalesUnitPriceScale4", "SalesUnitPriceScale5", "SalesUnitPriceUnitScale1",
            "SalesUnitPriceUnitScale2", "SalesUnitPriceUnitScale3", "SalesUnitPriceUnitScale4", "SalesUnitPriceUnitScale5", "PurchUnitPriceScale1", "PurchUnitPriceScale2", "PurchUnitPriceScale3", "PurchUnitPriceScale4", "PurchUnitPriceScale5", "PurchUnitPriceUnitScale1", "PurchUnitPriceUnitScale2",
            "PurchUnitPriceUnitScale3", "PurchUnitPriceUnitScale4", "PurchUnitPriceUnitScale5", "BulkSales", "BulkShippingFee", "CoolDeliveryCharge", "SalespersonCode", "LastOrderDate", "WebOrderNumber", "ExpirationStart", "Expired", "RecordSalesDate", "RecordSalesApplicable", "PurchaseAccountDate",
            "AcctForPurchasesApplicableble", "SalesRevRate", "SalesRevPrice", "PurchaseRevRate", "PurchaseRevPrice", "ActualFlag", "HistoryNum", "Remarks", "UpDailyPayment", "UpTime", "SeqNo",
        ]

        const rows = s3csvData.map((item) => JSON.stringify(Object.values(item).map((value) => value || "")).slice(1, -1));
        const finallycsvData = header + "\n" + rows.join("\n");
        if(s3csvData.length > 0){
            const blob = new Blob([finallycsvData], { type: "text/csv;charset=utf-8;" });
            const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
            await Storage.put(`ArPriceInfo/${nowdate}.csv`, blob, {
                contentType: blob.type,
                })
        }

        oldlogmsg[0] = "価格表取り込みが正常に実行されました"
        const logcon = oldlogmsg.concat(newlogmsg)
        const logcsv = logcon.join("\n")
        setLogData(logcon)
        const blob = new Blob([logcsv], { type: "text/csv;charset=utf-8;" });
        const nowdate = moment(new Date()).format("YYYYMMDDHHmmss");
        FileSaver.saveAs(blob, "実行ログ" + nowdate + ".log");
        setopenSumbit(false)
        setLogOpen(true)
        }catch (error) {
            if (error.message === 'No current user') {
                //ユーザープールに障害が発生した場合
                console.log('User pool is invalid.Please reauthenticate.');
                //ログイン画面に戻る
                signOut();
            } else {
                //他のエラーを処理する
                console.log('An error occurred:', error);
            }
        }

    };
    const sortByMaterialCode = (a, b)=> {
          if (a[2] === null || a[2] === undefined || a[2] === "") {
            return 1;
          } else if (b[2] === null || b[2] === undefined || b[2] === "") {
            return 1;
          } else {
            return Number(a[2]) - Number(b[2])
          }
        }
        const sortBySuppGroup = (a, b)=> {
          if (a[7] === null || a[7] === undefined || a[7] === "") {
            return 1;
          } else if (b[7] === null || b[7] === undefined || b[7] === "") {
            return 1;
          } else {
            return a[7].localeCompare(b[7]);
          }
        }
        const sortByOrderReceiptCode = (a, b)=> {
          if (a[8] === null || a[8] === undefined || a[8] === "") {
            return 1;
          } else if (b[8] === null || b[8] === undefined || b[8] === "") {
            return 1;
          } else {
            return a[8].localeCompare(b[8]);
          }
        }
        const sortByShipmentCode = (a, b)=> {
          if (a[10] === null || a[10] === undefined || a[10] === "") {
            return 1;
          } else if (b[10] === null || b[10] === undefined || b[10] === "") {
            return 1;
          } else {
            return a[10].localeCompare(b[10]);
          }
        }
        const sortByEUARBmgmtCode = (a, b)=> {
          if (a[12] === null || a[12] === undefined || a[12] === "") {
            return 1;
          } else if (b[12] === null || b[12] === undefined || b[12] === "") {
            return 1;
          } else {
            return a[12].localeCompare(b[12]);
          }
        }
        const multiSort = (arr, ...sortFuncs)=> {
          return arr.sort((a, b) => {
            for (const func of sortFuncs) {
              const result = func(a, b);
              if (result !== 0) {
                return result;
              }
            }
            return 0;
          });
        }

    const submit = async () => {
        if (!inputValue.suppName) {
            handleSetErrorcode("エラーコード：1012");
            handleSetMsg1Text(" ");
            handleSetMsg2Text("仕入先名を入力してください");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, suppName: true })
            return;
        }
        if (inputValue.checkBox2 && (!inputValue.oldstorageLocal2)) {
            handleSetErrorcode("エラーコード：1015");
            handleSetMsg1Text("「既存（改定前）の価格表データ一覧ファイルの指定」が未入力です。");
            handleSetMsg2Text("ファイルの指定を行ってください。");
            handleClickConfirmOpen();
            setIsValid({ ...isValid, oldstorageLocal2:true })
            return;
        }
        try {


        if (inputValue.checkBox1) {
            handleSetErrorcode("ダウンロード情報");
            handleSetMsg1Text(" ");
            handleSetMsg2Text("格納ファイル名：" + inputValue.suppName + "_旧履歴データ.csv");
            handleClickConfirmOpen();

            try {
                const header = '"使用グループ区分","品目コード(ARB)","Material Code","品目名","販売伝票タイプ","仕入先コード","仕入先名称","受注先Gr","受注先コード","受注先名称","出荷先コード","出荷先名称","EU(ARB管理)コード","エンドユーザー名称","東レエンドユーザー","東レエンドユーザー名","容量","容量単位","価格単位","規定数量","1注文可能数","DT直送/受注後手配品(輸入品含む)","価格種別（販売）","価格種別（仕入）","販売単価(Price Scale1)","販売単価(Price Scale2)","販売単価(Price Scale3)","販売単価(Price Scale4)","販売単価(Price Scale5)","販売単価(Unit Scale1)","販売単価(Unit Scale2)","販売単価(Unit Scale3)","販売単価(Unit Scale4)","販売単価(Unit Scale5)","仕入単価(Price Scale1)","仕入単価(Price Scale2)","仕入単価(Price Scale3)","仕入単価(Price Scale4)","仕入単価(Price Scale5)","仕入単価(Unit Scale1)","仕入単価(Unit Scale2)","仕入単価(Unit Scale3)","仕入単価(Unit Scale4)","仕入単価(Unit Scale5)","出荷単位時のﾊﾞﾗ販売可否","ﾊﾞﾗ出荷手数料","クール便送料","営業担当者コード","前回受注年月日","WEB受注番号","有効期限開始","有効期限終了","売上計上(日付)","売上計上(適用)","仕入計上(日付)","仕入計上(適用)","販売改訂率(%)","販売改訂価格","仕入改訂率(%)","仕入改訂価格","実績フラグ","履歴番号","備考","更新日付","更新時刻","Seq№"\n'
                const keys_order = [
                    "UseGroupClassification", "ItemCode", "MaterialCode", "ItemName", "SalesDocType", "SuppCode", "SuppName", "SuppGroup", "OrderReceiptCode", "OrderName", "ShipmentCode", "DestName", "EUARBmgmtCode", "EndUserName", "TorayEndUser", "TorayEndUserName",
                    "Capacity", "CapacityUnit", "PriceUnit", "RegQty", "OrdQty", "DTDelOrderRec", "PriceTypeSales", "PriceTypePurchase", "SalesUnitPriceScale1", "SalesUnitPriceScale2", "SalesUnitPriceScale3", "SalesUnitPriceScale4", "SalesUnitPriceScale5", "SalesUnitPriceUnitScale1",
                    "SalesUnitPriceUnitScale2", "SalesUnitPriceUnitScale3", "SalesUnitPriceUnitScale4", "SalesUnitPriceUnitScale5", "PurchUnitPriceScale1", "PurchUnitPriceScale2", "PurchUnitPriceScale3", "PurchUnitPriceScale4", "PurchUnitPriceScale5", "PurchUnitPriceUnitScale1", "PurchUnitPriceUnitScale2",
                    "PurchUnitPriceUnitScale3", "PurchUnitPriceUnitScale4", "PurchUnitPriceUnitScale5", "BulkSales", "BulkShippingFee", "CoolDeliveryCharge", "SalespersonCode", "LastOrderDate", "WebOrderNumber", "ExpirationStart", "Expired", "RecordSalesDate", "RecordSalesApplicable", "PurchaseAccountDate",
                    "AcctForPurchasesApplicableble", "SalesRevRate", "SalesRevPrice", "PurchaseRevRate", "PurchaseRevPrice", "ActualFlag", "HistoryNum", "Remarks", "UpDailyPayment", "UpTime", "SeqNo",
                ]

                const priceData = await queryArPrice();

                const uniqueDataMap = new Map();
                priceData.dataAll?.forEach(item => {
                const key = Object.values(item).slice(0, -2).join('-');
                if (item.ActualFlag === "0" && (!uniqueDataMap.has(key) || parseInt(item.HistoryNum) > parseInt(uniqueDataMap.get(key).HistoryNum))) {
                    uniqueDataMap.set(key, item);
                }
                });
                const uniqueData = Array.from(uniqueDataMap.values());
                let arr = [];
                let csvarr = []
                const searchValue = {
                    SuppName: inputValue.suppName
                }
                try {
                    arr = await searchData(searchValue)
                } catch (error) {
                    if (error.message === 'No current user') {
                        //ユーザープールに障害が発生した場合

                        //ログイン画面に戻る
                        signOut();
                    } else {
                        //他のエラーを処理する

                    }
                }

                if(arr.length > 0){
                arr.forEach(item => {
                    const valuesInOrder = keys_order.map(key => item[key]);

                    csvarr.push(valuesInOrder)
                  });
                }
                const seqNos = uniqueData.map((item) =>item.SeqNo);
                const filterdata = csvarr.filter(val=>seqNos.includes(val[65]) && val[60] === "0")

                const sortarr = multiSort(filterdata, sortByMaterialCode,sortBySuppGroup,sortByOrderReceiptCode,sortByShipmentCode,sortByEUARBmgmtCode);
                const resultString = sortarr.map(subArray => JSON.stringify(subArray.map((value) => value || "")).slice(1, -1)).join("\n");

                const csvData = header + resultString
                if(inputValue.oldstorageLocal1){
                    await writeFile(fileHandle,csvData)
                }else{
                    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
                    FileSaver.saveAs(blob, inputValue.suppName + "_旧履歴データ.csv");

                    // const wb = XLSX.utils.book_new();
                    // console.log(csvarr)
                    // const ws = XLSX.utils.json_to_sheet(csvarr);
                    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                    // XLSX.writeFile(wb, 'YourFileName.xlsx');

                }

            } catch (error) {
                if (error.message === 'No current user') {
                    //ユーザープールに障害が発生した場合
                    console.log('User pool is invalid.Please reauthenticate.');
                    //ログイン画面に戻る
                    signOut();
                } else {
                    //他のエラーを処理する
                    console.log('An error occurred:', error);
                }
                // return gaEvent("Error", "FileSaveAs2", e.name),
                // alert("Unable to save file.")
                // void gaEvent("Error", "Unable to write file", "FSAccess")
            }
            setopenSumbit(false)
            return
        }

        if(inputValue.checkBox2 && (inputValue.oldstorageLocal2 || inputValue.newstorageLocal2)){
            handleSetMsg1Text("価格表データをアップロードし、価格改定の処理を行っています。");
            handleSetMsg2Text("しばらくお待ちください。");
            setopenSumbit(true)
            const priceData = await queryArPrice();

            const uniqueDataMap = new Map();
            priceData.dataAll?.forEach(item => {
            const key = Object.values(item).slice(0, -2).join('-');
            if (!uniqueDataMap.has(key) || parseInt(item.HistoryNum) > parseInt(uniqueDataMap.get(key).HistoryNum)) {
                uniqueDataMap.set(key, item);
            }
            });
            const uniqueData = Array.from(uniqueDataMap.values());
            await readXlsxFile(priceData,uniqueData)
            if(inputValue.newstorageLocal2 && inputValue.oldstorageLocal2){
                setOldUploadFile(null)
                setNewUploadFile(null)
                setinputValue({ ...inputValue, oldstorageLocal2: "", newstorageLocal2: ""})
            }else if(inputValue.newstorageLocal2){
                setNewUploadFile(null)
                setinputValue({ ...inputValue, newstorageLocal2: "" })
            }else if(inputValue.oldstorageLocal2){
                setOldUploadFile(null)
                setinputValue({ ...inputValue, oldstorageLocal2: "" })
            }
            setopenSumbit(false)
        }
        } catch (error) {
            if (error.message === 'No current user') {
                //ユーザープールに障害が発生した場合
                console.log('User pool is invalid.Please reauthenticate.');
                //ログイン画面に戻る
                signOut();
            } else {
                //他のエラーを処理する
                console.log('An error occurred:', error);
            }
        }


        // param.closedialogArPriceRevisionScreen()
    }
    const checked1 = () => {
        setinputValue({ ...inputValue, checkBox1: !inputValue.checkBox1, checkBox2: !inputValue.checkBox2, oldstorageLocal2:"", newstorageLocal2:"" })
    }
    const checked2 = () => {
        setinputValue({ ...inputValue, checkBox2: !inputValue.checkBox2, checkBox1: !inputValue.checkBox1, oldstorageLocal1:"" })
    }
    const signOut = async () => {
        await Auth.signOut();
        try {
            await Auth.signOut();
        } catch (error) {
            console.log("error signing out: ", error);
        }
        document.location.reload();
    };

    // const getprice = async () =>{
    //     try {
    //         const priceinfo = await queryArPrice();

    //         // setPriceData(priceinfo)
    //         const uniqueDataMap = new Map();
    //         priceinfo.dataAll?.forEach(item => {
    //         const key = Object.values(item).slice(0, -2).join('-');
    //         if (!uniqueDataMap.has(key) || parseInt(item.HistoryNum) > parseInt(uniqueDataMap.get(key).HistoryNum)) {
    //             uniqueDataMap.set(key, item);
    //         }
    //         });
    //         const uniqueinfo = Array.from(uniqueDataMap.values());
    //         // setUniqueData(uniqueinfo)
    //     } catch (error) {
    //         console.log("error",error);
    //     }
    // }

    useEffect(async () => {
        setSearchProgress(true)
        // let user = ""
        // try {
        //     user = await Auth.currentUserInfo();
        //     const agencyPriceGroup = await API.graphql(
        //         graphqlOperation(customerByeMail, {
        //             Agency_Email: user.attributes.email,
        //         })
        //     );
        //     const agentinfo = agencyPriceGroup.data.customerByeMail !== null ? agencyPriceGroup.data.customerByeMail["items"] : []
        //     if (agentinfo.length > 0) {
        //         const Permission = agentinfo[0].Permission
        //     }
        // } catch (error) {
        //     if (error.message === 'No current user') {
        //         //ユーザープールに障害が発生した場合
        //         console.log('User pool is invalid.Please reauthenticate.');
        //         //ログイン画面に戻る
        //         signOut();
        //     } else {
        //         //他のエラーを処理する
        //         console.log('An error occurred:', error);
        //         //ログイン画面に戻る
        //         signOut();
        //     }
        // }
        // try {
        //     const priceinfo = await queryArPrice();

        //     setPriceData(priceinfo)
        //     const uniqueDataMap = new Map();
        //     priceinfo.dataAll?.forEach(item => {
        //     const key = Object.values(item).slice(0, -2).join('-');
        //     if (!uniqueDataMap.has(key) || parseInt(item.HistoryNum) > parseInt(uniqueDataMap.get(key).HistoryNum)) {
        //         uniqueDataMap.set(key, item);
        //     }
        //     });
        //     const uniqueinfo = Array.from(uniqueDataMap.values());
        //     setUniqueData(uniqueinfo)
        // } catch (error) {
        //     console.log("error",error);
        // }
        // await getprice()
        setSearchProgress(false)
    }, [])

    const getNewFileHandle = ()=> {
        if ('showSaveFilePicker' in window) {
          const opts = {
            types: [{
            //   description: 'csv file',
            //   accept: {'text/plain': ['.csv']},
              description: "CSV Files",
              accept: {
                "text/csv": [".csv"],
              },
            }],
            excludeAcceptAllOption:true,
            suggestedName:inputValue.suppName + "_旧履歴データ.csv"
          };
          return window.showSaveFilePicker(opts);
        }
        const opts = {
          type: 'save-file',
          accepts: [{
            description: 'csv file',
            extensions: ['csv'],
            mimeTypes: ['text/csv'],
          }],
        };
        return window.chooseFileSystemEntries(opts);
      }

     const writeFile = async (fileHandle, contents) => {
        if (fileHandle.createWriter) {
          const writer = await fileHandle.createWriter();
          await writer.write(0, contents);
          await writer.close();
          return;
        }
        const writable = await fileHandle.createWritable();
        await writable.write(contents);
        await writable.close();
      }

    const saveFile = async ()=>{
        if(inputValue.checkBox1){
            let newFileHandle;
            try {
                newFileHandle = await getNewFileHandle()
            } catch (e) {
                console.log("error",e);
                if ("AbortError" === e.name)
                    return ;
                // return gaEvent("Error", "FileSaveAs1", e.name),
                void alert("An error occured trying to open the file.")
            }




            setSaveFile(newFileHandle)
            dialogTextChange("oldstorageLocal1",newFileHandle.name)

        }else{
            return
        }

    }

    const getUploadFileHandle = () => {
        const pickerOpts = {
            types: [
                {
                    description: 'Excel files',
                    accept: { 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] },
                },
            ],
            excludeAcceptAllOption: true,
            multiple: false,
          };
        if ('showOpenFilePicker' in window) {
          return window.showOpenFilePicker(pickerOpts).then((handles) => handles[0]);
        }
        return window.chooseFileSystemEntries();
    }

    const getFileHandle = async (type)=>{
        if(inputValue.checkBox2){
            let newgetFileHandle;
            try {
                newgetFileHandle = await getUploadFileHandle()
            } catch (e) {
                console.log("error",e);
                if ("AbortError" === e.name)
                    return;
                void alert("An error occured trying to open the file.")
            }
            const file = await newgetFileHandle.getFile();




            if(type === "old"){
                setOldUploadFile(file)
                dialogTextChange("oldstorageLocal2",newgetFileHandle.name)
            }else if(type === "new"){
                setNewUploadFile(file)
                dialogTextChange("newstorageLocal2",newgetFileHandle.name)
            }
        }else{
            return
        }

    }
    const handleClearClick = (value)=>{
        if(value === "oldstorageLocal1"){
            setNewUploadFile(null)
            dialogTextChange("oldstorageLocal1","")
        }else if(value === "newstorageLocal2"){
            setNewUploadFile(null)
            dialogTextChange("newstorageLocal2","")
        }else if(value === "oldstorageLocal2"){
            setOldUploadFile(null)
            dialogTextChange("oldstorageLocal2","")
        }
    }

    return (
        <div style={{ width: "1000px", fontSize: "14px" }}>
            <div className={classes.textDivDialog} style={{ height: "60px" }}>
                <InputGroup className="mb-3" style={{ height: "30px" }}>
                    <label className={classes.textlabelDialog} style={{ width: "140px", textAlign: "left" }}>
                        <InputBase
                            className={classes.textlabelSpanDialog}
                            defaultValue="（必須）仕入先名称"
                            inputProps={{ 'aria-label': 'naked' }}
                            disabled={true}
                            style={{color:"black" }}
                        />
                    </label>
                    <span className={classes.textDivDialog} style={{ display: "inline-block" }}>
                        <Autocomplete
                            className={classes.autocomplete}
                            sx={{
                                display: 'inline-block',
                                '& input': {
                                    width: 440,
                                    height: 0,
                                    bgcolor: 'background.paper',
                                    color: (theme) =>
                                        theme.palette.getContrastText(theme.palette.background.paper),
                                },
                            }}
                            options={param.SuppNameList}
                            style={{ width: "440px", height: "25px", borderRadius: "4px", marginLeft: "5%" }}
                            value={inputValue.SuppName}
                            name="suppName"
                            id="suppName"
                            disabled={searchProgress}
                            onChange={(e, value) => { dialogTextsuppNameChange(e, value) }}
                            renderInput={(params) => <TextField {...params} label="" variant="outlined"style={{ borderRadius: "4px", border: isValid.suppName === true ? "1px solid pink" : "1px solid #707070", }} />}
                        />
                    </span>
                </InputGroup>
            </div>
            <DialogContent style={{maxHeight:600}} >
                <div style={{ marginLeft: "30px", marginBottom:"10px" }}>
                    <div style={{ height: "30px", marginLeft:"24px" }}>仕入先別価格表データのダウンロード（csv）</div>
                    <div style={{ display: "flex" }}>
                        <div style={{ lineHeight: "60px" }}>
                            <input type="radio" className={classes.inputSize1} onChange={() => { checked1() }} name="checkBox1" id="checkBox1" checked={inputValue.checkBox1} />
                        </div>
                        <div style={{ width: "800px", backgroundColor: "RGB(217,217,217)", }}>
                            <div>
                                <div style={{margin:16,marginLeft:20}}>
                                    <span className={classes.textlabelSpanDialog} >ファイルを保存する場所の指定（ファイル名まで指定可）</span>
                                </div>
                                <div className={classes.textDivDialog} style={{ marginLeft:30,marginBottom:16 }}>
                                        <Paper elevation={0} component="form" style={{border:isValid.oldstorageLocal1 ? "1px solid pink" : "1px solid #707070", width:"90%"}} >
                                            <div style={{display:"inline-block"}} onClick={()=>{ (inputValue.suppName && inputValue.checkBox1) ? saveFile() : ""}}>
                                            <span style={{padding:5,paddingBottom:7,borderRight:"1px solid #707070",width: '120px', display: 'inline-block',backgroundColor: "RGB(217,217,217)"}}>
                                            ファイルを選択
                                            </span>
                                            <div style={{display:"inline-block",paddingLeft:6}} >
                                            <InputBase
                                                className={classes.input}
                                                placeholder="選択されていません"
                                                variant="filled"
                                                style={{fontSize:"14px", color:"black",width:"440px"}}
                                                onChange={saveFile}
                                                disabled={ !(inputValue.suppName && inputValue.checkBox1)}
                                                value={inputValue.oldstorageLocal1}

                                            />
                                            </div>
                                            </div>
                                            {inputValue.oldstorageLocal1 && (
                                                    <IconButton
                                                    style={{padding:"0",margin:4, float:"right"}}
                                                    onClick={()=>handleClearClick("oldstorageLocal1")}>
                                                    <ClearIcon />
                                                    </IconButton>
                                            )}
                                            </Paper>

                                </div>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
                <br></br>
                <div style={{ marginLeft: "30px" }}>
                    <div style={{ height: "30px", marginLeft:"24px" }}>仕入先別価格表データのアップロード（xlsx）</div>
                    <div style={{ display: "flex" }}>
                        <div style={{ lineHeight: "60px" }}>
                            <input type="radio" className={classes.inputSize1} name="checkBox2" id="checkBox2" onChange={() => checked2()} checked={inputValue.checkBox2} />
                        </div>
                        <div style={{ width: "800px", backgroundColor: "RGB(217,217,217)", }}>
                            <div>
                                <div style={{margin:16,marginLeft:20}}>
                                    <span className={classes.textlabelSpanDialog} >既存（改定前）の価格表データ一覧ファイルの指定</span>
                                </div>
                                <div className={classes.textDivDialog} style={{ marginLeft:30,marginBottom:16 }}>
                                        <Paper elevation={0} component="form" style={{border:isValid.oldstorageLocal2 ? "1px solid pink" : "1px solid #707070", width:"90%"}} >
                                        <div style={{display:"inline-block"}} onClick={()=>getFileHandle("old")}>
                                            <span style={{padding:5,paddingBottom:7,borderRight:"1px solid #707070",width: '120px', display: 'inline-block',backgroundColor: "RGB(217,217,217)"}}>
                                            ファイルを選択
                                            </span>
                                            <div style={{display:"inline-block",paddingLeft:6}} >
                                            <InputBase
                                                className={classes.input}
                                                placeholder="選択されていません"
                                                variant="filled"
                                                style={{fontSize:"14px", color:"black",width:"440px"}}
                                                onChange={()=>{getFileHandle("old")}}
                                                value={inputValue.oldstorageLocal2}
                                                disabled={!inputValue.checkBox2}
                                            />
                                            </div>
                                        </div>
                                        {inputValue.oldstorageLocal2 && (
                                                    <IconButton
                                                    style={{padding:"0",margin:4, float:"right"}}
                                                    onClick={()=>handleClearClick("oldstorageLocal2")}>
                                                    <ClearIcon />
                                                    </IconButton>
                                            )}
                                            </Paper>
                                </div>
                                <div style={{margin:16,marginLeft:20}}>
                                    <span className={classes.textlabelSpanDialog} >価格改定結果を追加する価格表データ一覧ファイルの指定</span>
                                </div>
                                <div className={classes.textDivDialog} style={{ marginLeft:30,marginBottom:16 }}>
                                        <Paper elevation={0} component="form" style={{border:isValid.newstorageLocal2 ? "1px solid pink" : "1px solid #707070", width:"90%"}} >
                                        <div style={{display:"inline-block"}} onClick={()=>{getFileHandle("new")}} >
                                            <span style={{padding:5,paddingBottom:7,borderRight:"1px solid #707070",width: '120px', display: 'inline-block',backgroundColor: "RGB(217,217,217)"}}>
                                            ファイルを選択
                                            </span>
                                            <div style={{display:"inline-block",paddingLeft:6}} >
                                            <InputBase
                                                className={classes.input}
                                                placeholder="選択されていません"
                                                variant="filled"
                                                style={{fontSize:"14px", color:"black",width:"440px"}}
                                                onChange={()=>{getFileHandle("new")}}
                                                disabled={!inputValue.checkBox2}
                                                value={inputValue.newstorageLocal2}
                                            />
                                            </div>
                                            </div>
                                            {inputValue.newstorageLocal2 && (
                                                    <IconButton
                                                    style={{padding:"0",margin:4, float:"right"}}
                                                    onClick={()=>handleClearClick("newstorageLocal2")}>
                                                    <ClearIcon />
                                                    </IconButton>
                                            )}
                                            </Paper>
                                </div>
                                <br></br>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
            <div style={{ width: "100%", height: "40px", textAlign: "right" }}>
                <Button onClick={() => param.closedialogArPriceRevisionScreen()} className="btn" style={{ height: 40, width: 100, fontSize: '15px', color: 'white', background: "#000", marginRight: '10px', borderRadius: '8px' }}>戻&nbsp;&nbsp;&nbsp;る
                </Button>
                <Button className="btn" onClick={() => submit()} style={{ height: 40, width: 100, fontSize: '15px', color: 'white', background: "#000", border: "1px solid #000", marginRight: '10px', borderRadius: '8px' }}>実&nbsp;&nbsp;&nbsp;行
                </Button>
                <Button className="btn" onClick={() => { Executionlog() }} style={{ height: 40, width: 100, fontSize: '15px', color: 'white', background: "#000", border: "1px solid #000", marginRight: '50px', borderRadius: '8px' }}>実行ログ
                </Button>
            </div>
            </DialogActions>
            <Dialog
                onClose={handleConfirmClose}
                aria-labelledby="customized-dialog-title"
                open={openConfirm}
                PaperProps={{
                    style: {
                        backgroundColor: '#FFCC00',
                        boxShadow: 'none',
                        width: "533px",
                        minheight: "220px"
                    },
                }}>
                <DialogContent dividers style={{ textAlign: "center", color: "white" }}>
                    <Typography gutterBottom variant="h6" style={{ color: "white", marginTop: "10px" }} className={classes.textFieldDialog}>
                        {errorcode}
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textFieldDialog}>
                        {msg1Text}
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textField2Dialog}>
                        {msg2Text}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => {
                        handleConfirmOK();
                    }} color="primary" variant="contained">
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                aria-labelledby="customized-dialog-title"
                open={openSumbit}
                PaperProps={{
                    style: {
                        backgroundColor: '#FFCC00',
                        boxShadow: 'none',
                        // width: "600px",
                        maxWidth : "700px",
                        height: "220px"
                    },
                }}>
                <DialogContent dividers style={{ textAlign: "center", color: "white" }}>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textFieldDialog}>
                      &nbsp;&nbsp;
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textFieldDialog}>
                      {/* 価格表データをアップロードし、価格改定の処理を行っています。 */}
                      {msg1Text}
                    </Typography>
                    <Typography gutterBottom variant="h6" style={{ color: "white" }} className={classes.textField2Dialog}>
                      {/* しばらくお待ちください。 */}
                      {msg2Text}
                    </Typography>
                </DialogContent>
            </Dialog>
            <Dialog
                open={logOpen}

                style={{maxHeight:600,overflow:"auto",top:100}}
                maxWidth='xl'
            >
                <DialogTitle>
                  実行ログ <Button style={{float:"right"}}><CloseIcon onClick={handlelogClose} /></Button>
                </DialogTitle>
                <DialogContent style={{margin:5,padding:20,border:"1px solid black",minHeight:300}}>
                {logData.map((val,index)=>{
                 return(
                    val.startsWith("1.") || val.startsWith("2.") || val.startsWith("価格表") ?
                        <DialogContentText key={index} style={{marginBottom:4,color:"black"}}>
                        {val}
                        </DialogContentText> :
                        <DialogContentText key={index} style={{marginBottom:4,color:"black"}}>
                        &nbsp;&nbsp;&nbsp;&nbsp;{val}
                        </DialogContentText>
                    )
                })}
                </DialogContent>
            </Dialog>
            <div style={{ position: "fixed", left: "50%", top: "50%", display: searchProgress === true ? "block" : "none" }}>
                    <CircularProgress />
            </div>
        </div>
    )
}