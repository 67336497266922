/* eslint-disable no-irregular-whitespace */
/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  InventorySearchEmd.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2023/06/27 Rayoo)Fu : 新規作成
 *
 * Summary InventorySearchEmd画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
 import "./style/ar.css";
 import Meta from "../components/Meta";
 import userAuth from "../components/userAuth";
 import React, { useEffect,useReducer,useState} from "react";
 import { useLocation } from "react-router-dom";
 import TextField from "@material-ui/core/TextField";
 import { MuiPickersUtilsProvider } from '@material-ui/pickers';
 import moment from "moment";
 import { DatePicker } from '@material-ui/pickers';
 import DateFnsUtils from '@date-io/date-fns';
 import jaLocale from 'date-fns/locale/ja';
 import Button from "@material-ui/core/Button";
 import { Container, Row, Col } from "react-bootstrap";
 import BootstrapTable from "react-bootstrap-table-next";
 import ToolkitProvider from "react-bootstrap-table2-toolkit";
 import paginationFactory from "react-bootstrap-table2-paginator";
 import API, { graphqlOperation } from "@aws-amplify/api";
 import { makeStyles} from "@material-ui/core/styles";
 import CircularProgress from '@material-ui/core/CircularProgress';
 import Autocomplete from '@mui/material/Autocomplete';
import {
   listArAgencyOrderInfosEmd,
   queryArPriceInfosByQueryIDIndex,
   customerByeMail,
   listArcalendars,
   listArOrderRecipients,queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex,
   queryArCustomerAddressesByCustomerReceiptCodeIndex,
   queryArFukuiWHSeinoLTSByPostCodeIndex,
   listArItemMasters
 } from "../graphql/queries";
 import {
   createArAgencyOrderInfo,
   updateArAgencyOrderInfo,
   deleteArAgencyOrderInfo
 } from "../graphql/mutations";
 /*  AWS標準設定 with Login Auth. */
 import Amplify , { Auth }from "aws-amplify";
 import awsconfig from "../aws-exports";
 Amplify.configure(awsconfig);
 const pageTitle = "AR BROWN Order System";
 import { InputGroup } from "react-bootstrap";
 import cellEditFactory from "react-bootstrap-table2-editor";
 import LinearProgress from '@material-ui/core/LinearProgress';
 import "../components/ArGlobal";
 import { useHistory } from "react-router-dom";
 import { v4 as uuidv4 } from "uuid";
 import Dialog from "@material-ui/core/Dialog";
 import DialogActions from "@material-ui/core/DialogActions";
 import DialogContent from "@material-ui/core/DialogContent";
 import DialogContentText from "@material-ui/core/DialogContentText";
 import DialogTitle from "@material-ui/core/DialogTitle";
 const QUERY = "QUERY";
 const initialInventoryState = { Invs: [] };
 const reducer = (state, action) => {
   switch (action.type) {
     case QUERY:
       return { ...state, Invs: action.Invs };
     default:
       return state;
   }
 };
 const useStyles = makeStyles((theme) => ({
   textField: {
     marginLeft: theme.spacing(1),
     marginRight: theme.spacing(1),
     width: "30ch",
   },
   autocomplete: {
    width:"55ch",
    border:'1px solid black',
  },
   textFieldTop: {
     marginLeft: theme.spacing(1),
     marginRight: theme.spacing(1),
     background: "white",
     border: "1px solid #707070",
     borderRadius: "6px",
     color: "black",
   },
   textFieldTop1: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: "white",
    border: "1px solid #707070",
    borderRadius: "6px",
    color: "black",
  },
   datePicker: {
     width: "25ch",
   },
   textFieldHope: {
     marginLeft: theme.spacing(0),
     color: "black",
     width: "9rem",
     border: "none",
     marginRight: "1em",
     fontWeight: "bold",
     background: "none",
   },
   //入力ボックスのセルの設定
   textFieldOrderQuantity: {
     margin: theme.spacing(0),
     position: "relative",
     height: "100%",
     textAlign: "center",
   },
   resize: {
     height: "100%",
     textAlign: "center",
   },
   textlabel: {
     marginLeft: theme.spacing(1),
     marginRight: theme.spacing(1),
     height:'30px',
     width:'15ch',
     background: "rgb(91,56,9)",
     border: "1px solid #707070",
     color: "white",
     fontWeight: "bold",
     fontSize:'14px',
     alignItems: 'center',
     justifyContent:'center'
   },
   textlabelOrder: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    height:'30px',
    width:'16ch',
    background: "rgb(91,56,9)",
    color: "white",
    fontWeight: "bold",
    fontSize:'14px',
    alignItems: 'center',
    justifyContent:'center'
  },
  search1:{
    width:'100%',height:'100%',
    // width:'100%',height:'100%',display:searchPage === true ? 'none' : "block"
  },
  search2:{
    width:'100%',height:'100%'
  }
 }));
 const headerStyle = {
   padding:0,
   fontSize:"14px",
   backgroundColor: "white",
   borderBottom: "3px solid #804F29",
 };
 let orderselect_row = [];
 let gobackArr = [];
 let orderlastContractor = [];
 let orderlastContractorCopy = [];
 let checkedArr = [];
 let agencyId = "";
 let salesGroup = "162";
 let agencyEmail = "";
let Agency_Name = "";
let Company_Name = "";
let User_Name = "";
let AssigneeCode = "";
let subId = "";
 let orderCodeChecked = ""
 function InventorySearch() {
   const [inventories, setInv] = useReducer(reducer, initialInventoryState);
   let inventoriesCopy = []
   const [loaddingFlag, setLoadingFlag] = useState(false);
   const [pageInitFlag,setPageInitFlag] = useState(false)
   const [loaddingFlag1, setLoadingFlag1] = useState(false);
   const [userAuthInfo,setUseAuthInfo] = useState("")
   const aBoutOrderQuantityFun = (dataAll)=>{
    for (let ele of inventoriesCopy) {
      for (let item of dataAll) {
        if(item.id === ele.id){
          item.OrderQuantity = ele.OrderQuantity
          break;
        }
      }
     }
    return dataAll
   }
   function numtomoney(number, toFixed) {
    let str = [];
    if (!number) return 0;
    if (typeof number === "number") number = String(number);
    let tempNumber = number.split(".");
    number = tempNumber[0];
    if (number.length <= 3) {
      if (tempNumber[1] && toFixed !== 0) {
        number += "." + tempNumber[1].slice(0, toFixed);
      }
      return number;
    }
    number
      .split("")
      .reverse()
      .forEach((item, index) => {
        if (index !== 0 && index % 3 === 0) {
          str.push(",", item);
        } else {
          str.push(item);
        }
      });
    number = str.reverse().join("");
    if (tempNumber[1] && toFixed !== 0) {
      number += "." + tempNumber[1].slice(0, toFixed);
    }
    return number;
  }
  function moneytonum(val) {
    let num = val ? val.trim() : ""
    let ss = num.toString();
    if (ss.length === 0) {
      return "0";
    }
    return ss.replace(/,/g, "");
  }
  const handleSetCount = (id, Object) => {
     if (Object === "" || Object === null || Object === undefined) {
       setQ(id, "");
       return;
     }
     if (isNaN(Object)) {
       setQ(id, "");
       return;
     }
     if (Object <= 0) {
       setQ(id, "");
       return;
     }
     setQ(id, Object);
   };
   function setQ(id, num) {
     for (let j = 0; j < inventories.Invs.length; j++) {
       if (inventories.Invs[j].id === id) {
        inventories.Invs[j].OrderQuantity = num;
         break;
       }
     }
      }
    function isValidNumber(str) {
    const regex = /^[0-9]*\.?[0-9]+$/;
    return regex.test(str);
  }
   let dataAll = [];
   let columns = [
     { dataField: "id", text: "ID", hidden: true },
     {
       dataField: "ItemCode",
       text: "品目",
       headerStyle:{...headerStyle,
         minWidth:"120px",
         maxWidth:"120px"},
       type: "string",
       editable: false,
       fixed: "left",
       style: {maxWidth: "180px",width: "180px",padding:0,marign:0},
     },
     {
       dataField: "ItemName",
       text: "品目名",
       headerStyle:{...headerStyle,
         minWidth:"100px",
         maxWidth:"100px"},
       editable: false,
       type: "string",
       style: {minWidth:"120px",width: "200px",padding:0,marign:0},
     },
     {
       dataField: "SuppName",
       text: "仕入先",
       style: {maxWidth: "200px",padding:0,marign:0 },
       headerStyle:{...headerStyle,
         minWidth:"120px",
         maxWidth:"120px"},
       type: "string",
       editable: false,
     },
     {
       dataField: "SuppGroup",
       text: "受注先Gr",
       headerStyle:{...headerStyle,
         minWidth:"50px",
         maxWidth:"50px"},
       type: "string",
       editable: false,
       style: {maxWidth: "50px",minWidth:"50px",padding:0,marign:0 },
     },
     {
       dataField: "OrderName",
       text: "受注先",
       style: {maxWidth: "200px",minWidth:"100px",padding:0,marign:0 },
       headerStyle:{...headerStyle,
         minWidth:"120px",
         maxWidth:"120px"},
       type: "string",
       editable: false,
     },
     {
       dataField: "DestName",
       text: "出荷先",
       style: {maxWidth: "200px",minWidth:"100px",padding:0,marign:0,wordWrap: "break-word"},
       headerStyle:{...headerStyle,
         minWidth:"120px",
         maxWidth:"120px"},
       type: "string",
       editable: false,
     },
     {
       dataField: "EndUserName",
       text: "エンドユーザー",
       style: {maxWidth: "200px",minWidth:"150px",padding:0,marign:0 },
       headerStyle:{...headerStyle,
         minWidth:"120px",
         maxWidth:"120px"},
       type: "string",
       editable: false,
     },
     {
       dataField: "Capacity",
       text: "容量",
       headerStyle:{...headerStyle,
         minWidth:"40px",
         maxWidth:"40px"},
       editable: false,
       style: {maxWidth:"60px",minWidth:"60px",padding:0,marign:0},
       formatter: (cellContent, row) => {
        if (row.id !== null) {
          return (
            <span>{row.Capacity}{row.CapacityUnit}</span>
          );
        }
      },
     },
     {
       dataField: "PriceUnit",
       text: "価格単位",
       headerStyle:{...headerStyle,
         minWidth:"50px",
         maxWidth:"50px"},
       align: "left",
       headerAlign: "left",
       editable: false,
       style: {
         maxWidth: "50px",minWidth:"50px",padding:0,marign:0
       },
     },
     {
       dataField: "RegQty",
       text: "規定数量",
       headerStyle:{...headerStyle,
         minWidth:"50px",
         maxWidth:"50px"},
       align: "right",
       style: {
         maxWidth: "50px",minWidth:"50px",padding:0,marign:0
       },
       editable: false,
     },
     {
       dataField: "PriceTypePurchase",
       text: "価格種別（仕入）",
       headerStyle:{...headerStyle,
         minWidth:"60px",
         maxWidth:"60px"},
       align: "left",
       style: {maxWidth: "60px",minWidth:"60px",padding:0,marign:0},
       editable: false,
     },
     {
       dataField: "SalesUnitPriceScale1",
       text: "販売単価(Price Scale1)",
       headerStyle:{...headerStyle,
         minWidth:"60px",
         maxWidth:"60px"},
       align: "right",
       editable: false,
       style: {
         maxWidth: "60px",minWidth:"60px",padding:0,marign:0
       },
       formatter: (cellContent, row) => {
        if (row.SalesUnitPriceScale1 !== null) {
          return (
            <p>{numtomoney(isValidNumber(moneytonum(row.SalesUnitPriceScale1)) === false ? "" : row.SalesUnitPriceScale1)}</p>
          );
        }
      },
     },
     {
       dataField: "SalesUnitPriceUnitScale1",
       text: "販売単価(Unit Scale1)",
       headerStyle:{...headerStyle,
         minWidth:"60px",
         maxWidth:"60px"},
       align: "right",
       editable: false,
       style: {
         maxWidth: "60px",minWidth:"60px",padding:0,marign:0
       },
     },
     {
       dataField: "PurchUnitPriceScale1",
       text: "仕入単価(Price Scale1)",
       headerStyle:{...headerStyle,
         minWidth:"60px",
         maxWidth:"60px"},
       align: "right",
       style: {
         maxWidth: "60px",minWidth:"60px",padding:0,marign:0
       },
       formatter: (cellContent, row) => {
        if (row.PurchUnitPriceScale1 !== null) {
          return (
            <p>{numtomoney(isValidNumber(moneytonum(row.PurchUnitPriceScale1)) === false ? "" : row.PurchUnitPriceScale1)}</p>
          );
        }
      },
       editable: false,
     },
     {
       dataField: "PurchUnitPriceUnitScale1",
       text: "仕入単価(Unit Scale1)",
       headerStyle:{...headerStyle,
         minWidth:"60px",
         maxWidth:"60px"},
       align: "right",
       editable: false,
       style: {
         maxWidth: "60px",minWidth:"60px",padding:0,marign:0
       },
     },
     {
       dataField: "BulkSales",
       text: "出荷単位時のﾊﾞﾗ販売可否",
       headerStyle:{...headerStyle,
         minWidth:"60px",
         maxWidth:"60px"},
       style: {
         maxWidth: "60px",minWidth:"60px",padding:0,marign:0
       },
       align: "right",
       editable: false,
     },
     {
       dataField: "OrderQuantity",
       style: {
         margin: "0px",
         padding: "0px 0px",
         fontSize: "18px",
         height: "40px",
       },
       text: "発注数",
       editable: true,
       type: "number",
       headerStyle :{
         margin:0,
         padding:0,
         fontSize:"14px",
         minWidth: "80px",
         maxWidth:"80px",
         background:"white",
         borderBottom: "3px solid #804F29",
       },
       events: {
       },
       formatter: (cellContent, row,index) => {
        row.OrderQuantity = inventories.Invs[index].OrderQuantity
         if (row.id !== null) {
           return (
             <TextField
               autoComplete="off"
               id={row.id}
               variant="outlined"
               style={{ alignItems: "center" }}
               onChange={(e) => {
                 handleSetCount(
                   row.id,
                   e.target.value
                 );
               }}
               name="OrderQuantity"
               value={inventories.Invs[index].OrderQuantity}
               InputLabelProps={{
                 className: classes.resize,
               }}
               className={classes.textFieldOrderQuantity}
               InputProps={{
                 disableunderline: "true",
                 className: classes.resize,
               }}
             />
           );
         }
       },
     },
   ];
   const location = useLocation();
   //福井西濃LT
   const FukuiSeinoLTFun = async (postCode) => {
    let leadTime = 0
    let infoArr = []
    const PostCodeInfo = await API.graphql(graphqlOperation(queryArFukuiWHSeinoLTSByPostCodeIndex,
      { PostCode: postCode }));
    if (PostCodeInfo.data.queryArFukuiWHSeinoLTSByPostCodeIndex !== null) {
      let nextToken = PostCodeInfo.data.queryArFukuiWHSeinoLTSByPostCodeIndex.nextToken;
      infoArr = PostCodeInfo.data.queryArFukuiWHSeinoLTSByPostCodeIndex.items;
      while (nextToken !== null) {
        let InvDataSec = await API.graphql(
          graphqlOperation(queryArFukuiWHSeinoLTSByPostCodeIndex, {
            PostCode: postCode,
            limit: 10000,
            nextToken: nextToken,
          }
          ))
        nextToken = InvDataSec.data.queryArFukuiWHSeinoLTSByPostCodeIndex.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.queryArFukuiWHSeinoLTSByPostCodeIndex.items.length;
          i++
        ) {
          infoArr.push(InvDataSec.data.queryArFukuiWHSeinoLTSByPostCodeIndex.items[i]);
        }
      }
    }
    if (infoArr.length > 0) {
     if(infoArr[0] && infoArr[0].leadTime){
       leadTime = infoArr[0].leadTime
     }
    }
    return leadTime
  }
  const reduceDays = async (date, days) => {
    let holidays = IsWorkday
    let tempDate = new Date(date);
    for (let i = 0; i < days; i++) {
      tempDate.setDate(tempDate.getDate() - 1);
      if (holidays.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === tempDate.getTime())) {
        tempDate.setDate(tempDate.getDate() - 1);
        i--
      }
    }
    return tempDate;
  }
   const ValidDate = async(element,selectedDate)=>{
      let flag1 = false
      let flag2 = false
      let shipDate = ""
        if(element.RecordSalesApplicable === "出荷日" || element.AcctForPurchasesApplicableble === "出荷日"){
          let leadTime = 0
          if (element.ShipmentCode) {
            let listCustomerAddressesInfo = await API.graphql(
              graphqlOperation(queryArCustomerAddressesByCustomerReceiptCodeIndex, {
                CustomerReceiptCode: element.ShipmentCode,
              })
            );
            if (listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex !== null) {
              let CustomerAddressesInfo = listCustomerAddressesInfo.data.queryArCustomerAddressesByCustomerReceiptCodeIndex.items;
              //福井西濃LT
              if (CustomerAddressesInfo.length > 0 && CustomerAddressesInfo[0].PostCode) {
                leadTime = await FukuiSeinoLTFun(CustomerAddressesInfo[0].PostCode)
              }
            }
          }
          shipDate = await reduceDays((moment(selectedDate).format('YYYY/MM/DD')), parseInt(leadTime))
        }
      if (element.Expired) {
        if (element.RecordSalesApplicable === "出荷日") {
          if ((moment(element.ExpirationStart).format('YYYY/MM/DD') <= moment(shipDate).format('YYYY/MM/DD')) && (moment(element.Expired).format('YYYY/MM/DD') >= moment(shipDate).format('YYYY/MM/DD'))) {
            flag1 = true
          }
        } else if (element.RecordSalesApplicable === "納品日") {
          if ((moment(element.ExpirationStart).format('YYYY/MM/DD') <= moment(selectedDate).format('YYYY/MM/DD')) && (moment(element.Expired).format('YYYY/MM/DD') >= moment(selectedDate).format('YYYY/MM/DD'))) {
            flag1 = true
          }
        } else if (element.RecordSalesApplicable === "受注日") {
          if ((moment(element.ExpirationStart).format('YYYY/MM/DD') <= moment(new Date()).format('YYYY/MM/DD')) && (moment(element.Expired).format('YYYY/MM/DD') >= moment(new Date()).format('YYYY/MM/DD'))){
          flag1 = true
          }
        }
        if (element.AcctForPurchasesApplicableble === "出荷日") {
          if ((moment(element.ExpirationStart).format('YYYY/MM/DD') <= moment(shipDate).format('YYYY/MM/DD')) && (moment(element.Expired).format('YYYY/MM/DD') >= moment(shipDate).format('YYYY/MM/DD'))) {
            flag2 = true
          }
        } else if (element.AcctForPurchasesApplicableble === "納品日") {
          if ((moment(element.ExpirationStart).format('YYYY/MM/DD') <= moment(selectedDate).format('YYYY/MM/DD')) && (moment(element.Expired).format('YYYY/MM/DD') >= moment(selectedDate).format('YYYY/MM/DD'))) {
            flag2 = true
          }
        } else if (element.AcctForPurchasesApplicableble === "受注日") {
          if ((moment(element.ExpirationStart).format('YYYY/MM/DD') <= moment(new Date()).format('YYYY/MM/DD')) && (moment(element.Expired).format('YYYY/MM/DD') >= moment(new Date()).format('YYYY/MM/DD'))) {
            flag2 = true
          }
        }
      } else {
        if (element.RecordSalesApplicable === "出荷日") {
          if (moment(element.RecordSalesDate).format('YYYY/MM/DD') <= moment(shipDate).format('YYYY/MM/DD')) {
            flag1 = true
          }
        } else if (element.RecordSalesApplicable === "納品日") {
         if ((moment(element.RecordSalesDate).format('YYYY/MM/DD')) <= (moment(selectedDate).format('YYYY/MM/DD'))) {
          flag1 = true
          }
        } else if (element.RecordSalesApplicable === "受注日") {
          if ((moment(element.RecordSalesDate).format('YYYY/MM/DD')) <= moment(new Date()).format('YYYY/MM/DD')) {
            flag1 = true
          }
        }
        if (element.AcctForPurchasesApplicableble === "出荷日") {
          if (moment(element.PurchaseAccountDate).format('YYYY/MM/DD') <= moment(shipDate).format('YYYY/MM/DD')) {
            flag2 = true
          }
        } else if (element.AcctForPurchasesApplicableble === "納品日") {
          if ((moment(element.PurchaseAccountDate).format('YYYY/MM/DD')) <= (moment(selectedDate).format('YYYY/MM/DD'))) {
            flag2 = true
          }
        } else if (element.AcctForPurchasesApplicableble === "受注日") {
          if ((moment(element.PurchaseAccountDate).format('YYYY/MM/DD')) <= moment(new Date()).format('YYYY/MM/DD')) {
            flag2 = true
          }
        }
      }
      if (flag1 || flag2) {
        return true
      }else{
        return false
      }
  }
   const searchBtn = async function(orderCode,itemcode,OrderName,date){
     setLoadingFlag(true);
    try{
     const InvData = await API.graphql(
         graphqlOperation(queryArPriceInfosByQueryIDIndex, {
           QueryId:"P",
           limit:10000,
           filter: {ActualFlag:{eq:"0"},
                and: [
              {
                ItemCode: {eq: itemcode},
              },
              {
                or: [
                  { OrderReceiptCode: {eq: orderCode}},
                  { OrderName: { eq:""}}
                ]
              },
            ]
          }
         })
       );
       if(InvData.data.queryArPriceInfosByQueryIDIndex !== null) {
       let nextToken = InvData.data.queryArPriceInfosByQueryIDIndex.nextToken;
       dataAll = InvData.data.queryArPriceInfosByQueryIDIndex.items;
       while (nextToken !== null) {
         let InvDataSec = await API.graphql(
           graphqlOperation(queryArPriceInfosByQueryIDIndex, {
             QueryId:"P",
             limit:10000,
             nextToken: nextToken,
             filter:{ActualFlag:{eq:"0"},
                  and: [
              {
                ItemCode: {eq: itemcode},
              },
              {
                or: [
                  { OrderReceiptCode: { eq:orderCode}},
                  { OrderName: {eq:""}}
                ]
              },
            ]
          }
          }
         ))
         if(InvDataSec.data.queryArPriceInfosByQueryIDIndex !== null) {
         nextToken = InvDataSec.data.queryArPriceInfosByQueryIDIndex.nextToken;
         for (
           let i = 0;
           i < InvDataSec.data.queryArPriceInfosByQueryIDIndex.items.length;
           i++
         ) {
           dataAll.push(InvDataSec.data.queryArPriceInfosByQueryIDIndex.items[i]);
         }
       }}}
      }catch(error){
        if(error.message === 'No current user'){
          // ユーザープールに障害が発生した場合
          console.log('User pool is invalid.Please reauthenticate.');
          // ログイン画面に戻る
          signOut();
          }else{
          // 他のエラーを処理する
          console.log('An error occurred:',error);
          }
       }
         let filterList = []
         for(let i = 0;i < dataAll.length;i++){
        let flag = true
        //受注先空白の場合
       if(!dataAll[i].OrderReceiptCode){
        if(dataAll[i].PriceTypePurchase !== "EU" && dataAll[i].PriceTypePurchase !== "代" && dataAll[i].PriceTypePurchase !== "B" && dataAll[i].PriceTypePurchase !== "A"){
           flag = false
         }else{
           if(dataAll[i].PriceTypePurchase === "B" || dataAll[i].PriceTypePurchase === "A"){
             const InvData = await API.graphql(
               graphqlOperation(queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex, {
                OrderReceiptCode:orderCode,
               })
             );
             if(InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex !== null && InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items.length > 0){
                  let find1 = (InvData.data.queryArOrderRecipientTransGrpsByOrderRecipientCodeIndex.items).findIndex((item)=>{
                    return dataAll[i].SuppGroup === item.AgencyType
                  })
                  if(find1 <= -1){
                    flag = false
                  }
           }else{
            flag = false
           }
          }
         }
       }
       //受注先、KISCO(AA10192600)東和電気(AA10548806)は営業Grpが１６２(EMW)のみが表示
       if(OrderName.includes("KISCO") || OrderName.includes("東和電気")){
        const { accessToken } = await Auth.currentSession();
        const cognitogroups = accessToken.payload['cognito:groups'];
        if(cognitogroups && cognitogroups[0] !== 'silicone162'){
            flag = false
       }else{
        if(dataAll[i].OrderReceiptCode === ""){
          if(dataAll[i].SalesDocType === "ZT11"){
            if(dataAll[i].SuppGroup !== "B10"){
                flag = false
            }else{
                flag = true
              }
          }
          if(dataAll[i].SalesDocType === "ZT31" || dataAll[i].SalesDocType === "ZS01"){
              if(dataAll[i].SuppGroup !== "B0"){
                  flag = false
              }else{
                  flag = true
              }
          }
       }
       }
      }
       if(flag === true){
        filterList.push(dataAll[i])
       }
         }
         dataAll = filterList
         let Data = []
         for(let element = 0;element < dataAll.length;element++){
          let result = await ValidDate(dataAll[element],date)
          if(result){
            Data.push(dataAll[element])
          }
         }
        dataAll = Data
            const result = [];
            const map = new Map();
            for (const item of dataAll) {
              const key = JSON.stringify({ ItemCode: item.ItemCode, OrderReceiptCode: item.OrderReceiptCode, ShipmentCode: item.ShipmentCode, EUARBmgmtCode: item.EUARBmgmtCode,PriceTypePurchase:item.PriceTypePurchase });
              if (!map.has(key)) {
                map.set(key, item);
                result.push(item);
              } else {
                const existingItem = map.get(key);
                if (existingItem.HistoryNum < item.HistoryNum) {
                  map.set(key, item);
                  result.splice(result.indexOf(existingItem), 1, item);
                }
              }
            }
            dataAll = result
         if(orderCode === "AA89104100" || orderCode === "AA89108100" || orderCode === "AA89106200"){
          dataAll = dataAll.filter((item)=>{
              if(item.PriceTypePurchase === "A"){
                return true
            }
         })
         }
         //注文登録画面で「戻るボタン」を押下し、明細画面に戻り、以前選択された注文情報で検索する場合、その情報がリストの先頭に表示されます。
         if (location.state && location.state.QuoteNumber !== undefined) {
          if(gobackArr.length > 0){
            for(let item in gobackArr){
              dataAll.forEach((element,index)=>{
                if(element.id === gobackArr[item].id){
                  dataAll.splice(index,1)
                }
              })
              dataAll.unshift(gobackArr[item])
            }
          }
         }
     setInv({ type: QUERY, Invs: dataAll });
     setLoadingFlag(false);
    }
    const [OrderReceiptCodeDisable,setOrderReceiptCodeDisable] = useState(false)
      useEffect(() => {
     async function listInvData() {
       orderselect_row = []
       orderlastContractor = []
       checkedArr = []
       gobackArr = []
       try{
        let useAuth = await userAuth();
        setUseAuthInfo(useAuth)
       const user = await Auth.currentUserInfo();
       const { accessToken } = await Auth.currentSession();
       const cognitogroups = accessToken.payload['cognito:groups'];
       if (cognitogroups && cognitogroups[0] === "silicone162") {
        salesGroup = '162'
       }else if (cognitogroups && cognitogroups[0] === "silicone141"){
        salesGroup = '141'
      }else if (cognitogroups && cognitogroups[0] === "silicone181"){
        salesGroup = '181'
      }
         const agencyPriceGroup = await API.graphql(
           graphqlOperation(customerByeMail, {
             Agency_Email: user.attributes.email,
           })
         );
         const agentinfo = agencyPriceGroup.data.customerByeMail !== null ? agencyPriceGroup.data.customerByeMail["items"] : []
         if (agentinfo.length > 0) {
           agencyId = agentinfo[0].Agency_id;
           agencyEmail = agentinfo[0].Agency_Email;
          Agency_Name = agentinfo[0].Agency_Name;
           Company_Name = agentinfo[0].Company_Name;
           subId = agentinfo[0].SubId;
           User_Name = agentinfo[0].User_Name;
           AssigneeCode = agentinfo[0].AssigneeCode;
         }
        }catch(error){
          if(error.message === 'No current user'){
            // ユーザープールに障害が発生した場合
            console.log('User pool is invalid.Please reauthenticate.');
            // ログイン画面に戻る
            signOut();
            }else {
            // 他のエラーを処理する
            console.log('An error occurred:',error);
            }
         }
        setLoadingFlag1(true)
        dataAll = await PriceInfoList2();
        let IsWorkdays = await getIsWorkday()
        let selectedDateInitval = await selectedDateInit()
        let option1List = [],itemCodes = {};
        let option2List = [];
        let OrderRecipientDataAll = [];
        const OrderRecipientList = await API.graphql(
          graphqlOperation(listArOrderRecipients, {
            limit:10000,
         }))
         if(OrderRecipientList.data.listArOrderRecipients !== null) {
        let nextToken = OrderRecipientList.data.listArOrderRecipients.nextToken;
        OrderRecipientDataAll = OrderRecipientList.data.listArOrderRecipients.items;
        while (nextToken !== null) {
          let InvDataSec = await API.graphql(
            graphqlOperation(listArOrderRecipients, {
              limit:10000,
              nextToken: nextToken,
           }))
           if(InvDataSec.data.listArOrderRecipients !== null){
          nextToken = InvDataSec.data.listArOrderRecipients.nextToken;
          for (
            let i = 0;
            i < InvDataSec.data.listArOrderRecipients.items.length;
            i++
          ) {
            OrderRecipientDataAll.push(InvDataSec.data.listArOrderRecipients.items[i]);
          }
        }
      }
      }
        dataAll.forEach((item)=>{
          if(!itemCodes[item.SAPCODE]){
            itemCodes[item.SAPCODE] = true
            option1List.push({label:item.SAPCODE + ":" + item.ItemText,year:item.SAPCODE})
          }
        })
        itemCodes = {}
        OrderRecipientDataAll.forEach((item)=>{
          let OrderRecipientName2 = item.OrderRecipientName2 !== null ? item.OrderRecipientName2 : ""
          let OrderRecipientName3 = item.OrderRecipientName3 !== null ? item.OrderRecipientName3 : ""
          let OrderRecipientName4 = item.OrderRecipientName4 !== null ? item.OrderRecipientName4 : ""
          option2List.push({label:item.OrderReceiptCode + ":" + OrderRecipientName2 + " " + OrderRecipientName3 + " " + OrderRecipientName4,year:item.OrderReceiptCode,name:OrderRecipientName2 + " " + OrderRecipientName3 + " " + OrderRecipientName4})
        })
        setoptions2(option2List)
        setoptions1(option1List)
        setIsWorkday(IsWorkdays)
        setSelectedData(selectedDateInitval)
        setPageInitFlag(true)
        setLoadingFlag1(false)
        if (location.state && location.state.QuoteNumber !== undefined) {
          setLoadingFlag(true);
           const pageData = await API.graphql(
             graphqlOperation(listArAgencyOrderInfosEmd, {
               QuoteNumber: location.state.QuoteNumber,
             })
           );
           let srAgencyOrderdataAll = pageData.data.listArAgencyOrderInfosEmd !== null ? pageData.data.listArAgencyOrderInfosEmd.items : []
           let IdArr = []
           for (let i = 0; i < srAgencyOrderdataAll.length; i++) {
                  IdArr.push(srAgencyOrderdataAll[i].Product_id)
              }
           dataAll = await PriceInfoList1(IdArr)
          orderCodeChecked = {Code:srAgencyOrderdataAll[0].SelectContractor,name:srAgencyOrderdataAll[0].SelectContractorNm}
          setSelectedOrderName(srAgencyOrderdataAll[0].SelectContractorNm)
          setSelectedOrdeCode(srAgencyOrderdataAll[0].SelectContractor)
           setInputValueOrderName(srAgencyOrderdataAll[0].SelectContractorNm)
          for (let item in dataAll) {
             for (let i = 0; i < srAgencyOrderdataAll.length; i++) {
               if ((dataAll[item]["ItemCode"] === srAgencyOrderdataAll[i].Product_Code) && dataAll[item]["id"] === srAgencyOrderdataAll[i].Product_id) {
                 dataAll[item]["OrderQuantity"] = srAgencyOrderdataAll[i].OrderQuantity;
                 orderselect_row.push(dataAll[item]["id"]);
                 gobackArr.push(dataAll[item]);
                 orderlastContractor.push({code:srAgencyOrderdataAll[i].SelectContractor,id:dataAll[item]["id"]})
                 checkedArr.push(dataAll[item])
                 setOrderReceiptCodeDisable(true)
                 if (srAgencyOrderdataAll[i].OrderQuantity === null) {
                   srAgencyOrderdataAll[i].OrderQuantity = 0;
                 }
               }
             }
           }
           orderlastContractorCopy = JSON.parse(JSON.stringify(orderlastContractor))
           let InvsArr = JSON.parse(JSON.stringify(checkedArr))
         setInv({ type: QUERY, Invs: InvsArr});
         setLoadingFlag(false);
         }
     }
     listInvData();
   },[])
   const PriceInfoList1 = async (IdArr)=>{
    let arr = [];
    let filter = [];
    for(let i = 0 ; i < IdArr.length;i++){
      filter.push({id: {eq: IdArr[i]}})
    }
    const InvData = await API.graphql(
      graphqlOperation(queryArPriceInfosByQueryIDIndex, {
        QueryId:"P",
        limit:10000,
        filter: {or: filter}
    }));
    if(InvData.data.queryArPriceInfosByQueryIDIndex !== null) {
    let nextToken = InvData.data.queryArPriceInfosByQueryIDIndex.nextToken;
    arr = InvData.data.queryArPriceInfosByQueryIDIndex.items;
    while (nextToken !== null) {
      let InvDataSec = await API.graphql(
        graphqlOperation(queryArPriceInfosByQueryIDIndex, {
          QueryId:"P",
          limit:10000,
          filter: {or: filter},
          nextToken: nextToken,
        })
      );
      if(InvDataSec.data.queryArPriceInfosByQueryIDIndex !== null){
      nextToken = InvDataSec.data.queryArPriceInfosByQueryIDIndex.nextToken;
      for (
        let i = 0;
        i < InvDataSec.data.queryArPriceInfosByQueryIDIndex.items.length;
        i++
      ) {
        arr.push(InvDataSec.data.queryArPriceInfosByQueryIDIndex.items[i]);
      }
    }
  }
  }
    return arr
   }
   const PriceInfoList2 = async ()=>{
    let ItemMaster = [];
    const list = await API.graphql(graphqlOperation(listArItemMasters, { limit: 10000,filter: {DelFlag: {ne: "1"}} }));
    if (list.data.listArItemMasters.items && list.data.listArItemMasters.items.length > 0) {
      ItemMaster = list.data.listArItemMasters.items
      let nextToken = list.data.listArItemMasters.nextToken;
      while (nextToken !== null) {
        let InvDataSec = await API.graphql(
          graphqlOperation(listArItemMasters, {
            limit: 10000,
            filter: {DelFlag: {ne: "1"}},
            nextToken: nextToken,
          }))
        if (InvDataSec.data.listArItemMasters !== null) {
          nextToken = InvDataSec.data.listArItemMasters.nextToken;
          for (
            let i = 0;
            i < InvDataSec.data.listArItemMasters.items.length;
            i++
          ) {
            ItemMaster.push(InvDataSec.data.listArItemMasters.items[i]);
          }
        }
      }
    }
    return ItemMaster
   }
   const customTotal = (from, to, size) => (
     <span
       style={{ marginLeft: "1em" }}
       className="react-bootstrap-table-pagination-total"
     >
       {size} の結果の {from} から {to} を表示しています
     </span>
   );
   const sizePerPageRenderer = ({
     options,
     currSizePerPage,
     onSizePerPageChange,
   }) => (
     <div className="btn-group" role="group">
       {options.map((option) => (
         <button
           key={option.text}
           type="button"
           onClick={() => onSizePerPageChange(option.page)}
           className={`btn ${currSizePerPage === `${option.page}` ? "btn-warning" : "btn-light"
             }`}
         >
           {option.text}
         </button>
       ))}
     </div>
   );
   const pageButtonRenderer = ({ page, onPageChange, currSizePerPage }) => {
     const handleClick = (e) => {
       e.preventDefault();
       onPageChange(page);
     };
     return (
       <button
       key={page}
         className={`btn ${currSizePerPage === `${page}` ? "btn-warning" : "btn-light"
           }`}
         type="button"
         onClick={handleClick}
       >
         <li className="page-item">
           <a style={{ color: "black" }} href="#" >
             {page}
           </a>
         </li>
       </button>
     );
   };
   const classes = useStyles();
   const options = {
     paginationSize: 5,
     pageStartIndex: 1,
     showTotal: true,
     withFirstAndLast: true,
     firstPageText: "最初",
     prePageText: "戻る",
     nextPageText: "次へ",
     lastPageText: "最後",
     nextPageTitle: "最初のページ",
     prePageTitle: "プレページ",
     firstPageTitle: "次のページ",
     lastPageTitle: "最後のページ",
     paginationTotalRenderer: customTotal,
     sizePerPageRenderer: sizePerPageRenderer,
     pageButtonRenderer: pageButtonRenderer,
     disablePageTitle: true,
     sizePerPageList: [
       {
         text: "5",
         value: 5,
       },
       {
         text: "10",
         value: 10,
       },
       {
         text: "20",
         value: 20,
       },
       {
         text: "100",
         value: 100,
       },
     ],
   };
   const defaultSorted = [
     {
       dataField: "id",
       order: "desc",
     },
   ];
   const selectRow = {
     mode: "checkbox",
     clickToSelect: false,
     clickToEdit: true,
     bgColor: "white",
     selected: orderselect_row,
     selectionHeaderRenderer: () => "カートに入れる",
     headerColumnStyle: {
       margin:0,
       padding:0,
       fontSize:"14px",
       backgroundColor: "white",
       borderBottom: "3px solid #804F29",
       minWidth:"120px",
       maxWidth:"120px"
     },
     selectColumnPosition: "right",
     onSelect: (row, isSelect,index) => {
       if (isSelect){
        if(inventories.Invs[index].OrderQuantity === null || (!inventories.Invs[index].OrderQuantity)){
          return false
        }
        row = inventories.Invs[index]
        let selectedOrderCodeCopy = selectedOrderCode
        orderCodeChecked = {Code:selectedOrderCode,name:selectedOrderName}
        for(let i = 0;i < orderlastContractorCopy.length;i++){
          if(row.id === orderlastContractorCopy[i].id){
            selectedOrderCodeCopy = orderlastContractorCopy[i].code
          }
        }
        orderlastContractor.push({code:selectedOrderCodeCopy,id:row.id});
        for (let j = 0; j < orderlastContractor.length; j++) {
          if(orderselect_row.length !== 0 && (orderlastContractor[j].code && orderlastContractor[j].code !== selectedOrderCode)){
           handleSetMsgId("エラー");
           handleSetMsgText("同じ受注先以外は選択不可です");
           handleSetMsgBtnOK("はい");
           handleClickOpen2();
           return false
            }
          }
         orderselect_row.push(row.id);
         checkedArr.push(row)
       } else {
        let findOrder = orderlastContractor.findIndex(function (item) {
          return item.id === row.id;
        });
        if(findOrder > -1){
          orderlastContractor.splice(findOrder, 1);
         }
         let find = orderselect_row.findIndex(function (item) {
           return item === row.id;
         });
         let find1 = checkedArr.findIndex(function (item) {
          return item.id === row.id;
        });
        let findgobackArr = gobackArr.findIndex(function (item) {
          return item.id === row.id;
        });
         if(find > -1){
          orderselect_row.splice(find, 1);
         }
         if(find1 > -1){
         checkedArr.splice(find1, 1)
         }
         if(findgobackArr > -1){
         gobackArr.splice(findgobackArr, 1)
          }
        if(gobackArr.length === 0){
          setOrderReceiptCodeDisable(false)
        }
       }
     },
     onSelectAll: (isSelect) => {
       if (isSelect) {
         for (let i = 0; i < inventories.Invs.length; i++) {
           orderselect_row.push(inventories.Invs[i].id);
           checkedArr.push(inventories.Invs[i].id);
         }
       } else {
         orderselect_row = [];
         checkedArr = [];
       }
     },
   };
   const [IsWorkday, setIsWorkday] = useState([])
   const getIsWorkday = async () => {
    let holidays = [];
    // let format = new Date(today.getFullYear() + 1, 11, 31)
    const listArcalendarsList = await API.graphql(
      graphqlOperation(listArcalendars, {
        filter: { IsWorkday: { eq: "0" }},
        limit: 10000,
      }))
    if (listArcalendarsList.data.listArcalendars !== null) {
      let nextToken = listArcalendarsList.data.listArcalendars.nextToken;
      holidays = listArcalendarsList.data.listArcalendars.items;
      while (nextToken !== null) {
        let InvDataSec = await API.graphql(
          graphqlOperation(listArcalendars, {
            filter: { IsWorkday: { eq: "0" }},
            limit: 10000,
            nextToken: nextToken,
          }))
        nextToken = InvDataSec.data.listArcalendars.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.listArcalendars.items.length;
          i++
        ) {
          holidays.push(InvDataSec.data.listArcalendars.items[i]);
        }
      }
    }
    return holidays;
  }
  const selectedDateInit = async()=>{
    let IsWorkdays = await getIsWorkday()
    let tempDate = new Date();
    if(!location.state){
for (let i = 0; i < 3; i++) {
  tempDate.setDate(tempDate.getDate() + 1);
  if (IsWorkdays.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(tempDate).format('YYYY/MM/DD')).getTime())) {
    tempDate.setDate(tempDate.getDate() + 1);
    i--
  }
}
}else{
  let formatY = new Date();
  for (let i = 0; i < 3; i++) {
    formatY.setDate(formatY.getDate() + 1);
    if (IsWorkdays.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(formatY).format('YYYY/MM/DD')).getTime())) {
      formatY.setDate(formatY.getDate() + 1);
      i--
    }
  }
  tempDate = location.state.DesiredDeliveryDate.replace(/\//g, "-");
  if (moment(tempDate).format('YYYYMMDD') <= moment(formatY).format('YYYYMMDD')) {
    tempDate = formatY;
  }
}
return moment(tempDate).utcOffset(9).format('YYYY-MM-DD')
   }
   let formatYselect = "";
   let formatY = "";
   let todayTemp = new Date();
   let today = new Date(todayTemp.getTime() + 1000 * 60 * 60 * 72);
   formatY += today.getFullYear();
   formatY += today.getMonth() + 1 < 10 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1;
   formatY += today.getDate() < 10 ? "0" + today.getDate() : today.getDate();
   if (location.state) {
     formatYselect = location.state.DesiredDeliveryDate.replace(/\//g, "-");
     if (moment(formatYselect).utcOffset(9).format('YYYYMMDD') <= formatY) {
       formatYselect = formatY;
     }
   } else {
       formatYselect = formatY;
   }
   // const DateFunPick = today;
   //const DateFunPick = moment(formatY).utcOffset(9).format('YYYY-MM-DD');
   const [selectedDate, setSelectedData] = React.useState(moment(formatYselect).utcOffset(9).format('YYYY-MM-DD'));
   //日付を変更するには、ジャンプします
   const DateFun = () => {
       if (location.state) {
         let str = location.state.DesiredDeliveryDate.replace(/\//g, "-");
         return str;
       } else {
         return "";
       }
   };
   const history = useHistory();
   function orderClick() {
     createArOrders();
   }
   const [msg1, setMsgId] = React.useState(Object);
   const handleSetMsgId = (Object) => {
     setMsgId(Object);
   };
   const [msgText, setMsgText] = React.useState(Object);
   const handleSetMsgText = (Object) => {
     setMsgText(Object);
   };
   const [msgbtnOK, setMsgBtnOK] = React.useState(Object);
   const handleSetMsgBtnOK = (Object) => {
     setMsgBtnOK(Object);
   };
   const [open2, setOpen2] = React.useState(false);
   const handleClickOpen2 = () => {
     setOpen2(true);
   };
   const handleCloseNG2 = () => {
    setOpen2(false);
  };
  const handleCloseOK2 = () => {
    setOpen2(false);
  };
    //重複するオブジェクトを削除する
   function unique(arr) {
     return arr.filter((item, index) => {
       return arr.indexOf(item) === index;
     });
   }
   const createOrderDetailsNumber = () => {
     let format = "";
     let nTime = new Date();
     format += nTime.getFullYear().toString().substring(2);
     format +=
       nTime.getMonth() + 1 < 10
         ? "0" + (nTime.getMonth() + 1)
         : nTime.getMonth() + 1;
     format += nTime.getDate() < 10 ? "0" + nTime.getDate() : nTime.getDate();
     format += nTime.getHours() < 10 ? "0" + nTime.getHours() : nTime.getHours();
     format +=
       nTime.getMinutes() < 10 ? "0" + nTime.getMinutes() : nTime.getMinutes();
     format +=
       nTime.getSeconds() < 10 ? "0" + nTime.getSeconds() : nTime.getSeconds();
     if (nTime.getMilliseconds() < 10) {
       format += "00" + nTime.getMilliseconds();
     } else if (nTime.getMilliseconds() < 100) {
       format += "0" + nTime.getMilliseconds();
     } else {
       format += nTime.getMilliseconds();
     }
     format += Math.floor(Math.random() * 88) + 10;
     let QuoteNumber = "E" + format;
     return QuoteNumber;
   }
   const createArOrders = async () => {
    setLoadingFlag1(true)
     let hopedateValue = moment(selectedDate).utcOffset(9).format('YYYY-MM-DD');
    if(!selectedOrderCode){
      handleSetMsgId("");
      handleSetMsgText("受注先を入力してください");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      setLoadingFlag1(false)
      return;
    }
    if((orderselect_row === "" || orderselect_row.length === 0) && !selectedItemCode){
      handleSetMsgId("");
      handleSetMsgText("品目を入力してください");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      setLoadingFlag1(false)
      return;
  }
     if (orderselect_row === "" || orderselect_row.length === 0) {
       handleSetMsgId("エラー");
       handleSetMsgText("「カートに入れる」をチェックしてください");
       handleSetMsgBtnOK("はい");
       handleClickOpen2();
       setLoadingFlag1(false)
       return;
     }
     if (hopedateValue === null || hopedateValue.length === 0) {
       handleSetMsgId("エラー");
       handleSetMsgText("希望納期を入力してください");
       handleSetMsgBtnOK("はい");
       handleClickOpen2();
       setLoadingFlag1(false)
       return;
     }
     if (orderselect_row === "" || orderselect_row.length === 0) {
       handleSetMsgId("エラー");
       handleSetMsgText("1つ以上の商品をお選びください");
       handleSetMsgBtnOK("はい");
       handleClickOpen2();
       setLoadingFlag1(false)
       return;
     }
     let format = "";
     let nTime = new Date();
     format += nTime.getFullYear().toString().substring(2);
     format +=
       nTime.getMonth() + 1 < 10
         ? "0" + (nTime.getMonth() + 1)
         : nTime.getMonth() + 1;
     format += nTime.getDate() < 10 ? "0" + nTime.getDate() : nTime.getDate();
     format += nTime.getHours() < 10 ? "0" + nTime.getHours() : nTime.getHours();
     format +=
       nTime.getMinutes() < 10 ? "0" + nTime.getMinutes() : nTime.getMinutes();
     format +=
       nTime.getSeconds() < 10 ? "0" + nTime.getSeconds() : nTime.getSeconds();
     if (nTime.getMilliseconds() < 10) {
       format += "00" + nTime.getMilliseconds();
     } else if (nTime.getMilliseconds() < 100) {
       format += "0" + nTime.getMilliseconds();
     } else {
       format += nTime.getMilliseconds();
     }
     format += Math.floor(Math.random() * 88) + 10;
     let QuoteNumber = "P" + format;
     if (hopedateValue.length > 17) {
       hopedateValue = hopedateValue + "T00:00.000Z";
     } else if (hopedateValue.length > 0) {
       hopedateValue = hopedateValue + "T00:00:00.000Z";
     } else {
       hopedateValue = "";
     }
     if (location.state) {
       let hasNoneOrderQuantity = false;
       let orderrRecipientExArr = []
       for (let i = 0; i < orderselect_row.length; i++) {
           for (let j = 0; j < inventories.Invs.length; j++) {
           if (hasNoneOrderQuantity) {
             break;
           }
           if (inventories.Invs[j].id === orderselect_row[i]) {
                if(inventories.Invs[j].OrderReceiptCode === "AA89104100" || inventories.Invs[j].OrderReceiptCode === "AA89108100" || inventories.Invs[j].OrderReceiptCode === "AA89106200"){
                  orderrRecipientExArr.push(inventories.Invs[j])
                }
             if (!inventories.Invs[j].OrderQuantity || inventories.Invs[j].OrderQuantity === null){
               hasNoneOrderQuantity = true;
              break;
            }
             let reg = /[.]/ ;
             if(reg.test(inventories.Invs[j].OrderQuantity)){
              return;
             }
             if(selectedOrderCode !== "AA89104100" && selectedOrderCode !== "AA89108100" && selectedOrderCode !== "AA89106200"){
              if(inventories.Invs[j].BulkSales === "0"){
                if(inventories.Invs[j].RegQty && inventories.Invs[j].OrderQuantity && (Number(inventories.Invs[j].OrderQuantity) % parseInt(inventories.Invs[j].RegQty) !== 0)){
                  handleSetMsgId("エラー");
                  handleSetMsgText("規定数量の倍数を設定してください");
                  handleSetMsgBtnOK("はい");
                  handleClickOpen2();
                  setLoadingFlag1(false)
                  return;
                }
                if((inventories.Invs[j].RegQty).includes(">=")){
                  if(inventories.Invs[j].RegQty && inventories.Invs[j].OrderQuantity && (parseInt(inventories.Invs[j].RegQty) < Number(inventories.Invs[j].OrderQuantity))){
                    handleSetMsgId("エラー");
                  handleSetMsgText("規定数量下限値以上の値を設定してください");
                  handleSetMsgBtnOK("はい");
                  handleClickOpen2();
                  setLoadingFlag1(false)
                    return;
                  }
                }
                if((inventories.Invs[j].RegQty).includes("<=")){
                  if(inventories.Invs[j].RegQty && inventories.Invs[j].OrderQuantity && (parseInt(inventories.Invs[j].RegQty) > Number(inventories.Invs[j].OrderQuantity))){
                    handleSetMsgId("エラー");
                  handleSetMsgText("規定数量下限値以上の値を設定してください");
                  handleSetMsgBtnOK("はい");
                  handleClickOpen2();
                  setLoadingFlag1(false)
                    return;
                  }
                }
              }else{
                if((inventories.Invs[j].RegQty).includes(">=")){
                  if(inventories.Invs[j].RegQty && inventories.Invs[j].OrderQuantity && (parseInt(inventories.Invs[j].RegQty) < Number(inventories.Invs[j].OrderQuantity))){
                    handleSetMsgId("エラー");
                  handleSetMsgText("規定数量下限値以上の値を設定してください");
                  handleSetMsgBtnOK("はい");
                  handleClickOpen2();
                  setLoadingFlag1(false)
                    return;
                  }
                }
                if((inventories.Invs[j].RegQty).includes("<=")){
                  if(inventories.Invs[j].RegQty && inventories.Invs[j].OrderQuantity && (parseInt(inventories.Invs[j].RegQty) > Number(inventories.Invs[j].OrderQuantity))){
                    handleSetMsgId("エラー");
                  handleSetMsgText("規定数量下限値以上の値を設定してください");
                  handleSetMsgBtnOK("はい");
                  handleClickOpen2();
                  setLoadingFlag1(false)
                    return;
                  }
                }
              }
            }
            if(inventories.Invs[j].OrdQty && inventories.Invs[j].OrderQuantity){
              let OrdQty = isNaN(parseInt(inventories.Invs[j].OrdQty)) ? 0 : parseInt(inventories.Invs[j].OrdQty)
              if(OrdQty !== 0 && (Number(inventories.Invs[j].OrderQuantity) > OrdQty)){
              handleSetMsgId("エラー");
              handleSetMsgText(`1注文可能数は${OrdQty}です。1注文可能数以下で入力してください`);
              handleSetMsgBtnOK("はい");
              handleClickOpen2();
              setLoadingFlag1(false);
              return;
              }
          }
           }
         }
       }
       if(orderrRecipientExArr.length > 1){
        handleSetMsgId("エラー");
        handleSetMsgText("受注先がサンプル品の場合、一つだけを選択してください");
        handleSetMsgBtnOK("はい");
        handleClickOpen2();
        setLoadingFlag1(false)
        return;
       }
       if (hasNoneOrderQuantity) {
         handleSetMsgId("エラー");
         handleSetMsgText("発注数を入力してください");
         handleSetMsgBtnOK("はい");
         handleClickOpen2();
         setLoadingFlag1(false)
         return;
       }
       // 既存のデータを削除
       let oldQuoteNumber = location.state.QuoteNumber;
       let listArAgencyOrderInfo = await API.graphql(
         graphqlOperation(listArAgencyOrderInfosEmd, {
           QuoteNumber: oldQuoteNumber,
         })
       );
       let order = [];
       let order2 = [];
       if (listArAgencyOrderInfo.data.listArAgencyOrderInfosEmd !== null) {
         order2 = listArAgencyOrderInfo.data.listArAgencyOrderInfosEmd["items"];
         for (let j = 0; j < order2.length; j++) {
           order.push(order2[j].Product_id);
         }
       }
       orderselect_row = unique(orderselect_row);
       let count = 1;
       for (let i = 0; i < orderselect_row.length; i++) {
         let findIndex = order.indexOf(orderselect_row[i]);
         if (findIndex > -1) {
           for (let j = 0; j < inventories.Invs.length; j++) {
             if (inventories.Invs[j].id === orderselect_row[i]) {
               let arInfo = inventories.Invs[j];
               let currentId = order2[findIndex].id;
               try{
               await API.graphql(
                 graphqlOperation(updateArAgencyOrderInfo, {
                   input: {
                     id: currentId,
                     Agency_id: agencyId,
                     AgencyEmail: agencyEmail,
                     AgencyName: Agency_Name,
                     GroupName: Company_Name,
                     SubId:subId,
                     QuoteNumber: oldQuoteNumber,
                     Product_Code: arInfo.ItemCode,
                     Product_Name_j: arInfo.ItemName,
                     OrderQuantity: parseFloat(arInfo.OrderQuantity),
                     Product_id: arInfo.id,
                     DetailNo: count * 10,
                     DesiredDeliveryDate: hopedateValue,
                     OrderStatus: "1",
                     UserAuth:userAuthInfo,
                     SelectContractor:orderCodeChecked.Code,
                     SelectContractorNm:orderCodeChecked.name,
                     Contractor: orderCodeChecked.Code,
                     ContractorNm: orderCodeChecked.name,
                     Supplier:arInfo.SuppCode,
                     User_Name:User_Name,
                     AssigneeCode:AssigneeCode
                   },
                 })
               );
              }catch(error){
                if(error.message === 'No current user'){
                  // ユーザープールに障害が発生した場合
                  console.log('User pool is invalid.Please reauthenticate.');
                  // ログイン画面に戻る
                  signOut();
                  }else {
                  // 他のエラーを処理する
                  console.log('An error occurred:',error);
                  }
                  return ;
               }
               count++;
               order.splice(findIndex, 1);
               order2.splice(findIndex, 1);
             }
           }
         } else {
           for (let j = 0; j < inventories.Invs.length; j++) {
             let arCreatInfo = inventories.Invs[j];
             if (orderselect_row[i] === arCreatInfo.id) {
              try{
               await API.graphql(
                 graphqlOperation(createArAgencyOrderInfo, {
                   input: {
                     id: uuidv4(),
                     Agency_id: agencyId,
                     AgencyEmail: agencyEmail,
                     AgencyName: Agency_Name,
                     GroupName: Company_Name,
                     SubId:subId,
                     QuoteNumber: oldQuoteNumber,
                     Product_Code: arCreatInfo.ItemCode,
                     Product_Name_j: arCreatInfo.ItemName,
                     OrderQuantity: parseFloat(arCreatInfo.OrderQuantity),
                     Product_id: arCreatInfo.id,
                     DetailNo: count * 10,
                     RegistrationDate: new Date(),
                     DesiredDeliveryDate: hopedateValue,
                     OrderDetailsNumber: createOrderDetailsNumber(),
                     ShippingDestination:arCreatInfo.ShipmentCode ? arCreatInfo.ShipmentCode : "",
                     ShippingDestinationNm:arCreatInfo.DestName ? arCreatInfo.DestName : "",
                     EndUserId:arCreatInfo.EUARBmgmtCode ? arCreatInfo.EUARBmgmtCode : "",
                     EndUserNm:arCreatInfo.EndUserName ? arCreatInfo.EndUserName : "",
                     SalesUnitPriceScale:arCreatInfo.SalesUnitPriceScale1 ? arCreatInfo.SalesUnitPriceScale1 : "",
                     PurchUnitPriceScale:arCreatInfo.PurchUnitPriceScale1 ? arCreatInfo.PurchUnitPriceScale1 : "",
                     OrderStatus: "1",
                     RegQty:arCreatInfo.RegQty ? arCreatInfo.RegQty : "",
                     BulkSales:arCreatInfo.BulkSales ? arCreatInfo.BulkSales : "",
                     Capacity:arCreatInfo.Capacity ? arCreatInfo.Capacity : "",
                      SuppName:arCreatInfo.SuppName ? arCreatInfo.SuppName : "",
                      BulkShippingFee:arCreatInfo.BulkShippingFee ? arCreatInfo.BulkShippingFee : "",
                      CoolDeliveryCharge:arCreatInfo.CoolDeliveryCharge ? arCreatInfo.CoolDeliveryCharge : "",
                      Sales_Document_Type:arCreatInfo.SalesDocType ? arCreatInfo.SalesDocType : "",
                      CapacityUnit:arCreatInfo.CapacityUnit ? arCreatInfo.CapacityUnit : "",
                      PriceUnit:arCreatInfo.PriceUnit ? arCreatInfo.PriceUnit : "",
                      UserAuth:userAuthInfo,
                      SelectContractor:orderCodeChecked.Code,
                      SelectContractorNm:orderCodeChecked.name,
                      Contractor: orderCodeChecked.Code,
                      ContractorNm: orderCodeChecked.name,
                      Supplier:arCreatInfo.SuppCode,
                      SalesGroup:salesGroup,
                      EndUserIdDirectDelivery:arCreatInfo.TorayEndUser ? arCreatInfo.TorayEndUser : "",
                      User_Name:User_Name,
                      AssigneeCode:AssigneeCode
                    },
                 })
               );
              }catch(error){
                if(error.message === 'No current user'){
                  // ユーザープールに障害が発生した場合
                  console.log('User pool is invalid.Please reauthenticate.');
                  // ログイン画面に戻る
                  signOut();
                  }else {
                  // 他のエラーを処理する
                  console.log('An error occurred:',error);
                  }
                  return
               }
               count++;
             }
           }
         }
       }
       for (let i = 0; i < order2.length; i++) {
         await API.graphql(
           graphqlOperation(deleteArAgencyOrderInfo, {
             input: {
               id: order2[i].id,
               QuoteNumber: oldQuoteNumber,
             },
           })
         );
       }
       history.push({
         pathname: "/OrderEmd",
         state: { QuoteNumber: oldQuoteNumber,CalculationOk:true},
       });
     } else {
      let orderrRecipientExArr = []
       let hasNoneOrderQuantity = false;
       for (let i = 0; i < orderselect_row.length; i++) {
           for (let j = 0; j < checkedArr.length; j++) {
           if (hasNoneOrderQuantity) {
             break;
           }
           if (checkedArr[j].id === orderselect_row[i]) {
             if(checkedArr[j].OrderReceiptCode === "AA89104100" || checkedArr[j].OrderReceiptCode === "AA89108100" || checkedArr[j].OrderReceiptCode === "AA89106200"){
                  orderrRecipientExArr.push(checkedArr[j])
                }
             if (checkedArr[j].OrderQuantity === '' || checkedArr[j].OrderQuantity === null){
              hasNoneOrderQuantity = true;
              break;
             }
             let reg = /[.]/ ;
             if(reg.test(checkedArr[j].OrderQuantity)){
              return;
             }
             if(selectedOrderCode !== "AA89104100" && selectedOrderCode !== "AA89108100" && selectedOrderCode !== "AA89106200"){
              if(checkedArr[j].BulkSales === "0"){
                if(checkedArr[j].RegQty && checkedArr[j].OrderQuantity && (Number(checkedArr[j].OrderQuantity) % parseInt(checkedArr[j].RegQty) !== 0)){
                  handleSetMsgId("エラー");
                  handleSetMsgText("規定数量の倍数を設定してください");
                  handleSetMsgBtnOK("はい");
                  handleClickOpen2();
                  setLoadingFlag1(false)
                  return;
                }
                if((checkedArr[j].RegQty).includes(">=")){
                  if(checkedArr[j].RegQty && checkedArr[j].OrderQuantity && (parseInt(checkedArr[j].RegQty) < Number(checkedArr[j].OrderQuantity))){
                    handleSetMsgId("エラー");
                  handleSetMsgText("規定数量下限値以上の値を設定してください");
                  handleSetMsgBtnOK("はい");
                  handleClickOpen2();
                  setLoadingFlag1(false)
                    return;
                  }
                }
                if((checkedArr[j].RegQty).includes("<=")){
                  if(checkedArr[j].RegQty && checkedArr[j].OrderQuantity && (parseInt(checkedArr[j].RegQty) > Number(checkedArr[j].OrderQuantity))){
                    handleSetMsgId("エラー");
                  handleSetMsgText("規定数量下限値以上の値を設定してください");
                  handleSetMsgBtnOK("はい");
                  handleClickOpen2();
                  setLoadingFlag1(false)
                    return;
                  }
                }
              }else{
                if((checkedArr[j].RegQty).includes(">=")){
                  if(checkedArr[j].RegQty && checkedArr[j].OrderQuantity && (parseInt(checkedArr[j].RegQty) < Number(checkedArr[j].OrderQuantity))){
                    handleSetMsgId("エラー");
                  handleSetMsgText("規定数量下限値以上の値を設定してください");
                  handleSetMsgBtnOK("はい");
                  handleClickOpen2();
                  setLoadingFlag1(false)
                    return;
                  }
                }
                if((checkedArr[j].RegQty).includes("<=")){
                  if(checkedArr[j].RegQty && checkedArr[j].OrderQuantity && (parseInt(checkedArr[j].RegQty) > Number(checkedArr[j].OrderQuantity))){
                    handleSetMsgId("エラー");
                  handleSetMsgText("規定数量下限値以上の値を設定してください");
                  handleSetMsgBtnOK("はい");
                  handleClickOpen2();
                  setLoadingFlag1(false)
                    return;
                  }
                }
              }
            }
            if(checkedArr[j].OrdQty && checkedArr[j].OrderQuantity){
              let OrdQty = isNaN(parseInt(checkedArr[j].OrdQty)) ? 0 : parseInt(checkedArr[j].OrdQty)
              if(OrdQty !== 0 && (Number(checkedArr[j].OrderQuantity) > OrdQty)){
              handleSetMsgId("エラー");
              handleSetMsgText(`1注文可能数は${OrdQty}です。1注文可能数以下で入力してください`);
              handleSetMsgBtnOK("はい");
              handleClickOpen2();
              setLoadingFlag1(false)
              return;
              }
          }
           }
         }
       }
       if(orderrRecipientExArr.length > 1){
        handleSetMsgId("エラー");
        handleSetMsgText("受注先がサンプル品の場合、一つだけを選択してください");
        handleSetMsgBtnOK("はい");
        handleClickOpen2();
        setLoadingFlag1(false)
        return;
       }
       if (hasNoneOrderQuantity) {
         handleSetMsgId("エラー");
         handleSetMsgText("発注数を入力してください");
         handleSetMsgBtnOK("はい");
         handleClickOpen2();
         setLoadingFlag1(false)
         return;
       }
       let countK = 1;
       for (let i = 0; i < orderselect_row.length; i++) {
           for (let j = 0; j < checkedArr.length; j++) {
           if (checkedArr[j].id === orderselect_row[i]) {
            try{
             await API.graphql(
               graphqlOperation(createArAgencyOrderInfo, {
                 input: {
                   id: uuidv4(),
                   Agency_id: agencyId,
                   AgencyEmail: agencyEmail,
                   AgencyName: Agency_Name,
                   GroupName: Company_Name,
                   SubId:subId,
                   QuoteNumber: QuoteNumber,
                   Product_Code: checkedArr[j].ItemCode,
                   Product_Name_j: checkedArr[j].ItemName,
                   OrderQuantity: parseFloat(checkedArr[j].OrderQuantity),
                   Product_id: checkedArr[j].id,
                   DetailNo: countK * 10,
                   RegistrationDate: new Date(),
                   DesiredDeliveryDate: hopedateValue,
                   OrderDetailsNumber: createOrderDetailsNumber(),
                   OrderStatus: "1",
                   ShippingDestination:checkedArr[j].ShipmentCode ? checkedArr[j].ShipmentCode : "",
                     ShippingDestinationNm:checkedArr[j].DestName ? checkedArr[j].DestName : "",
                     EndUserId:checkedArr[j].EUARBmgmtCode ? checkedArr[j].EUARBmgmtCode : "",
                     EndUserNm:checkedArr[j].EndUserName ? checkedArr[j].EndUserName : "",
                     SalesUnitPriceScale:checkedArr[j].SalesUnitPriceScale1 ? checkedArr[j].SalesUnitPriceScale1 : "",
                     PurchUnitPriceScale:checkedArr[j].PurchUnitPriceScale1 ? checkedArr[j].PurchUnitPriceScale1 : "",
                     RegQty:checkedArr[j].RegQty ? checkedArr[j].RegQty : "",
                     BulkSales:checkedArr[j].BulkSales ? checkedArr[j].BulkSales : "",
                     Capacity:checkedArr[j].Capacity ? checkedArr[j].Capacity : "",
                      SuppName:checkedArr[j].SuppName ? checkedArr[j].SuppName : "",
                      BulkShippingFee:checkedArr[j].BulkShippingFee ? checkedArr[j].BulkShippingFee : "",
                      CoolDeliveryCharge:checkedArr[j].CoolDeliveryCharge ? checkedArr[j].CoolDeliveryCharge : "",
                      Sales_Document_Type:checkedArr[j].SalesDocType ? checkedArr[j].SalesDocType : "",
                      CapacityUnit:checkedArr[j].CapacityUnit ? checkedArr[j].CapacityUnit : "",
                      PriceUnit:checkedArr[j].PriceUnit ? checkedArr[j].PriceUnit : "",
                      UserAuth:userAuthInfo,
                      SelectContractor:orderCodeChecked.Code,
                      SelectContractorNm:orderCodeChecked.name,
                      Contractor: orderCodeChecked.Code,
                      ContractorNm: orderCodeChecked.name,
                      Supplier:checkedArr[j].SuppCode,
                      SalesGroup:salesGroup,
                      EndUserIdDirectDelivery:checkedArr[j].TorayEndUser ? checkedArr[j].TorayEndUser : "",
                      User_Name:User_Name,
                      AssigneeCode:AssigneeCode
                    },
               })
             );
              }catch(error){
                if(error.message === 'No current user'){
                  // ユーザープールに障害が発生した場合
                  console.log('User pool is invalid.Please reauthenticate.');
                  // ログイン画面に戻る
                  signOut();
                  }else {
                  // 他のエラーを処理する
                  console.log('An error occurred:',error);
                  }
               }
             countK++;
             break;
           }
         }
       }
       history.push({ pathname: "/OrderEmd", state: {QuoteNumber: QuoteNumber,CalculationOk:true}});
     }
     setLoadingFlag1(false)
   };
   const signOut = async () => {
    await Auth.signOut();
    try {
      await Auth.signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
    document.location.reload();
  };
  const [inputValue, setInputValue] = useState('');
  const [inputValueOrderName, setInputValueOrderName] = useState('');
  const [selectedOrderName, setSelectedOrderName] = useState(null);
  const [selectedOrderCode, setSelectedOrdeCode] = useState(null);
  const [selectedItemCode, setSelectedItemCode] = useState(null);
  const [options1,setoptions1] = useState([]);
  const [options2,setoptions2] = useState([]);
  const handleInputChange = (event, value) => {
    setInputValue(value);
  };
  const handleOrderNameInputChange = (event, value) => {
    setInputValueOrderName(value);
  };
  const handleOrderNameSelectChange = (event, value) => {
    orderselect_row = []
    checkedArr = []
    orderlastContractor = []
    for(let i = 0;i < gobackArr.length;i++){
      orderselect_row.push(gobackArr[i].id);
      checkedArr.push(gobackArr[i]);
      orderlastContractor.push({code:gobackArr[i].SelectContractor,id:gobackArr[i]["id"]});
    }
    if(orderselect_row.length > 1){
        handleSetMsgId("エラー");
        handleSetMsgText("複数品の場合、受注先を変更しないでください");
        handleSetMsgBtnOK("はい");
        handleClickOpen2();
        return;
    }
    setSelectedOrderName(value ? value.name : "");
    setSelectedOrdeCode(value ? value.year : "")
    if(selectedItemCode && value){
      searchBtn(value.year,selectedItemCode,value.name,selectedDate);
    }
  };
  const handleItemCodeSelectChange = (event, value) => {
    orderselect_row = []
    checkedArr = []
    orderlastContractor = []
    for(let i = 0;i < gobackArr.length;i++){
      orderselect_row.push(gobackArr[i].id);
      checkedArr.push(gobackArr[i]);
      orderlastContractor.push({code:gobackArr[i].SelectContractor,id:gobackArr[i]["id"]});
    }
    setSelectedItemCode(value ? value.year : "");
    if(selectedOrderCode && value){
      searchBtn(selectedOrderCode,value.year,selectedOrderName,selectedDate);
    }
  };
  const IsWorkdayFun = async(date)=>{
    // const date1 = new Date(date);
    // const date2 = new Date();
    // date1.setHours(0, 0, 0, 0);
    // date2.setHours(0, 0, 0, 0);
    // const diffTime = Math.abs(date1 - date2);
    // const daysDifference = Math.ceil(diffTime / (1000 * 3600 * 24));
    // let day = ""
    // if (daysDifference === 0){
    //     let days = 3;
    //     let tempDate = new Date(date);
    // for (let i = 0; i < days; i++) {
    //   tempDate.setDate(tempDate.getDate() + 1);
    //   if (IsWorkday.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(tempDate).format('YYYY/MM/DD')).getTime())) {
    //     tempDate.setDate(tempDate.getDate() + 1);
    //     i--
    //   }
    // }
    // day = tempDate
    // }else if(daysDifference === 1){
    //   let days = 2;
    //     let tempDate = new Date(date);
    // for (let i = 0; i < days; i++) {
    //   tempDate.setDate(tempDate.getDate() + 1);
    //   if (IsWorkday.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(tempDate).format('YYYY/MM/DD')).getTime())) {
    //     tempDate.setDate(tempDate.getDate() + 1);
    //     i--
    //   }
    // }
    // day = tempDate
    // }else if(daysDifference === 2){
    //   let days = 1;
    //     let tempDate = new Date(date);
    // for (let i = 0; i < days; i++) {
    //   tempDate.setDate(tempDate.getDate() + 1);
    //   if (IsWorkday.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(tempDate).format('YYYY/MM/DD')).getTime())) {
    //     tempDate.setDate(tempDate.getDate() + 1);
    //     i--
    //   }
    // }
    // day = tempDate
    // }else{
    //   day = date
    // }
    if(selectedItemCode && selectedOrderCode && date){
      inventories.Invs.forEach((item)=>{
        if (item.OrderQuantity) {
          inventoriesCopy.push({id:item.id,OrderQuantity:item.OrderQuantity})
        }
      })
      await searchBtn(selectedOrderCode,selectedItemCode,selectedOrderName,date)
     dataAll = aBoutOrderQuantityFun(inventories.Invs)
     inventoriesCopy = []
     setInv({ type: QUERY, Invs: dataAll });
    }
    setSelectedData(moment(date).utcOffset(9).format('YYYY-MM-DD'))
  }
  const disableWeekends = (date)=> {
      return IsWorkday.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === date.getTime())
  }
   return (
     <Container
       style={{ backgroundColor: "", minWidth: "100%", minHeight: "74vh" }}
     >
       <Meta title={pageTitle} />
       <Row>
         <Col>
           <ToolkitProvider
             keyField="id"
             data={inventories.Invs}
             columns={columns}
             search
             columnToggle
             headerWrapperClasses="foo"
             wrapperClasses="boo"
           >
             {(props) => (
               <div>
                 <div>
                   <Row>
                     <Col
                       style={{
                         display: "flex",
                         justifyContent: "left",
                         alignItems: "bottom",
                       }}
                     >
                      <div style={{display:'flex',marginBottom:"10px"}}>
         <h4
         style={{
           textAlign: "left",
           color: "black",
           height:30,
           width:'20%',
           borderLeft: "4px solid #804F29",
           paddingLeft: "4px",
         }}
       >商品選択
       </h4>
       <Col style={{width:'80%',overflow:"hidden"}}>
       <div style={{width:'100%',height:140,display:'flex'}}>
        <div style={{width:'90%',minWidth:"900px",height:140,display:'flex',flexWrap:'wrap',padding:"30px 10px 0px 10px"}}>
        <div style={{width:"80%",height:'30px',display:"flex"}}>
              <InputGroup.Text
              className={classes.textlabelOrder}
              id="basic-name">
              [必須]受注先
               </InputGroup.Text>
               <Autocomplete
      className={classes.autocomplete}
      id="combo-box-demo"
      options={options2}
      value={selectedOrderName}
      disabled={OrderReceiptCodeDisable}
      inputValue={inputValueOrderName}
      onInputChange={handleOrderNameInputChange}
      onChange={handleOrderNameSelectChange}
      renderInput={(params) => <TextField {...params} label="" variant="standard"/>}
    /></div>
              <div style={{width:"80%",height:'30px',display:"flex"}}>
                                  <InputGroup.Text
                                    className={classes.textlabelOrder}
                                    id="basic-name"
                                    style={{borderRadius:"0px"}}
                                   >[必須]品目
                                  </InputGroup.Text>
                                  <Autocomplete
       className={classes.autocomplete}
       options={options1}
       value={selectedItemCode}
       inputValue={inputValue}
       onInputChange={handleInputChange}
       onChange={handleItemCodeSelectChange}
      renderInput={(params) => <TextField {...params} variant="standard"/>}
    />
    </div>
    <Button className="btn" onClick={()=>searchBtn()} style={{display:"none",height:33,width:120,fontSize:'18px',color:'black',border:'3px solid black',background:"white",margin:'auto'}}>検索</Button>
              </div>
        </div></Col>
         </div>
                     </Col>
                     <Col
                       style={{
                         display: "flex",
                         justifyContent: "right",
                         alignItems: "top",
                       }}
                     >
                       <div
                         style={{
                           display: "flex",
                           justifyContent: "right",
                           alignItems: "center",
                           paddingTop: "70px"
                         }}
                       >
                         <InputGroup className="mb-3">
                           <div
                             style={{ display:pageInitFlag === true ? "flex" : "none"}}
                           >
                             <InputGroup.Text
                               className={classes.textFieldHope}
                               id="basic-from"
                             >
                               <span style={{ color: "red" }}>［必須］</span>
                               希望納期
                             </InputGroup.Text>
                             <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                               <DatePicker
                                 className={classes.datePicker}
                                 disablePast
                                 disableToolbar
                                 variant="inline"
                                 inputVariant="standard"
                                 format="yyyy/MM/dd"
                                 id="hopedate"
                                 label=""
                                 value={selectedDate}
                                 defaultValue={DateFun()}
                                onChange={(date)=>{
                                  IsWorkdayFun(date)
                                }}
                                shouldDisableDate={disableWeekends}
                               />
                             </MuiPickersUtilsProvider>
                           </div>
                           <div style={{ display: "flex" }}>
                             <Button
                               type="button"
                               className=" btn btn-danger"
                               style={{
                                 marginLeft: "1em",
                                 width: "120px",
                               }}
                               onClick={() => {
                                 orderClick();
                               }}
                             >
                               注文登録へ
                             </Button>
                           </div>
                         </InputGroup>
                       </div>
                     </Col>
                   </Row>
                 </div>
                 <hr />
                 <div style={{ width: '100%', height: '100%', overflow: "auto" }}>
                 <div style={{ width: '99%', height: '100%'}}>
                   {loaddingFlag && <LinearProgress />}
                   <BootstrapTable
                     striped={true}
                     hover={true}
                     bootstrap4
                     condensed
                     data={inventories.Invs}
                     columns={columns}
                     keyField="id"
                     {...props.baseProps}
                     pagination={paginationFactory(options)}
                     defaultSorted={defaultSorted}
                     selectRow={selectRow}
                     defaultSortDirection="desc"
                     filterPosition="inline"
                     headerWrapperClasses="foo"
                     wrapperClasses="boo "
                     noDataIndication={"no results found"}
                     cellEdit={cellEditFactory({
                       mode: "click",
                       blurToSave: true,
                       afterSaveCell: (beforeValue, afterValue, row) => {
                         handleSetCount(row.id, afterValue)
                       }
                     })}
                   />{" "}
                 </div>
                 <div style={{ display: "none" }}>
                   <hr />
                   <span>表記させる項目をこのアイコンでお選び頂けます</span>
                   <br />
                   <br />
                   <br />
                   <hr />
                 </div>
               </div>
               </div>
             )}
           </ToolkitProvider>
         </Col>
       </Row>
      <CircularProgress style={{display:loaddingFlag1 === true ? "block" : "none",position: 'fixed',top: '50%', left: '50%', margin:"auto"}}/>
       <Dialog
         open={open2}
         onClose={handleCloseNG2}
         disableEscapeKeyDown={true}
         aria-labelledby="alert-dialog-title"
         aria-describedby="alert-dialog-description"
       >
         <DialogTitle id="alert-dialog-title">{msg1}</DialogTitle>
         <DialogContent>
           <DialogContentText id="alert-dialog-description">
             {msgText}
           </DialogContentText>
         </DialogContent>
         <DialogActions>
           <Button color="secondary" autoFocus onClick={handleCloseOK2}>
             {msgbtnOK}
           </Button>
         </DialogActions>
       </Dialog>
     </Container>
   );
 }
 export default InventorySearch;