import React from "react";
const defaultSorted = [
    {
        dataField: "SAPCODE",
        order: "asc",
    },
];
const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
        {size} の結果の {from} から {to} を表示しています
    </span>
);
const pageButtonRenderer = ({ page, onPageChange, currSizePerPage }) => {
    const handleClick = (e) => {
        e.preventDefault();
        onPageChange(page);
    };
    return (
        <button
            key={page}
            className={`btn ${currSizePerPage === `${page}` ? "btn-warning" : "btn-light"
                }`}
            type="button"
            onClick={handleClick}
        >
            <li className="page-item">
                <a style={{ color: "black" }} href="#">
                    {page}
                </a>
            </li>
        </button>
    );
};
const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
}) => (
    <div className="btn-group" role="group">
        {options.map((option) => (
            <button
                key={option.text}
                type="button"
                onClick={() => onSizePerPageChange(option.page)}
                className={`btn ${currSizePerPage === `${option.page}` ? "btn-warning" : "btn-light"
                    }`}
            >
                {option.text}
            </button>
        ))}
    </div>
);
const options = {
    paginationSize: 20,
    pageStartIndex: 1,
    showTotal: true,
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: "最初",
    prePageText: "戻る",
    nextPageText: "次へ",
    lastPageText: "最後",
    nextPageTitle: "最初のページ",
    prePageTitle: "プレページ",
    firstPageTitle: "次のページ",
    lastPageTitle: "最後のページ",
    paginationTotalRenderer: customTotal,
    sizePerPageRenderer: sizePerPageRenderer,
    pageButtonRenderer: pageButtonRenderer,
    disablePageTitle: true,
    sizePerPageList: [
        {
            text: "20",
            value: 20,
        },
        {
            text: "50",
            value: 50,
        },
        {
            text: "100",
            value: 100,
        }
    ],
};
const openValueInit = {
    sAPCODE: true,
    GMID: true,
    ItemText: true,
    ARBSuppAgencyCode: true,
    SuppCode: true,
    suppName: true,
    ItemGrp: true,
    ItemGrpName: true,
    InventoryTier: true,
    OrderAdjustment: true,
    Capacity: true,
    CapacityUnit: true,
    BaseNumUnit: true,
    OrderUnitpriceUnit: true,
    UnitPerOrder: true,
    StorageLoc: true,
    ChilledGoods: true,
    USproduct: true,
    Expiration: true,
    CustomerMOQ: true,
    CustomerLTguidance: true,
    PriceChangeFactor: true,
    StandardLeadTime: true,
    LatestLT: true,
    QuotationReplyLT: true,
    StipulateRestdays: true,
    FreeHoldingdays: true,
    HScode: true,
    ProductCountry: true,
    MFGPlant: true,
    Remark: true,
    StorageTemp: true,
    ProductSizeWDH: true,
    OuterBoxSizeWDH: true,
    DelFlag: true,
};
const dialogText1 = {
    dialogText: {
        SAPCODE: "",
        GMID: "",
        ItemText: "",
        ARBSuppAgencyCode: "",
        SuppCode: "",
        SuppName: "",
        ItemGrp: "",
        ItemGrpName: "",
        InventoryTier: "",
        OrderAdjustment: "",
        Capacity: "",
        CapacityUnit: "",
        BaseNumUnit: "",
        OrderUnitpriceUnit: "",
        UnitPerOrder: "",
        StorageLoc: "",
        ChilledGoods: "",
        USproduct: "",
        Expiration: "",
        CustomerMOQ: "",
        CustomerLTguidance: "",
        PriceChangeFactor: "",
        StandardLeadTime: "",
        LatestLT: "",
        QuotationReplyLT: "",
        StipulateRestdays: "",
        FreeHoldingdays: "",
        HScode: "",
        ProductCountry: "",
        MFGPlant: "",
        Remark: "",
        StorageTemp: "",
        ProductSizeWDH: "",
        OuterBoxSizeWDH: "",
        DelFlag: "",
    }

}
const dialogText2 = {
    SAPCODE: "",
    GMID: "",
    ItemText: "",
    ARBSuppAgencyCode: "",
    SuppCode: "",
    SuppName: "",
    ItemGrp: "",
    ItemGrpName: "",
    InventoryTier: "",
    OrderAdjustment: "",
    Capacity: "",
    CapacityUnit: "",
    BaseNumUnit: "",
    OrderUnitpriceUnit: "",
    UnitPerOrder: "",
    StorageLoc: "",
    ChilledGoods: "",
    USproduct: "",
    Expiration: "",
    CustomerMOQ: "",
    CustomerLTguidance: "",
    PriceChangeFactor: "",
    StandardLeadTime: "",
    LatestLT: "",
    QuotationReplyLT: "",
    StipulateRestdays: "",
    FreeHoldingdays: "",
    HScode: "",
    ProductCountry: "",
    MFGPlant: "",
    Remark: "",
    StorageTemp: "",
    ProductSizeWDH: "",
    OuterBoxSizeWDH: "",
    DelFlag: "",
}
const ArItemMasterConst = {
    PROC_INQUIRY: "照会",
    PROC_ADD: "新規登録",
    PROC_CADD: "参照登録",
    PROC_REF: "変更",
    PROC_DEL: "削除",
    DilogDetill: "",
    DilogDetillDisabled:{
        dialogTextSAPCODE:false,
        dialogTextGMID:false,
        dialogTextItemText:false,
        dialogTextItemGrp:false,
        dialogTextItemGrpName:false,
        dialogTextARBSuppAgencyCode:false,
        dialogTextCapacity:false,
        dialogTextCapacityUnit:false,
        dialogTextBaseNumUnit:false,
        dialogTextOrderUnitpriceUnit:false,
        dialogTextUnitPerOrder:false,
        dialogTextInventoryTier:false,
        dialogTextOrderAdjustment:false,
        dialogTextStorageLoc:false,
        dialogTextChilledGoods:false,
        dialogTextUSproduct:false,
        dialogTextExpiration:false,
        dialogTextCustomerMOQ:false,
        dialogTextCustomerLTguidance:false,
        dialogTextPriceChangeFactor:false,
        dialogTextSuppCode:false,
        dialogTextSuppName:false,
        dialogTextStandardLeadTime:false,
        dialogTextLatestLT:false,
        dialogTextQuotationReplyLT:false,
        dialogTextStipulateRestdays:false,
        dialogTextFreeHoldingdays:false,
        dialogTextHScode:false,
        dialogTextProductCountry:false,
        dialogTextMFGPlant:false,
        dialogTextRemark:false,
        dialogTextStorageTemp:false,
        dialogTextProductSizeWDH:false,
        dialogTextOuterBoxSizeWDH:false,
        dialogTextDelFlag:true,
    }
}
const DilogDetillDisabled = {
    add:{
        dialogTextSAPCODE:false,
        dialogTextGMID:false,
        dialogTextItemText:false,
        dialogTextItemGrp:false,
        dialogTextItemGrpName:false,
        dialogTextARBSuppAgencyCode:false,
        dialogTextCapacity:false,
        dialogTextCapacityUnit:false,
        dialogTextBaseNumUnit:false,
        dialogTextOrderUnitpriceUnit:false,
        dialogTextUnitPerOrder:false,
        dialogTextInventoryTier:false,
        dialogTextOrderAdjustment:false,
        dialogTextStorageLoc:false,
        dialogTextChilledGoods:false,
        dialogTextUSproduct:false,
        dialogTextExpiration:false,
        dialogTextCustomerMOQ:false,
        dialogTextCustomerLTguidance:false,
        dialogTextPriceChangeFactor:false,
        dialogTextStandardLeadTime:false,
        dialogTextLatestLT:false,
        dialogTextQuotationReplyLT:false,
        dialogTextSuppCode:false,
        dialogTextSuppName:false,
        dialogTextStipulateRestdays:false,
        dialogTextFreeHoldingdays:false,
        dialogTextHScode:false,
        dialogTextProductCountry:false,
        dialogTextMFGPlant:false,
        dialogTextRemark:false,
        dialogTextStorageTemp:false,
        dialogTextProductSizeWDH:false,
        dialogTextOuterBoxSizeWDH:false,
        dialogTextDelFlag:true,
    },
    modify:{
        dialogTextSAPCODE:true,
        dialogTextGMID:true,
        dialogTextItemText:false,
        dialogTextItemGrp:false,
        dialogTextItemGrpName:false,
        dialogTextARBSuppAgencyCode:false,
        dialogTextCapacity:false,
        dialogTextCapacityUnit:false,
        dialogTextBaseNumUnit:false,
        dialogTextOrderUnitpriceUnit:false,
        dialogTextUnitPerOrder:false,
        dialogTextInventoryTier:false,
        dialogTextOrderAdjustment:false,
        dialogTextStorageLoc:false,
        dialogTextChilledGoods:false,
        dialogTextUSproduct:false,
        dialogTextExpiration:false,
        dialogTextCustomerMOQ:false,
        dialogTextCustomerLTguidance:false,
        dialogTextPriceChangeFactor:false,
        dialogTextStandardLeadTime:false,
        dialogTextLatestLT:false,
        dialogTextQuotationReplyLT:false,
        dialogTextSuppCode:false,
        dialogTextSuppName:false,
        dialogTextStipulateRestdays:false,
        dialogTextFreeHoldingdays:false,
        dialogTextHScode:false,
        dialogTextProductCountry:false,
        dialogTextMFGPlant:false,
        dialogTextRemark:false,
        dialogTextStorageTemp:false,
        dialogTextProductSizeWDH:false,
        dialogTextOuterBoxSizeWDH:false,
        dialogTextDelFlag:true,
    },
    delete:{
        dialogTextSAPCODE:true,
        dialogTextGMID:true,
        dialogTextItemText:true,
        dialogTextItemGrp:true,
        dialogTextItemGrpName:true,
        dialogTextARBSuppAgencyCode:true,
        dialogTextCapacity:true,
        dialogTextCapacityUnit:true,
        dialogTextBaseNumUnit:true,
        dialogTextOrderUnitpriceUnit:true,
        dialogTextUnitPerOrder:true,
        dialogTextInventoryTier:true,
        dialogTextOrderAdjustment:true,
        dialogTextStorageLoc:true,
        dialogTextChilledGoods:true,
        dialogTextUSproduct:true,
        dialogTextExpiration:true,
        dialogTextCustomerMOQ:true,
        dialogTextCustomerLTguidance:true,
        dialogTextPriceChangeFactor:true,
        dialogTextSuppCode:true,
        dialogTextSuppName:true,
        dialogTextStandardLeadTime:true,
        dialogTextLatestLT:true,
        dialogTextQuotationReplyLT:true,
        dialogTextStipulateRestdays:true,
        dialogTextFreeHoldingdays:true,
        dialogTextHScode:true,
        dialogTextProductCountry:true,
        dialogTextMFGPlant:true,
        dialogTextRemark:true,
        dialogTextStorageTemp:true,
        dialogTextProductSizeWDH:true,
        dialogTextOuterBoxSizeWDH:true,
        dialogTextDelFlag:true,
    }
}
export {
    ArItemMasterConst,
    dialogText2,
    dialogText1,
    openValueInit,
    options,
    defaultSorted,
    DilogDetillDisabled
}