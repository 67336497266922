import React from "react";
import { InputGroup } from "react-bootstrap";
import useStyles from "./ArItemMasterStyle";
import {checkedMasterlist} from "./ArItemMasterMethod";
export default function MasterDisplayItem(params) {
    const classes = useStyles()
    const checkboxHandleChange = ()=>{
        let SelectAllFlagInit = true
        let settingButtonDisable = true
        const checkedlist = checkedMasterlist();
        for (let item in checkedlist) {
          if(checkedlist[item] === false){
            SelectAllFlagInit = false
          }else{
            settingButtonDisable = false
          }
      }
      params.checkBoxChange(SelectAllFlagInit,settingButtonDisable)
    }
    return (
        <div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px",background:"white",color:"black"}}
                    id="basic-name"
                >全項目チェック
                </InputGroup.Text>
                <input type="checkbox"className={classes.inputSizeBa} id="selectAll" name="selectAll" onClick={()=>params.DisplayProjectsSettingsSelectAll()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >品目
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="sAPCODE" name="sAPCODE" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >Material Code
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="GMID" name="GMID" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >品目名
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="ItemText" name="ItemText" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >ARB仕入元代理店コード
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="ARBSuppAgencyCode" name="ARBSuppAgencyCode" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >品目グループ*
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="ItemGrp" name="ItemGrp" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >品目グループ名*
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="ItemGrpName" name="ItemGrpName" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入先コード
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="SuppCode" name="SuppCode" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >仕入先名
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="suppName" name="suppName" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >容量*
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="Capacity" name="Capacity" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >容量単位*
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="CapacityUnit" name="CapacityUnit" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >基本数量単位
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="BaseNumUnit" name="BaseNumUnit" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >受注単価単位
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="OrderUnitpriceUnit" name="OrderUnitpriceUnit" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >発注単価単位
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="UnitPerOrder" name="UnitPerOrder" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >在庫区分
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="InventoryTier" name="InventoryTier" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >受注調整品
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="OrderAdjustment" name="OrderAdjustment" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >保管場所
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="StorageLoc" name="StorageLoc" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >冷蔵品
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="ChilledGoods" name="ChilledGoods" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >米国品
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="USproduct" name="USproduct" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >有効期限
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="Expiration" name="Expiration" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >客先MOQ
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="CustomerMOQ" name="CustomerMOQ" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >客先LT案内
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="CustomerLTguidance" name="CustomerLTguidance" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >価格変換係数(kg→数量)
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="PriceChangeFactor" name="PriceChangeFactor" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >標準リードタイム
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="StandardLeadTime" name="StandardLeadTime" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >最新LT
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="LatestLT" name="LatestLT" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >見積回答LT
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="QuotationReplyLT" name="QuotationReplyLT" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >規定残日数
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="StipulateRestdays" name="StipulateRestdays" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >フリー保有日数
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="FreeHoldingdays" name="FreeHoldingdays" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >HSコード
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="HScode" name="HScode" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >生産国
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="ProductCountry" name="ProductCountry" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >製造工場
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="MFGPlant" name="MFGPlant" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >保管温度
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="StorageTemp" name="StorageTemp" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >Remark
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="Remark" name="Remark" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >製品サイズ
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="ProductSizeWDH" name="ProductSizeWDH" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >外装カートンサイズ
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="OuterBoxSizeWDH" name="OuterBoxSizeWDH" onClick={()=>checkboxHandleChange()}/>
            </div>
            <div style={{ width: "300px", height: '40px', display: "flex", justifyContent: "space-evenly" }}>
                <InputGroup.Text
                    className={classes.textlabelDialogBa}
                    style={{ height: '30px', borderRadius: "0px" }}
                    id="basic-name"
                >削除フラグ
                </InputGroup.Text>
                <input type="checkbox" className={classes.inputSizeBa} id="DelFlag" name="DelFlag" onClick={()=>checkboxHandleChange()}/>
            </div>
        </div>
    )
}