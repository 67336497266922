/* eslint-disable no-irregular-whitespace */
/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  InventorySearch.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary InventorySearch画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
import "./style/ar.css";
import Meta from "../components/Meta";
import userAuth from "../components/userAuth";
import React, { useEffect, useReducer, useState } from "react";
import { useLocation } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from "moment";
// import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import jaLocale from 'date-fns/locale/ja';
import Button from "@material-ui/core/Button";
/*コンテナ（画面レイアウト）デザイン*/
import { Container, Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
//フィルター
import filterFactory, {
  textFilter,
  selectFilter,
  Comparator,
} from "react-bootstrap-table2-filter";
//検索
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
/*  GraphQL API Acess */
import API, { graphqlOperation } from "@aws-amplify/api";
import {
  agencyGroupSortList,
  customerByeMail,
  AdminAgencyPriceGroupSortList,
  listArAgencyOrderInfos,
  orderByQuoteNumber,
  listArcalendars,
} from "../graphql/queries";
import {
  createArOrder,
  createArAgencyOrderInfo,
  updateArAgencyOrderInfo,
  deleteArAgencyOrderInfo,
  updateArOrder
} from "../graphql/mutations"; //,updateArOrder
/*  AWS標準設定 with Login Auth. */
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);
import { useHistory } from "react-router-dom";
// page content
const pageTitle = "AR BROWN Order System";
const { SearchBar, ClearSearchButton } = Search;
import { v4 as uuidv4 } from "uuid";
import { InputGroup } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import cellEditFactory from "react-bootstrap-table2-editor";
import LinearProgress from '@material-ui/core/LinearProgress';
import "../components/ArGlobal";
// import { PanoramaSharp } from '@material-ui/icons';
const QUERY = "QUERY";
const initialInventoryState = { Invs: [] };
const reducer = (state, action) => {
  switch (action.type) {
    case QUERY:
      return { ...state, Invs: action.Invs };
    default:
      return state;
  }
};
// const initialSupplier = {Invs:{}};
const reducerSupplier = (state, action) => {
  let obj = {};
  switch (action.type) {
    case QUERY:
      for (let i = 0; i < action.Invs.length; i++) {
        let cur = action.Invs[i];
        let curn = action.Invs[i];
        obj[cur] = curn;
      }
      return { ...state, ...obj };
    default:
      return state;
  }
};

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "30ch",
  },
  textFieldPink: {
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    width: "9rem",
    backgroundColor: "white",
    padding: "4px",
  },
  datePicker: {
    width: "25ch",
  },
  textFieldHope: {
    marginLeft: theme.spacing(0),
    color: "black",
    width: "9rem",
    border: "none",
    marginRight: "1em",
    fontWeight: "bold",
    background: "none",
  },
  textFieldBu: {
    align: "right",
    marginLeft: "1rem",
    marginRight: theme.spacing(0),
    width: "12ch",
    color: "white",
    borderRadius: "2px",
    backgroundColor: "red",
    "&:hover": {
      backgroundColor: " palevioletred;",
    },
  },
  //入力ボックスのセルの設定
  textFieldOrderQuantity: {
    margin: theme.spacing(0),
    position: "relative",
    height: "100%",
    textAlign: "center",
  },
  resize: {
    height: "100%",
    textAlign: "center",
  },
}));
const headerStyle = {
  backgroundColor: "white",
  borderBottom: "3px solid #804F29",
};
const headerSortingStyle = { backgroundColor: "#BEBEBE" };
function getStyles(name, priceGroup, theme) {
  return {
    fontWeight:
      priceGroup.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 9;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
let orderselect_row = [];
let agencyId = "";
let agencyEmail = "";
let Agency_Name = "";
let Company_Name = "";

const checkedArr = [];
function InventorySearch() {
  const [loginDisplay, setloginDisplay] = useState(true);
  const [inventories, setInv] = useReducer(reducer, initialInventoryState);
  const [selectOptions, setSupplier] = useReducer(reducerSupplier, {});
  const [adminAuthFlg, setAdminAuthFlgFlg] = React.useState(Object);
  const [loaddingFlag, setLoadingFlag] = useState(false);
  // const [selectOptions, setSelectOptions] = React.useState({});
  const handleSetAdminAuthFlg = (Object) => {
    setAdminAuthFlgFlg(Object);
  };
  const handleSetCount = (id, Object) => {
    if (Object === "" || Object === null || Object === undefined) {
      setQ(id, "");
      return;
    }
    if (isNaN(Object)) {
      setQ(id, "");
      return;
    }
    if (Object < 0) {
      setQ(id, "");
      return;
    }
    // if (Object > Quantity) {
    //   idTemp.value = Quantity;
    //   setQ(id, Quantity);
    //   return
    // }
    setQ(id, Object);
  };
  function setQ(id, num) {
    for (let j = 0; j < inventories.Invs.length; j++) {
      if (inventories.Invs[j].id === id) {
        inventories.Invs[j].OrderQuantity = num;
        break;
      }
    }
  }

  // let localHiddenFiles = JSON.parse(localStorage.getItem("hiddenFields"));
  // let hiddenFileds = localHiddenFiles
  //   ? localHiddenFiles
  //   : [
  //       "ID",
  //       "Product_Name_e",
  //       "Wholes_Rate",
  //       "Wholes_Price",
  //       "Campaign_price",
  //       "Campaign_partition_rate",
  //       "Storage_Temp",
  //       "LawsRegulations",
  //     ];
  let dataAll = null;
  let columns = [
    { dataField: "id", text: "ID", hidden: true },
    // { dataField: 'Agency_Price_Group', text: 'Group', filter: textFilter(), sort: true, hidden: true },
    {
      dataField: "Supplier",
      text: "製造元",
      formatter: (cell) => selectOptions[cell],
      filter: selectFilter({
        options: selectOptions,
        style: { backgroundColor: "", margin: "0px", width: "140px" },
      }),
      sort: true,
      headerSortingStyle,
      headerStyle,
      type: "string",
      editable: false,
    },
    {
      dataField: "Product_Code",
      text: "品目コード",
      width: "200px",
      filter: textFilter({
        placeholder: "",
        className: "",
        defaultValue: "",
        comparator: Comparator.LIKE, // default is Comparator.LIKE
        caseSensitive: true,
        style: { backgroundColor: "", margin: "0px", width: "100px" },
        delay: 500,
      }),
      sort: true,
      headerSortingStyle,
      headerStyle,
      type: "string",
      editable: false,
      fixed: "left",
    },
    {
      dataField: "Product_Name_j",
      text: "品名",
      filter: textFilter({
        style: { backgroundColor: "", margin: "0px", width: "120px" },
      }),
      sort: true,
      headerSortingStyle,
      headerStyle,
      editable: false,
      type: "string",
    },
    {
      dataField: "Product_Name_e",
      hidden: true,
      text: "製品名（英名）",
      headerSortingStyle,
      style: { margin: "0px", width: "300px" },
      headerStyle,
      type: "string",
      editable: false,
    },
    {
      dataField: "Capacity_Display",
      text: "容量",
      headerStyle,
      editable: false,
    },
    {
      dataField: "Catarog_Price",
      text: "定価",
      style: { margin: "0px", width: "100px" },
      headerStyle,
      align: "right",
      headerAlign: "left",
      editable: false,
    },
    {
      dataField: "Wholes_Rate",
      text: "仕切率",
      headerStyle,
      style: { margin: "0px", width: "80px" },
      align: "right",
      editable: false,
    },
    {
      dataField: "Wholes_Price",
      text: "単価",
      headerStyle,
      align: "right",
      editable: false,
      formatter: (cellContent, row) => {
        if (row.Wholes_Rate === "-") {
          return <span>-</span>;
        } else {
          return <span>{row.Wholes_Price}</span>;
        }
      },
    },
    // {
    //   dataField: "Campaign_price",
    //   hidden: hiddenFileds.indexOf("Campaign_price") > -1 ? true : false,
    //   text: "キャンペーン価格",
    //   style: { margin: "0px", width: "140px" },
    //   headerStyle,
    //   align: "right",
    //   isDummyField: true,
    //   editable: false,
    //   formatter: (cellContent, row) => {
    //     if (row.Campaign_price === null) {
    //       return <span>―</span>;
    //     } else {
    //       return <span>{row.Campaign_price}</span>;
    //     }
    //   },
    // },
    // {
    //   dataField: "Campaign_partition_rate",
    //   hidden:
    //     hiddenFileds.indexOf("Campaign_partition_rate") > -1 ? true : false,
    //   text: "キャンペーン仕切率",
    //   his: false,
    //   align: "right",
    //   style: { margin: "0px", width: "140px" },
    //   headerStyle,
    //   isDummyField: true,
    //   editable: false,
    //   formatter: (cellContent, row) => {
    //     if (row.Campaign_partition_rate === null) {
    //       return <span>―</span>;
    //     } else {
    //       return <span>{row.Campaign_partition_rate}</span>;
    //     }
    //   },
    // },
    {
      dataField: "TempInventoryQuantity",
      hidden: loginDisplay ? true : false,
      text: "発注数",
      align: "right",
      type: "number",
      headerStyle,
      // filter: numberFilter({
      //   defaultValue: { number: 0, comparator: Comparator.GE },
      //   comparators: [Comparator.EQ, Comparator.GE, Comparator.LE],
      //   style: { display: 'inline-grid' },
      //   className: 'custom-numberfilter-class',
      //   comparatorStyle: { backgroundColor: 'antiquewhite', margin: '0px', width: '70px' },
      //   comparatorClassName: 'custom-comparator-class',
      //   numberStyle: { backgroundColor: 'cadetblue', margin: '0px', width: '100px' },
      //   numberClassName: 'custom-number-class'
      // }),
      editable: false,
    },
    // {
    //   dataField: "Delivery_Term",
    //   hidden: hiddenFileds.indexOf("Delivery_Term") > -1 ? true : false,
    //   style: { margin: "0px", width: "120px" },
    //   text: "欠品時目安納期",
    //   headerStyle,
    //   editable: false,
    // },
    // {
    //   dataField: "Storage_Temp",
    //   hidden: hiddenFileds.indexOf("Storage_Temp") > -1 ? true : false,
    //   tyle: { margin: "0px", width: "100px" },
    //   text: "保管温度",
    //   headerStyle,
    //   editable: false,
    // },
    // {
    //   dataField: "LawsRegulations",
    //   hidden: hiddenFileds.indexOf("LawsRegulations") > -1 ? true : false,
    //   tyle: { margin: "0px", width: "60px" },
    //   text: "法規制",
    //   headerStyle,
    //   editable: false,
    // },
    {
      dataField: "OrderQuantity",
      style: {
        margin: "0px",
        width: "100px",
        padding: "0px 0px",
        fontSize: "18px",
        height: "40px",
      },
      text: "発注数",
      editable: true,
      hidden: loginDisplay ? false : true,
      type: "number",
      // editable: (content, row) => row.Quantity > 0,
      // editCellStyle: (cell) => {
      //   const backgroundColor = cell > 1 ? "#00BFFF" : "#00FFFF";
      //   return { backgroundColor };
      // },
      headerStyle,
      events: {
        // onClick: (e, column, columnIndex, row, rowIndex) => {
        //   console.log(e);
        //   console.log(column);
        //   console.log(columnIndex);
        //   console.log(row);
        //   console.log(rowIndex);
        //   return false;
        // },
        // onMouseEnter: (e, column, columnIndex, row, rowIndex) => {
        //   console.log(e);
        //   console.log(column);
        //   console.log(columnIndex);
        //   console.log(row);
        //   console.log(rowIndex);
        // },
        // onChange: (e) => {
        //   console.log("e.value=", e.target.value)
        // },
        // onMouseLeave: (e) => {
        //   console.log("onMouseLeave=", e.target.value)
        // },
        // onBlur: (e) => {
        //   console.log("onBlur=", e.target.value)
        // }
      },
      formatter: (cellContent, row) => {
        if (row.id !== null) {
          return (
            <TextField
              id={row.id}
              variant="outlined"
              // type="number"
              style={{ alignItems: "center" }}
              onChange={(e) => {
                handleSetCount(
                  row.id,
                  e.target.value
                );
              }}
              name="OrderQuantity"
              value={row.OrderQuantity}
              InputLabelProps={{
                className: classes.resize,
              }}
              className={classes.textFieldOrderQuantity}
              InputProps={{
                disableUnderline: true,
                className: classes.resize,
                // inputProps: {
                //   onInput: () => {
                //   },
                //   // min: 0,
                // }
              }}
            />
          );
        }
      },
    },
  ];

  // const [columns, setColumns] = useState(columnsDefault());
  // eslint-disable-next-line no-unused-vars
  const location = useLocation();
  let titleTemp = [];
  const [Title, setTitle] = useState(titleTemp);
  const [IsWorkday, setIsWorkday] = useState([])
  const [pageInitFlag,setPageInitFlag] = useState(false)
  const getIsWorkday = async () => {
   let holidays = [];
   // let format = new Date(today.getFullYear() + 1, 11, 31)
   const listArcalendarsList = await API.graphql(
     graphqlOperation(listArcalendars, {
       filter: { IsWorkday: { eq: "0" }},
       limit: 10000,
     }))
   if (listArcalendarsList.data.listArcalendars !== null) {
     let nextToken = listArcalendarsList.data.listArcalendars.nextToken;
     holidays = listArcalendarsList.data.listArcalendars.items;
     while (nextToken !== null) {
       let InvDataSec = await API.graphql(
         graphqlOperation(listArcalendars, {
           filter: { IsWorkday: { eq: "0" }},
           limit: 10000,
           nextToken: nextToken,
         }))
       nextToken = InvDataSec.data.listArcalendars.nextToken;
       for (
         let i = 0;
         i < InvDataSec.data.listArcalendars.items.length;
         i++
       ) {
         holidays.push(InvDataSec.data.listArcalendars.items[i]);
       }
     }
   }
   return holidays;
 }
 const selectedDateInit = async(day)=>{
   let IsWorkdays = day
   let tempDate = new Date();
   if(!location.state){
for (let i = 0; i < 2; i++) {
 tempDate.setDate(tempDate.getDate() + 1);
 if (IsWorkdays.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(tempDate).format('YYYY/MM/DD')).getTime())) {
   tempDate.setDate(tempDate.getDate() + 1);
   i--
 }
}
}else{
 let formatY = new Date();
 for (let i = 0; i < 2; i++) {
   formatY.setDate(formatY.getDate() + 1);
   if (IsWorkdays.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === new Date(moment(formatY).format('YYYY/MM/DD')).getTime())) {
     formatY.setDate(formatY.getDate() + 1);
     i--
   }
 }
 tempDate = location.state.DesiredDeliveryDate.replace(/\//g, "-");
 if (moment(tempDate).format('YYYYMMDD') <= moment(formatY).format('YYYYMMDD')) {
   tempDate = formatY;
 }
}
return moment(tempDate).utcOffset(9).format('YYYY-MM-DD')
  }
  const disableWeekends = (date)=> {
    return IsWorkday.some(h => new Date(moment(h.Date).format('YYYY/MM/DD')).getTime() === date.getTime())
}
const IsWorkdayFun = (date)=>{
  setSelectedData(moment(date).format('YYYY-MM-DD'))
}
  useEffect(() => {
    let btnToggleSupplier = document.getElementById("btnToggleSupplier");
    if (btnToggleSupplier !== null) {
      btnToggleSupplier.click();
    }

    // 非同期型（async）で在庫情報をagencyGroupInvListからAgency_Price_GroupをKeyに取得
    // 1) Auth.currentUserInfo() から、email情報を取得して、customerByeMailからeMailをKeyにAgent情報を取得
    //  ※agencyGroupIDはAgent.jsで一度取得しているから再利用可能なはず！
    // 2) agencyGroupIDを取得して、これをKeyにagencyGroupInvListから在庫情報を取得
    async function listInvData() {
      //   let nTime = new Date();
      //   let format = "";
      // format += nTime.getFullYear() + "-";
      // format += (nTime.getMonth() + 1) < 10 ? "0" + (nTime.getMonth() + 1) : (nTime.getMonth() + 1);
      // format += "-";
      // format += nTime.getDate() < 10 ? "0" + (nTime.getDate()) : (nTime.getDate());
      // handleSetHopedate(format);

      // get the Login user infomation. Set to user
      // const { accessToken } = await Auth.currentSession();
      // const cognitogroups = accessToken.payload['cognito:groups'];
      // const userAuth = cognitogroups[0];
      // handleSetloaddisabledflg2(true);
      const userAuths = await userAuth();
      orderselect_row = [];
      let agentGroupID;
      if (userAuths === "1") {
        titleTemp = "商品選択";
        setTitle(titleTemp);
        handleSetAdminAuthFlg(true);
        const user = await Auth.currentUserInfo();
        const agencyPriceGroup = await API.graphql(
          graphqlOperation(customerByeMail, {
            Agency_Email: user.attributes.email,
          })
        );
        const agentinfo = agencyPriceGroup.data.customerByeMail["items"];
        if (agentinfo.length > 0) {
          agentGroupID = agentinfo[0].Agency_Price_Group;
          agencyId = agentinfo[0].Agency_id;
          agencyEmail = agentinfo[0].Agency_Email;
          Agency_Name = agentinfo[0].Agency_Name;
          Company_Name = agentinfo[0].Company_Name;
        }
        setloginDisplay(true);
      } else {
        // admin
        titleTemp = "商品管理";
        setTitle(titleTemp);
        handleSetAdminAuthFlg(false);
        const AdminAgencyPriceList = await API.graphql(
          graphqlOperation(AdminAgencyPriceGroupSortList, {
            Admin_Group: "admin",
          })
        );
        //データベースから結果を取得できず、結果は空です
        if (
          AdminAgencyPriceList.data.AdminAgencyPriceGroupSortList.items.length >
          0
        ) {
          agentGroupID =
            AdminAgencyPriceList.data.AdminAgencyPriceGroupSortList.items[0]
              .Admin_Agency_Price_Group;
          setAgencyPriceGroup({
            type: QUERY,
            Invs: AdminAgencyPriceList.data.AdminAgencyPriceGroupSortList.items,
          });
        }
        setloginDisplay(false);
      }
      setLoadingFlag(true);
      let IsWorkdays = await getIsWorkday()
      let selectedDateInitval = await selectedDateInit(IsWorkdays)
      setIsWorkday(IsWorkdays)
      setSelectedData(selectedDateInitval)
      setPageInitFlag(true)
      const InvData = await API.graphql(
        graphqlOperation(agencyGroupSortList, {
          Agency_Price_Group: agentGroupID,
          sortDirection: "DESC",
          limit: 1000,
        })
      );
      let nextToken = InvData.data.AgencyGroupSortList.nextToken;
      dataAll = InvData.data.AgencyGroupSortList.items;

      let allSupplier = [];
      for (let i = 0; i < dataAll.length; i++) {
        allSupplier.push(dataAll[i].Supplier);
      }
      let distinctSupplier = [];
      let hash = {};
      distinctSupplier = allSupplier.reduce((item, next) => {
        hash[next] ? "" : (hash[next] = true && item.push(next));
        return item;
      }, []);
      setSupplier({ type: QUERY, Invs: distinctSupplier });
      // setInv({ type: QUERY, Invs: dataAll });

      while (nextToken !== null) {
        let InvDataSec = await API.graphql(
          graphqlOperation(agencyGroupSortList, {
            Agency_Price_Group: agentGroupID,
            sortDirection: "DESC",
            limit: 1000,
            nextToken: nextToken,
          })
        );
        nextToken = InvDataSec.data.AgencyGroupSortList.nextToken;
        for (
          let i = 0;
          i < InvDataSec.data.AgencyGroupSortList.items.length;
          i++
        ) {
          dataAll.push(InvDataSec.data.AgencyGroupSortList.items[i]);
        }
      }
      dataAll = dataAll.filter(item => {
        return item.Product_Name_j !== '送料';
      })
      if (location.state && location.state.QuoteNumber !== undefined) {
        // flagPage = true
        const pageData = await API.graphql(
          graphqlOperation(listArAgencyOrderInfos, {
            QuoteNumber: location.state.QuoteNumber,
          })
        );
        // delete location.state.QuoteNumber;
        let srAgencyOrderdataAll = pageData.data.listArAgencyOrderInfos.items;

        for (let item in dataAll) {
          for (let i = 0; i < srAgencyOrderdataAll.length; i++) {
            if (dataAll[item]["Product_Code"] === srAgencyOrderdataAll[i].Product_Code) {
              // alert(srAgencyOrderdataAll[i].Product_Code)
              dataAll[item]["OrderQuantity"] = srAgencyOrderdataAll[i].OrderQuantity;
              checkedArr.push(dataAll[item]["id"]);

              orderselect_row.push(dataAll[item]["id"]);
              if (srAgencyOrderdataAll[i].OrderQuantity === null) {
                srAgencyOrderdataAll[i].OrderQuantity = 0;
              }
              // 画面の仮在庫数を戻る
              dataAll[item]["TempInventoryQuantity"] = srAgencyOrderdataAll[i].OrderQuantity;
            }
          }
        }
      } else {
        // flagPage = false;
      }
      // let allSupplier = [];
      for (let i = 0; i < dataAll.length; i++) {
        allSupplier.push(dataAll[i].Supplier);
      }
      // let distinctSupplier = [];
      // let hash = {};
      distinctSupplier = allSupplier.reduce((item, next) => {
        hash[next] ? "" : (hash[next] = true && item.push(next));
        return item;
      }, []);
      setSupplier({ type: QUERY, Invs: distinctSupplier });
      setInv({ type: QUERY, Invs: dataAll });
      setLoadingFlag(false);
      // let btnToggleSupplier = document.getElementById("btnToggleSupplier");
      // if (btnToggleSupplier !== null) {
      //   btnToggleSupplier.click();
      // }
      // let btnToggleProduct_Name_e = document.getElementById('btnToggleProduct_Name_e');
      // if (btnToggleProduct_Name_e !== null) {
      //   btnToggleProduct_Name_e.click();
      // }
    }
    listInvData();
  }, []);

  const customTotal = (from, to, size) => (
    <span
      style={{ marginLeft: "1em" }}
      className="react-bootstrap-table-pagination-total"
    >
      {size} の結果の {from} から {to} を表示しています
    </span>
  );
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${currSizePerPage === `${option.page}` ? "btn-warning" : "btn-light"
            }`}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
  const pageButtonRenderer = ({ page, onPageChange, currSizePerPage }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    return (
      <button
        className={`btn ${currSizePerPage === `${page}` ? "btn-warning" : "btn-light"
          }`}
        type="button"
        onClick={handleClick}
      >
        <li className="page-item">
          <a style={{ color: "black" }} href="#" >
            {page}
          </a>
        </li>
      </button>
    );
  };
  const CaptionElement = () => (
    <h3
      // style={{
      //   textAlign: "left",
      //   color: "black",
      //   marginBottom: "2rem",
      // }}
      style={{
        borderLeft: "4px solid #804F29",
        marginTop: "1rem",
        paddingLeft: "4px",
        marginBottom: "1rem",
      }}
    >
      {Title}
    </h3>
  );

  const classes = useStyles();
  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    showTotal: true,
    withFirstAndLast: true,
    firstPageText: "最初",
    prePageText: "戻る",
    nextPageText: "次へ",
    lastPageText: "最後",
    nextPageTitle: "最初のページ",
    prePageTitle: "プレページ",
    firstPageTitle: "次のページ",
    lastPageTitle: "最後のページ",
    paginationTotalRenderer: customTotal,
    sizePerPageRenderer: sizePerPageRenderer,
    pageButtonRenderer: pageButtonRenderer,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "20",
        value: 20,
      },
      {
        text: "100",
        value: 100,
      },
    ],
  };
  // //keep-alive効果を達成するために状態を維持する必要があります
  // const CustomToggleList = ({ columns, onColumnToggle, toggles }) => (
  //   <div
  //     className="btn-group btn-group-toggle btn-group-vertical"
  //     data-toggle="buttons"
  //   >
  //     {
  //       <div className="v1">
  //         {columns
  //           .map((column) => ({
  //             ...column,
  //             //toggle：リスト名はありますか？
  //             toggle: toggles[column.dataField], //sessionStorage.getItem("arrstatus") ? (JSON.parse(sessionStorage.getItem("arrstatus"))[column.dataField] === false ? false : true) : toggles[column.dataField]
  //           }))
  //           .map((column) => {
  //             return column.dataField !== "id" &&
  //               column.dataField !== "OrderQuantity" ? (
  //               <button
  //                 type="button"
  //                 key={`${column.dataField}`}
  //                 //真の場合はctiveになり、そうでない場合は赤になります
  //                 className={`btn btn-warning list-custom-class ${
  //                   column.toggle ? "btn-secondary" : "btn-warning"
  //                 }`}
  //                 data-toggle="button"
  //                 aria-pressed={column.toggle ? "false" : "true"}
  //                 //切替
  //                 onClick={() => {
  //                   let findIndex = hiddenFileds.indexOf(column.dataField);
  //                   if (findIndex > -1) {
  //                     hiddenFileds[findIndex] = "";
  //                   } else {
  //                     hiddenFileds.push(column.dataField);
  //                   }
  //                   localStorage.setItem(
  //                     "hiddenFields",
  //                     JSON.stringify(hiddenFileds)
  //                   );
  //                   onColumnToggle(column.dataField);
  //                   setColumns(columnsDefault());
  //                 }} //!column.toggle クリック後に反対の値を取る
  //                 id={`btnToggle${column.dataField}`}
  //               >
  //                 {column.text}
  //               </button>
  //             ) : (
  //               ""
  //             );
  //           })}{" "}
  //       </div>
  //     }
  //   </div>
  // );
  const defaultSorted = [
    {
      dataField: "id",
      order: "desc",
    },
  ];

  // const selectRowAdmin = {
  //   mode : 'checkbox',
  //   hideSelectColumn: true,
  // }
  const selectRow = {
    mode: "checkbox",
    clickToSelect: false,
    clickToEdit: true,
    hideSelectColumn: loginDisplay ? false : true,
    bgColor: "white",
    selected: orderselect_row,
    selectionHeaderRenderer: () => "カートに入れる",
    headerColumnStyle: {
      backgroundColor: "white",
      borderBottom: "3px solid #804F29",
    },
    selectColumnPosition: "right",
    onSelect: (row, isSelect) => {
      if (isSelect) {
        orderselect_row.push(row.id);
      } else {
        let find = orderselect_row.findIndex(function (item) {
          return item === row.id;
        });
        orderselect_row.splice(find, 1);
      }
    },
    onSelectAll: (isSelect) => {
      if (isSelect) {
        for (let i = 0; i < inventories.Invs.length; i++) {
          orderselect_row.push(inventories.Invs[i].id);
        }
      } else {
        orderselect_row = [];
      }
    },
  };

  const history = useHistory();
  function orderClick() {
    createArOrders();
  }

  const [msg1, setMsgId] = React.useState(Object);
  const handleSetMsgId = (Object) => {
    setMsgId(Object);
  };
  const [msgText, setMsgText] = React.useState(Object);
  const handleSetMsgText = (Object) => {
    setMsgText(Object);
  };
  const [msgbtnOK, setMsgBtnOK] = React.useState(Object);
  const handleSetMsgBtnOK = (Object) => {
    setMsgBtnOK(Object);
  };
  const [open2, setOpen2] = React.useState(false);
  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleCloseNG2 = () => {
    setOpen2(false);
  };
  const handleCloseOK2 = () => {
    setOpen2(false);
  };
  const [selectedDate, setSelectedData] = React.useState();
  // const [selectedDate, setSelectedData] = React.useState<MaterialUiPickersDate>(null);
  //重複するオブジェクトを削除する
  function unique(arr) {
    return arr.filter((item, index) => {
      return arr.indexOf(item) === index;
    });
  }

  const createOrderDetailsNumber = () => {
    let format = "";
    let nTime = new Date();
    format += nTime.getFullYear().toString().substring(2);
    format +=
      nTime.getMonth() + 1 < 10
        ? "0" + (nTime.getMonth() + 1)
        : nTime.getMonth() + 1;
    format += nTime.getDate() < 10 ? "0" + nTime.getDate() : nTime.getDate();
    format += nTime.getHours() < 10 ? "0" + nTime.getHours() : nTime.getHours();
    format +=
      nTime.getMinutes() < 10 ? "0" + nTime.getMinutes() : nTime.getMinutes();
    format +=
      nTime.getSeconds() < 10 ? "0" + nTime.getSeconds() : nTime.getSeconds();
    if (nTime.getMilliseconds() < 10) {
      format += "00" + nTime.getMilliseconds();
    } else if (nTime.getMilliseconds() < 100) {
      format += "0" + nTime.getMilliseconds();
    } else {
      format += nTime.getMilliseconds();
    }
    format += Math.floor(Math.random() * 88) + 10;
    let QuoteNumber = "E" + format;
    return QuoteNumber;
  }

  const createArOrders = async () => {
    let hopedateValue = moment(selectedDate).utcOffset(9).format('YYYY-MM-DD');
    // let hopedateValue = document.getElementById("hopedate").value;
    if (orderselect_row === "" || orderselect_row.length === 0) {
      handleSetMsgId("エラー");
      handleSetMsgText("製品を選択してください");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      return;
    }

    if (hopedateValue === null || hopedateValue.length === 0) {
      handleSetMsgId("エラー");
      handleSetMsgText("希望納期を入力してください");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      return;
    }
    if (orderselect_row === "" || orderselect_row.length === 0) {
      handleSetMsgId("エラー");
      handleSetMsgText("1つ以上の商品をお選びください");
      handleSetMsgBtnOK("はい");
      handleClickOpen2();
      return;
    }
    let id = uuidv4();
    let format = "";
    let nTime = new Date();
    format += nTime.getFullYear().toString().substring(2);
    format +=
      nTime.getMonth() + 1 < 10
        ? "0" + (nTime.getMonth() + 1)
        : nTime.getMonth() + 1;
    format += nTime.getDate() < 10 ? "0" + nTime.getDate() : nTime.getDate();
    format += nTime.getHours() < 10 ? "0" + nTime.getHours() : nTime.getHours();
    format +=
      nTime.getMinutes() < 10 ? "0" + nTime.getMinutes() : nTime.getMinutes();
    format +=
      nTime.getSeconds() < 10 ? "0" + nTime.getSeconds() : nTime.getSeconds();
    if (nTime.getMilliseconds() < 10) {
      format += "00" + nTime.getMilliseconds();
    } else if (nTime.getMilliseconds() < 100) {
      format += "0" + nTime.getMilliseconds();
    } else {
      format += nTime.getMilliseconds();
    }
    format += Math.floor(Math.random() * 88) + 10;
    let QuoteNumber = "P" + format;
    if (hopedateValue.length > 17) {
      hopedateValue = hopedateValue + "T00:00.000Z";
    } else if (hopedateValue.length > 0) {
      hopedateValue = hopedateValue + "T00:00:00.000Z";
    } else {
      hopedateValue = "";
    }

    if (location.state) {
      let hasNoneOrderQuantity = false;
      for (let i = 0; i < orderselect_row.length; i++) {
        // let OrderQuantity = document.getElementById(orderselect_row[i]).value;
        for (let j = 0; j < inventories.Invs.length; j++) {
          if (hasNoneOrderQuantity) {
            break;
          }
          if (inventories.Invs[j].id === orderselect_row[i]) {
            if (inventories.Invs[j].OrderQuantity === '' || inventories.Invs[j].OrderQuantity === null)
              hasNoneOrderQuantity = true;
            break;
          }
        }
      }
      if (hasNoneOrderQuantity) {
        handleSetMsgId("エラー");
        handleSetMsgText("発注数を入力してください");
        handleSetMsgBtnOK("はい");
        handleClickOpen2();
        return;
      }
      // 既存のデータを削除
      let oldQuoteNumber = location.state.QuoteNumber;
      let listArAgencyOrderInfo = await API.graphql(
        graphqlOperation(listArAgencyOrderInfos, {
          QuoteNumber: oldQuoteNumber,
        })
      );
      let order = [];
      let order2 = [];
      if (listArAgencyOrderInfo.data.listArAgencyOrderInfos !== null) {
        order2 = listArAgencyOrderInfo.data.listArAgencyOrderInfos["items"];
        for (let j = 0; j < order2.length; j++) {
          order.push(order2[j].Product_id);
        }
      }
      // // 0 del 1 insert 2 update
      // for (let i = 0; i < order.length - 1; i++) {
      //   for (let j = 0; j < order.length - 1 - i; j++) {
      //     if (order[j] > order[j + 1]) {
      //       let temp = order[j];
      //       order[j] = order[j + 1];
      //       order[j + 1] = temp;
      //     }
      //   }
      // }
      // for (let i = 0; i < orderselect_row.length - 1; i++) {
      //   for (let j = 0; j < orderselect_row.length - 1 - i; j++) {
      //     if (orderselect_row[j] > orderselect_row[j + 1]) {
      //       let temp = orderselect_row[j];
      //       orderselect_row[j] = orderselect_row[j + 1];
      //       orderselect_row[j + 1] = temp;
      //     }
      //   }
      // }
      orderselect_row = unique(orderselect_row);
      let count = 1;
      let Orderitem = "";
      let ProductName = "";
      let OrderOrderQuantity = "";
      for (let i = 0; i < orderselect_row.length; i++) {
        let findIndex = order.indexOf(orderselect_row[i]);
        if (findIndex > -1) {
          for (let j = 0; j < inventories.Invs.length; j++) {
            if (inventories.Invs[j].id === orderselect_row[i]) {
              let arInfo = inventories.Invs[j];
              let currentId = order2[findIndex].id;
              // if (arInfo.OrderQuantity !== null && arInfo.OrderQuantity !== 0) {
              //update
              Orderitem += arInfo.Product_Code + ";";
              if (count === 1) {
                ProductName = arInfo.Product_Name_j;
              }
              OrderOrderQuantity += arInfo.OrderQuantity + ";";
              await API.graphql(
                graphqlOperation(updateArAgencyOrderInfo, {
                  input: {
                    id: currentId,
                    Agency_id: agencyId,
                    QuoteNumber: oldQuoteNumber,
                    Product_Code: arInfo.Product_Code,
                    Product_Name_j: arInfo.Product_Name_j,
                    OrderQuantity: parseFloat(arInfo.OrderQuantity),
                    Wholes_Rate: arInfo.Wholes_Rate,
                    Wholes_Price: arInfo.Wholes_Price,
                    Price: arInfo.Price,
                    Campaign_price: arInfo.Catarog_Price,
                    Product_id: arInfo.id,
                    Quantity: arInfo.TempInventoryQuantity,
                    SalesGroup: arInfo.SalesGroup,
                    delflg: "",
                    DetailNo: count * 10,
                    Purchasing_Document_Type: arInfo.Purchasing_Document_Type,
                    Purchasing_Organization: arInfo.Purchasing_Organization,
                    purchasing_Group: arInfo.purchasing_Group,
                    Supplier: arInfo.Supplier2,
                    Tax_Code: arInfo.Tax_Code,
                    Storage_Location: arInfo.Storage_Location,
                    Storage_Location_U: arInfo.Storage_Location_U,
                    Basic_Quantity_Unit: arInfo.Basic_Quantity_Unit,
                    Sales_Document_Type: arInfo.Sales_Document_Type,
                    Salesperson: arInfo.Salesperson,
                    Segment: arInfo.Segment,
                    Agency_Price_Group: arInfo.Agency_Price_Group,
                    AgencyEmail: agencyEmail,
                    AgencyName: Agency_Name,
                    GroupName: Company_Name,
                    // DesiredDeliveryDate: arInfo.DesiredDeliveryDate ? arInfo.DesiredDeliveryDate : hopedateValue,
                    OrderStatus: "1",
                  },
                })
              );
              count++;
              // } else {
              // select order OrderQuantity has changed to 0
              //Cancel an order with a quantity of 0
              //delte
              // await API.graphql(
              //   graphqlOperation(updateArAgencyOrderInfo, {
              //     input: {
              //       id: currentId,
              //       Agency_id: agencyId,
              //       QuoteNumber: oldQuoteNumber,
              //       Product_Code: arInfo.Product_Code,
              //       Product_Name_j: arInfo.Product_Name_j,
              //       OrderQuantity: 0,
              //       Wholes_Rate: arInfo.Wholes_Rate,
              //       Wholes_Price: arInfo.Wholes_Price,
              //       Campaign_price: arInfo.Catarog_Price,
              //       Product_id: arInfo.id,
              //       Quantity: arInfo.TempInventoryQuantity,
              //       SalesGroup: arInfo.SalesGroup,
              //       delflg: "Z1",
              //     },
              //   })
              // );
              // // count++;
              // await API.graphql(
              //   graphqlOperation(deleteArAgencyOrderInfo, {
              //     input: {
              //       id: currentId,
              //       QuoteNumber: oldQuoteNumber,
              //     },
              //   })
              // );
              // }
              order.splice(findIndex, 1);
              order2.splice(findIndex, 1);
            }
            // continue;
          }
        } else {
          for (let j = 0; j < inventories.Invs.length; j++) {
            let arCreatInfo = inventories.Invs[j];
            if (orderselect_row[i] === arCreatInfo.id) {
              Orderitem += arCreatInfo.Product_Code + ";";
              if (count === 1) {
                ProductName = arCreatInfo.Product_Name_j;
              }
              OrderOrderQuantity += arCreatInfo.OrderQuantity + ";";
              console.log("inventories.Invs[j]  create", arCreatInfo)
              //create order
              await API.graphql(
                graphqlOperation(createArAgencyOrderInfo, {
                  input: {
                    id: uuidv4(),
                    Agency_id: agencyId,
                    QuoteNumber: oldQuoteNumber,
                    Product_Code: arCreatInfo.Product_Code,
                    Product_Name_j: arCreatInfo.Product_Name_j,
                    OrderQuantity: parseFloat(arCreatInfo.OrderQuantity),
                    Wholes_Rate: arCreatInfo.Wholes_Rate,
                    Wholes_Price: arCreatInfo.Wholes_Price,
                    Campaign_price: arCreatInfo.Catarog_Price,
                    Price: arCreatInfo.Price,
                    Product_id: arCreatInfo.id,
                    Quantity: arCreatInfo.TempInventoryQuantity,
                    SalesGroup: arCreatInfo.SalesGroup,
                    Purchasing_Document_Type: arCreatInfo.Purchasing_Document_Type,
                    Purchasing_Organization: arCreatInfo.Purchasing_Organization,
                    purchasing_Group: arCreatInfo.purchasing_Group,
                    Supplier: arCreatInfo.Supplier2,
                    Tax_Code: arCreatInfo.Tax_Code,
                    Storage_Location: arCreatInfo.Storage_Location,
                    Storage_Location_U: arCreatInfo.Storage_Location_U,
                    Basic_Quantity_Unit: arCreatInfo.Basic_Quantity_Unit,
                    Sales_Document_Type: arCreatInfo.Sales_Document_Type,
                    Salesperson: arCreatInfo.Salesperson,
                    delflg: "",
                    DetailNo: count * 10,
                    Segment: arCreatInfo.Segment,
                    Agency_Price_Group: arCreatInfo.Agency_Price_Group,
                    AgencyEmail: agencyEmail,
                    AgencyName: Agency_Name,
                    GroupName: Company_Name,
                    RegistrationDate: new Date(),
                    DesiredDeliveryDate: hopedateValue,
                    OrderStatus: "1",
                    OrderDetailsNumber: createOrderDetailsNumber(),
                    UserAuth:'1'

                  },
                })
              );
              count++;
            }
          }
        }
      }
      const orderByQuoteNumberIngo = await API.graphql(
        graphqlOperation(orderByQuoteNumber, { QuoteNumber: oldQuoteNumber })
      );

      if (orderByQuoteNumberIngo.data.orderByQuoteNumber !== null) {
        const order = orderByQuoteNumberIngo.data.orderByQuoteNumber["items"];

        if (order.length > 0) {
          await API.graphql(
            graphqlOperation(updateArOrder, {
              input: {
                id: order[0].id,
                QuoteNumber: oldQuoteNumber,
                DesiredDeliveryDate: hopedateValue /*希望納期日 */,
                Orderitem: Orderitem,
                ProductName: ProductName,
                OrderOrderQuantity: OrderOrderQuantity,
              },
            })
          );
        }
      }

      // //delete canceled orders
      // console.log("order2..", order2.length);
      // for (let i = 0; i < order2.length; i++) {
      //   await API.graphql(
      //     graphqlOperation(updateArAgencyOrderInfo, {
      //       input: {
      //         id: order2[i].id,
      //         Agency_id: agencyId,
      //         Product_Code: order2[i].Product_Code,
      //         QuoteNumber: oldQuoteNumber,
      //         OrderQuantity: 0,
      //         delflg: "Z1",
      //         DetailNo: count * 10,
      //       },
      //     })
      //   );
      //   count++;
      // }
      for (let i = 0; i < order2.length; i++) {
        await API.graphql(
          graphqlOperation(deleteArAgencyOrderInfo, {
            input: {
              id: order2[i].id,
              QuoteNumber: oldQuoteNumber,
            },
          })
        );
      }
      history.push({
        pathname: "/Order",
        state: { QuoteNumber: oldQuoteNumber },
      });
    } else {
      let hasNoneOrderQuantity = false;
      for (let i = 0; i < orderselect_row.length; i++) {
        // let OrderQuantity = document.getElementById(orderselect_row[i]).value;
        for (let j = 0; j < inventories.Invs.length; j++) {
          if (hasNoneOrderQuantity) {
            break;
          }
          if (inventories.Invs[j].id === orderselect_row[i]) {
            if (inventories.Invs[j].OrderQuantity === '' || inventories.Invs[j].OrderQuantity === null)
              hasNoneOrderQuantity = true;
            break;
          }
        }
      }
      if (hasNoneOrderQuantity) {
        handleSetMsgId("エラー");
        handleSetMsgText("発注数を入力してください");
        handleSetMsgBtnOK("はい");
        handleClickOpen2();
        return;
      }

      let Orderitem = "";
      let ProductName = "";
      let OrderOrderQuantity = "";
      let countK = 1;
      for (let i = 0; i < orderselect_row.length; i++) {
        // let OrderQuantity = document.getElementById(orderselect_row[i]).value;
        for (let j = 0; j < inventories.Invs.length; j++) {
          if (inventories.Invs[j].id === orderselect_row[i]) {
            Orderitem += inventories.Invs[j].Product_Code + ";";
            if (countK === 1) {
              ProductName = inventories.Invs[j].Product_Name_j;
            }
            OrderOrderQuantity += inventories.Invs[j].OrderQuantity + ";";
            console.log("inventories.Invs[j]", inventories.Invs[j])
            await API.graphql(
              graphqlOperation(createArAgencyOrderInfo, {
                input: {
                  id: uuidv4(),
                  Agency_id: agencyId,
                  QuoteNumber: QuoteNumber,
                  Product_Code: inventories.Invs[j].Product_Code,
                  Product_Name_j: inventories.Invs[j].Product_Name_j,
                  OrderQuantity: parseFloat(inventories.Invs[j].OrderQuantity),
                  Wholes_Rate: inventories.Invs[j].Wholes_Rate,
                  Wholes_Price: inventories.Invs[j].Wholes_Price,
                  Price: inventories.Invs[j].Price,
                  Campaign_price: inventories.Invs[j].Catarog_Price,
                  Product_id: inventories.Invs[j].id,
                  Quantity: inventories.Invs[j].TempInventoryQuantity,
                  SalesGroup: inventories.Invs[j].SalesGroup,
                  Purchasing_Document_Type: inventories.Invs[j].Purchasing_Document_Type,
                  Purchasing_Organization: inventories.Invs[j].Purchasing_Organization,
                  purchasing_Group: inventories.Invs[j].purchasing_Group,
                  Supplier: inventories.Invs[j].Supplier2,
                  Tax_Code: inventories.Invs[j].Tax_Code,
                  Storage_Location: inventories.Invs[j].Storage_Location,
                  Storage_Location_U: inventories.Invs[j].Storage_Location_U,
                  Basic_Quantity_Unit: inventories.Invs[j].Basic_Quantity_Unit,
                  Sales_Document_Type: inventories.Invs[j].Sales_Document_Type,
                  Salesperson: inventories.Invs[j].Salesperson,
                  delflg: "",
                  DetailNo: countK * 10,
                  Segment: inventories.Invs[j].Segment,
                  Agency_Price_Group: inventories.Invs[j].Agency_Price_Group,
                  AgencyEmail: agencyEmail,
                  AgencyName: Agency_Name,
                  GroupName: Company_Name,
                  RegistrationDate: new Date(),
                  DesiredDeliveryDate: hopedateValue,
                  OrderStatus: "1",
                  OrderDetailsNumber: createOrderDetailsNumber(),
                  UserAuth:'1'

                },
              })
            );
            countK++;
            break;
          }
        }
      }
      await API.graphql(
        graphqlOperation(createArOrder, {
          input: {
            id: id,
            OrderStatus: "1",
            QuoteNumber: QuoteNumber,
            DesiredDeliveryDate: hopedateValue,
            AgencyID: agencyId,
            AgencyEmail: agencyEmail,
            AgencyName: Agency_Name,
            GroupName: Company_Name,
            Orderitem: Orderitem,
            ProductName: ProductName,
            OrderOrderQuantity: OrderOrderQuantity,
            RegistrationDate: new Date()
          },
        })
      );
      history.push({ pathname: "/Order", state: { QuoteNumber: QuoteNumber } });
    }
  };
  const theme = useTheme();
  const [priceGroup, setPriceGroup] = React.useState("");
  const initialAgencyPrice = { Invs: [] };
  const reducerAgencyPrice = (state, action) => {
    let obj = [];
    switch (action.type) {
      case QUERY:
        for (let i = 0; i < action.Invs.length; i++) {
          obj.push(action.Invs[i].Admin_Agency_Price_Group);
        }
        setPriceGroup(obj[0]);
        return { ...state, Invs: obj };
      default:
        return state;
    }
  };
  let [selectAgencyPriceOptions, setAgencyPriceGroup] = useReducer(
    reducerAgencyPrice,
    initialAgencyPrice
  );

  async function listInvData2(agentGroupID) {
    setInv({ type: QUERY, Invs: [] });
    const InvData = await API.graphql(
      graphqlOperation(agencyGroupSortList, {
        Agency_Price_Group: agentGroupID,
        sortDirection: "DESC",
        limit: 10000,
      })
    );
    let nextToken = InvData.data.AgencyGroupSortList.nextToken;
    let dataAll = InvData.data.AgencyGroupSortList.items;
    while (nextToken !== null) {
      let InvDataSec = await API.graphql(
        graphqlOperation(agencyGroupSortList, {
          Agency_Price_Group: agentGroupID,
          sortDirection: "DESC",
          limit: 10000,
          nextToken: nextToken,
        })
      );
      nextToken = InvDataSec.data.AgencyGroupSortList.nextToken;
      for (
        let i = 0;
        i < InvDataSec.data.AgencyGroupSortList.items.length;
        i++
      ) {
        dataAll.push(InvDataSec.data.AgencyGroupSortList.items[i]);
      }
    }
    let allSupplier = [];
    for (let i = 0; i < dataAll.length; i++) {
      allSupplier.push(dataAll[i].Supplier);
    }
    let distinctSupplier = [];
    let hash = {};
    distinctSupplier = allSupplier.reduce((item, next) => {
      hash[next] ? "" : (hash[next] = true && item.push(next));
      return item;
    }, []);
    setSupplier({ type: QUERY, Invs: distinctSupplier });
    setInv({ type: QUERY, Invs: dataAll });
  }

  const handleChange = (event) => {
    setPriceGroup(event.target.value);
    listInvData2(event.target.value);
  };
  return (
    <Container
      style={{ backgroundColor: "", minWidth: "100%", minHeight: "74vh" }}
    >
      <div>
        <Meta title={pageTitle} />
      </div>
      <br />
      <Row>
        <Col>
          <ToolkitProvider
            keyField="id"
            data={inventories.Invs}
            columns={columns}
            search
            columnToggle
            headerWrapperClasses="foo"
            wrapperClasses="boo"
          >
            {(props) => (
              <div>
                {<CaptionElement />}
                <div>
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "bottom",
                      }}
                    >
                      <div style={{ width: "17rem" }}>
                        <SearchBar
                          className="form-control"
                          placeholder="品目コード"
                          style={{
                            fontSize: "14px",
                            height: "auto",
                            padding: "8px 16px",
                            lineHeight: "1.3333333",
                            borderRadius: "2px",
                          }}
                          {...props.searchProps}
                        />
                        <ClearSearchButton
                          className="btn btn-dark btn-md "
                          {...props.searchProps}
                        />
                      </div>
                      <div style={{ width: "22rem" }}>
                        <span style={{ color: "red", 'font-size': '12px' }}>※単価が「ー」となっている品目は御見積商品となり、<br></br>
                          該当商品が含まれている場合は、合計金額が表示されません。</span>
                      </div>
                    </Col>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        alignItems: "top",
                      }}
                    >
                      <div style={{ display: loginDisplay ? "none" : "flex" }}>
                        <FormControl hidden={adminAuthFlg} fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            価格グループ
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={priceGroup}
                            onChange={handleChange}
                            input={<OutlinedInput label="Name" />}
                            MenuProps={MenuProps}
                            autoWidth
                          >
                            {selectAgencyPriceOptions.Invs.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, priceGroup, theme)}
                              >
                                {name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "right",
                          alignItems: "center",
                          paddingTop: "2px",
                        }}
                      >
                        <InputGroup className="mb-3">
                          <div
                            style={{ display: pageInitFlag === true ? "flex" : "none" }}
                          >
                            <InputGroup.Text
                              className={classes.textFieldHope}
                              id="basic-from"
                            >
                              <span style={{ color: "red" }}>［必須］</span>
                              希望納期
                            </InputGroup.Text>
                            {/* <TextField
                                id="hopedate"
                                label=""
                                type="date"
                                minDate="2022-10-13"
                                className={classes.textFieldPink}
                                InputLabelProps={{
                                  shrink: true,
                                  minDate: "2022-10-14"
                                }}
                                defaultValue={DateFun()}
                              /> */}
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                              <DatePicker
                                className={classes.datePicker}
                                disablePast
                                disableToolbar
                                variant="inline"
                                inputVariant="standard"
                                format="yyyy/MM/dd"
                                id="hopedate"
                                label=""
                                value={selectedDate}
                                onChange={(date)=>IsWorkdayFun(date)}
                                shouldDisableDate={disableWeekends}
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                          <div style={{ display: loginDisplay ? "flex" : "none" }}>
                            <Button
                              type="button"
                              className=" btn btn-danger"
                              onClick={() => {
                                orderClick();
                              }}
                              style={{
                                isplay: loginDisplay ? "block" : "none",
                                marginLeft: "1em",
                                width: "120px",
                              }}
                            >
                              {/* <svg
                                  style={{ marginRight: "2px" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-plus-lg"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2Z"
                                  />
                                </svg> */}
                              注文登録へ
                            </Button>
                          </div>
                        </InputGroup>
                      </div>
                    </Col>
                  </Row>
                </div>
                <hr />
                <div>
                  {loaddingFlag && <LinearProgress />}
                  <BootstrapTable
                    striped={true}
                    hover={true}
                    bootstrap4
                    condensed
                    keyField="id"
                    data={inventories.Invs}
                    columns={columns}
                    {...props.baseProps}
                    pagination={paginationFactory(options)}
                    defaultSorted={defaultSorted}
                    selectRow={selectRow}
                    filter={filterFactory()}
                    defaultSortDirection="desc"
                    filterPosition="inline"
                    headerWrapperClasses="foo"
                    wrapperClasses="boo "
                    noDataIndication={"no results found"}
                    cellEdit={cellEditFactory({
                      mode: "click",
                      blurToSave: true,
                      afterSaveCell: (beforeValue, afterValue, row) => {
                        handleSetCount(row.id, afterValue)
                      }
                    })}
                  />{" "}
                </div>
                <div style={{ display: "none" }}>
                  <hr />
                  <span>表記させる項目をこのアイコンでお選び頂けます</span>
                  <br />
                  <br />
                  {/* <CustomToggleList {...props.columnToggleProps} /> */}
                  <br />
                  <hr />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
      <Dialog
        open={open2}
        onClose={handleCloseNG2}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{msg1}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msgText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseOK2} color="secondary" autoFocus>
            {msgbtnOK}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default InventorySearch;
