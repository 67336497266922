/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  UserList.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary UserList画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
// pages
import Meta from "../components/Meta";
import userAuth from "../components/userAuth";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  getArAgencyByAgencyid,
  listAllArAgency,
  customerByeMail
} from "../graphql/queries";

/*  GraphQL API Acess */
import API, { graphqlOperation } from "@aws-amplify/api";

/*  AWS標準設定 with Login Auth. */
import Amplify, { Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
Amplify.configure(awsconfig);
import Grid from "@material-ui/core/Grid";
import { Container, Row, Col,InputGroup } from "react-bootstrap";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from '@material-ui/core/CircularProgress';
const customTotal = (from, to, size) => (
  <span
    style={{ marginLeft: "1em" }}
    className="react-bootstrap-table-pagination-total"
  >
    {size} の結果の {from} から {to} を表示しています
  </span>
);
const pageButtonRenderer = ({ page, onPageChange }) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };
  return (
    <button className="btn-secondary btn" type="button" onClick={handleClick} key ={page}>
      <li className="page-item">
        <a style={{ color: "white" }} href="#">
          {page}
        </a>
      </li>
    </button>
  );
};

const sizePerPageRenderer = ({
  options,
  currSizePerPage,
  onSizePerPageChange,
}) => (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${
            currSizePerPage === `${option.page}` ? "btn-warning" : "btn-light"
            }`}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
const options = {
  paginationSize: 20,
  pageStartIndex: 1,
  showTotal: true,
  withFirstAndLast: true,
  firstPageText: "最初",
  prePageText: "戻る",
  nextPageText: "次へ",
  lastPageText: "最後",
  nextPageTitle: "最初のページ",
  prePageTitle: "プレページ",
  firstPageTitle: "次のページ",
  lastPageTitle: "最後のページ",
  paginationTotalRenderer: customTotal,
  sizePerPageRenderer: sizePerPageRenderer,
  pageButtonRenderer: pageButtonRenderer,
  disablePageTitle: true,
  sizePerPageList: [
    {
      text: "20",
      value: 20,
    },
    {
      text: "50",
      value: 50,
    },
    {
      text: "100",
      value: 100,
    },
  ],
};
// dialog 項目 END
let defaultSorted = [
  {
    dataField: "Agency_id",
    order: "asc",
  }
];

let userAuths = "";
export const UserList = () => {
  const [agencyList, setAgencyList] = useState([]);
  const useStyles = makeStyles((theme) => ({
    textlabel: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      background: "rgb(91,56,9)",
      border: "1px solid #707070",
      color: "white",
      fontWeight: "bold",
      fontSize: '14px',
      alignItems: 'center',
      justifyContent: 'center',
      width:"20%"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      background: "transparent",
      border: "1px solid #707070",
      borderRadius: "6px",
      color: "black",
      width:"80%"
    },
  }));
  const classes = useStyles()
  const [searchValue, setSearchValue] = useState({
    Agency_id: "",
    Agency_Name: ""
})
const [AgencyIdList, setAgencyIdList] = useState([])
const searchAgencyIdChange = (e, value) => {
  setSearchValue({ ...searchValue, Agency_id: value ? value.split(":")[0] : "" })
}
const searchAgencyNameChange = (e, value) => {
  setSearchValue({ ...searchValue, Agency_Name: value ? value.split(":")[1] : "" })
}
const [searchProgress, setSearchProgress] = useState(false)
    // 検索ボタンが押されました
    const sortByAgencyId = (a, b)=> {
      if (!a.Agency_id) {
        return 1;
      } else if (!b.Agency_id) {
        return 1;
      } else {
        return a.Agency_id.localeCompare(b.Agency_id)
      }
    }
    const sortByAgencyEmail = (a, b)=> {
      if (!a.Agency_Email) {
        return 1;
      } else if (!b.Agency_Email) {
        return 1;
      } else {
        return a.Agency_Email.localeCompare(b.Agency_Email)
      }
    }
const multiSort = (arr, ...sortFuncs)=> {
      return arr.sort((a, b) => {
        for (const func of sortFuncs) {
          const result = func(a, b);
          if (result !== 0) {
            return result;
          }
        }
        return 0;
      });
}
const searchBtn = async () => {
  setSearchProgress(true)
    try {
      const user = await Auth.currentUserInfo();
      const agencyPriceGroup = await API.graphql(
        graphqlOperation(customerByeMail, {
          Agency_Email: user.attributes.email,
        })
      );
      const agentinfo = agencyPriceGroup.data.customerByeMail["items"];
      userAuths = await userAuth();
      let arr = []
      let dataSource = []
      if (userAuths === "1") {
        if (agentinfo.length > 0) {
          // 代理店の全員
          let arAgencyList = await API.graphql(
            graphqlOperation(getArAgencyByAgencyid, {
              Agency_id: agentinfo[0].Agency_id
            })
          );
          dataSource = arAgencyList.data.getArAgencyByAgencyid["items"]
        }
      }else{
      let arAgencyList = await API.graphql(
        graphqlOperation(listAllArAgency)
      );
      dataSource = arAgencyList.data.listAllArAgency["items"]
    }
      dataSource.forEach(item => {
        let flag = true
        if (Object.keys(searchValue).length !== 0) {
            for (let name in searchValue) {
                if (item[name]) {
                    if (!(item[name]).includes(searchValue[name])) {
                        flag = false;
                        break;
                    }
                } else {
                    flag = false
                    break;
                }
            }
        }
        if (flag === true) {
            arr.push(item)
        }
    })
      arr = multiSort(arr,sortByAgencyId,sortByAgencyEmail);
      setAgencyList(arr)
      } catch (error) {
          if (error.message === 'No current user') {
              //ユーザープールに障害が発生した場合
              console.log('User pool is invalid.Please reauthenticate.');
              //ログイン画面に戻る
              signOut();
          } else {
              //他のエラーを処理する
              console.log('An error occurred:', error);
          }
      }
      setSearchProgress(false)
  }
  const signOut = async () => {
    await Auth.signOut();
    try {
        await Auth.signOut();
    } catch (error) {
        console.log("error signing out: ", error);
    }
    document.location.reload();
};
  useEffect(() => {
    // 1) Auth.currentSession() から、Cogniteのユーザグループ情報を取得
    async function getLoginGroup() {
    try {
      setSearchProgress(true)
      // get the Login User Group from the top of the cognito groups list
      const user = await Auth.currentUserInfo();
      const agencyPriceGroup = await API.graphql(
        graphqlOperation(customerByeMail, {
          Agency_Email: user.attributes.email,
        })
      );
      const agentinfo = agencyPriceGroup.data.customerByeMail["items"];
      userAuths = await userAuth();
      let arAgencyLists = "";
      if (userAuths === "1") {
        if (agentinfo.length > 0) {
          // 代理店の全員
          let arAgencyList = await API.graphql(
            graphqlOperation(getArAgencyByAgencyid, {
              Agency_id: agentinfo[0].Agency_id
            })
          );
          arAgencyLists = arAgencyList.data.getArAgencyByAgencyid["items"]
        }
      } else {
        // 全員
        let arAgencyList = await API.graphql(
          graphqlOperation(listAllArAgency)
        );
        arAgencyLists = arAgencyList.data.listAllArAgency["items"]
      }
      let agencyIdList = []
      for (let i = 0; i < arAgencyLists.length; i++) {
        if (arAgencyLists[i].Agency_id) {
          agencyIdList.push(arAgencyLists[i].Agency_id + ":" + arAgencyLists[i].Agency_Name)
        }
    }
    arAgencyLists = multiSort(arAgencyLists,sortByAgencyId,sortByAgencyEmail);
      setAgencyIdList(Array.from(new Set(agencyIdList)))
      setAgencyList(arAgencyLists);
    } catch (error) {
      if (error.message === 'No current user') {
          //ユーザープールに障害が発生した場合
          console.log('User pool is invalid.Please reauthenticate.');
          //ログイン画面に戻る
          signOut();
      } else {
          //他のエラーを処理する
          console.log('An error occurred:', error);
      }
  }
      setSearchProgress(false)
    }
    getLoginGroup();
  }, []);

  const headerStyle = {
    backgroundColor: "white",
    borderBottom: "3px solid #B45210",
  };
  let columns = [
    { dataField: "id", text: "ID", hidden: true },
    { dataField: "Agency_id", text: "受注先コード", headerStyle , sort: true},
    { dataField: "Agency_Name", text: "受注先名", headerStyle },
    { dataField: "Company_Name", text: "拠点名", headerStyle },
    { dataField: "User_Name", text: "氏名", headerStyle },
    { dataField: "Tel", text: "電話番号", headerStyle },
    { dataField: "Agency_Email", text: "メールアドレス", headerStyle },
    { dataField: "Oper", text: "ユーザー管理権限", headerStyle },
    {
      dataField: "edit",
      text: "操作",
      headerStyle,
      formatter: () => {
        return (
          <span>
            <div>
              {/* <Button
                variant="contained"
                color="primary"
                onClick={() => {
                //  editClick(row.id);
                }}
              >
                編集
              </Button>
              <Button variant="contained" color="secondary" onClick={() => { handleDeleteClick(row.id); }}>
                削除
              </Button> */}
            </div>
          </span>
        );
      },
    },
  ];

  // dialog 項目 END
  const pageTitle = "ユーザー一覧｜ARBOS AR BROWN Order System";

  return (
    <Container
      style={{ backgroundColor: "", minWidth: "82vw", minHeight: "74vh" }}
    >
      <div style={{position: 'fixed',top: '50%', left: '50%', display: searchProgress === true ? "block" : "none",zIndex:10}}>
            <CircularProgress style={{margin:"auto"}}/>
        </div>
      <div>
        <Meta title={pageTitle} />
      </div>
      <div style={{ minWidth: "1000px", }}>
      <Grid>
        <Grid>
          <Row
            style={{
              borderRadius: "0.25em",
              color: "purple",
              //textAlign: 'center',
              padding: "0.5em",
            }}
          >
            <div style={{ display: 'flex', marginBottom: "10px",width:"100%"}}>
            <Col>
              <h3 style={{
              textAlign: "left",
              color: "black",
              height: 30,
              width: '20%',
              paddingLeft: "4px",
              whiteSpace:"nowrap"}}>ユーザー一覧</h3>
            </Col>
                <div style={{ width: '80%', height: 140}}>
                    <div style={{ width: '90%',minWidth:"90%",height: 140, border: '2px solid rgb(191,191,191)', display: 'flex', flexWrap: "wrap",justifyContent:"center", padding: "30px 0px 0px 0px" }}>
                        <div style={{ width: "50%", height: '25px' }}>
                            <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap" }}>
                                <InputGroup.Text
                                    className={classes.textlabel}
                                    id="basic-name"
                                    style={{ borderRadius: "0px",minWidth:"100px"}}
                                >受注先コード
                                </InputGroup.Text>
                                <Autocomplete
                                    className={classes.textField}
                                    sx={{
                                      display: 'inline-block',
                                      '& input': {
                                          height: 0,
                                      },
                                  }}
                                    options={AgencyIdList}
                                    value={searchValue.Agency_id}
                                    maxLength ="20"
                                    onChange={(e, value) => { searchAgencyIdChange(e, value) }}
                                    name="AgencyId"
                                    id="AgencyId"
                                    isOptionEqualToValue={(option, value) => option.split(":")[0] === value || option === "" || value === ""}
                                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                />
                            </InputGroup>
                        </div>
                        <div style={{ width: "50%", height: '25px'}}>
                            <InputGroup className="mb-3" style={{ width: "100%", display: 'flex', flexWrap: "nowrap", }}>
                                <InputGroup.Text
                                    className={classes.textlabel}
                                    id="basic-name"
                                    style={{ borderRadius: "0px",minWidth:"100px"}}
                                >受注先名
                                </InputGroup.Text>
                                <Autocomplete
                                    className={classes.textField}
                                    sx={{
                                      display: 'inline-block',
                                      '& input': {
                                          height: 0,
                                      },
                                  }}
                                    options={AgencyIdList}
                                    id="AgencyName"
                                    value={searchValue.Agency_Name}
                                    name="AgencyName"
                                    maxLength ="20"
                                    isOptionEqualToValue={(option, value) => option.split(":")[1] === value || option === "" || value === ""}
                                    onChange={(e, value) => { searchAgencyNameChange(e, value) }}
                                    renderInput={(params) => <TextField {...params} label="" variant="outlined" />}
                                />
                            </InputGroup>
                        </div>
                        <Button className="btn" onClick={()=>searchBtn()} style={{ height: '40px', width: '120px', fontSize: '18px', color: 'black', border: '3px solid black', background: "white", margin: 'auto' }}>検索</Button>
                    </div>
              </div>
            </div>
          </Row>
        </Grid>
        <Grid>
          <BootstrapTable
            striped={true}
            hover={true}
            wrapperClasses="boo bg-white"
            bootstrap4
            keyField="id"
            data={agencyList}
            columns={columns}
            noDataIndication={"no results found"}
            defaultSorted={defaultSorted}
            pagination={paginationFactory(options)}
          />
        </Grid>
      </Grid>
      </div>
    </Container>
  );
};
export default UserList;
