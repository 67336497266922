import React,{useEffect, useState} from 'react'
/*コンテナ（画面レイアウト）デザイン*/
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Container,InputGroup, FormControl } from "react-bootstrap";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import API, { graphqlOperation } from "@aws-amplify/api";
import {listEndUserSpecialPricings} from "../graphql/queries";
import {updateEndUserSpecialPricing} from "../graphql/mutations";
import {message } from 'antd';
const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "20ch",
  },
  textFieldNm: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "60ch",
  },
  textKField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "60ch",
  },
}));
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      {size} の結果の {from} から {to} を表示しています
    </span>
  );
  const pageButtonRenderer = ({ page, onPageChange, currSizePerPage }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    return (
      <button
      key={page}
        className={`btn ${currSizePerPage === `${page}` ? "btn-warning" : "btn-light"
          }`}
        type="button"
        onClick={handleClick}
      >
        <li className="page-item">
          <a style={{ color: "black" }} href="#">
            {page}
          </a>
        </li>
      </button>
    );
  };
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div className="btn-group" role="group">
      {options.map((option) => (
        <button
          key={option.text}
          type="button"
          onClick={() => onSizePerPageChange(option.page)}
          className={`btn ${currSizePerPage === `${option.page}` ? "btn-warning" : "btn-light"
            }`}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
  const options = {
    paginationSize: 20,
    pageStartIndex: 1,
    showTotal: true,
    withFirstAndLast: true, // Hide the going to First and Last page button
    firstPageText: "最初",
    prePageText: "戻る",
    nextPageText: "次へ",
    lastPageText: "最後",
    nextPageTitle: "最初のページ",
    prePageTitle: "プレページ",
    firstPageTitle: "次のページ",
    lastPageTitle: "最後のページ",
    paginationTotalRenderer: customTotal,
    sizePerPageRenderer: sizePerPageRenderer,
    pageButtonRenderer: pageButtonRenderer,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "20",
        value: 20,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      }
    ],
  };
  let dataAll = "";
const EndUserSpecialPricing = () => {
  const [products,setTableData] = useState([])
  const classes = useStyles();
    const defaultSorted = [
        {
          dataField: "EndUserCode",
          order: "asc",
        },
      ];
      const [upShow,setUpShow] = useState({show:false,EndUserCode:"",Price:"",id:""});
      const upShowFun = function(row){
        setUpShow({show:true,EndUserCode:row.EndUserCode,OrderCode:row.OrderCode,ItemCode:row.ItemCode,Price:row.Price,id:row.id})
      }
      const upHiddenFun = function(){
        setUpShow({...upShow,show:false})
      }
      const headerStyle = {
        backgroundColor: "white",
        borderBottom: "3px solid #804F29",
      };
      let columns = [
        { dataField: "OrderCode", text: "受注先コード", sort: true ,headerStyle,style:{width:"20%",minWidth:"200px"}},
        { dataField: "EndUserCode", text: "エンドユーザー", sort: true ,headerStyle,style:{width:"20%",minWidth:"200px"}},
        { dataField: "ItemCode", text: "品目コード", sort: true ,headerStyle,style:{width:"20%",minWidth:"200px"}},
        { dataField: "Price", text: "価格", sort: true ,headerStyle,style:{width:"20%",minWidth:"200px"},
        formatter:(cellContent)=>{
          return (<span>{numtomoney(cellContent)}</span>)
        }
      },
        {
          dataField: "opreate",
          text: "操作",
          style: { margin: "0px", minWidth:"100px"},
          headerStyle,
          formatter: (cellContent,row) => {
            if (row) {
              return (
                <p onClick={()=>upShowFun(row)} style={{cursor:"pointer",color:"#3f51b5",height:10}} >
                  <span className="cellTxt" style={{height:10}}>編集</span></p>
              )
            }
        }
        }
      ];
      async function EndUserSpecialPricingInit(){
        try{
        const listArEndUserSpecialPricings = await API.graphql(graphqlOperation(listEndUserSpecialPricings,{limit: 1000}));
        if (listArEndUserSpecialPricings.data.listEndUserSpecialPricings !== null) {
          let nextToken = listArEndUserSpecialPricings .data.listEndUserSpecialPricings.nextToken;
          dataAll = listArEndUserSpecialPricings.data.listEndUserSpecialPricings.items;
          while (nextToken !== null) {
            let InvDataSec = await API.graphql(graphqlOperation(listEndUserSpecialPricings, { limit: 1000,nextToken:nextToken }));
            nextToken = InvDataSec.data.listEndUserSpecialPricings.nextToken;
            for (let i = 0; i < InvDataSec.data.listEndUserSpecialPricings.items.length; i++) {
              dataAll.push(InvDataSec.data.listEndUserSpecialPricings.items[i]);
            }
          }
        }
        setTableData(dataAll);
      }catch(err){
        console.log(err)
              }
    }
      useEffect(()=>{
        EndUserSpecialPricingInit()
      },[])
      //通常の形式を追加すると、数字のみを入力できます
      const changeRate = (e)=>{
        setUpShow({...upShow,Price:e.target.value.replace(/[^0-9]/g, '')})
      }
      function moneytonum(val) {
        let num = val ? val.trim() : "";
        let ss = num.toString();
        if (ss.length === 0) {
          return "";
        }
        return ss.replace(/,/g, "");
      }
      function numtomoney(number, toFixed) {
        let str = [];
        if (!number) return "";
        if (typeof number === "number") number = String(number);
        let tempNumber = number.split(".");
        number = tempNumber[0];
        if (number.length <= 3) {
          if (tempNumber[1]) {
            number += "." + tempNumber[1].slice(0, toFixed);
          }
          return number;
        }
        number
          .split("")
          .reverse()
          .forEach((item, index) => {
            if (index !== 0 && index % 3 === 0) {
              str.push(",", item);
            } else {
              str.push(item);
            }
          });
        number = str.reverse().join("");
        if (tempNumber[1]) {
          number += "." + tempNumber[1].slice(0, toFixed);
        }
        return number;
      }
      const upRateFun = function(){
        //NULL 不可
        if(upShow.Price === ""){
          message.error(`価格の空にすることはできません`,5,);
          return;
        }
          upRate();
          setUpShow({...upShow,show:false})
          message.success(`正常に変更されました`,5,);
      }
      async function upRate(){
        try{
        await API.graphql(graphqlOperation(updateEndUserSpecialPricing, {
          input:{
            id:upShow.id,
            EndUserCode:upShow.EndUserCode,
            OrderCode:upShow.OrderCode,
            ItemCode:upShow.ItemCode,
            Price	:upShow.Price

          },
        }))
        EndUserSpecialPricingInit()
      }catch(err){
        console.log(err)
              }
      }
   return(
    <Container
    style={{ backgroundColor: "", minWidth: "82vw", minHeight: "74vh" }}
  >
    <h4
        style={{
          textAlign: "left",
          color: "black",
          borderLeft: "4px solid #804F29",
          paddingLeft: "4px",
          minWidth:"320px"
        }}
      >
        エンドユーザー特価設定画面
      </h4>
      <ToolkitProvider
        keyField="id"
        data={products}
        columns={columns}
      >
        {(props) => (
          <div>
            <BootstrapTable
              bootstrap4
              striped={true}
              hover={true}
              headerStyle
              keyField="id"
              condensed
              columns={columns}
              data={products}
              {...props.baseProps}
              pagination={paginationFactory(options)}
              noDataIndication={"no results found"}
              defaultSorted={defaultSorted}
            />{" "}
          </div>
        )}
      </ToolkitProvider>
      <Dialog
    open={upShow.show}
    fullWidth={true}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    style={{zIndex:500}}
  >
  <DialogContent>
            <p style={{fontSize:"20px"}}>エンドユーザー編集</p>
            <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name"><span style={{color:"red"}}></span>受注先コード</InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              defaultValue={upShow.OrderCode}
              aria-describedby="basic-name"
              className={classes.textFieldNm}
              maxLength="40"
            />
          </InputGroup>
           <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name">エンドユーザー</InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              readOnly
              defaultValue={upShow.EndUserCode}
              aria-describedby="basic-name"
              className={classes.textFieldNm}
              maxLength="40"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name"><span style={{color:"red"}}></span>品目コード</InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              readOnly
              defaultValue={upShow.ItemCode}
              aria-describedby="basic-name"
              className={classes.textFieldNm}
              maxLength="40"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text className={classes.textField} id="basic-name"><span style={{color:"red"}}>*</span>価格</InputGroup.Text>
            <FormControl
              placeholder=""
              aria-label=""
              //フォーマットを追加
              value={numtomoney(moneytonum(upShow.Price))}
              aria-describedby="basic-name"
              className={classes.textFieldNm}
              onChange={(e)=>changeRate(e)}
              maxLength="40"
            />
          </InputGroup>
        <div style={{width:"36%",display:"flex",float:"right",paddingTop:"3%"}}>
        <Button variant="contained" color="primary" style={{
       minWidth:"104px",
       width:"104px",
       fontSize: "14px",
       padding: "5px",
       height: "36.5px",
       color:"white",
      }} onClick = {upRateFun}>OK</Button>
      <Button variant="contained" color="primary" style={{
       minWidth:"104px",
       width:"104px",
       fontSize: "14px",
       padding: "5px",
       height: "36.5px",
       marginLeft:"7px",
       color:"white",
      }} onClick = {upHiddenFun}>キャンセル</Button>
      </div>
    </DialogContent>
  </Dialog>
       </Container>
)
}
export default EndUserSpecialPricing


