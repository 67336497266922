import API, { graphqlOperation } from "@aws-amplify/api";
import { listArItemMasters,queryArPriceInfosByQueryIDIndex,queryArItemMastersBySAPCODEIndex } from "../../../graphql/queries";
import userAuth from "../../../components/userAuth";
import { createArItemMaster, updateArItemMaster,updateArPriceInfo } from "../../../graphql/mutations";
import { dialogText1, dialogText2, ArItemMasterConst, DilogDetillDisabled } from "./ArItemMasterConst";
import { Auth } from "aws-amplify";
//   tableList
const headerStyle = {
    backgroundColor: "white",
    borderBottom: "3px solid #804F29",
    fontSize: "70%",
    whiteSpace: "nowrap"
};
function Columns(displayItemSetting) {
    return (
        [
            { dataField: "id", text: "ID", hidden: true, sort: true, csvExport: false },
            { dataField: "SAPCODE", text: "品目", hidden: displayItemSetting.sAPCODE === true ? false : true, sort: true, style: { minWidth: "200px"}, headerStyle },
            { dataField: "GMID", text: "Material Code", sort: true, hidden: displayItemSetting.GMID === true ? false : true, headerStyle },
            { dataField: "ItemText", text: "品目名", hidden: displayItemSetting.ItemText === true ? false : true, sort: true, style: { minWidth: "518px"}, headerStyle },
            { dataField: "ARBSuppAgencyCode", text: "ARB仕入元代理店コード", hidden: displayItemSetting.ARBSuppAgencyCode === true ? false : true, sort: true, headerStyle },
            { dataField: "SuppCode", text: "仕入先コード", sort: true, hidden: displayItemSetting.SuppCode === true ? false : true, headerStyle },
            { dataField: "SuppName", text: "仕入先名", sort: true, hidden: displayItemSetting.suppName === true ? false : true, style: { minWidth: "368px"}, headerStyle},
            { dataField: "ItemGrp", text: "品目グループ*", sort: true, hidden: displayItemSetting.ItemGrp === true ? false : true, headerStyle },
            { dataField: "ItemGrpName", text: "品目グループ名*", hidden: displayItemSetting.ItemGrpName === true ? false : true, sort: true, style: { minWidth: "200px"}, headerStyle },
            { dataField: "InventoryTier", text: "在庫区分", hidden: displayItemSetting.InventoryTier === true ? false : true, sort: true, style: { minWidth: "140px"}, headerStyle },
            { dataField: "OrderAdjustment", text: "受注調整品", hidden: displayItemSetting.OrderAdjustment === true ? false : true, sort: true,style: { minWidth: "140px"}, headerStyle },
            { dataField: "Capacity", text: "容量*", sort: true, hidden: displayItemSetting.Capacity === true ? false : true, headerStyle },
            { dataField: "CapacityUnit", text: "容量単位*", hidden: displayItemSetting.CapacityUnit === true ? false : true, sort: true, headerStyle },
            { dataField: "BaseNumUnit", text: "基本数量単位", hidden: displayItemSetting.BaseNumUnit === true ? false : true, sort: true, headerStyle },
            { dataField: "OrderUnitpriceUnit", text: "受注単価単位", hidden: displayItemSetting.OrderUnitpriceUnit === true ? false : true, sort: true, headerStyle },
            { dataField: "UnitPerOrder", text: "発注単価単位", hidden: displayItemSetting.UnitPerOrder === true ? false : true, sort: true, headerStyle },
            { dataField: "StorageLoc", text: "保管場所", hidden: displayItemSetting.StorageLoc === true ? false : true, sort: true, headerStyle },
            { dataField: "ChilledGoods", text: "冷蔵品", hidden: displayItemSetting.ChilledGoods === true ? false : true, sort: true,style: { minWidth: "140px"}, headerStyle },
            { dataField: "USproduct", text: "米国品", hidden: displayItemSetting.USproduct === true ? false : true, sort: true, headerStyle },
            { dataField: "Expiration", text: "有効期限", hidden: displayItemSetting.Expiration === true ? false : true, sort: true, headerStyle },
            { dataField: "CustomerMOQ", text: "客先MOQ", hidden: displayItemSetting.CustomerMOQ === true ? false : true, sort: true, headerStyle },
            { dataField: "CustomerLTguidance", text: "客先LT案内", hidden: displayItemSetting.CustomerLTguidance === true ? false : true, sort: true, headerStyle },
            { dataField: "PriceChangeFactor", text: "価格変換係数(kg→数量)", hidden: displayItemSetting.PriceChangeFactor === true ? false : true, sort: true, headerStyle },
            { dataField: "StandardLeadTime", text: "標準リードタイム", hidden: displayItemSetting.StandardLeadTime === true ? false : true, sort: true,style: { minWidth: "140px"},headerStyle },
            { dataField: "LatestLT", text: "最新LT", sort: true, hidden: displayItemSetting.LatestLT === true ? false : true,style: { minWidth: "140px"}, headerStyle },
            { dataField: "QuotationReplyLT", text: "見積回答LT", hidden: displayItemSetting.QuotationReplyLT === true ? false : true, sort: true, headerStyle },
            { dataField: "StipulateRestdays", text: "規定残日数", hidden: displayItemSetting.StipulateRestdays === true ? false : true, sort: true, headerStyle },
            { dataField: "FreeHoldingdays", text: "フリー保有日数", hidden: displayItemSetting.FreeHoldingdays === true ? false : true, sort: true, headerStyle },
            { dataField: "HScode", text: "HSコード", sort: true, hidden: displayItemSetting.HScode === true ? false : true, headerStyle },
            { dataField: "ProductCountry", text: "生産国", hidden: displayItemSetting.ProductCountry === true ? false : true, sort: true, headerStyle },
            { dataField: "MFGPlant", text: "製造工場", hidden: displayItemSetting.MFGPlant === true ? false : true, sort: true, headerStyle },
            { dataField: "Remark", text: "Remark", hidden: displayItemSetting.Remark === true ? false : true, sort: true,style: { minWidth: "353px"}, headerStyle },
            { dataField: "StorageTemp", text: "保管温度", hidden: displayItemSetting.StorageTemp === true ? false : true, sort: true,style: { minWidth: "100px"}, headerStyle },
            { dataField: "ProductSizeWDH", text: "製品サイズ(縦×横×高さ)", hidden: displayItemSetting.ProductSizeWDH === true ? false : true, sort: true,style: { minWidth: "1175px"}, headerStyle },
            { dataField: "OuterBoxSizeWDH", text: "外装カートンサイズ(縦×横×高さ)", hidden: displayItemSetting.OuterBoxSizeWDH === true ? false : true, sort: true,style: { minWidth: "680px"}, headerStyle },
            { dataField: "DelFlag", text: "削除フラグ", hidden: displayItemSetting.DelFlag === true ? false : true, sort: true, headerStyle },
        ]
    )
}
function checkedMasterlist() {
    const checkedMasterlist = {
        sAPCODE: document.getElementById("sAPCODE").checked,
        GMID: document.getElementById("GMID").checked,
        ItemText: document.getElementById("ItemText").checked,
        ARBSuppAgencyCode: document.getElementById("ARBSuppAgencyCode").checked,
        SuppCode: document.getElementById("SuppCode").checked,
        suppName: document.getElementById("suppName").checked,
        ItemGrp: document.getElementById("ItemGrp").checked,
        ItemGrpName: document.getElementById("ItemGrpName").checked,
        InventoryTier: document.getElementById("InventoryTier").checked,
        OrderAdjustment: document.getElementById("OrderAdjustment").checked,
        Capacity: document.getElementById("Capacity").checked,
        CapacityUnit: document.getElementById("CapacityUnit").checked,
        BaseNumUnit: document.getElementById("BaseNumUnit").checked,
        OrderUnitpriceUnit: document.getElementById("OrderUnitpriceUnit").checked,
        UnitPerOrder: document.getElementById("UnitPerOrder").checked,
        StorageLoc: document.getElementById("StorageLoc").checked,
        ChilledGoods: document.getElementById("ChilledGoods").checked,
        USproduct: document.getElementById("USproduct").checked,
        Expiration: document.getElementById("Expiration").checked,
        CustomerMOQ: document.getElementById("CustomerMOQ").checked,
        CustomerLTguidance: document.getElementById("CustomerLTguidance").checked,
        PriceChangeFactor: document.getElementById("PriceChangeFactor").checked,
        StandardLeadTime: document.getElementById("StandardLeadTime").checked,
        LatestLT: document.getElementById("LatestLT").checked,
        QuotationReplyLT: document.getElementById("QuotationReplyLT").checked,
        StipulateRestdays: document.getElementById("StipulateRestdays").checked,
        FreeHoldingdays: document.getElementById("FreeHoldingdays").checked,
        HScode: document.getElementById("HScode").checked,
        ProductCountry: document.getElementById("ProductCountry").checked,
        MFGPlant: document.getElementById("MFGPlant").checked,
        Remark: document.getElementById("Remark").checked,
        StorageTemp: document.getElementById("StorageTemp").checked,
        ProductSizeWDH: document.getElementById("ProductSizeWDH").checked,
        OuterBoxSizeWDH: document.getElementById("OuterBoxSizeWDH").checked,
        DelFlag: document.getElementById("DelFlag").checked,
    }
    sessionStorage.setItem("displayItemSettingMasterObj", JSON.stringify(checkedMasterlist))
    return checkedMasterlist
}
async function searchData(searchValue) {
    let arr = [];
    let obj = {};
    let dataAll = [];
    const listArOrder = await API.graphql(graphqlOperation(listArItemMasters, { limit: 10000 }));
    if (listArOrder.data.listArItemMasters !== null) {
        let nextToken = listArOrder.data.listArItemMasters.nextToken;
        dataAll = listArOrder.data.listArItemMasters.items;
        while (nextToken !== null) {
          let InvDataSec = await API.graphql(
            graphqlOperation(listArItemMasters, {
              limit: 10000,
              nextToken: nextToken,
            }))
          nextToken = InvDataSec.data.listArItemMasters.nextToken;
          for (
            let i = 0;
            i < InvDataSec.data.listArItemMasters.items.length;
            i++
          ) {
            dataAll.push(InvDataSec.data.listArItemMasters.items[i]);
          }
        }
      }
    obj = searchValue
    dataAll.forEach(item => {
        let flag = true
        if (Object.keys(obj).length !== 0) {
            for (let name in obj) {
                if (item[name]) {
                    if (!(item[name]).includes(obj[name])) {
                        flag = false;
                        break;
                    }
                } else {
                    flag = false
                    break;
                }
            }
        }
        if (flag === true) {
            arr.push(item)
        }
    })
    return arr
}
async function listOrderData(setUserAuths) {
    let userAuths
    try {
        userAuths = await userAuth();
    } catch (error) {
        if (error.message === 'No current user') {
            //ユーザープールに障害が発生した場合
            console.log('User pool is invalid.Please reauthenticate.');
            //ログイン画面に戻る
            // signOut();
        } else {
            //他のエラーを処理する
            console.log('An error occurred:', error);
        }
    }
    setUserAuths(userAuths)
}
async function openDilogDetill(type, setdilogDetill, data,orderselect_row) {
    const procType = type
    ArItemMasterConst.DilogDetill = procType
    const dialogText = await getSelectTableData(type, data,orderselect_row)
    switch (procType) {
        case ArItemMasterConst.PROC_INQUIRY:
            ArItemMasterConst.DilogDetillDisabled = DilogDetillDisabled.delete
            dialogText1.dialogText = JSON.parse(JSON.stringify(dialogText))
            break;
        case ArItemMasterConst.PROC_ADD:
            ArItemMasterConst.DilogDetillDisabled = DilogDetillDisabled.add
            dialogText1.dialogText = JSON.parse(JSON.stringify(dialogText))
            break;
        case ArItemMasterConst.PROC_CADD:
            ArItemMasterConst.DilogDetillDisabled = DilogDetillDisabled.add
            dialogText1.dialogText = JSON.parse(JSON.stringify(dialogText))
            // 参照登録は品目一覧画面で選択した内容をそのまま表示する
            // dialogText1.dialogText.SAPCODE = ""
            // dialogText1.dialogText.GMID = ""
            // dialogText1.dialogText.ItemText = ""
            break;
        case ArItemMasterConst.PROC_REF:
            ArItemMasterConst.DilogDetillDisabled = DilogDetillDisabled.modify
            dialogText1.dialogText = JSON.parse(JSON.stringify(dialogText))
            break;
        case ArItemMasterConst.PROC_DEL:
            ArItemMasterConst.DilogDetillDisabled = DilogDetillDisabled.delete
            dialogText1.dialogText = JSON.parse(JSON.stringify(dialogText))
            break;
        default:
    }
    setdilogDetill(true)
}
async function getSelectTableData(type, data,orderselect_row) {
    let obj = {}
    if ((orderselect_row).length !== 0 && type !== ArItemMasterConst.PROC_ADD) {
        let item = await data.map((item) => {
            // if(item.id === orderselect_row[0]){
            //     console.log(item.id , orderselect_row[0])
            // }
            return item.id === orderselect_row[0] ? item : null
        }).filter(Boolean)
        obj = item[0]
    } else {
        obj = dialogText2
    }
    return obj
}
const signOut = async () => {
    await Auth.signOut();
    try {
        await Auth.signOut();
    } catch (error) {
        console.log("error signing out: ", error);
    }
    document.location.reload();
};
async function addData(data) {
  await API.graphql(graphqlOperation(createArItemMaster,{
        input: {
            SAPCODE: data.SAPCODE,
            GMID: data.GMID,
            ItemText: data.ItemText,
            ARBSuppAgencyCode: data.ARBSuppAgencyCode,
            SuppCode: data.SuppCode,
            SuppName: data.SuppName,
            ItemGrp: data.ItemGrp,
            ItemGrpName: data.ItemGrpName,
            InventoryTier: data.InventoryTier,
            OrderAdjustment: data.OrderAdjustment,
            Capacity: data.Capacity,
            CapacityUnit: data.CapacityUnit,
            BaseNumUnit: data.BaseNumUnit,
            OrderUnitpriceUnit: data.OrderUnitpriceUnit,
            UnitPerOrder: data.UnitPerOrder,
            StorageLoc: data.StorageLoc,
            ChilledGoods: data.ChilledGoods,
            USproduct: data.USproduct,
            Expiration: data.Expiration,
            CustomerMOQ: data.CustomerMOQ,
            CustomerLTguidance: data.CustomerLTguidance,
            PriceChangeFactor: data.PriceChangeFactor,
            StandardLeadTime: data.StandardLeadTime,
            LatestLT: data.LatestLT,
            QuotationReplyLT: data.QuotationReplyLT,
            StipulateRestdays: data.StipulateRestdays,
            FreeHoldingdays: data.FreeHoldingdays,
            HScode: data.HScode,
            ProductCountry: data.ProductCountry,
            MFGPlant: data.MFGPlant,
            Remark: data.Remark,
            StorageTemp: data.StorageTemp,
            ProductSizeWDH: data.ProductSizeWDH,
            OuterBoxSizeWDH: data.OuterBoxSizeWDH,
            DelFlag: data.DelFlag,
        }
    }));
}
async function modifyData(data,iSDelFlag) {
try{
    await queriesDataMethods(updateArItemMaster, data)
    if(iSDelFlag){
        let dataAll = [];
        const listArOrder = await API.graphql(graphqlOperation(queryArPriceInfosByQueryIDIndex, {
            QueryId: "P",
            limit: 10000,
            filter: {ItemCode:{ eq:data.SAPCODE },SuppName:{ eq:data.SuppName } }
        }));
        if (listArOrder.data.queryArPriceInfosByQueryIDIndex !== null) {
            dataAll = listArOrder.data.queryArPriceInfosByQueryIDIndex.items
            let nextToken = listArOrder.data.queryArPriceInfosByQueryIDIndex.nextToken;
            while (nextToken !== null) {
                let InvDataSec = await API.graphql(
                    graphqlOperation(queryArPriceInfosByQueryIDIndex, {
                        QueryId: "P",
                        limit: 10000,
                        nextToken: nextToken,
                        filter: {ItemCode: {eq: data.SAPCODE},SuppName:{ eq:data.SuppName }}
                    }))
                if (InvDataSec.data.queryArPriceInfosByQueryIDIndex !== null) {
                    nextToken = InvDataSec.data.queryArPriceInfosByQueryIDIndex.nextToken;
                    for (
                        let i = 0;
                        i < InvDataSec.data.queryArPriceInfosByQueryIDIndex.items.length;
                        i++
                    ) {
                        dataAll.push(InvDataSec.data.queryArPriceInfosByQueryIDIndex.items[i]);
                    }
                }
            }
        }
        for(let i = 0 ;i < dataAll.length;i++){
            await API.graphql(graphqlOperation(updateArPriceInfo, {
                input: {
                  id: dataAll[i].id,
                  ActualFlag : "9"
                }
              }))
        }
    }
}catch (error) {
        if (error.message === 'No current user') {
            //ユーザープールに障害が発生した場合
            console.log('User pool is invalid.Please reauthenticate.');
            //ログイン画面に戻る
            signOut();
        } else {
            //他のエラーを処理する
            console.log('An error occurred:', error);
        }
    }
}
async function deleteData(data) {
    let dataAll = [];
    // await API.graphql(graphqlOperation(deleteArItemMaster, {
    //     input: {
    //         id: data.id
    //     }
    // }));
    try{
    await API.graphql(graphqlOperation(updateArItemMaster, {
        input: {
            id:data.id,
            DelFlag:"1"
        }
    }));
}catch (error) {
    if (error.message === 'No current user') {
        //ユーザープールに障害が発生した場合
        console.log('User pool is invalid.Please reauthenticate.');
        //ログイン画面に戻る
        signOut();
    } else {
        //他のエラーを処理する
        console.log('An error occurred:', error);
    }
}
    const listArOrder = await API.graphql(graphqlOperation(queryArPriceInfosByQueryIDIndex, {
        QueryId: "P",
        limit: 10000,
        filter: {ItemCode:{ eq:data.SAPCODE },SuppName:{ eq:data.SuppName } }
    }));
    if (listArOrder.data.queryArPriceInfosByQueryIDIndex !== null) {
        dataAll = listArOrder.data.queryArPriceInfosByQueryIDIndex.items
        let nextToken = listArOrder.data.queryArPriceInfosByQueryIDIndex.nextToken;
        while (nextToken !== null) {
            let InvDataSec = await API.graphql(
                graphqlOperation(queryArPriceInfosByQueryIDIndex, {
                    QueryId: "P",
                    limit: 10000,
                    nextToken: nextToken,
                    filter: {ItemCode: {eq: data.SAPCODE},SuppName:{ eq:data.SuppName }}
                }))
            if (InvDataSec.data.queryArPriceInfosByQueryIDIndex !== null) {
                nextToken = InvDataSec.data.queryArPriceInfosByQueryIDIndex.nextToken;
                for (
                    let i = 0;
                    i < InvDataSec.data.queryArPriceInfosByQueryIDIndex.items.length;
                    i++
                ) {
                    dataAll.push(InvDataSec.data.queryArPriceInfosByQueryIDIndex.items[i]);
                }
            }
        }
    }
    for(let i = 0 ;i < dataAll.length;i++){
        await API.graphql(graphqlOperation(updateArPriceInfo, {
            input: {
              id: dataAll[i].id,
              ActualFlag : "2"
            }
          }))
    }
}
async function queriesDataMethods(ArItemMaster, data) {
    const results = await API.graphql(graphqlOperation(ArItemMaster, {
        input: {
            SAPCODE: data.SAPCODE,
            GMID: data.GMID,
            ItemText: data.ItemText,
            ARBSuppAgencyCode: data.ARBSuppAgencyCode,
            SuppCode: data.SuppCode,
            SuppName: data.SuppName,
            ItemGrp: data.ItemGrp,
            ItemGrpName: data.ItemGrpName,
            InventoryTier: data.InventoryTier,
            OrderAdjustment: data.OrderAdjustment,
            Capacity: data.Capacity,
            CapacityUnit: data.CapacityUnit,
            BaseNumUnit: data.BaseNumUnit,
            OrderUnitpriceUnit: data.OrderUnitpriceUnit,
            UnitPerOrder: data.UnitPerOrder,
            StorageLoc: data.StorageLoc,
            ChilledGoods: data.ChilledGoods,
            USproduct: data.USproduct,
            Expiration: data.Expiration,
            CustomerMOQ: data.CustomerMOQ,
            CustomerLTguidance: data.CustomerLTguidance,
            PriceChangeFactor: data.PriceChangeFactor,
            StandardLeadTime: data.StandardLeadTime,
            LatestLT: data.LatestLT,
            QuotationReplyLT: data.QuotationReplyLT,
            StipulateRestdays: data.StipulateRestdays,
            FreeHoldingdays: data.FreeHoldingdays,
            HScode: data.HScode,
            ProductCountry: data.ProductCountry,
            MFGPlant: data.MFGPlant,
            Remark: data.Remark,
            StorageTemp: data.StorageTemp,
            ProductSizeWDH: data.ProductSizeWDH,
            OuterBoxSizeWDH: data.OuterBoxSizeWDH,
            DelFlag: data.DelFlag,
            id:data.id
        }
    }));
    return results
}
async function inputCheck(dialogText) {
    let ItemMaster = [];

    let list = await API.graphql(graphqlOperation(queryArItemMastersBySAPCODEIndex, { SAPCODE: dialogText.SAPCODE}));
    // const list = await API.graphql(graphqlOperation(queryArItemMastersBySAPCODEIndex, {
    //     limit: 1,
    //     filter: { SAPCODE: { eq: dialogText.SAPCODE } }
    // }));
    if (list.data.queryArItemMastersBySAPCODEIndex.items && list.data.queryArItemMastersBySAPCODEIndex.items.length > 0) {
        ItemMaster = list.data.queryArItemMastersBySAPCODEIndex.items
        // let nextToken = list.data.listArItemMasters.nextToken;
        // while (nextToken !== null) {
        //     let InvDataSec = await API.graphql(
        //         graphqlOperation(listArItemMasters, {
        //             limit: 10000,
        //             nextToken: nextToken,
        //             filter: { SAPCODE: { eq: dialogText.SAPCODE } }
        //         }))
        //     if (InvDataSec.data.listArItemMasters !== null) {
        //         nextToken = InvDataSec.data.listArItemMasters.nextToken;
        //         for (
        //             let i = 0;
        //             i < InvDataSec.data.listArItemMasters.items.length;
        //             i++
        //         ) {
        //             ItemMaster.push(InvDataSec.data.listArItemMasters.items[i]);
        //         }
        //     }
        // }
    }
    return ItemMaster
}
async function inputCheck1(dialogText) {
    let ItemMaster = [];
    const list = await API.graphql(graphqlOperation(listArItemMasters, {
        limit: 10000,
        filter: {
            GMID: { eq: dialogText.GMID },
            ItemText: { eq: dialogText.ItemText },
            ItemGrp:{ eq: dialogText.ItemGrp }
        }
    }));

    if (list.data.listArItemMasters.items && list.data.listArItemMasters.items.length > 0) {
        ItemMaster = list.data.listArItemMasters.items
        let nextToken = list.data.listArItemMasters.nextToken;
        while (nextToken !== null) {
            let InvDataSec = await API.graphql(
                graphqlOperation(listArItemMasters, {
                    limit: 10000,
                    nextToken: nextToken,
                    filter: {
                        GMID: { eq: dialogText.GMID },
                        ItemText: { eq: dialogText.ItemText },
                    }
                }))
            if (InvDataSec.data.listArItemMasters !== null) {
                nextToken = InvDataSec.data.listArItemMasters.nextToken;
                for (
                    let i = 0;
                    i < InvDataSec.data.listArItemMasters.items.length;
                    i++
                ) {
                    ItemMaster.push(InvDataSec.data.listArItemMasters.items[i]);
                }
            }
        }
    }
    return ItemMaster
}
export {
    deleteData,
    modifyData,
    addData,
    openDilogDetill,
    listOrderData,
    searchData,
    checkedMasterlist,
    Columns,
    inputCheck,
    inputCheck1
}