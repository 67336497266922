/* ar-brown App (C) 2021 arbrown.co.jp All rights reserved.
 *******************************************************************************
 * Program ID:  Contact.js
 * $Revision:   Ver1.0
 * $Modtime:    $
 *******************************************************************************
 * Author      (作成／更新年月日、担当者)
 *  2021/08/01 Rayoo)li : 新規作成
 *
 * Summary Contact画面
 *
 * Instructions / Preconditions
 *  なし
 *
 *******************************************************************************
 */
import Meta from "../components/Meta";
/*コンテナ（画面レイアウト）デザイン*/
import { Container, Row, Col, InputGroup, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Amplify, { API, Auth, graphqlOperation } from "aws-amplify";
import awsconfig from "../aws-exports";
import React, { useEffect, useState } from "react";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { customerByeMail } from "../graphql/queries";
import moment from "moment";
Amplify.configure(awsconfig);

function Contact() {
  // page content
  const pageTitle = "AR BROWN Order System";
  // const postInquiry = async (body) => {
  //   const APIName = "amplifyFormAPI";
  //   const path = "/inquiry";
  //   const params = {
  //     body: body,
  //   };
  //   return await API.post(APIName, path, params);
  // };
  function simulateNetworkRequest() {
    return new Promise((resolve) => setTimeout(resolve, 2000));
  }

  const [isLoading, setLoading] = useState(false);
  const [AgencyEmail,setAgencyEmail] = useState("");
  const [AgencyInfo,setAgencyInfo] = useState(Object);

  useEffect(() => {
    if (isLoading) {
      simulateNetworkRequest().then(() => {
        setLoading(false);
      });
    }
    const getAgencyInfo = async ()=>{
      const user = await Auth.currentUserInfo();
      const agencyPriceGroup = await API.graphql(
        graphqlOperation(customerByeMail, {
          Agency_Email: user.attributes.email,
        })
      );
      const agentinfo = agencyPriceGroup.data.customerByeMail["items"];
      if (agentinfo.length > 0) {
         setAgencyEmail(agentinfo[0].Agency_Email);
         setAgencyInfo(agentinfo[0])
      }
    }
    getAgencyInfo();

  }, [isLoading]);

  const [msgId, setMsgId] = React.useState(Object);
  const handleSetMsgId = (Object) => {
    setMsgId(Object);
  };
  const [msgText, setMsgText] = React.useState(Object);
  const handleSetMsgText = (Object) => {
    setMsgText(Object);
  };
  const [msgBtn, setMsgBtn] = React.useState(Object);
  const handleSetMsgBtn = (Object) => {
    setMsgBtn(Object);
  };
  async function arSendClick() {
    let companyname = document.getElementById("companyname").value;
    let name = document.getElementById("name").value;
    let email = document.getElementById("email").value;
    let product = document.getElementById("product").value;
    let textTitle = document.getElementById("textTitle").value;
    let text = document.getElementById("text").value;

    if (
      name === "" ||
      email === "" ||
      product === "" ||
      textTitle === "" ||
      text === "" ||
      companyname === ""
    ) {
      handleSetMsgId("エラー");
      handleSetMsgText("全項目を入力してください");
      handleSetMsgBtn("OK");
      handleClickOpen();
      return;
    }
    let date = moment(new Date().getTime()).utcOffset(9).format("YYYY/MM/DD HH:mm")
    let messageInfo = name + "　様からお問い合わせです。<br><br>" +

    "■受付日-時刻：" + date + "<br>" +
    "--------------------------------" + "<br>" +
    "お問い合わせ内容" + "<br>" +
    "--------------------------------" + "<br>" +
    "■御社名：" + companyname + "<br>" +
    "■お名前(フリガナ)：" + AgencyInfo.FirstName + "<br>" +
    "■メールアドレス：" + email + "<br>" +
    "■お問い合わせ内容：" + textTitle + "<br>" +
    "■お問い合わせ内容詳細：" + "<br>" + text + "<br><br>" +
    "以上、よろしくお願いいたします。"

    const bodyContent = {
      templete: "contact",
      subject: "お問い合わせ_" + AgencyInfo.Agency_Name + "_" + AgencyInfo.Company_Name + "_" + product ,
      msgUrl: "",
      txtEnd: "",
      receiveUser: "",
      message:messageInfo
    };


    setLoading(true);
    try {
      // await postInquiry({
      //   companyname: companyname,
      //   name: name,
      //   email: email,
      //   product: product,
      //   textTitle: textTitle,
      //   text: text,
      // });
      const body = JSON.stringify(bodyContent);
      const requestOptions = {
        method: "POST",
        body
      };
      fetch(global.ArGlob.ContactEmaiEndPoint, requestOptions)
        .then((response) => {
          if (response.ok) {
            console.log("response",response)
           console.log("send email success")
          }
          setLoading(false)
          return response.json();
        })
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
           setLoading(false)
          console.log("失敗", error);
        });
      //window.alert('お問い合わせの送信が完了しました。')
      handleSetMsgId("送信確認");
      handleSetMsgText("お問い合わせの送信が完了しました。");
      handleSetMsgBtn("OK");
      handleClickOpen();
    } catch (e) {
      //	window.alert(e);
      handleSetMsgId("送信エラー");
      handleSetMsgText("お問い合わせの送信に失敗しました。");
      handleSetMsgBtn("OK");
    }
  }

  function arSetClick() {
    let companyname = document.getElementById("companyname");
    let name = document.getElementById("name");
    let email = document.getElementById("email");
    let product = document.getElementById("product");
    let textTitle = document.getElementById("textTitle");
    let text = document.getElementById("text");
    companyname.value = "";
    name.value = "";
    email.value = "";
    product.value = "";
    text.value = "";
    textTitle.value = "";
  }
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container
      style={{ backgroundColor: "", minWidth: "100%", minHeight: "74vh" }}
    >
      <div>
        <Meta title={pageTitle} />
      </div>
      <br></br>
      <Row>
        <Col>
          <InputGroup className="mb-3">
            <span style={{ width: "250px", textAlign: "left" }}>
              <InputGroup.Text id="basic-Username">
              御社名<span style={{ color: "red" }}>［必須］</span>
              </InputGroup.Text>
            </span>
            <FormControl
              placeholder="○○会社"
              aria-label="companyname"
              aria-describedby="basic-Username"
              id="companyname"
              maxLength="30"
              defaultValue={AgencyInfo.Agency_Name}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <span style={{ width: "250px", textAlign: "left" }}>
              <InputGroup.Text id="basic-Username">
                お名前<span style={{ color: "red" }}>［必須］</span>
              </InputGroup.Text>
            </span>
            <FormControl
              placeholder="○○さん"
              aria-label="Username"
              aria-describedby="basic-Username"
              id="name"
              maxLength="30"
              defaultValue={AgencyInfo.FirstName}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <span style={{ width: "250px", textAlign: "left" }}>
              <InputGroup.Text id="basic-email">
                メールアドレス<span style={{ color: "red" }}>［必須］</span>
              </InputGroup.Text>
            </span>
            <FormControl
              placeholder="○○@○○.com"
              aria-label="email"
              aria-describedby="basic-email"
              id="email"
              type="email"
              maxLength="40"
              defaultValue={AgencyEmail}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <span style={{ width: "250px", textAlign: "left" }}>
              <InputGroup.Text id="basic-product">
              型番<span style={{ color: "red" }}>［必須］</span>
              </InputGroup.Text>
            </span>
            <FormControl
              placeholder="型番"
              aria-label="product"
              aria-describedby="basic-product"
              id="product"
              maxLength="80"
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <span style={{ width: "250px", textAlign: "left" }}>
              <InputGroup.Text id="basic-textTitle">
              お問い合わせ内容<span style={{ color: "red" }}>［必須］</span>
              </InputGroup.Text>
            </span>
            <FormControl
              placeholder="お問い合わせ内容"
              aria-label="textTitle"
              aria-describedby="basic-textTitle"
              id="textTitle"
              maxLength="80"
            />
          </InputGroup>
          <InputGroup>
            <InputGroup.Text id="basic-text">
              お問い合わせ内容詳細
              <span style={{ width: "", textAlign: "left", color: "red" }}>
                ［必須］
              </span>
            </InputGroup.Text>
            <FormControl
              placeholder="お問い合わせ内容詳細"
              as="textarea"
              rows="6"
              aria-label="With textarea"
              id="text"
              maxLength="1000"
            ></FormControl>
          </InputGroup>
          <br></br>

          <Button
            className="btn btn-danger "
            style={{ marginRight: "1rem" }}
            data-toggle="modal"
            disabled={isLoading}
            onClick={!isLoading ? arSendClick : null}
          >
            {isLoading ? "送信中...." : "お問合わせ"}
          </Button>
          <Button
            className="btn btn-dark "
            data-toggle="modal"
            onClick={arSetClick}
          >
            リセット
          </Button>
        </Col>
      </Row>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{msgId}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {msgText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            {msgBtn}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Contact;
